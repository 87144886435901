/* eslint-disable */
import React, { Component } from "react";
import ProductsForm from "./ProductsForm";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getVarcodeProduct } from "../../../actions/Products";
import UpdateProduct from "./UpdateProduct";

export class AddProduct extends Component {
  static propTypes = {
    products: PropTypes.array,
    getVarcodeProduct: PropTypes.func.isRequired,
  };

  state = {
    nameInput: "",
    result: false,
    codigoB: "",
    varcode: "",
  };

  state2 = {
    varcode: "",
  };

  componentDidMount() {
    //para que cuando ingrese al formulario el foco este en la caja de texto
    this.nameInput.focus();
  }

  handleChange = (e) => {
    console.log({ value: e.target.value });
  };

  // eslint-disable-next-line
  onChange = (e) => this.setState({ [e.target.nameInput]: e.target.value });

  onSubmit = (e) => {
    e.preventDefault();
    //console.log("Ingresado");
    document.getElementById("barcode").disabled = false;
    this.nameInput.value = "";
    this.componentDidMount();

    this.setState({ result: false, products: [] });
  };

  onEnterPress = (e) => {
    //cuando el lecto termina de leer da un salto de linea
    //el shift no debe de estar precionado por que saldrian otros caracteres

    if (e.keyCode === 13 /*&& e.shiftKey === false*/) {
      e.preventDefault();
      document.getElementById("barcode").disabled = true;
      this.setState({ result: true, codigoB: this.nameInput.value });
      let varcode = this.nameInput.value.toLowerCase();
      this.setState({ varcode });
      this.props.getVarcodeProduct(varcode);
    }
  };

  render() {
    var result = this.state.result;
    var nameInp = this.state.codigoB;
    const obj = this.props.products;

    function Verify() {
      var id;
      var nombre;
      var cantidadProduc;
      var precioCompra;
      var precioVenta;
      var descripcion;
      var idcategoria;
      var nombreCategoria;
      var idMarca;
      var nombreMarca;

      if (nameInp !== "") {
        obj.map((pro) => {
          // eslint-disable-next-line
          return (
            (id = pro.id_product),
            (nombre = pro.name),
            (cantidadProduc = pro.stock),
            (precioCompra = pro.purchase_price),
            (precioVenta = pro.sale_price),
            (descripcion = pro.description),
            (idcategoria = pro.category.id),
            (nombreCategoria = pro.category.name),
            (idMarca = pro.brand.id),
            (nombreMarca = pro.brand.name)
          );
        });
      }
      if (result && nameInp !== "" && id === undefined) {
        //console.log(id)
        return <ProductsForm varcode={nameInp} />;
      } else if (result && nameInp !== "" && id !== undefined) {
        //console.log("el producto ya existe");
        //console.log(id)
        return (
          <UpdateProduct
            id_product={id}
            name={nombre}
            varcode={nameInp}
            stock={cantidadProduc}
            purchase_price={precioCompra}
            sale_price={precioVenta}
            description={descripcion}
            idCategory={idcategoria}
            nameCategory={nombreCategoria}
            idbrand={idMarca}
            nameBrand={nombreMarca}
          />
        );
      }

      return "";
    }

    return (
      <React.Fragment>
        <div className="row pt-5">
          <div className="col text-center text-uppercase">
            <h4 className="text-center text-primary">
              Ingreso de Productos Con Codigo de Barras
            </h4>
          </div>
        </div>

        <div className="card card-body mt-4 mb-4">
          <div className="row">
            <div className="col-12">
              <div className="alert alert-danger" role="alert">
                ADVERTENCIA! Una vez ingresado los productos solo el
                administrados podrá modificar la cantidad agregada, tenga en
                cuento esto antes de agregar un nuevo producto o mas cantidad al
                stock de un producto ya existente.
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 ">
              <h5 className="text-success">Codigo de Barras</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-9 col-sm-12 col-md-8">
              <div ref={(frm) => (this.myform = frm)}>
                <input
                  className="form-control text-light bg-dark"
                  type="text"
                  name="barcode-text"
                  id="barcode"
                  onKeyDown={this.onEnterPress}
                  row="1"
                  ref={(input) => {
                    this.nameInput = input;
                  }}
                />
              </div>
            </div>

            <div className="col-lg-3 col-sm-12 col-md-4">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={this.onSubmit}
              >
                Nueva Lectura
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Verify />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.FindProduct.products,
});

export default connect(mapStateToProps, { getVarcodeProduct })(AddProduct);

/* eslint-enable */
