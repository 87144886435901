import React, { Component } from "react";
import "./styles/PersonalInfo.css";
import Form from "../../components/Forms/PersonalInfo";
class PersonalInfo extends Component {
  render() {
    return (
      <div className="personal-info-container">
        <div className="row">
          <div className="col text-center">
            {/*<img
                src="https://icon-library.net/images/boy-icon-png/boy-icon-png-10.jpg"
                alt="avatar"
              />*/}
          </div>
        </div>
        
        <Form />
      </div>
    );
  }
}

export default PersonalInfo;
