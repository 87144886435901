import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import HistoryGraph from "../../assets/icons/history.png";
import { getHistory } from "../../actions/ActApointments";
import { getClinicFile } from "../../actions/ActClinicfile";
import Loading from "./Loading";

class ClinicAppointmentHistory extends Component {
  state = {};
  static propTypes = {
    getClinicFile: PropTypes.func.isRequired,
    apointments: PropTypes.array.isRequired,
    getHistory: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    file: PropTypes.array,
  };

  componentDidMount() {
    this.props.getHistory();
  }

  render() {
    if (this.props.loading) {
      return <Loading></Loading>;
    }
    const inLineStyles = this.props.file
      ? {
          alignItems: "center",
          paddingTop: "2rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }
      : {
          display: "none",
        };
    const apointments = this.props.apointments;
    var parsedDict = { dataArray: [] };
    for (var {
      id,
      date,
      time,
      cliente: { first_name: clientName },
      cliente: { username: clientEmail },
      cliente: { id: clientId },
      pet: { name: petName },
      doctor: { first_name: attendantName },
    } of apointments) {
      let parsedApointment = {
        apointmentId: id,
        clientId,
        apointmentDate: date,
        apointmentTime: time,
        clientName,
        pacientName: petName,
        whoAttends: attendantName,
        email: clientEmail,
      };
      /*console.log(parsedApointment);*/
      parsedDict.dataArray.push(parsedApointment);
    }

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className="row"
                style={{
                  /*height: "6rem",*/
                  color: "black",
                  backgroundColor: "#dfe6e9",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2em",
                  marginBottom: "2em",
                }}
              >
                <div className="col-xl-4 col-lg-4 col-md-8 col-sm-8">
                  <div className="col text-center">
                    <p>Historial de citas atendidas</p>
                    <img
                      src={HistoryGraph}
                      alt=""
                      style={{ maxWidth: "8rem" }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  /*height: "30%",*/
                  alignItems: "center",
                  paddingTop: "2rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 h-100">
                  <div
                    className="row h-100"
                    style={{ justifyContent: "center", padding: "0.1rem" }}
                  >
                    <div className="col-12">
                      <p style={{ alignSelf: "center" }}>
                        Has atendido un total de
                        <strong> {this.props.apointments.length}</strong> citas
                      </p>
                      <div style={{ flexBasis: "100%" }}>
                        <MaterialTable
                          localization={{
                            toolbar: {
                              searchTooltip: "Buscar",
                              searchPlaceholder: "Cliente, fecha",
                            },
                            body: {
                              emptyDataSourceMessage:
                                "No hay citas, dia tranquilo al parecer",
                            },
                            header: {
                              actions: "Mas",
                            },
                          }}
                          options={{
                            pageSize: 6,
                            headerStyle: {
                              backgroundColor: "#222f3e",
                              color: "white",
                              paddingLeft: "20px",
                            },
                            exportButton: false,
                            actionsColumnIndex: -1,
                            detailPanelColumnAlignment: "right",
                          }}
                          columns={[
                            {
                              title: "Username",
                              field: "email",
                            },
                            {
                              title: "Cliente",
                              field: "clientName",
                            },
                            {
                              title: "Paciente",
                              field: "pacientName",
                            },
                            {
                              title: "Fecha",
                              field: "apointmentDate",
                            },
                            /* */
                          ]}
                          data={parsedDict.dataArray}
                          title="Historial"
                          /*actions={[
                            {
                              icon: "add",
                              tooltip: "Mostrar información",
                              onClick: (event, rowData) => {
                                event.preventDefault();
                                
                                this.props.getClinicFile(rowData.apointmentId);
                              }
                            }
                          ]}*/
                          onRowClick={(event, rowData) => {
                            event.preventDefault();
                            this.props.getClinicFile(rowData.apointmentId);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                  style={inLineStyles}
                >
                  <strong className="text-primary text-center">
                    <h3>Detalles de la cita</h3>
                  </strong>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          {this.props.file ? (
                            <table class="table">
                              <thead>
                                <tr className="bg-primary">
                                  <th scope="col">Nombre</th>
                                  <th scope="col">Descripción</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.file.anamnesis ? (
                                  <tr>
                                    <td>Anamnesis</td>
                                    <td>{this.props.file.anamnesis}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.alimentacion ? (
                                  <tr>
                                    <td>Alimentacion</td>
                                    <td>{this.props.file.alimentacion}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.md ? (
                                  <tr>
                                    <td>Medio ambiente</td>
                                    <td>{this.props.file.md}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.mp ? (
                                  <tr>
                                    <td>Medicamentos previos</td>
                                    <td>{this.props.file.mp}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.vacunado ? (
                                  <tr>
                                    <td>Vacunado</td>
                                    <td>{this.props.file.vacunado}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.desparacitado ? (
                                  <tr>
                                    <td>Desparacitado</td>
                                    <td>{this.props.file.desparacitado}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.temperatura ? (
                                  <tr>
                                    <td>Temperatura</td>
                                    <td>{this.props.file.temperatura}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.fc1 ? (
                                  <tr>
                                    <td>Frecuencia cardiaca</td>
                                    <td>{this.props.file.fc1}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.fc2 ? (
                                  <tr>
                                    <td>Frecuencia cardiaca</td>
                                    <td>{this.props.file.fc2}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.presionArterial ? (
                                  <tr>
                                    <td className="text-danger">
                                      Presión arterial
                                    </td>
                                    <td>{this.props.file.presionArterial}</td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td className="text-danger">
                                      Presión arterial
                                    </td>
                                    <td>{this.props.file.presionArterial}</td>
                                  </tr>
                                )}
                                {this.props.file.fr ? (
                                  <tr>
                                    <td>Frecuencia respiratoria</td>
                                    <td>{this.props.file.fr}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.peso ? (
                                  <tr>
                                    <td>Peso</td>
                                    <td>{this.props.file.peso}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.condicionc ? (
                                  <tr>
                                    <td>Condicion corporal</td>
                                    <td>{this.props.file.condicionc}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.piel ? (
                                  <tr>
                                    <td>Piel</td>
                                    <td>{this.props.file.piel}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.ojos ? (
                                  <tr>
                                    <td>Ojos</td>
                                    <td>{this.props.file.ojos}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.oidos ? (
                                  <tr>
                                    <td>Oidos</td>
                                    <td>{this.props.file.oidos}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.nariz ? (
                                  <tr>
                                    <td>Nariz</td>
                                    <td>{this.props.file.nariz}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.boca ? (
                                  <tr>
                                    <td>Boca</td>
                                    <td>{this.props.file.boca}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.cardio ? (
                                  <tr>
                                    <td>Cardio</td>
                                    <td>{this.props.file.cardio}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.respiratorio ? (
                                  <tr>
                                    <td>Respiratorio</td>
                                    <td>{this.props.file.respiratorio}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.abdomen ? (
                                  <tr>
                                    <td>Abdomen</td>
                                    <td>{this.props.file.abdomen}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.gastro ? (
                                  <tr>
                                    <td>Gastro</td>
                                    <td>{this.props.file.gastro}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.urogenital ? (
                                  <tr>
                                    <td>Urogenital</td>
                                    <td>{this.props.file.urogenital}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.linfaticos ? (
                                  <tr>
                                    <td>Linfaticos</td>
                                    <td>{this.props.file.linfaticos}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.musculos ? (
                                  <tr>
                                    <td>Musculos</td>
                                    <td>{this.props.file.musculos}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.neuro ? (
                                  <tr>
                                    <td>Neuro</td>
                                    <td>{this.props.file.neuro}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.ad ? (
                                  <tr>
                                    <td>Ayuda diagnostica</td>
                                    <td>{this.props.file.ad}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.diagnostico ? (
                                  <tr>
                                    <td>Diagnostico</td>
                                    <td>{this.props.file.diagnostico}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.tratamiento ? (
                                  <tr>
                                    <td>Tratamiento</td>
                                    <td>{this.props.file.tratamiento}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                {this.props.file.observaciones ? (
                                  <tr>
                                    <td>Observaciones</td>
                                    <td>{this.props.file.observaciones}</td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                              </tbody>
                            </table>
                          ) : (
                            <ul>
                              <li>Nada fuera de lo común</li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ flexDirection: "column", paddingBottom: "2rem" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  apointments: state.RedApoint.apointments,
  loading: state.RedApoint.loading,
  user: state.RedAuth.user,
  file: state.History.file,
});

export default connect(mapStateToProps, { getHistory, getClinicFile })(
  ClinicAppointmentHistory
);
