import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import HistoryGraph from "../../assets/icons/history.png";
import Loading from "./Loading";
import { getHistory } from "../../actions/ActApointments";
import { getGroomFile, updatePictures } from "../../actions/ActGroomfile";
import { archiveAppointment } from "../../actions/ActApointments";
import { Redirect } from "react-router-dom";
import { cleanFile } from "../../actions/ActClinicfile";
import AppointmentDetailsCard from "./cards/AppointmentDetailsCard";
class History extends Component {
  state = {
    selectedPetTodeliver: null,
    petDelivered: null,
  };
  static propTypes = {
    archiveAppointment: PropTypes.func.isRequired,
    updatePictures: PropTypes.func.isRequired,
    getGroomFile: PropTypes.func.isRequired,
    apointments: PropTypes.array.isRequired,
    getHistory: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    file: PropTypes.array,
  };

  componentDidMount() {
    this.props.cleanFile();
    this.props.getHistory();
  }
  render() {
    if (this.state.petDelivered) {
      return <Redirect to="/dashboard"></Redirect>;
    }
    const inLineStyles = this.props.file
      ? {
          alignItems: "center",
          paddingTop: "2rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }
      : {
          display: "none",
        };
    const apointments = this.props.apointments;
    var parsedDict = { dataArray: [] };
    for (var {
      id,
      date,
      time,
      status,
      cliente: { first_name: clientName },
      cliente: { username: clientEmail },
      cliente: { id: clientId },
      pet: { name: petName },
      doctor: { first_name: attendantName },
    } of apointments) {
      let parsedApointment = {
        apointmentId: id,
        clientId,
        apointmentDate: date,
        apointmentTime: time,
        clientName,
        pacientName: petName,
        appointmentType: status === "COMPLETADA" ? "Consulta" : "Grooming",
        whoAttends: attendantName,
        email: clientEmail,
      };
      /*console.log(parsedApointment);*/
      parsedDict.dataArray.push(parsedApointment);
    }
    return (
      <Fragment>
        {this.props.loading ? (
          <React.Fragment>
            {" "}
            <Loading></Loading>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className="row"
                style={{
                  height: "6rem",
                  color: "black",
                  backgroundColor: "#dfe6e9",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2em",
                  marginBottom: "2em",
                }}
              >
                <div className="col-xl-4 col-lg-4 col-md-8 col-sm-8">
                  <div className="col text-center">
                    <h4>Historial de citas atendidas</h4>
                    <img
                      src={HistoryGraph}
                      alt=""
                      style={{ maxWidth: "8rem" }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  alignItems: "center",
                  paddingTop: "2rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 h-100">
                  <div
                    className="row h-100"
                    style={{
                      justifyContent: "center",
                      padding: "0.5rem",
                    }}
                  >
                    <p style={{ alignSelf: "center" }}>
                      Has atendido un todal de
                      <strong> {this.props.apointments.length}</strong> citas
                    </p>
                    <div style={{ flexBasis: "100%" }}>
                      <MaterialTable
                        localization={{
                          toolbar: {
                            searchTooltip: "Buscar",
                            searchPlaceholder: "Cliente, fecha",
                          },
                          body: {
                            emptyDataSourceMessage:
                              "No hay citas, dia tranquilo al parecer",
                          },
                          header: {
                            actions: "Mas",
                          },
                        }}
                        options={{
                          pageSize: 6,
                          headerStyle: {
                            backgroundColor: "#222f3e",
                            color: "white",
                            paddingLeft: "20px",
                          },
                          exportButton: false,
                          actionsColumnIndex: -1,
                          detailPanelColumnAlignment: "right",
                        }}
                        columns={[
                          {
                            title: "Cliente",
                            field: "clientName",
                          },
                          {
                            title: "Paciente",
                            field: "pacientName",
                          },
                          {
                            title: "Tipo de cita",
                            field: "appointmentType",
                          },
                          {
                            title: "Fecha",
                            field: "apointmentDate",
                          },
                        ]}
                        data={parsedDict.dataArray}
                        title="Historial"
                        actions={[
                          {
                            icon: "add",
                            tooltip: "Mostrar información",
                            onClick: (event, rowData) => {
                              event.preventDefault();
                              this.props.getGroomFile(rowData.apointmentId);
                            },
                          },
                        ]}
                        onRowClick={(event, rowData) => {
                          event.preventDefault();
                          this.props.getGroomFile(rowData.apointmentId);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                  style={inLineStyles}
                >
                  <strong className="text-primary text-center">
                    <h3>Resumen de la cita</h3>
                  </strong>
                  <div className="row">
                        <div className="col-12 pl-4 mt-2">
                          <AppointmentDetailsCard
                            data={this.props.file}
                          ></AppointmentDetailsCard>
                        </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      flexDirection: "column",
                      paddingBottom: "5rem",
                      height: "5rem",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  apointments: state.RedApoint.apointments,
  loading: state.RedApoint.loading,
  user: state.RedAuth.user,
  file: state.History.file,
});

export default connect(mapStateToProps, {
  getHistory,
  getGroomFile,
  updatePictures,
  archiveAppointment,
  cleanFile,
})(History);
