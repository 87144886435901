import React, { Component } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getCommisionsBasicRank,
  LoadedCommissionRank,
  getTotalCommissionRank,
  getCommisionsSurgeryRank,
  getTotalSurgeryCommissionRank,
  getCommisionsOtherRank,
  getTotalOtherCommissionRank,
  getCommisionsPharmacyRank,
  getTotalPharmacyCommissionRank,
  updateStateComission,
  getDeativateCommissionRank
} from "../../../actions/Commissions";
import MaterialTable from "material-table";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import MoodIcon from "@material-ui/icons/Mood";
import FormHelperText from "@material-ui/core/FormHelperText";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../../assets/logo.png";
import Cachorro from "../../../assets/cachorro.png";
import Operacion from "../../../assets/operacion.png";
import Pildora from "../../../assets/pildora.png";
import Hospital from "../../../assets/hospital.png";
export class CommissionRank extends Component {
  static propTypes = {
    commisionsBasicRank: PropTypes.array.isRequired,
    loadingBasicRank: PropTypes.bool,
    getCommisionsBasicRank: PropTypes.func.isRequired,

    totalBasicRank: PropTypes.object.isRequired,
    getTotalCommissionRank: PropTypes.func.isRequired,
    loadingTotalBasic: PropTypes.bool,

    commissionSurgeryRank: PropTypes.array.isRequired,
    getCommisionsSurgeryRank: PropTypes.func.isRequired,
    loadingSurgeryRank: PropTypes.bool,

    totalSurgeryRank: PropTypes.object.isRequired,
    getTotalSurgeryCommissionRank: PropTypes.func.isRequired,
    loadingTotalSurgery: PropTypes.bool,

    commissionOtherRank: PropTypes.array.isRequired,
    getCommisionsOtherRank: PropTypes.func.isRequired,
    loadingOtherRank: PropTypes.bool,

    totalOtherRank: PropTypes.object.isRequired,
    getTotalOtherCommissionRank: PropTypes.func.isRequired,
    loadingTotalOther: PropTypes.bool,

    LoadedCommissionRank: PropTypes.func.isRequired,

    getCommisionsPharmacyRank: PropTypes.func.isRequired,
    getTotalPharmacyCommissionRank: PropTypes.func.isRequired,
    commissionPharRank: PropTypes.array.isRequired,
    totalPharmRank: PropTypes.object.isRequired,
    loadingPharmRank: PropTypes.bool,
    loadingtotalPharmRank: PropTypes.bool,

    updateStateComission: PropTypes.func.isRequired,
    getDeativateCommissionRank: PropTypes.func.isRequired,

    deactivateComRank: PropTypes.array.isRequired,
    loadingDeactivateRank: PropTypes.bool
  };

  state = {
    selectedDateInitial: null,
    selectedDateEnd: null,
    inicio: null,
    final: null,
    cargado: false,
    inicioError: "",
    finalError: "",
    rangoError: "",
    columnasTotales: [
      { title: "Nombre", field: "name" },
      {
        title: "Total de Comisiones",
        field: "total_commission",
        type: "numeric"
      }
    ],
    datosTotales: [],
    columnsBasico: [
      { title: "Fecha", field: "date" },
      { title: "Nombre", field: "namecommission" },
      { title: "Precio", field: "precio", type: "numeric" },
      {
        title: "Porcentaje de Comsion",
        field: "commission_percentage",
        type: "numeric"
      },
      { title: "Tamaño(si aplica)", field: "size" },
      { title: "Descripcion", field: "description" },
      {
        title: "Total de la comision",
        field: "total_commission",
        type: "numeric"
      }
    ],
    datosBasico: [],
    columnsSurgery: [
      { title: "Fecha", field: "date" },
      { title: "Nombre", field: "namecommission" },
      { title: "Precio", field: "precio", type: "numeric" },
      {
        title: "Porcentaje de Comsion",
        field: "commission_percentage",
        type: "numeric"
      },
      { title: "Anestesia", field: "type_of_anesthesia" },
      {
        title: "Precio Anestesia",
        field: "total_anesthesia",
        type: "numeric"
      },
      {
        title: "Comision Anestesia",
        field: "commission_percentage_anesthesia",
        type: "numeric"
      },
      {
        title: "Total de la comision",
        field: "total_commission",
        type: "numeric"
      },
      { title: "Tamaño(si aplica)", field: "size" },
      { title: "Descripcion", field: "description" }
    ],
    datosSurgery: [],
    columnsOther: [
      { title: "Fecha", field: "date" },
      { title: "Descripcion", field: "description" },
      { title: "Precio", field: "precio", type: "numeric" },
      {
        title: "Porcentaje de Comsion",
        field: "commission_percentage",
        type: "numeric"
      },
      { title: "Anestesia", field: "type_of_anesthesia" },
      {
        title: "Precio Anestesia",
        field: "total_anesthesia",
        type: "numeric"
      },
      {
        title: "Comision Anestesia",
        field: "commission_percentage_anesthesia",
        type: "numeric"
      },
      {
        title: "Total de la comision",
        field: "total_commission",
        type: "numeric"
      }
    ],
    datosOther: [],
    TotalAll: 0,
    columnsPharmacy: [
      { title: "Fecha", field: "date" },
      { title: "Descripcion", field: "description" },
      { title: "Precio", field: "precio", type: "numeric" },
      { title: "Cantidad", field: "amount", type: "numeric" },
      { title: "Total Venta", field: "total_sale", type: "numeric" },
      {
        title: "Porcentaje de Comsion",
        field: "commission_percentage",
        type: "numeric"
      },
      {
        title: "Total de la comision",
        field: "total_commission",
        type: "numeric"
      }
    ],
    datosPharmacy: [],
    datosdesact: []
  };

  handleDateChangeInitial = e => {
    const date = moment(e).format("YYYY-MM-DD");
    //console.log(date);
    this.setState({ selectedDateInitial: e, inicio: date });
  };

  handleDateChangeEnd = e => {
    const date = moment(e).format("YYYY-MM-DD");
    //console.log(date);
    this.setState({ selectedDateEnd: e, final: date });
  };

  validate = () => {
    let iserror = false;
    const errors = {
      inicioError: "",
      finalError: "",
      rangoError: ""
    };
    if (this.state.inicio === null) {
      iserror = true;
      errors.inicioError = "Debe seleccionar una Fecha de Inicio";
    }
    if (this.state.final === null) {
      iserror = true;
      errors.finalError = "Debe seleccionar una Fecha final";
    }
    if (this.state.inicio > this.state.final) {
      iserror = true;
      errors.rangoError = "El Rango de las Fechas esta al revés verifique";
    }
    this.setState({
      ...errors
    });
    return iserror;
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({
      datosTotales: [],
      datosBasico: [],
      datosSurgery: [],
      datosOther: []
    });
    const err = this.validate();
    if (!err) {
      this.props.getCommisionsBasicRank(
        this.props.doctor,
        this.state.inicio,
        this.state.final
      );
      this.props.getTotalCommissionRank(
        this.props.doctor,
        this.state.inicio,
        this.state.final
      );
      this.props.getCommisionsSurgeryRank(
        this.props.doctor,
        this.state.inicio,
        this.state.final
      );
      this.props.getTotalSurgeryCommissionRank(
        this.props.doctor,
        this.state.inicio,
        this.state.final
      );
      this.props.getCommisionsOtherRank(
        this.props.doctor,
        this.state.inicio,
        this.state.final
      );
      this.props.getTotalOtherCommissionRank(
        this.props.doctor,
        this.state.inicio,
        this.state.final
      );
      this.props.getCommisionsPharmacyRank(
        this.props.doctor,
        this.state.inicio,
        this.state.final
      );
      this.props.getTotalPharmacyCommissionRank(
        this.props.doctor,
        this.state.inicio,
        this.state.final
      );
      this.props.getDeativateCommissionRank(
        this.props.doctor,
        this.state.inicio,
        this.state.final
      );
    }
  };

  calculoTotal() {
    var cant1 = 0;
    var cant2 = 0;
    var cant3 = 0;
    var cant4 = 0;
    var total = 0;

    if (this.props.totalBasicRank.total_commission__sum !== null) {
      cant1 = parseFloat(this.props.totalBasicRank.total_commission__sum);
    }
    if (this.props.totalSurgeryRank.total_commission__sum !== null) {
      cant2 = parseFloat(this.props.totalSurgeryRank.total_commission__sum);
    }
    if (this.props.totalOtherRank.total_commission__sum !== null) {
      cant3 = parseFloat(this.props.totalOtherRank.total_commission__sum);
    }
    if (this.props.totalPharmRank.total_commission__sum !== null) {
      cant4 = parseFloat(this.props.totalPharmRank.total_commission__sum);
    }
    total = cant1 + cant2 + cant3 + cant4;
    return total;
  }

  componentDidUpdate() {
    if (
      this.props.loadingBasicRank &&
      this.props.loadingTotalBasic &&
      this.props.loadingSurgeryRank &&
      this.props.loadingTotalSurgery &&
      this.props.loadingOtherRank &&
      this.props.loadingTotalOther &&
      this.props.loadingPharmRank &&
      this.props.loadingtotalPharmRank &&
      this.props.loadingDeactivateRank
    ) {
      const serviciosBasico = this.props.commisionsBasicRank.map(basico => ({
        id: basico.id,
        namecommission: basico.namecommission,
        precio: basico.precio,
        commission_percentage: basico.commission_percentage,
        size: basico.size,
        description: basico.description,
        total_commission: basico.total_commission,
        date: moment(basico.date).format("DD/MM/YYYY")
      }));

      const serviciosCirugia = this.props.commissionSurgeryRank.map(
        cirugia => ({
          id: cirugia.id,
          namecommission: cirugia.namecommission,
          precio: cirugia.precio,
          commission_percentage: cirugia.commission_percentage,
          size: cirugia.size,
          description: cirugia.description,
          total_commission: cirugia.total_commission,
          type_of_anesthesia: cirugia.type_of_anesthesia,
          total_anesthesia: cirugia.total_anesthesia,
          commission_percentage_anesthesia:
            cirugia.commission_percentage_anesthesia,
          date: moment(cirugia.date).format("DD/MM/YYYY")
        })
      );

      const otrosServicios = this.props.commissionOtherRank.map(otros => ({
        id: otros.id,
        namecommission: otros.namecommission,
        precio: otros.precio,
        commission_percentage: otros.commission_percentage,
        description: otros.description,
        total_commission: otros.total_commission,
        type_of_anesthesia: otros.type_of_anesthesia,
        total_anesthesia: otros.total_anesthesia,
        commission_percentage_anesthesia:
          otros.commission_percentage_anesthesia,
        date: moment(otros.date).format("DD/MM/YYYY")
      }));
      const comFarmacia = this.props.commissionPharRank.map(farm => ({
        id: farm.id,
        namecommission: farm.namecommission,
        precio: farm.precio,
        commission_percentage: farm.commission_percentage,
        description: farm.description,
        total_commission: farm.total_commission,
        amount: farm.amount,
        date: moment(farm.date).format("DD/MM/YYYY"),
        total_sale: parseFloat(farm.precio) * parseFloat(farm.amount)
      }));

      const desactivadas = this.props.deactivateComRank.map(deact => ({
        id: deact.id,
        namecommission: deact.namecommission,
        precio: deact.precio,
        commission_percentage: deact.commission_percentage,
        size: deact.size,
        description: deact.description,
        total_commission: deact.total_commission,
        type_of_anesthesia: deact.type_of_anesthesia,
        total_anesthesia: deact.total_anesthesia,
        commission_percentage_anesthesia:
          deact.commission_percentage_anesthesia,
        date: moment(deact.date).format("DD/MM/YYYY")
      }));

      const totalBasico = {
        name: "Total de servicios Basicos",
        total_commission: this.props.totalBasicRank.total_commission__sum
      };
      const totalSurgery = {
        name: "Total de Cirugias",
        total_commission: this.props.totalSurgeryRank.total_commission__sum
      };
      const totalotros = {
        name: "Total de Otros Servicios",
        total_commission: this.props.totalOtherRank.total_commission__sum
      };
      const totalFarm = {
        name: "Total de Farmacia",
        total_commission: this.props.totalPharmRank.total_commission__sum
      };

      const total = this.calculoTotal();

      this.state.datosTotales.push(totalBasico);
      this.state.datosTotales.push(totalSurgery);
      this.state.datosTotales.push(totalotros);
      this.state.datosTotales.push(totalFarm);
      this.setState({
        datosBasico: serviciosBasico,
        datosSurgery: serviciosCirugia,
        datosOther: otrosServicios,
        datosPharmacy: comFarmacia,
        datosdesact: desactivadas,
        TotalAll: total,
        cargado: true
      });
      this.props.LoadedCommissionRank();
    }
  }

  GenerarPDF = e => {
    e.preventDefault();
    var pdf = new jsPDF("l");
    pdf.setFontSize(14);
    pdf.setFontStyle("Arial");
    pdf.text("NOMBRE: " + this.props.namedoc, 14, 45);
    pdf.text("RANGO: " +moment(this.state.inicio).format("DD/MM/YYYY")+ "-" +moment(this.state.final).format("DD/MM/YYYY"), 14, 50);
    pdf.text("COMISIONES", 133, 30);
    pdf.setFontSize(10);
    var logo = new Image();
    logo.src = Logo;
    pdf.addImage(logo, "JPEG", 14, 0, 35, 35);
    var columnsT = ["Nombre", "Total de comisiones"];
    var filasT = [];
    for (let i = 0; i < this.state.datosTotales.length; i++) {
      let fila = [
        this.state.datosTotales[i].name,
        "Q" +  this.state.datosTotales[i].total_commission
      ];
      filasT.push(fila);
    }
    pdf.autoTable(columnsT, filasT, {
      margin: { top: 58 },
      pageBreak: "avoid",
      headStyles: { fillColor: [30, 132, 73] },
      theme: "grid",
      styles: { overflow: "ellipsize", cellWidth: "wrap" },
    });

    pdf.text(
      "TOTAL GANADO: Q" + this.state.TotalAll,
      14,
      pdf.autoTable.previous.finalY + 10
    );
    pdf.addPage();
    var columnsB = [
      "Fecha",
      "Nombre",
      "Precio",
      "Porcentaje de Comision",
      "Tamaño(si aplica)",
      "Descripcion",
      "Total de la comision"
    ];
    var filasB = [];
    for (let i = 0; i < this.state.datosBasico.length; i++) {
      let fila = [
        this.state.datosBasico[i].date,
        this.state.datosBasico[i].namecommission,
        this.state.datosBasico[i].precio,
        this.state.datosBasico[i].commission_percentage + "%",
        this.state.datosBasico[i].size,
        this.state.datosBasico[i].description,
      "Q" + this.state.datosBasico[i].total_commission
      ];
      filasB.push(fila);
    }
    pdf.autoTable(columnsB, filasB, {
      pageBreak: "avoid",
      headStyles: { fillColor: [52, 152, 219] },
      theme: "grid",
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle("Arial");
        var cachorro = new Image();
        cachorro.src = Cachorro;
        if (cachorro) {
          pdf.addImage(cachorro, "PNG", data.settings.margin.left, 15, 10, 10);
        }
        pdf.text("SERVICIOS BASICOS", data.settings.margin.left + 15, 22);
      },
      margin: { top: 30 }
    });

    pdf.addPage();

    var columnsS = [
      "Fecha",
      "Nombre",
      "Precio",
      "Porcentaje de Comision",
      "Anestesia",
      "Precio Anestesia",
      "Comission Anestecia",
      "Tamaño(si aplica)",
      "Descripcion",
      "Total de la comision"
    ];
    var filasS = [];
    for (let i = 0; i < this.state.datosSurgery.length; i++) {
      let fila = [
        this.state.datosSurgery[i].date,
        this.state.datosSurgery[i].namecommission,
        this.state.datosSurgery[i].precio,
        this.state.datosSurgery[i].commission_percentage + "%",
        this.state.datosSurgery[i].type_of_anesthesia,
        this.state.datosSurgery[i].total_anesthesia,
        this.state.datosSurgery[i].commission_percentage_anesthesia + "%",
        this.state.datosSurgery[i].size,
        this.state.datosSurgery[i].description,
        "Q" +  this.state.datosSurgery[i].total_commission
      ];
      filasS.push(fila);
    }

    pdf.autoTable(columnsS, filasS, {
      pageBreak: "avoid",
      headStyles: { fillColor: [125, 60, 152] },
      theme: "grid",
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle("Arial");
        var operacion = new Image();
        operacion.src = Operacion;
        if (operacion) {
          pdf.addImage(operacion, "PNG", data.settings.margin.left, 15, 10, 10);
        }
        pdf.text("CIRUGIAS", data.settings.margin.left + 15, 22);
      },
      margin: { top: 30 }
    });

    pdf.addPage();

    var columnsF = [
      "Fecha",
      "Nombre",
      "Descripcion",
      "Precio",
      "Cantidad",
      "Total Venta",
      "Porcentaje de Comision",
      "Total de Comision"
    ];
    var filasF = [];
    for (let i = 0; i < this.state.datosPharmacy.length; i++) {
      let fila = [
        this.state.datosPharmacy[i].date,
        this.state.datosPharmacy[i].namecommission,
        this.state.datosPharmacy[i].description,
        this.state.datosPharmacy[i].precio,
        this.state.datosPharmacy[i].amount,
        this.state.datosPharmacy[i].total_sale,
        this.state.datosPharmacy[i].commission_percentage + "%",
        "Q" +  this.state.datosPharmacy[i].total_commission
      ];
      filasF.push(fila);
    }

    pdf.autoTable(columnsF, filasF, {
      pageBreak: "avoid",
      headStyles: { fillColor: [230, 126, 34] },
      theme: "grid",
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle("Arial");
        var pildora = new Image();
        pildora.src = Pildora;
        if (pildora) {
          pdf.addImage(pildora, "PNG", data.settings.margin.left, 15, 10, 10);
        }
        pdf.text("FARMACIA", data.settings.margin.left + 15, 22);
      },
      margin: { top: 30 }
    });

    pdf.addPage();
    var columnsO = [
      "Fecha",
      "Nombre",
      "Descripcion",
      "Precio",
      "Porcentaje de Comision",
      "Anestesia",
      "Precio Anestesia",
      "Comission Anestecia",
      "Total de la comision"
    ];
    var filasO = [];
    for (let i = 0; i < this.state.datosOther.length; i++) {
      let fila = [
        this.state.datosOther[i].date,
        this.state.datosOther[i].namecommission,
        this.state.datosOther[i].description,
        this.state.datosOther[i].precio,
        this.state.datosOther[i].commission_percentage + "%",
        this.state.datosOther[i].type_of_anesthesia,
        this.state.datosOther[i].total_anesthesia,
        this.state.datosOther[i].commission_percentage_anesthesia + "%",
        "Q" +  this.state.datosOther[i].total_commission
      ];
      filasO.push(fila);
    }

    pdf.autoTable(columnsO, filasO, {
      pageBreak: "avoid",
      headStyles: { fillColor: [230, 126, 34] },
      theme: "grid",
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.setFontStyle("Arial");
        var hospital = new Image();
        hospital.src = Hospital;
        if (hospital) {
          pdf.addImage(hospital, "PNG", data.settings.margin.left, 15, 10, 10);
        }
        pdf.text("OTROS", data.settings.margin.left + 15, 22);
      },
      margin: { top: 30 }
    });
    
    pdf.save("Comisiones.pdf");
  };



  render() {
    const date = this.state.selectedDateInitial;
    const date2 = this.state.selectedDateEnd;

    const tablas = (
      <div className="container-fluid">
        <div className="row pt-5">
          <div className="col-12">
            <h5>
              Total Ganado
              <MoodIcon />
            </h5>
            <Input
              className="form-control bg-warning text-dark"
              type="Number"
              variant="outlined"
              name="precio"
              value={this.state.TotalAll}
              startAdornment={
                <InputAdornment position="start">Q</InputAdornment>
              }
              readOnly
            />
          </div>
        </div>
        <div className="row pt-2">
            <div className="col-1">
              <button
                type="button"
                className="btn btn-danger btn-lg active"
                onClick={this.GenerarPDF}
              >
                <i className="far fa-file-pdf"></i>
              </button>
            </div>
          </div>
        <div className="pt-5">
          <MaterialTable
            title="TOTALES"
            columns={this.state.columnasTotales}
            data={this.state.datosTotales}
            options={{
              headerStyle: {
                backgroundColor: "rgba(30, 132, 73 ,0.7)",
                color: "#FFF"
              },
              search: false,
              paging: false
              // actionsColumnIndex: -1,
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar"
              },
              body: {
                emptyDataSourceMessage:
                  "No se encontro ninguna comision relacionada."
              },
              header: {
                actions: "Acciones"
              }
            }}
          />
        </div>
        <div className="pt-5 pb-3">
          <MaterialTable
            title="SERVICIOS BÁSICOS"
            columns={this.state.columnsBasico}
            data={this.state.datosBasico}
            options={{
              headerStyle: {
                backgroundColor: "rgba(52, 152, 219 ,0.7)",
                color: "#FFF"
              },
              search: false,
              exportButton: true,
              exportFileName: "Servicios Basicos",
              exportAllData: true
              //paging: false
              // actionsColumnIndex: -1,
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar"
              },
              body: {
                emptyDataSourceMessage:
                  "No se encontro ninguna comision relacionada",
                editRow: {
                  deleteText: "Esta seguro de eliminar esta comision"
                }
              },
              header: {
                actions: "Acciones"
              }
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      let datosBasico = this.state.datosBasico;

                      for (let i = 0; i < datosBasico.length; i++) {
                        if (datosBasico[i].id === oldData.id) {
                          datosBasico.splice(i, 1);
                          break;
                        }
                      }
                      let id = oldData.id;
                      let estado_comision = "DESACTIVA";
                      this.props.updateStateComission(id, estado_comision);

                      this.setState({ datosBasico }, () => resolve());
                    }
                    resolve();
                  }, 2000);
                })
            }}
          />
        </div>
        <div className="pt-5 pb-3">
          <MaterialTable
            title="CIRUGIAS"
            columns={this.state.columnsSurgery}
            data={this.state.datosSurgery}
            options={{
              headerStyle: {
                backgroundColor: "rgba(125, 60, 152 ,0.7)",
                color: "#FFF"
              },
              search: false,
              exportButton: true,
              exportFileName: "Cirugias",
              exportAllData: true
              //paging: false
              // actionsColumnIndex: -1,
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar"
              },
              body: {
                emptyDataSourceMessage:
                  "No se encontro ninguna comision relacionada",
                editRow: {
                  deleteText: "Esta seguro de eliminar esta comision"
                }
              },
              header: {
                actions: "Acciones"
              }
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      let datosSurgery = this.state.datosSurgery;

                      for (let i = 0; i < datosSurgery.length; i++) {
                        if (datosSurgery[i].id === oldData.id) {
                          datosSurgery.splice(i, 1);
                          break;
                        }
                      }
                      //datosBasico.splice(index, 1);
                      let id = oldData.id;
                      let estado_comision = "DESACTIVA";
                      this.props.updateStateComission(id, estado_comision);

                      this.setState({ datosSurgery }, () => resolve());
                    }
                    resolve();
                  }, 1000);
                })
            }}
          />
        </div>
        <div className="pt-5 pb-3">
          <MaterialTable
            title="FARMACIA"
            columns={this.state.columnsPharmacy}
            data={this.state.datosPharmacy}
            options={{
              headerStyle: {
                backgroundColor: "rgba(183, 149, 11,0.9)",
                color: "#FFF"
              },
              search: false,
              exportButton: true,
              exportFileName: "Farmacia",
              exportAllData: true
              //paging: false
              // actionsColumnIndex: -1,
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar"
              },
              body: {
                emptyDataSourceMessage:
                  "No se encontro ninguna comision relacionada",
                editRow: {
                  deleteText: "Esta seguro de eliminar esta comision"
                }
              },
              header: {
                actions: "Acciones"
              }
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      let datosPharmacy = this.state.datosPharmacy;

                      for (let i = 0; i < datosPharmacy.length; i++) {
                        if (datosPharmacy[i].id === oldData.id) {
                          datosPharmacy.splice(i, 1);
                          break;
                        }
                      }
                      //datosBasico.splice(index, 1);
                      let id = oldData.id;
                      let estado_comision = "DESACTIVA";
                      this.props.updateStateComission(id, estado_comision);

                      this.setState({ datosPharmacy }, () => resolve());
                    }
                    resolve();
                  }, 1000);
                })
            }}
          />
        </div>

        <div className="pt-5">
          <MaterialTable
            title="OTROS SERVICIOS"
            columns={this.state.columnsOther}
            data={this.state.datosOther}
            options={{
              headerStyle: {
                backgroundColor: "rgba(230, 126, 34 ,0.8)",
                color: "#FFF"
              },
              search: false,
              exportButton: true,
              exportFileName: "Otros",
              exportAllData: true
              //paging: false
              // actionsColumnIndex: -1,
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar"
              },
              body: {
                emptyDataSourceMessage:
                  "No se encontro ninguna comision relacionada",
                editRow: {
                  deleteText: "Esta seguro de eliminar esta comision"
                }
              },
              header: {
                actions: "Acciones"
              }
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      let datosOther = this.state.datosOther;

                      for (let i = 0; i < datosOther.length; i++) {
                        if (datosOther[i].id === oldData.id) {
                          datosOther.splice(i, 1);
                          break;
                        }
                      }
                      //datosBasico.splice(index, 1);
                      let id = oldData.id;
                      let estado_comision = "DESACTIVA";
                      this.props.updateStateComission(id, estado_comision);

                      this.setState({ datosOther }, () => resolve());
                    }
                    resolve();
                  }, 1000);
                })
            }}
          />
        </div>
        <div className="pt-5 pb-3">
          <MaterialTable
            title="COMISIONES DESECHADAS"
            columns={this.state.columnsSurgery}
            data={this.state.datosdesact}
            options={{
              headerStyle: {
                backgroundColor: "rgba(123, 36, 28 ,0.8)",
                color: "#FFF"
              },
              search: true,
              exportButton: true,
              exportFileName: "Desechadas",
              exportAllData: true
              //paging: false
              // actionsColumnIndex: -1,
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar"
              },
              body: {
                emptyDataSourceMessage:
                  "No se encontro ninguna comision relacionada"
              },
              header: {
                actions: "Acciones"
              }
            }}
          />
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="card  border-success">
            <div className="card-body">
              <form onSubmit={this.onSubmit} noValidate>
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-sm-12 col-lg-6">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className="form-control"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Fecha Inicial"
                        value={date}
                        onChange={date => this.handleDateChangeInitial(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        helperText={this.state.inicioError}
                        error={
                          this.state.inicioError.length === 0 ? false : true
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12 col-lg-6">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className="form-control"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline2"
                        label="Fecha Final"
                        value={date2}
                        onChange={date => this.handleDateChangeEnd(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        helperText={this.state.finalError}
                        error={
                          this.state.finalError.length === 0 ? false : true
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <FormHelperText error={true}>
                    {this.state.rangoError}
                  </FormHelperText>
                </div>
                <div className="row justify-content-center  pt-2">
                  <div className="col-lg-11 col-md-10 col-sm-12">
                    <button type="submit" className="btn btn-success btn-block">
                      BUSCAR COMISIONES
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {this.state.cargado ? tablas : ""}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  commisionsBasicRank: state.CommissionRank.commisionsBasicRank,
  loadingBasicRank: state.CommissionRank.loadingBasicRank,
  totalBasicRank: state.CommissionRank.totalBasicRank,
  loadingTotalBasic: state.CommissionRank.loadingTotalBasic,
  commissionSurgeryRank: state.CommissionRank.commissionSurgeryRank,
  loadingSurgeryRank: state.CommissionRank.loadingSurgeryRank,
  totalSurgeryRank: state.CommissionRank.totalSurgeryRank,
  loadingTotalSurgery: state.CommissionRank.loadingTotalSurgery,
  commissionOtherRank: state.CommissionRank.commissionOtherRank,
  loadingOtherRank: state.CommissionRank.loadingOtherRank,
  totalOtherRank: state.CommissionRank.totalOtherRank,
  loadingTotalOther: state.CommissionRank.loadingTotalOther,
  commissionPharRank: state.CommissionRank.commissionPharRank,
  totalPharmRank: state.CommissionRank.totalPharmRank,
  loadingPharmRank: state.CommissionRank.loadingPharmRank,
  loadingtotalPharmRank: state.CommissionRank.loadingtotalPharmRank,

  deactivateComRank: state.CommissionRank.deactivateComRank,
  loadingDeactivateRank: state.CommissionRank.loadingDeactivateRank
});

export default connect(
  mapStateToProps,
  {
    getCommisionsBasicRank,
    LoadedCommissionRank,
    getTotalCommissionRank,
    getCommisionsSurgeryRank,
    getTotalSurgeryCommissionRank,
    getCommisionsOtherRank,
    getTotalOtherCommissionRank,
    getCommisionsPharmacyRank,
    getTotalPharmacyCommissionRank,
    updateStateComission,
    getDeativateCommissionRank
  }
)(CommissionRank);
