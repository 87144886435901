export const whatWeFound = [
  {
    /*Nudos: false,*/
    name: "nudos",
    label: "Nudos",
  },
  {
    /*PielIrritada: false,*/
    name: "pielirritada",
    label: "Piel Irritada",
  },
  {
    /*Heridas: false,*/
    name: "heridas",
    label: "Heridas",
  },
  {
    /*Raspones: false,*/
    name: "raspones",
    label: "Raspones",
  },
  {
    /*PeloMaltradado: false,*/
    name: "pelomaltratado",
    label: "Pelo Maltradado",
  },
  {
    /*PielYPeloExcesivamenteSucio: false,*/
    name: "pielypelosucio",
    label: "Piel y pelo excesivamente sucio",
  },
  {
    /*PeloHumedo: false,*/
    name: "pelohumedo",
    label: "Pelo húmedo",
  },
  {
    /*UnasLargas: false,*/
    name: "unaslargas",
    label: "Uñas largas",
  },
  {
    /*UnasEncarnada: false,*/
    name: "unasencarnadas",
    label: "Uñas encarnadas",
  },
  {
    /*Pulgas: false,*/
    name: "pulgas",
    label: "Pulgas",
  },
  {
    /*Garrapatas: false,*/
    name: "garrapatas",
    label: "Garrapatas",
  },
  {
    /*SarroDental: false,*/
    name: "sarrodental",
    label: "Sarro dental",
  },
  {
    /*Acaros: false,*/
    name: "acaros",
    label: "Acaros",
  },
  {
    /*DientesFlojos: false,*/
    name: "dientesflojos",
    label: "Dientes flojos",
  },
  {
    /*Ptialistmo: false,*/
    name: "ptialistmo",
    label: "Ptialistmo",
  },
  {
    /*HematomaAuricularDerecho: false,*/
    name: "hematomaad",
    label: "Hematoma auricular derecho",
  },
  {
    /*HematomaAuricularIzquierdo: false,*/
    name: "hematomaai",
    label: "Hematoma auricular izquierdo",
  },
  {
    /*Otitis: false,*/
    name: "otitis",
    label: "Otitis",
  },
  {
    /*Conjuntivitis: false,*/
    name: "conjuntivitis",
    label: "Conjuntivitis",
  },
  {
    /*OjoDerechoIrritado: false,*/
    name: "odi",
    label: "Ojo derecho irritado",
  },
  {
    /*OjoIzquierdoIrritado: false,*/
    name: "oii",
    label: "Ojo izquierdo irritado",
  },
  {
    /*OjoDerechoEdematizado: false,*/
    name: "ode",
    label: "Ojo derecho edematizado",
  },
  {
    /*OjoIzquierdoEdematizado: false,*/
    name: "oie",
    label: "Ojo izquierdo edematizado",
  },
  {
    /*OjoDerechoConHematoma: false,*/
    name: "odh",
    label: "Ojo derecho con hematoma",
  },
  {
    /*OjoIzquierdoConHematoma: false,*/
    name: "oih",
    label: "Ojo izquierdo con hematoma",
  },
  {
    /*PeloOxidadoPorLagrimaSeca: false,*/
    name: "pols",
    label: "Pelo oxidado por lagrima seca",
  },
  {
    /*EdemasOHematomasEnCuerpo: false,*/
    name: "ehc",
    label: "Edemas o hematomas en cuerpo",
  },
];

export const whatWeRecomend = [
  {
    name: "scmv",
    label: "Solicitar cita con médico veterinario",
  },
  {
    name: "cfpcend",
    label: "Corte frecuente de pelo y cepillado para evitar nudos y dermatitis",
  },
  {
    name: "apga",
    label: "Administrar pulguicidas, garrapaticidas y acaricidas",
  },
  {
    name: "ld",
    label: "Limpieza dental",
  },
  {
    name: "cfuepieu",
    label:
      "Corte frecuente de uñas para evitar problemas infecciosos por encarnación de uñas",
  },
  {
    name: "dmjepd",
    label: "Dar a su mascota juguetes especiales para limpiar los dientes",
  },
  {
    name: "dtco",
    label: "Dar tratamiento contra la otitis",
  },
  {
    name: "dtceo",
    label: "Dar tratamiento contra edema ocular",
  },
  {
    name: "laole",
    label: "Limpie en el aréa de los ojos con un líquido especial.",
  },
  {
    name: "dtchaoc",
    label:
      "Dar tratamiento contra hematomas; auriculares, oculares y coporales.",
  },
];

export const times = [
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
];
