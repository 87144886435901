import React, { Component } from "react";
import { connect } from "react-redux";
import { getClients, searchClients } from "../../actions/ActClients";
import PropTypes from "prop-types";
/*import { Redirect } from "react-router-dom";*/
import NewClientProfile from "../Forms/NewClientProfile";
import MaterialTable from "material-table";
import { getCertainProfile } from "../../actions/ActProfile";
import AllClientsIcon from "../../assets/icons/allClients.svg";
import Loading from "../common/Loading";
import "./styles/frames.css";
import SecretaryPets from "./SecretaryPets";
class ClientsPage extends Component {
  state = {
    userToGetProfile: null,
    show: false,
    showPetModal: false,
    clientToLoadOnModal: null,
    clientToLoadUsername: null,
  };
  static propTypes = {
    clients: PropTypes.array.isRequired,
    getCertainProfile: PropTypes.func.isRequired,
    searchClients: PropTypes.func.isRequired,
    profile: PropTypes.object,
    profileLoading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getClients();
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  hidePModal = () => {
    this.setState({ showPetModal: false });
  };

  render() {
    if (this.props.loading) {
      return <Loading></Loading>;
    }
    return (
      <React.Fragment>
        {this.state.showPetModal ? (
          <Modal show={this.state.showPetModal} handleClose={this.hidePModal}>
            <SecretaryPets
              clientId={this.state.clientToLoadOnModal}
            ></SecretaryPets>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {this.state.show ? (
          <Modal show={this.state.show} handleClose={this.hideModal}>
            <NewClientProfile
              clientId={this.state.clientToLoadOnModal}
              username={this.state.clientToLoadUsername}
              first_name={this.state.clientToLoadFirst_name}
              email={this.state.clientToLoadEmail}
            ></NewClientProfile>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {this.state.show2 ? (
          <Modal show={this.state.show2} handleClose={this.hideModal}>
            <NewClientProfile
              clientId={this.state.clientToLoadOnModal}
              username={this.state.clientToLoadUsername}
            ></NewClientProfile>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {/*tabla de clientes */}
        {/*<div className="clients-frame">*/}
        <div className="container-fluid">
          <div
            className="row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              color: "black",
              height: "8rem",
            }}
          >
            <p style={{ alignSelf: "center" }}>Modulo clientes</p>
          </div>
          <div className="row align-items-center pt-5">
            <div className="col-12">
              <div className="col-12 d-flex pb-3 pt-5 justify-content-center">
                <img src={AllClientsIcon} alt="" className="header-icon" />
                <br />
                <p className="pl-5">Perfil de clientes y sus mascotas</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex pb-0 pt-0 justify-content-center align-items-center">
              <form className="row row-cols-lg-auto g-3 align-items-center m-2" onSubmit={e=>{
                e.preventDefault();
                this.props.searchClients(document.getElementById("SearchQuery").value);
              }}>
                <div className="col-8">
                  <div className="input-group">
                    <div className="input-group-text">
                      <i className="fa fa-search"></i>
                    </div>
                    <input type="text" className="form-control" placeholder="Nombre, Apellido, Telefono, Correo"
                    id="SearchQuery" name="query"/>
                  </div>
                </div>
                <div className="col-4">
                  <button type="submit" className="btn btn-primary">Buscar</button>
                </div>
              </form>
            </div>
            <div className="col">
              {this.props.clients.length>0 ? 
            <MaterialTable
                localization={{
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Cliente",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encuentran coincidencias.",
                  },
                  header: {
                    actions: "Acciones",
                  },
                }}
                options={{
                  pageSize: 10,
                  headerStyle: {
                    backgroundColor: "#01579b",
                    color: "white",
                    paddingLeft: "20px",
                  },
                  actionsColumnIndex: -1,
                  detailPanelColumnAlignment: "right",
                }}
                columns={[
                  {
                    title: "Username",
                    field: "username",
                  },

                  {
                    title: "Cliente",
                    field: "first_name",
                  },
                  {
                    title: "Email",
                    field: "email",
                  },
                ]}
                data={this.props.clients}
                title="Lista de clientes"
                actions={[
                  {
                    icon: "add",
                    tooltip: "Perfil",
                    onClick: (event, rowData) => {
                      event.preventDefault();
                      this.showModal();
                      this.setState({
                        clientToLoadOnModal: rowData.id,
                        clientToLoadUsername: rowData.username,
                        clientToLoadFirst_name: rowData.first_name,
                        clientToLoadEmail: rowData.email,
                      });
                    },
                  },
                  {
                    icon: "emoji_nature",
                    tooltip: "Mascotas",
                    onClick: (e, rowData) => {
                      e.preventDefault();
                      this.setState({ showPetModal: true });
                      this.setState({
                        clientToLoadOnModal: rowData.id,
                      });
                    },
                  },
                ]}
              />
              :
              <h1>Sin datos para mostrar</h1>  
            }
            </div>
          </div>
        </div>
        {/*</div>*/}
      </React.Fragment>
    );
  }
}

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show
    ? "modal display-block clients-frame"
    : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section
        className="modal-main clients-frame"
        style={{ overflow: "scroll" }}
      >
        <span class="circle" onClick={handleClose}></span>
        {children}
      </section>
    </div>
  );
};

const mapSateToProps = (state) => ({
  clients: state.RedClients.clients,
  profileLoading: state.Profile.loading,
  loading: state.RedClients.loading,
  profile: state.Profile.profileInfo,
});

export default connect(mapSateToProps, { getClients, getCertainProfile, searchClients })(
  ClientsPage
);
