import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  GET_PRODUCTS,
  ADD_PRODUCTS,
  GET_PRODUCTSVARCODE,
  UPDATE_PRODUCT,
  LOADED_PRODUCT,
  UPDATE_STOCK_PRODUCT,
  FINISH_UPDATE,
  UPDATE_STATUS_PRODUCT,
  __BASE__URL
} from "./types";
import { tokenConfig } from "./ActAuths";

export const LoadedProduct = () => dispatch => {
  //solo sirve para indicar que el producto buscado por codigo de barras ya se cargo
  dispatch({ type: LOADED_PRODUCT });
};

export const finishUpdate = () => dispatch => {
  //solo sirve para indicar que el producto buscado por codigo de barras ya se cargo
  dispatch({ type: FINISH_UPDATE });
};

//ADD PRODUCTS
export const addproduct = product => (dispatch, getState) => {
  const url = __BASE__URL + "/api/products/";
  const body = JSON.stringify({
    ...product
  });
  //console.log(body);
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch(createMessage({ addproduct: "Producto Agregado con exito!" }));
      dispatch({
        type: ADD_PRODUCTS
        //payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//GET PRODUCTS
export const get_products = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/products/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data
      });
    })
    .catch(
      err => dispatch(returnErrors(err?.response?.data, err?.response?.data))
      //console.log('error')
    );
};

//obtiene el producto segun el codigo de barras
export const getVarcodeProduct = varcode => (dispatch, getState) => {
  const body = JSON.stringify({
    varcode
  });

  const url = __BASE__URL + "/api/products/varcode";
  try {
    axios
      .post(url, body, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_PRODUCTSVARCODE,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

//ACTUALIZAR PRODUCTO
export const updateProduct = (
  id_product,
  name,
  stock,
  category,
  brand,
  description,
  purchase_price,
  sale_price
) => (dispatch, getState) => {
  //Headers

  const body = JSON.stringify({
    id_product,
    name,
    stock,
    category,
    brand,
    description,
    purchase_price,
    sale_price
  });
  //console.log(body);
  const url = __BASE__URL + `/api/products/updateProduct/${id_product}/`;
  try {
    axios
      .put(url, body, tokenConfig(getState))
      .then(res => {
        dispatch(
          createMessage({ updateproduct: "Producto Actualizado con exito!" })
        );
        dispatch({
          type: UPDATE_PRODUCT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

//Actualizar cantidad en Stock
export const updateStockProduct = (id_product, stock) => (
  dispatch,
  getState
) => {
  //Headers

  const body = JSON.stringify({
    id_product,
    stock
  });
  //console.log(body);
  const url = __BASE__URL + `/api/products/updatestock/${id_product}/`;
  try {
    axios
      .put(url, body, tokenConfig(getState))
      .then(res => {
        /*dispatch(
          createMessage({ updateproduct: "Producto Actualizado con exito!" })
        );*/
        dispatch({
          type: UPDATE_STOCK_PRODUCT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};


//Actualizar estado del Producto
export const updateStatusProduct = (id_product, estado_actual) => (
  dispatch,
  getState
) => {
  //Headers

  const body = JSON.stringify({
    id_product,
    estado_actual
  });
  //console.log(body);
  const url = __BASE__URL + `/api/products/updatestatus/${id_product}/`;
  try {
    axios
      .put(url, body, tokenConfig(getState))
      .then(res => {
        dispatch(
          createMessage({ updatestatus: "Producto Desactivado con exito!" })
        );
        dispatch({
          type: UPDATE_STATUS_PRODUCT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};