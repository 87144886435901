import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getALLReceiptHeader } from "../../../actions/Sales";
import MaterialTable from "material-table";
import ShowDetail from "./ShowDetail";
import moment from "moment";
export class ShowAllSales extends Component {
  static propTypes = {
    receipts: PropTypes.array.isRequired,
    getALLReceiptHeader: PropTypes.func.isRequired,
    loadedHeader: PropTypes.bool
  };
  componentDidMount() {
    this.props.getALLReceiptHeader();
  }
  state = {
    columns: [
      { title: "#Recibo", field: "id", editable: "never", type: "numeric" },
      { title: "Fecha", field: "date", editable: "never" },
      { title: "Nombre Cliente", field: "customer", editable: "never" },
      { title: "Efectivo", field: "totalcash", editable: "never" },
      { title: "Tarjeta", field: "totalcard", editable: "never" },
      { title: "Tipo de Pago", field: "typeofpay", editable: "never" },
      { title: "Total", field: "total", editable: "never", type: "numeric" }
    ]
  };

  render() {
    const datos = this.props.receipts.map(recibo => ({
      id: recibo.id,
      date: moment(recibo.date).format("DD/MM/YYYY"),
      customer: recibo.customer,
      totalcash: recibo.totalcash,
      totalcard: recibo.totalcard,
      typeofpay: recibo.typeofpay,
      total: recibo.total
    }));

    return (
      <React.Fragment>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <div className="pt-5">
          <MaterialTable
            title="RECIBOS"
            columns={this.state.columns}
            data={datos}
            options={{
              headerStyle: {
                backgroundColor: "rgba(34,153,84,0.8)",
                color: "#FFF"
              },
              /*rowStyle: {
                backgroundColor: "#EEE"
              },*/
              exportButton: true,
              exportFileName: "Encabezados Recibos",
              exportAllData: true

              // actionsColumnIndex: -1,
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar"
              },
              body: {
                emptyDataSourceMessage:
                  "No se encontro ningun recibo relacionado."
              },
              header: {
                actions: "Acciones"
              }
            }}
            detailPanel={[
              {
                render: rowData => {
                  return (
                    <div className="row justify-content-center">
                      <div
                        className="col-lg-8 col-md-8 col-sm-8 "
                        style={{ alignSelf: "center" }}
                      >
                        <ShowDetail
                          sale_id={rowData.id}
                          fecha={rowData.date}
                          nameCustomer={rowData.customer}
                          total={rowData.total}
                          totalcash={rowData.totalcash}
                          totalcard={rowData.totalcard}
                        />
                      </div>
                    </div>
                  );
                }
              }
            ]}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  receipts: state.Sales.receipts,
  loadedHeader: state.Sales.loadedHeader
});

export default connect(
  mapStateToProps,
  { getALLReceiptHeader }
)(ShowAllSales);
