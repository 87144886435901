import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getOnDeliveryAppointment } from "../../actions/ActApointments";
import { getGroomFile, updatePictures } from "../../actions/ActGroomfile";
import "./styles/promotions.scss";
import { archiveAppointment } from "../../actions/ActApointments";

import { cleanFile } from "../../actions/ActClinicfile";
class DeliverPage extends Component {
  state = {
    selectedPetTodeliver: null,
    petDelivered: false
  };
  static propTypes = {
    getOnDeliveryAppointment: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    getGroomFile: PropTypes.func.isRequired,
    updatePictures: PropTypes.func.isRequired,
    apointments: PropTypes.array.isRequired,
    archiveAppointment: PropTypes.func.isRequired,
    cleanFile: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    file: PropTypes.object
  };

  componentDidMount() {}

  toogleActive = e => {
    e.preventDefault();
    var btns = document.querySelectorAll("button.neumorphism-active");
    btns.forEach(function(btn) {
      btn.classList.toggle("neumorphism-active");
    });
    e.target.classList.toggle("neumorphism-active");
    if (e.target.name === "adds") {
      this.setState({ adds: true });
    }
    if (e.target.name === "adds") {
      this.setState({ adds: true, stats: false, history: false });
    } else if (e.target.name === "stats") {
      this.setState({ adds: false, stats: true, history: false });
    } else {
      this.setState({ adds: false, stats: false, history: true });
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="promotionsPage">
          <div className="tools">
            <button
              className="card-button"
              onClick={this.toogleActive}
              name="adds"
            >
              <i class="fas fa-ad"></i>
            </button>
            <button
              className="card-button"
              onClick={this.toogleActive}
              name="stats"
            >
              <i class="fas fa-poll"></i>
            </button>
            <button
              className="card-button"
              onClick={this.toogleActive}
              name="history"
            >
              <i class="fas fa-history"></i>
            </button>
          </div>
          <div className="info">
            {this.state.adds ? (
              <section id="adds">
                <h1>Campañas</h1>
                <p>
                  Da un nombre a la campaña, escribe la promocion que deseas
                  entregar por SMS y segmenta tus clientes, ahorra recursos
                  entregando la promocion a un segmento especifico.
                </p>
                <p>
                  <strong>Ejemplo:</strong>
                  <quote>
                    ¡10% de descuento en grooming para pug!{" "}
                    <strong>Nombre</strong> si agendas una cita para{" "}
                    <strong>NOMBRE DE MASCOTA</strong> ahora desde el siguiente
                    link, recuerda tu usuario <strong>Username</strong> y tu
                    número de telefono como contraseña. app.animalhomegt.com
                  </quote>
                </p>
                <p>
                  <small>
                    Se solicito enviar esta promocion a todos los clientes que
                    sean dueños de un perro raza pug registrados en el sistema
                  </small>
                </p>
              </section>
            ) : this.state.stats ? (
              <section id="stats">
                <h1>Estadisticas</h1>
                <p>
                  Visualiza cuantas personas accedieron a la{" "}
                  <strong>ultima </strong>
                  campaña desde el link enviado por SMS y a cuantas personas fue
                  entregado de manera sencilla
                </p>
              </section>
            ) : this.state.history ? (
              <section id="history">
                <h1>Historial</h1>
                <p>
                  Guarda las campañas hechas para que puedas facilmente
                  visualizar las que fueron mas productivas y las que no lo
                  fueron tanto para ayudarte a decidir tu proxima campaña
                </p>
              </section>
            ) : (
              <React.Fragment>
                <h1>Target marketing</h1>
                <p>
                  <strong>
                    Aprovecha tu data para generar potenciales ingresos
                  </strong>
                </p>
                <p>
                  {" "}
                  Ya tienes al mejor publico objetivo{" "}
                  <strong>TUS PROPIOS CLIENTES</strong> encuentra al cliente
                  ideal para cada promocion y asegurate que la reciban mediante
                  un SMS.
                </p>
                <p>
                  <strong>Servicio proximamente disponible</strong>
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  apointments: state.RedApoint.apointments,
  loading: state.RedApoint.loading,
  user: state.RedAuth.user,
  file: state.History.file
});

export default connect(mapStateToProps, {
  getOnDeliveryAppointment,
  archiveAppointment,
  getGroomFile,
  cleanFile,
  updatePictures
})(DeliverPage);
