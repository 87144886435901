import React, { Component } from "react";
/*import Form from "./Form";
import Leads from "./Leads";*/
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import PersonalInfo from "../accounts/PersonalInfo";
import { getProfile } from "../../actions/ActProfile";
import PetCardList from "../common/PetCard";
/*CSS */
import "./styles/Clients_Dashboard.css";
import { getPets } from "../../actions/ActPets";
class Dashboard extends Component {
  componentDidMount() {
    this.props.getPets();
    this.props.getProfile();
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    getPets: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
    pets: PropTypes.array.isRequired,
    profile: PropTypes.object.isRequired
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login" />;
    }
    return (
      <React.Fragment>
        <div className="container">
          <div className="row pt-lg-5 pt-md-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <PersonalInfo profile={this.props.profile} />
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="dog-cards-container">
                <img
                  src="https://static.wixstatic.com/media/3d4f8e_91fee6c8a2eb4910bc2995b07ecb77b9~mv2.png/v1/fit/w_2500,h_1330,al_c/3d4f8e_91fee6c8a2eb4910bc2995b07ecb77b9~mv2.png"
                  alt=""
                  id="HeaderPetContainer"
                />
                <PetCardList pets={this.props.pets} />
              </div>
            </div>
          </div>
        </div>

        {/*<Leads />*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.RedAuth.isAuthenticated,
  pets: state.RedPets.pets
  /*Bajar a props el atributo TIPO USUARIO PARA DECIDIR QUE DASHBOARD DESPLEGAR */
});
export default connect(
  mapStateToProps,
  { getPets, getProfile }
)(Dashboard);
