import {
  ADD_REFUND,
  GET_REFUND_HEADER,
  LOADED_REFUND_HEADER,
  ADD_REFUND_DETAIL,
  GET_DETAIL_REFUND,
  LOADED_REFUND_DETAIL,
  GET_ALL_REFUNDS_HEADER,
  DELETE_DETAILS_REFUNDS,
  DELETE_REFUND,
} from "../actions/types";

const initialState = {
  refunds: [],
  details: [],
  loadingHeader: false,
  loadedHeader: false,
  loadingDetail: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_REFUND:
      return {
        ...state,
        refunds: [...state.refunds, state.payload],
        loadingHeader: true
      };
    case GET_REFUND_HEADER:
      return {
        ...state,
        refunds: action.payload,
        loadingHeader: false,
        loadedHeader: true
      };
    case LOADED_REFUND_HEADER:
      return {
        ...state,
        loadedHeader: false
      };
    case ADD_REFUND_DETAIL:
      return {
        ...state
      };
    case GET_DETAIL_REFUND:
      return {
        ...state,
        details: action.payload,
        loadingDetail: true
      };
    case LOADED_REFUND_DETAIL:
      return {
        ...state,
        loadingDetail: false
      };
    case GET_ALL_REFUNDS_HEADER:
      return {
        ...state,
        refunds: action.payload
      };
      case DELETE_DETAILS_REFUNDS:
          return {
            ...state,
            details: state.details.filter(details => details.id !== action.payload)
          }  
        case DELETE_REFUND:
          return {
            ...state,
            refunds: state.refunds.filter(refunds => refunds.id !== action.payload)
          }    
    default:
      return state;
  }
}
