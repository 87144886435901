import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/Pages.css";
import FacebookIcon from "../../assets/icons/facebook.svg";
/*import ClinicAppointmentIcon from "../../assets/images/clinic.jpg";
import GroomAppointmentIcon from "../../assets/images/groom.jpg";*/
import PropTypes from "prop-types";
import { connect } from "react-redux";
class WichApointment extends Component {
  state = {
    rol: this.props.user.last_name === "cliente" ? "cliente" : "secretaria",
  };
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  render() {
    const pathToGrooming =
      this.state.rol === "secretaria"
        ? "/secretary/groomingdatemanagement"
        : "/client/groomingdatemanagement";
    const pathToClinical =
      this.state.rol === "secretaria"
        ? "/secretary/clinicaldatemanagement"
        : "/client/clinicaldatemanagement";
    return (
      <React.Fragment>
        <div className="container-fluid" style={{ height: "100vh" }}>
          <div
            className="row justify-content-center"
            style={{ height: "100%" }}
          >
            <div className="col">
              <div
                className="row justify-content-center align-items-center"
                style={{
                  top: "0%",
                  height: "8rem",
                  backgroundColor: "#2980b9",
                  fontWeight: "bold",
                  fontSize: "15rem",
                  color: "white",
                }}
              >
                <div className="col text-center">
                  <h4>Animal Home</h4>
                </div>
              </div>
              <div
                className="row justify-content-center align-items-center"
                style={{
                  /*height: "70vh",*/
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  alignItems: "center",
                }}
              >
                {this.props.user.last_name !== "cliente" ? (
                  <React.Fragment>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center">
                      <div
                        className="row "
                        style={{ flexDirection: "column", height: "100%" }}
                      >
                        {/*<img
                          src={ClinicAppointmentIcon}
                          alt=""
                          htmlFor="PathToClinical"
                        />*/}
                        {/*<div className="ImageTagWithButton">*/}
                        <Link
                          className="btn btn-warning"
                          to={{
                            pathname: pathToClinical,
                            actualDate: this.props.location.actualDate,
                            selectedTime: this.props.location.selectedTime,
                          }}
                          id="PathToClinical"
                          style={{
                            height: "10rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "4rem",
                            fontSize: "18px",
                          }}
                        >
                          Agenda una cita para nuestra Clínica
                        </Link>
                      </div>
                    </div>
                    {/*</div>*/}
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center pb-2 m-1">
                      <div
                        className="row "
                        style={{ flexDirection: "column", height: "100%" }}
                      >
                        {/*<img
                          src={GroomAppointmentIcon}
                          alt=""
                          htmlFor="PathToGrooming"
                        />*/}
                        {/*<div className="ImageTagWithButton">*/}
                        <Link
                          className="btn btn-info"
                          to={{
                            pathname: pathToGrooming,
                            actualDate: this.props.location.actualDate,
                            selectedTime: this.props.location.selectedTime,
                          }}
                          id="PathToGrooming"
                          style={{
                            height: "10rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "4rem",
                            fontSize: "18px",
                          }}
                        >
                          Agenda una cita para Grooming
                        </Link>
                        {/*</div>*/}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div
                      className="d-flex"
                      style={{
                        flexFlow: "column",
                        height: "50vh",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "4rem",
                      }}
                    >
                      <a href="https://www.facebook.com/Cl%C3%ADnica-Veterinaria-Animal-Home-187855935252270/">
                        <img
                          src={FacebookIcon}
                          alt="facebook_link"
                          style={{ maxWidth: "5rem" }}
                        />
                      </a>
                      <p>
                        Escribenos en faceobook o bien llama al número 2233 6636
                        ¡Esperamos tu llamada!
                      </p>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*<div className="decideapointmentcard">
          <div className="row h-100 align-items-center">
            <div className="col-sm-12 col-lg-6 col-md-6 text-center">
              <div className="justify-content-end">
                <img src={GroomAppointmentIcon} alt="" />
                <div className="">
                  <div>Servicio grooming</div>
                  <Link
                    className="btn btn-info"
                    to="/client/groomingdatemanagement"
                  >
                    Agendar cita
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 text-center">
              <img src={ClinicAppointmentIcon} alt="" />
              <div className="">
                <div>Servicio clínico</div>
                <Link
                  className="btn btn-info"
                  to="/client/clinicaldatemanagement"
                >
                  Agendar cita
                </Link>
              </div>
            </div>
          </div>
        </div>*/}
      </React.Fragment>
    );
  }
}

const mapSateToProps = (state) => ({
  user: state.RedAuth.user,
});

export default connect(mapSateToProps, {})(WichApointment);
