import { GROOMS_LOADING, GROOMS_LOADED } from "../actions/types";

const initialState = {
  loading: false,
  grooms: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GROOMS_LOADING:
      return {
        ...state,
        loading: true
      };

    case GROOMS_LOADED:
      return {
        ...state,
        grooms: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
