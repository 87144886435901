import axios from "axios";
//import { returnErrors, createMessage } from "./ActMessages";
import {
  REPORTS_SALES_TODAY,
  NUMBER_SALES_ACTUAL_DAY,
  TOTAL_SALES_TODAY,
  TOTAL_PRODUCTS_SALE_TODAY,
  LOADED_TODAY,
  TOTAL_CASH,
  TOTAL_CARD,
  TOTAL_SALES_GROOM,
  TOTAL_SALES_SERVICES,
  DETAIL_GROOMING,
  DETAIL_SERVICES,
  TOTAL_DEV_CARD,
  TOTAL_DEV_CASH,
  TOTAL_DEV_DETAILS,
  DETAIL_PHARM,
  TOTAL_SALES_PHARM,
  __BASE__URL
} from "./types";
import { tokenConfig } from "./ActAuths";
import { returnErrors } from "./ActMessages";

//Este devuelve todos los encabezados vendidos en el dia
export const getSalesToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/receiptsheaderActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: REPORTS_SALES_TODAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};
//Este devuelve la cantitad total de ventas en el dia
export const getNumberTotalSalesToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/NumeroVentasActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: NUMBER_SALES_ACTUAL_DAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};
//Este devuelve la suma total de dinero en el dia
export const getTotalSalesToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalVentasActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_TODAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//Este devuelve la suma total de dinero en efectivo en el dia
export const getTotalCashSalesToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalCashVentasActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_CASH,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//Este devuelve la suma total de dinero en tarjeta en el dia
export const getTotalCardSalesToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalCardVentasActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_CARD,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//Este devuele el detalle de los productos vendido en el dia
//por ejemplo el Concentrado pedigre se vendio 5 veces y dos clientes lo compraron
//tambien devuelve todos los detalles del producto como nombre precion de compra,precio de venta,descripcion,marca
export const getDetailsProductsSalesToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/receiptsDetailActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_PRODUCTS_SALE_TODAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const LoadedReportToday = () => dispatch => {
  dispatch({ type: LOADED_TODAY });
};

//--------------------------------POR MES-----------------------------------------------

//Este devuelve la cantitad total de ventas en el Mes
export const getNumberTotalSalesMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/NumeroVentasActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: NUMBER_SALES_ACTUAL_DAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//Este devuelve la suma total de dinero en el Mes
export const getTotalSalesMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalVentasActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_TODAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotaCashlSalesMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalCashVentasActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_CASH,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalCardSalesMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalCardVentasActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_CARD,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDetailsProductsSalesMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/receiptsDetailActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_PRODUCTS_SALE_TODAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//-----------------------Por Anio-----------------
//Este devuelve la cantitad total de ventas en el Anio
export const getNumberTotalSalesYear = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/NumeroVentasActualYear/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: NUMBER_SALES_ACTUAL_DAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalSalesYear = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalVentasActualYear/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_TODAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDetailsProductsSalesYear = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/receiptsDetailActualYear/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_PRODUCTS_SALE_TODAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//------------------grooming---------------------------
export const getSumGroomToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/GroomingSUMActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_GROOM,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getSumGroomActualMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/GroomingSUMActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_GROOM,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDetailGroomToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/GroomingActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DETAIL_GROOMING,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDetailGroomActualMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/GroomingActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DETAIL_GROOMING,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//-----------------------Servicios Clinicos---------------------------
export const getSumServicesToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/ServicesSUMActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_SERVICES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getSumgServicesActualMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/ServicesSUMActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_SERVICES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDetailServicesToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/ServicesActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DETAIL_SERVICES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDetailServicesActualMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/ServicesActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DETAIL_SERVICES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

///--------------Por Rango----------------------------
export const detailProductsRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/DetailRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_PRODUCTS_SALE_TODAY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const totalCardRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalCardRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_CARD,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const totalCashRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalCashRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_CASH,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const totalGroomRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/SumGroomRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_GROOM,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const detailGroomRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/DetailGroomRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DETAIL_GROOMING,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const totalServicesRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/SumServicesRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_SERVICES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const detailServicesRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/DetailServicesRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DETAIL_SERVICES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//-----------------------------------DEVOLUCIONES-----------------------------------------------------------------
export const getTotalCashDevToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalcashDevActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_DEV_CASH,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalCardDevToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalcardDevActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_DEV_CARD,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalCashDevMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalcashDevActualMont/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_DEV_CASH,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalCardDevMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalcardDevActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_DEV_CARD,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalCashDevRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalDevCashRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_DEV_CASH,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalCardDevRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalDevCardRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_DEV_CARD,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getdetailsDevToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/refundDetailToday/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_DEV_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getdetailsDevMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/refundDetailMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_DEV_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getdetailsDevRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/refundDetailRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_DEV_DETAILS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};
//////////////---------Farmacia-------------------
export const getSumPharmacyToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/PharmacySUMActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_PHARM,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getSumgPharmacyActualMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/PharmacySUMActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_PHARM,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDetailPharmacyToday = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/PharmacyActualDay/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DETAIL_PHARM,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDetailPharmacyActualMonth = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/PharmacyActualMonth/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DETAIL_PHARM,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const totalPharmacyRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/SumPharmacyRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SALES_PHARM,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const detailPharmacyRank = (inicio, final) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/DetailPharmacyRank";
  const body = JSON.stringify({
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DETAIL_PHARM,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};
