import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getVets } from "../../../actions/ActVets";
import Select from "react-select";
import Consulta from "../../../assets/consulta.svg";
import CommissionToday from "../AllCommissions/CommissionToday";
import CommissionRank from "../AllCommissions/CommissionRank";

export class AdminCommission extends Component {
  static propTypes = {
    vets: PropTypes.array.isRequired,
    getVets: PropTypes.func.isRequired,
    loading: PropTypes.bool
  };

  state = {
    isSearchable: false,
    opciones: [
      { label: "HOY", value: "Hoy" },
      { label: "MES ACTUAL", value: "Mes" },
      { label: "RANGO", value: "Rango" }
    ],
    selectOptionDoctor: "",
    selectOptionFecha: "",
    nombreDoc:""

  };

  componentDidMount() {
    this.props.getVets();
  }

  handleChangeOptionFecha = e => {
    try {
      this.setState({
        selectOptionFecha: e.value
      });
    } catch (error) {
      this.setState({ selectOptionFecha: "" });
    }
  };

  handleChangeOptionDoctor = e => {
    try {
      this.setState({
        selectOptionDoctor: e.value,
        nombreDoc:e.label
      });
    } catch (error) {
      this.setState({ selectOptionDoctor: "" });
    }
  };

  render() {
    const isSearchable = this.state.isSearchable;
    const selectOptionDoctor = this.state.selectOptionDoctor;
    const selectOptionFecha = this.state.selectOptionFecha;
    const doctores = this.props.vets.map(doc => ({
      value: doc.id,
      label: doc.first_name
    }));
    const nombreDoc = this.state.nombreDoc

    function Verify() {
      if (selectOptionFecha === "Hoy" && selectOptionDoctor !== null) {
        document.getElementById("detalle").scrollIntoView();
        return <CommissionToday fecha={"hoy"} doctor={selectOptionDoctor} namedoc={nombreDoc} />;
      } else if (selectOptionFecha === "Mes" && selectOptionDoctor !== null) {
        document.getElementById("detalle").scrollIntoView();
        return <CommissionToday fecha={"mes"} doctor={selectOptionDoctor} namedoc={nombreDoc} />;
      } else if (selectOptionFecha === "Rango" && selectOptionDoctor !== null) {
        document.getElementById("detalle").scrollIntoView();
        return <CommissionRank doctor={selectOptionDoctor} namedoc={nombreDoc} />;
      }
      return "";
    }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className="row"
                style={{
                  backgroundColor: "rgba(142, 68, 173,0.7)",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "7rem"
                }}
              >
                <div className="col text-center">
                  <h4>Reporte de Comisiones</h4>
                </div>
              </div>

              <div
                className="row pt-3"
                style={{
                  height: "40vh",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <img
                      src={Consulta}
                      className="card-img-top"
                      width="100"
                      height="100"
                      alt="programar"
                    />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        SELECCIONA AL DOCTOR
                      </h5>

                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                          <Select
                            defaultValue={{
                              label: "Selecciona doctor",
                              value: 0
                            }}
                            onChange={this.handleChangeOptionDoctor}
                            options={doctores}
                            isSearchable={isSearchable}
                            id="seleccionComision"
                          />
                        </div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                          <Select
                            defaultValue={{
                              label: "Selecciona Fecha",
                              value: 0
                            }}
                            onChange={this.handleChangeOptionFecha}
                            options={this.state.opciones}
                            isSearchable={isSearchable}
                            id="seleccion"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row justify-content-center  h-100 pt-5 pb-5"
                id="detalle"
              >
                <div className="col text-center">
                  <Verify />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  vets: state.RedVets.vets,
  loading: state.RedVets.loading
});

export default connect(
  mapStateToProps,
  { getVets }
)(AdminCommission);
