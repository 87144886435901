import React, { Component, Fragment } from "react";
import { getDaySchedule } from "../../actions/ActApointments";
import Calendar from "react-calendar";
import MaterialTable from "material-table";
import PropTypes from "prop-types";
import {
  getVaccines,
  addVaccine,
  getDewormers,
  addDewormer
} from "../../actions/ActRecords";
import VaccineIcon from "../../assets/icons/vaccine.svg";
import WantedIcon from "../../assets/icons/wanted.svg";
import { getCertainProfile } from "../../actions/ActProfile";
import PlusIcon from "../../assets/icons/plus.svg";
import ParasiteIcon from "../../assets/icons/parasite.svg";
import { getAllPets } from "../../actions/ActPets";
import { connect } from "react-redux";
import Loading from "../common/Loading";
import NewVaccine from "../Forms/NewVaccine";
import { addApointment } from "../../actions/ActApointments";
import {searchPets} from "../../actions/ActPets";
var times = [
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30"
];
function arrayRemove(arr, value) {
  return arr.filter(function(ele) {
    return ele !== value;
  });
}
class VacunacionYDesparacitacion extends Component {
  state = {
    workOnVaccine: false,
    workOnDewormer: false,
    /*hasVaccineFile: false,*/
    showVaccineHistory: false,
    showDewormerForm: false,
    showFileDetail: false,
    hasDewormerFile: false,
    showDewormerHistory: false,
    showVaccineForm: false,
    selectedPed: null,
    notes: "",
    time: "",
    vaccine: "",
    dewormer: "",
    observaciones: "",
    inDate: null,
    date: new Date()
  };
  static propTypes = {
    getAllPets: PropTypes.func.isRequired,
    getCertainProfile: PropTypes.func.isRequired,
    getDewormers: PropTypes.func.isRequired,
    addDewormer: PropTypes.func.isRequired,
    profileLoading: PropTypes.bool.isRequired,
    addApointment: PropTypes.func.isRequired,
    getDaySchedule: PropTypes.func.isRequired,
    schedule: PropTypes.array,
    vaccinesLoading: PropTypes.bool.isRequired,
    getVaccines: PropTypes.bool.isRequired,
    addVaccine: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    user: PropTypes.object.isRequired,
    pets: PropTypes.array,
    vaccines: PropTypes.array,
    dewormers: PropTypes.array,
    searchPets: PropTypes.func.isRequired
  };

  handeCalendarChange = date => {
    let inDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ inDate });
    this.props.getDaySchedule(inDate, this.props.user.id);
  };
  componentDidMount() {
    this.props.getAllPets();
  }
  handleChange = e => {
    // eslint-disable-next-line
    if ([e.target.name] == "time") {
      let selectedoption = document.getElementById("exampleFormControlSelect1");
      this.setState({
        [e.target.name]: selectedoption.value
      });
    } else {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  };

  showModal = () => {
    this.setState({ showVaccineForm: true });
  };

  hideModal = () => {
    this.setState({ showVaccineForm: false, showDewormerForm: false });
  };

  /*registerNewVaccineWithAppointment will add an appointment and a vaccine to the record as well */
  registerNewVaccineWithAppointment = e => {
    e.preventDefault();
    let { inDate, doctor, pet, notes, time, cliente } = this.state;
    let info = {
      date: inDate,
      time,
      doctor,
      cliente,
      pet,
      notes
    };
    this.props.addApointment(info);
    let { vaccine, observaciones } = this.state;
    let infoForVaccine = {
      vaccine,
      observaciones,
      pet,
      nextvaccine: inDate
    };
    this.props.addVaccine(infoForVaccine);
    this.setState({ inDate: null, time: "" });
    this.hideModal();
  };

  registerNewVaccineWithoutNextDate = e => {
    e.preventDefault();
    let { vaccine, observaciones, pet } = this.state;
    let info = {
      vaccine,
      observaciones,
      pet
    };
    this.props.addVaccine(info);
    this.hideModal();
  };

  registerNewDewormerWithoutNextDate = e => {
    e.preventDefault();
    let { vaccine, observaciones, pet } = this.state;
    let info = {
      dewormer: vaccine,
      observaciones,
      pet
    };
    this.props.addDewormer(info);
    this.hideModal();
  };

  registerNewDewormerWithAppointment = e => {
    e.preventDefault();
    let { inDate, doctor, pet, notes, time, cliente } = this.state;
    let info = {
      date: inDate,
      time,
      doctor,
      cliente,
      pet,
      notes
    };
    this.props.addApointment(info);
    let { vaccine, observaciones } = this.state;
    let infoForVaccine = {
      dewormer: vaccine,
      observaciones,
      pet,
      nextdewormer: inDate
    };
    this.props.addDewormer(infoForVaccine);
    this.hideModal();
    this.setState({ inDate: null, time: "" });
  };

  render() {
    if (this.props.profileLoading || this.props.vaccinesLoading) {
      return <Loading></Loading>;
    }

    return (
      <div>
        {this.state.showVaccineForm ? (
          <Modal show={this.state.showVaccineForm} handleClose={this.hideModal}>
            <span class="badge badge-light">Datos vacuna actual</span>
            <NewVaccine
              onChange={this.handleChange}
              label="Vacuna"
              placeholder="Nombre de la vacuna"
            ></NewVaccine>
            <button
              className="btn btn-danger"
              onClick={this.registerNewVaccineWithoutNextDate}
            >
              Registrar vacuna sin registrar nueva cita
            </button>
            <br />
            <span class="badge badge-light">Datos siguiente vacuna</span>
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 text-center">
                <Calendar
                  onChange={this.handeCalendarChange}
                  minDate={this.state.date}
                />
              </div>
              {this.state.inDate ? (
                <Fragment>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <p>Horarios disponibles de atención</p>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={this.handleChange}
                      name="time"
                    >
                      <option selected>Elige una opción...</option>

                      {this.props.schedule ? (
                        this.props.schedule.forEach(val => {
                          times = arrayRemove(times, val.time);
                        })
                      ) : (
                        <Loading />
                      )}
                      {times.map(time => {
                        return <option value={time}>{time}</option>;
                      })}
                    </select>
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1">
                        Notas proxima cita
                      </label>
                      <textarea
                        class="form-control"
                        name="notes"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={this.handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <ul>
                      <li>
                        <strong>Detalles cita</strong>
                      </li>
                      <li>Atiende: {this.props.user.first_name}</li>
                      <li>Fecha:{this.state.inDate}</li>
                      <li>
                        Observaciones próxima cita:
                        {this.state.observaciones | "No especifica"}
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>Detalles cliente</strong>
                      </li>
                      <li>Tel: {this.props.profile.phone}</li>
                      <li>Email:{this.state.selectedPed.owner.email}</li>
                    </ul>
                    <button
                      className="btn btn-warning"
                      onClick={this.registerNewVaccineWithAppointment}
                    >
                      Registrar vacuna con nueva cita
                    </button>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <p>Selecciona una fecha</p>
                </Fragment>
              )}
            </div>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {this.state.showDewormerForm ? (
          <Modal
            show={this.state.showDewormerForm}
            handleClose={this.hideModal}
          >
            <span class="badge badge-light">Datos desparasitante actual</span>
            <NewVaccine
              onChange={this.handleChange}
              label="Desparasitante"
              placeholder="Nombre del desparasitante"
            ></NewVaccine>
            <button
              className="btn btn-danger"
              onClick={this.registerNewDewormerWithoutNextDate}
            >
              Registrar desparasitante sin registrar nueva cita
            </button>
            <br />
            <span class="badge badge-light">
              Datos siguiente desparasitante
            </span>
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 text-center">
                <Calendar
                  onChange={this.handeCalendarChange}
                  minDate={this.state.date}
                />
              </div>
              {this.state.inDate ? (
                <Fragment>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <p>Horarios disponibles de atención</p>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={this.handleChange}
                      name="time"
                    >
                      <option selected>Elige una opción...</option>

                      {this.props.schedule ? (
                        this.props.schedule.forEach(val => {
                          times = arrayRemove(times, val.time);
                        })
                      ) : (
                        <Loading />
                      )}
                      {times.map(time => {
                        return <option value={time}>{time}</option>;
                      })}
                    </select>
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1">
                        Notas proxima cita
                      </label>
                      <textarea
                        class="form-control"
                        name="notes"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={this.handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <ul>
                      <li>
                        <strong>Detalles cita</strong>
                      </li>
                      <li>Atiende: {this.props.user.first_name}</li>
                      <li>Fecha:{this.state.inDate}</li>
                      <li>
                        Observaciones próxima cita:
                        {this.state.observaciones | "No especifica"}
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>Detalles cliente</strong>
                      </li>
                      <li>Tel: {this.props.profile.phone}</li>
                      <li>Email:{this.state.selectedPed.owner.email}</li>
                    </ul>
                    <button
                      className="btn btn-warning"
                      onClick={this.registerNewDewormerWithAppointment}
                    >
                      Registrar desparasitante con nueva cita
                    </button>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <p>Selecciona una fecha</p>
                </Fragment>
              )}
            </div>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <div className="cotainer-fluid">
          <div
            className="row"
            style={{
              height: "4rem",
              backgroundColor: "#ffa801",
              padding: "1rem"
            }}
          >
            <div className="col">
              <div className="row justify-content-center">
                <p style={{ alignSelf: "center", textAlign: "center" }}>
                  Control de vacunas y desparasitantes
                </p>
              </div>
            </div>
          </div>
          {/*Row table of pets */}
          <div className="row">
            <div className="col-12 d-flex pb-0 pt-0 justify-content-center align-items-center">
                <form className="row row-cols-lg-auto g-3 align-items-center m-2" onSubmit={e=>{
                  e.preventDefault();
                  this.props.searchPets(document.getElementById("SearchQuery").value);
                }}>
                  <div className="col-8">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="fa fa-search"></i>
                      </div>
                      <input type="text" className="form-control" placeholder="Nombre, Apellido, Telefono, Correo"
                      id="SearchQuery" name="query"/>
                    </div>
                  </div>
                  <div className="col-4">
                    <button type="submit" className="btn btn-primary">Buscar</button>
                  </div>
                </form>
              </div>
          </div>
          <div className="row justify-content-center p2-5 pb-2">
            <div
              style={{ alignSelf: "center", flexBasis: "50%", margin: "1rem" }}
            >
              <MaterialTable
                localization={{
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Paciente, Dueño"
                  },
                  body: {
                    emptyDataSourceMessage: "Ningún registro"
                  },
                  header: {
                    actions: "Acciones"
                  }
                }}
                options={{
                  pageSize: 5,
                  headerStyle: {
                    backgroundColor: "#f39c12",
                    color: "white",
                    paddingLeft: "20px"
                  },
                  showFirstLastPageButtons: false,
                  showTextRowsSelected: false
                }}
                columns={[
                  /*{
                          title: "ID",
                          field: "id"
                        },*/
                  {
                    title: "Paciente",
                    field: "name"
                  },
                  {
                    title: "Dueño username",
                    field: "owner.username"
                  },
                  {
                    title: "Dueño nombre",
                    field: "owner.first_name"
                  }
                ]}
                title="Pacientes"
                data={this.props.pets}
                padding="dense"
                onRowClick={(event, rowData) => {
                  event.preventDefault();
                  this.setState({ selectedPed: rowData });
                  this.props.getCertainProfile(rowData.owner.id);
                  this.props.getVaccines(rowData.id);
                  this.props.getDewormers(rowData.id);
                  this.setState({
                    doctor: this.props.user.id,
                    pet: rowData.id,
                    cliente: rowData.owner.id
                  });
                }}
              />
            </div>
            {this.state.selectedPed ? (
              <div
                className="row align-items-center justify-content-center mt-2"
                style={{ justifyContent: "center" }}
              >
                <div className="col-xl-12 col-lg-6 col-md-6 col-sm-5 text-center">
                  <div
                    onClick={() => {
                      this.setState({
                        workOnDewormer: true,
                        workOnVaccine: false
                      });
                    }}
                  >
                    <img
                      src={ParasiteIcon}
                      alt="ParasiteIcon"
                      id="ParasiteIcon"
                      style={{ maxWidth: "3rem" }}
                    />
                    <br />
                    <label htmlFor="ParasiteIcon">Desparasitación</label>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-6 col-md-6 col-sm-5 text-center">
                  <div
                    onClick={() => {
                      this.setState({
                        workOnVaccine: true,
                        workOnDewormer: false
                      });
                    }}
                  >
                    <img
                      src={VaccineIcon}
                      alt="VaccineIcon"
                      id="VaccineIcon"
                      style={{ maxWidth: "3rem" }}
                    />
                    <br />
                    <label htmlFor="VaccineIcon">Vacunación</label>
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
          {/*Row DIV */}
          <div className="row justify-content-center pt-5 pb-5">
            <div
              style={{
                alignSelf: "center",
                flexBasis: "100%",
                backgroundColor: "#1e272e",
                color: "white"
              }}
            >
              <p className="text-center">
                <strong>
                  {this.state.selectedPed ? (
                    this.state.selectedPed.name
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {this.state.workOnVaccine
                    ? `:Registro de Vacunación `
                    : this.state.workOnDewormer
                    ? `:Registro de Desparasitación`
                    : ""}
                </strong>
              </p>
            </div>
          </div>
          {/*Row puls and history icons */}

          {/*Row table files */}
          <div className="row justify-content-center mb-2">
            {this.state.selectedPed ? (
              <React.Fragment>
                {this.state.workOnVaccine ? (
                  <React.Fragment>
                    {this.props.vaccines.length > 0 ||
                    this.state.showVaccineHistory ? (
                      <React.Fragment>
                        <ul>
                          <li>
                            <strong>Cliente</strong>
                          </li>

                          <li>
                            {this.state.selectedPed.owner.first_name || null}
                          </li>
                          <li>Tel: {this.props.profile.phone || ""}</li>
                          <li>Dirección {this.props.profile.address || ""}</li>
                          <li>{this.state.selectedPed.owner.email || ""}</li>
                        </ul>
                        <ul>
                          <li>
                            <strong>Paciente</strong>
                          </li>
                          <li>{this.state.selectedPed.name || ""}</li>
                          <li>{this.state.selectedPed.race || ""}</li>
                          <li>{this.state.selectedPed.species || ""}</li>
                          <li>{this.state.selectedPed.color || ""}</li>
                        </ul>
                        <div
                          style={{
                            alignSelf: "center",
                            flexBasis: "85%",
                            overflow: "scroll"
                          }}
                        >
                          <MaterialTable
                            title="Ficha de vacunación"
                            padding="dense"
                            data={this.props.vaccines}
                            columns={[
                              { title: "Fecha", field: "date" },
                              { title: "Vacuna", field: "vaccine" },
                              {
                                title: "Proxima",
                                field: "nextvaccine"
                              },
                              {
                                title: "Observaciones",
                                field: "observaciones"
                              }
                            ]}
                          ></MaterialTable>
                          <button
                            className="btn"
                            onClick={e => {
                              e.preventDefault();
                              this.showModal();
                            }}
                          >
                            <img
                              src={PlusIcon}
                              alt="AddIcon"
                              id="AddIcon"
                              style={{ maxWidth: "3rem" }}
                            />
                            <br />
                            <label htmlFor="AddIcon">Vacuna</label>
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <FileNotFound />
                        <button
                          className="btn btn-info"
                          style={{
                            flexBasis: "50%",
                            maxWidth: "50%",
                            alignSelf: "center"
                          }}
                          onClick={e => {
                            e.preventDefault();
                            console.log("Perform create of vaccine file");
                            /*this.setState({ hasVaccineFile: true });*/
                            this.setState({ showVaccineHistory: true });
                            this.setState({ workOnDewormerFile: false });
                          }}
                        >
                          Crear nueva ficha
                        </button>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {this.state.workOnDewormer ? (
                  <React.Fragment>
                    {this.props.dewormers.length > 0 ||
                    this.state.showDewormerHistory ? (
                      <React.Fragment>
                        <ul>
                          <li>
                            <strong>Cliente</strong>
                          </li>

                          <li>
                            {this.state.selectedPed.owner.first_name || "null"}
                          </li>
                          <li>Tel. {this.props.profile.phone || ""}</li>
                          <li>
                            Direccion: {this.props.profile.address || ""}{" "}
                          </li>
                          <li>
                            Email:{" "}
                            {this.state.selectedPed.owner.email || "null"}
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <strong>Paciente</strong>
                          </li>
                          <li>{this.state.selectedPed.name || "null"}</li>
                          <li>{this.state.selectedPed.race || "null"}</li>
                          <li>{this.state.selectedPed.species || "null"}</li>
                          <li>{this.state.selectedPed.color || "null"}</li>
                        </ul>
                        <div
                          style={{
                            alignSelf: "center",
                            flexBasis: "85%",
                            overflow: "scroll"
                          }}
                        >
                          <MaterialTable
                            title="Ficha de desparasitación"
                            padding="dense"
                            options={{
                              exportButton: true
                            }}
                            data={this.props.dewormers}
                            columns={[
                              { title: "Fecha", field: "date" },
                              {
                                title: "Desparasitante",
                                field: "dewormer"
                              },
                              {
                                title: "Proxima",
                                field: "nextdewormer"
                              },
                              {
                                title: "Observaciones",
                                field: "observaciones"
                              }
                            ]}
                          ></MaterialTable>
                          <button
                            className="btn"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({ showDewormerForm: true });
                            }}
                          >
                            <img
                              src={PlusIcon}
                              alt="AddIcon"
                              id="AddIcon"
                              style={{ maxWidth: "3rem" }}
                            />
                            <br />
                            <label htmlFor="AddIcon">Desparasitante</label>
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <FileNotFound />
                        <button
                          className="btn btn-info"
                          style={{
                            flexBasis: "50%",
                            maxWidth: "50%",
                            alignSelf: "center"
                          }}
                          onClick={e => {
                            e.preventDefault();
                            console.log("Perform create of dewormer file");
                            this.setState({ hasDewormerFile: true });
                            this.setState({ showDewormerHistory: true });
                            this.setState({ workOnVaccineFile: false });
                          }}
                        >
                          Crear nueva ficha
                        </button>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment> </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {" "}
                <h1>Selecciona una mascota</h1>{" "}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pets: state.RedPets.pets,
  /*isAuthenticated: state.RedAuth.isAuthenticated,*/
  /*apointments: state.RedApoint.apointments,*/
  /*loading: state.RedApoint.loading,*/
  vaccinesLoading: state.RedRecords.loading,
  profileLoading: state.Profile.loading,
  schedule: state.RedApoint.schedule,
  vaccines: state.RedRecords.records,
  dewormers: state.RedRecords.dewormerRecords,
  profile: state.Profile.profileInfo,
  user: state.RedAuth.user
  /*user: state.RedAuth.user*/
});

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section
        className="modal-main clients-frame"
        style={{ overflow: "scroll" }}
      >
        <span class="circle" onClick={handleClose}></span>
        {children}
      </section>
    </div>
  );
};

const FileNotFound = function() {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-8 text-center">
            <img src={WantedIcon} alt="" style={{ maxWidth: "8rem" }} />
            <p>Oh! parece que aun no tienes una ficha registrada</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {
  getAllPets,
  getCertainProfile,
  getVaccines,
  getDaySchedule,
  addApointment,
  addVaccine,
  getDewormers,
  addDewormer,
  searchPets
})(VacunacionYDesparacitacion);
