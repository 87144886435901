import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  ADD_REFUND,
  GET_REFUND_HEADER,
  LOADED_REFUND_HEADER,
  ADD_REFUND_DETAIL,
  GET_DETAIL_REFUND,
  LOADED_REFUND_DETAIL,
  GET_ALL_REFUNDS_HEADER,
  DELETE_DETAILS_REFUNDS,
  DELETE_REFUND,
  __BASE__URL
} from "./types";
import { tokenConfig } from "./ActAuths";

export const addrefund = refund => (dispatch, getState) => {
  const url = __BASE__URL + "/api/refunds/";
  const body = JSON.stringify({
    ...refund
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ADD_REFUND,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//obtiene el encabezado para agregarle detalle
export const getRefundHeader = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/refundHeader/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_REFUND_HEADER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//obtiene todos los encabezados
export const getAllRefundsHeader = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/refunds/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_ALL_REFUNDS_HEADER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};



export const LoadedHeader = () => dispatch => {
  //para volver falsa la condicion despues de cargado el encabezado de la devolucion
  dispatch(createMessage({ addrefund: "Devolucion exitosa!" }));
  dispatch({ type: LOADED_REFUND_HEADER });
};

export const addrefunddetail = refunddetail => (dispatch, getState) => {
  const url = __BASE__URL + "/api/refundDetail/";
  const body = JSON.stringify({
    ...refunddetail
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ADD_REFUND_DETAIL
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDeatailsOfHeader = id_refund => (dispatch, getState) => {
  const body = JSON.stringify({
    id_refund
  });

  const url = __BASE__URL + "/api/refundDetail/details";
  try {
    axios
      .post(url, body, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_DETAIL_REFUND,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const LoadedDetail = () => dispatch => {
  //para volver falsa la condicion despues de cargado el encabezado de la devolucion
  dispatch({ type: LOADED_REFUND_DETAIL });
};


//deletes
//-----------DELETE DETAILS--------------
export const deletedetailsRefunds = id => (dispatch, getState) => {
  const url = __BASE__URL + "/api/refundDetailDelete/";
   try {
  axios
    .delete(url + id, tokenConfig(getState))
    .then(res => {
      //mensahe
      dispatch(
        //createMessage({ deleteCategory: "Categoria Eliminada con Exito!" })
      );
      dispatch({
        type: DELETE_DETAILS_REFUNDS,
        payload: id
      });
    })
    .catch(err =>
      console.log(err)
      //dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
   }catch (error) {
    //console.log(error);
  }
};

export const deleteRefund = id => (dispatch, getState) => {
  const url = __BASE__URL + "/api/refunds/";
  axios
    .delete(url + id, tokenConfig(getState))
    .then(res => {
      //mensahe
      dispatch(
        createMessage({ deleteRefund: "Devolucion eliminada con exito!" })
      );
      dispatch({
        type: DELETE_REFUND,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};