import React, { Component } from "react";
import Calendar from "react-calendar";
import Loading from "../common/Loading";
/*import PetAvatarList from "../common/PetAvatar";*/
import MaterialTable from "material-table";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getVets } from "../../actions/ActVets";
import { getDaySchedule } from "../../actions/ActApointments";
import "./styles/DateManagement.css";
import Dateresume from "../common/Dateresume";
import { createMessage } from "../../actions/ActMessages";
import { addApointment, getAvailableDays } from "../../actions/ActApointments";
import { Redirect } from "react-router-dom";
import "./scripts/scripts";

var times = [
  /*"09:00",
  "09:30",*/
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  /*"19:00",
  "19:30"*/
];

class DateManagement extends Component {
  state = {
    step: 1,
    redirectToReferrer: false,
    date: new Date(),
  };

  static propTypes = {
    pets: PropTypes.array.isRequired,
    vets: PropTypes.array.isRequired,
    getVets: PropTypes.func.isRequired,
    getDaySchedule: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    addApointment: PropTypes.func.isRequired,
    getAvailableDays: PropTypes.func.isRequired,
    availabledays: PropTypes.object,
    cliente: PropTypes.number.isRequired,
    achived: PropTypes.bool.isRequired,
  };

  handleChange = (e) => {
    // eslint-disable-next-line
    if ([e.target.name] == "apointment") {
      let selectedoption = document.getElementById("exampleFormControlSelect1");
      this.setState({
        [e.target.name]: selectedoption.value,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handeCalendarChange = (date) => {
    let inDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ inDate });
    this.props.createMessage({
      selectedDate:
        "Cita programada para el dia " +
        inDate +
        " selecciona una hora en el resumen.",
    });

    this.props.getDaySchedule(inDate, this.state.veterinary);
    this.nextStep();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.apointment) {
      this.setState({ disabledButton: true });
      let { inDate, veterinary, pet, notes, apointment } = this.state;
      let info = {
        date: inDate,
        doctor: veterinary,
        cliente: this.props.cliente,
        time: apointment,
        pet,
        notes,
      };

      this.props.addApointment(info);
    } else {
      this.props.createMessage({
        mustSelectDate: "Debe seleccionar una hora valida para la cita.",
      });
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    if (e.target.name === "pet") {
      this.props.pets.filter((p) => {
        // eslint-disable-next-line
        if (p.id == e.target.alt) {
          this.props.createMessage({
            selectedPet: "La cita sera para " + p.name,
          });
          this.setState({ petname: p.name });
        }
        return <React.Fragment></React.Fragment>;
      });
      this.setState({ [e.target.name]: e.target.alt });
    } else if (e.target.name === "veterinary") {
      this.props.vets.filter((veterinario) => {
        // eslint-disable-next-line
        if (veterinario.id == e.target.alt) {
          this.setState({ vetname: veterinario.first_name });
          this.props.createMessage({
            selectedVet: "Te atendera " + veterinario.first_name,
          });

          this.setState({ veterinary: veterinario.id });
        }
        return <React.Fragment></React.Fragment>;
      });
    } else {
      this.setState({ [e.target.name]: e.target.alt });
    }
  };

  //Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  componentDidMount() {
    this.props.getVets();
  }

  render() {
    if (this.props.achived === true) {
      this.props.createMessage({ addApointment: "Cita agendada" });
      return <Redirect to="/dashboard" />;
    }
    switch (this.state.step) {
      case 1:
        return (
          <React.Fragment>
            <div className="step-form">
              <div className="row h-100 ">
                <div className="col text-center my-auto">
                  <div className="social-icons">
                    <button className="social-icon instagram">
                      <i className="fas fa-user-md" />
                    </button>
                    <h5>1. ¿Quién te atendera?</h5>
                  </div>

                  <MaterialTable
                    localization={{
                      toolbar: {
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Cliente",
                      },
                      body: {
                        emptyDataSourceMessage:
                          "No se encuentran coincidencias.",
                      },
                      header: {
                        actions: "Seleccionar",
                      },
                    }}
                    options={{
                      pageSize: 3,
                      headerStyle: {
                        backgroundColor: "#01579b",
                        color: "white",
                        paddingLeft: "20px",
                      },
                      /*actionsColumnIndex: -1,*/
                      detailPanelColumnAlignment: "right",
                    }}
                    columns={[
                      {
                        title: "Nombre",
                        field: "first_name",
                      },
                    ]}
                    data={this.props.vets}
                    title="Veterinarios"
                    actions={[
                      {
                        icon: "add",
                        tooltip: "Seleccionar",
                        onClick: (event, rowData) => {
                          event.preventDefault();
                          console.log(rowData.id);
                          this.props.createMessage({
                            selectedApointmentPet:
                              "Atendera " + rowData.first_name,
                          });

                          this.setState({
                            veterinary: rowData.id,
                            vetname: rowData.first_name,
                          });
                          this.nextStep();
                        },
                      },
                    ]}
                    onRowClick={(event, rowData) => {
                      event.preventDefault();
                      console.log(rowData.id);
                      this.props.createMessage({
                        selectedApointmentPet: "Atendera " + rowData.first_name,
                      });

                      this.props.getAvailableDays(rowData.id);

                      this.setState({
                        veterinary: rowData.id,
                        vetname: rowData.first_name,
                      });

                      this.nextStep();
                    }}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <div className="step-form">
              <div className="row h-100 ">
                <div className="col text-center my-auto">
                  <div className="social-icons">
                    <button className="social-icon instagram">
                      <i class="fas fa-paw"></i>
                    </button>
                    <h5>3. Selecciona tu mascota</h5>
                  </div>

                  <MaterialTable
                    localization={{
                      toolbar: {
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Cliente",
                      },
                      body: {
                        emptyDataSourceMessage:
                          "No se encuentran coincidencias.",
                      },
                      header: {
                        actions: "Seleccionar",
                      },
                    }}
                    options={{
                      pageSize: 3,
                      headerStyle: {
                        backgroundColor: "#01579b",
                        color: "white",
                        paddingLeft: "20px",
                      },
                      /*actionsColumnIndex: -1,*/
                      detailPanelColumnAlignment: "right",
                    }}
                    columns={[
                      {
                        title: "Nombre",
                        field: "name",
                      },
                      {
                        title: "Edad",
                        field: "age",
                      },
                    ]}
                    data={this.props.pets}
                    title="Lista de mascotas"
                    actions={[
                      {
                        icon: "add",
                        tooltip: "Selecciona una mascota",
                        onClick: (event, rowData) => {
                          event.preventDefault();
                          console.log(rowData.id);
                          this.props.createMessage({
                            selectedApointmentPet:
                              "La cita sera para " + rowData.name,
                          });
                          this.setState({
                            pet: rowData.id,
                            petname: rowData.name,
                          });
                          this.nextStep();
                        },
                      },
                    ]}
                    onRowClick={(event, rowData) => {
                      event.preventDefault();
                      console.log(rowData.id);
                      this.props.createMessage({
                        selectedApointmentPet:
                          "La cita sera para " + rowData.name,
                      });
                      this.setState({
                        pet: rowData.id,
                        petname: rowData.name,
                      });
                      this.nextStep();
                    }}
                  />
                  <div className="d-flex justify-content-center mt-2">
                    <button
                      className="btn btn-warning justify-content-end  mr-2"
                      onClick={this.prevStep}
                    >
                      Regresar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <div className="step-form">
              <div className="row h-100 ">
                <div className="col text-center my-auto">
                  <div className="social-icons">
                    <button className="social-icon behance">
                      <i className="far fa-calendar-alt" />
                    </button>
                    <h5>4. Acordemos un día</h5>
                  </div>
                  {this.props.VetState.loading ? (
                    <Loading />
                  ) : (
                    <React.Fragment>
                      <div className="text-center justify-content-around d-flex pb-5">
                        <Calendar
                          onChange={this.handeCalendarChange}
                          minDate={this.state.date}
                          tileDisabled={({ date, view }) =>
                            [
                              !this.props.availabledays.lunes ? 1 : 1,
                              !this.props.availabledays.martes ? 2 : null,
                              !this.props.availabledays.miercoles ? 3 : null,
                              !this.props.availabledays.jueves ? 4 : null,
                              !this.props.availabledays.viernes ? 5 : null,
                              !this.props.availabledays.sabado ? 6 : null,
                              !this.props.availabledays.domingo ? 0 : 0,
                            ].some(
                              (disabledTile) => date.getDay() === disabledTile
                            )
                          }

                          /*tileDisabled={({ date }) =>
                            !this.props.availabledays.lunes
                              ? date.getDay() === 1
                              : !this.props.availabledays.martes
                              ? date.getDay() === 2
                              : !this.props.availabledays.miercoles
                              ? date.getDay() === 3
                              : !this.props.availabledays.jueves
                              ? date.getDay() === 4
                              : !this.props.availabledays.viernes
                              ? date.getDay() === 5
                              : !this.props.availabledays.sabado
                              ? date.getDay() === 6
                              : !this.props.availabledays.domingo
                              ? date.getDay === 0
                              : console.log("Trabaja todos los dias")
                          }*/
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <div className="d-flex justify-content-center mt-2">
                    <button
                      className="btn btn-warning justify-content-end  mr-2"
                      onClick={this.prevStep}
                    >
                      Regresar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <div className="step-form">
              <div className="row h-100">
                <div className="col text-center my-auto">
                  <div className="social-icons">
                    <button className="social-icon behance">
                      <i className="far fa-calendar-alt" />
                    </button>
                    <h5>3. Horario y Detalles</h5>
                  </div>
                  <Dateresume
                    veterinary={this.state.vetname}
                    pet={this.state.petname}
                    date={this.state.inDate}
                    time={this.state.time}
                    onChange={this.handleChange}
                    times={times}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    Cuentanos el motivo de tu vistia, podremos atenderte mejor
                    de esta manera.
                  </small>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Notas</span>
                    </div>
                    <textarea
                      name="notes"
                      className="form-control"
                      aria-label="Notas"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-warning justify-content-end  mt-2 mr-2"
                      onClick={this.prevStep}
                    >
                      Regresar
                    </button>
                    <form onSubmit={this.handleSubmit}>
                      {this.state.disabledButton ? (
                        <button
                          type="submit"
                          className="btn btn-block btn-secondary mt-2"
                          disabled
                        >
                          Notificando cita...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-block btn-success mt-2"
                        >
                          Solicitar cita
                        </button>
                      )}
                    </form>
                  </div>

                  <small id="emailHelp" className="form-text text-muted">
                    Al solicitar cita estas aceptando los terminos y condiciones
                    de nuestro servicio.
                  </small>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <h2>Hola Defauls</h2>
          </React.Fragment>
        );
    }
  }
}

const mapStateToProps = (state) => ({
  pets: state.RedPets.pets,
  vets: state.RedVets.vets,
  VetState: state.RedVets,
  cliente: state.RedAuth.user.id,
  availabledays: state.RedApoint.availabledays,
  achived: state.RedApoint.achive,
});
export default connect(mapStateToProps, {
  getVets,
  getDaySchedule,
  createMessage,
  addApointment,
  getAvailableDays,
})(DateManagement);
