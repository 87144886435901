import React, { Component } from "react";

import { PetCard } from "../common/PetCard";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pet from "../Forms/Pet";
import { Redirect } from "react-router-dom";
import { addPet } from "../../actions/ActPets";
import { createMessage } from "../../actions/ActMessages";
/*import dogOnWindow from "../../assets/icons/dogOnWindow.svg";*/
class PetsManagment extends Component {
  state = { form: { species: null, race: null }, redirect: false };
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    pets: PropTypes.array.isRequired,
    createMessage: PropTypes.func.isRequired,
    addPet: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.form.species !== null && this.state.race !== null) {
      let pet = { ...this.state.form };
      pet = {
        ...pet,
        owner: this.props.user.id,
      };
      this.props.addPet(pet);
      this.setState({ redirect: true });
    } else {
      this.props.createMessage({
        allFieldsRequiredOnPetForm:
          "Seleciona una especie y una raza antes de continuar",
      });
    }
  };

  render() {
    if (this.state.redirect === true) {
      this.props.createMessage({
        petCreated: "¡Su mascota ha sido registrada!",
      });
      this.setState({ redirect: false });
      return <Redirect to="/dashboard" />;
    }
    return (
      <React.Fragment>
        <div className="container">
          <p className="text-primary text-center p-5">Gestion de mascotas</p>
          <p className="text-center text-muted">
            Ingresa los datos de tu mascota, para que podamos brindarte un mejor
            servicio.
          </p>
          <div className="row pt-5">
            <div className="col-lg-6 col-12 col-md-6 mx-auto">
              <Pet
                onChange={this.handleChange}
                formValues={this.state.form}
                onSubmit={this.handleSubmit}
              />
            </div>
            <div className="col-lg-6 col-12 col-md-6 mx-auto">
              <h1 className="text-primary text-center">Vista previa</h1>
              <PetCard
                name={this.state.form.name}
                age={this.state.form.age}
                race={this.state.form.race}
                species={this.state.form.species}
                age_months={this.state.form.age_months}
                color={this.state.form.color}
                reproductive_state={this.state.form.reproductive_state}
                is_preview="1"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.RedAuth.isAuthenticated,
  user: state.RedAuth.user,
  pets: state.RedPets.pets,
  /*Bajar a props el atributo TIPO USUARIO PARA DECIDIR QUE DASHBOARD DESPLEGAR */
});
export default connect(mapStateToProps, { addPet, createMessage })(
  PetsManagment
);
