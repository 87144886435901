import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  GET_PETS /*, DELETE_PET, */,
  ADD_PET,
  LOADING_PETS,
  __BASE__URL,
} from "./types";
import { tokenConfig } from "./ActAuths";
/*import { createMessage } from "./ActMessages";*/

export const getPets = () => async (dispatch, getState) => {
  try {
    const url = __BASE__URL + "/api/user/pet/";
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: GET_PETS, payload: res.data });
      })
      .catch((err) =>
        dispatch(returnErrors(err?.response?.data, err?.response?.data))
      );
  } catch (error) {
    console.log(error);
  }
};

export const searchPets = (query) => (dispatch, getState) => {
  try {
    const url = __BASE__URL + "/api/pets/search";
    dispatch({ type: LOADING_PETS });
    axios
      .get(
        url,
        {
          params: {
            query: query,
          },
        },
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_PETS,
          payload: res.data.filter((pet) => (pet.status === true)),
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getUserPets = (info) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/user/pets";
  const body = JSON.stringify({
    ...info,
  });
  dispatch({ type: LOADING_PETS });
  axios
    .post(url, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PETS,
        payload: res.data.filter((pet) => (pet.status === true)),
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const addPet = (pet) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/user/pet/";
  axios
    .post(url, pet, tokenConfig(getState))
    .then((res) => {
      createMessage({ addPet: "¡Tu mascota ha sido registrada con exito!" });
      dispatch({ type: ADD_PET, payload: res.data });
    })
    .catch((err) =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const updatePet = (pet, id) => (dispatch, getState) => {
  const url = __BASE__URL + `/api/user/pet/${id}/`;
  console.log(url);
  axios
    .patch(url, pet, tokenConfig(getState))
    .then((res) => {
      createMessage({ addPet: res });
    })
    .catch((err) =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getAllPets = () => (dispatch, getState) => {
  dispatch({ type: LOADING_PETS });
  try {
    const url = __BASE__URL + "/api/allpets/";
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_PETS,
          payload: res.data.filter((pet) => pet.status === true),
        });
      })
      .catch((err) =>
        dispatch(returnErrors(err?.response?.data, err?.response?.data))
      );
  } catch (error) {
    console.log(error);
  }
};
