import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  APOINTMENTS_LOADING,
  APOINTMENTS_LOADED,
  DAYSCHEDULE_LOADING,
  UPDATE_APPOINTMENT,
  DAYSCHEDULE_LOADED,
  ADD_APOINTMENT,
  ADD_APOINTMENTERROR,
  DAYAPOINTMENTS_LOADING,
  DAYAPOINTMENTS_LOADED,
  NONGROOMAPPOINTMENTS_LOADED,
  GROOMAPPOINTMENTS_LOADED,
  __BASE__URL,
  HISTORY_FILE_FINISH,
  CLINIC_COUNT,
  GROOM_COUNT,
  LOADING_AVAILABLE_DAYS,
  AVAILABLE_DAYS_LOADED,
  CLEAN_APPOINTMENTS,
} from "./types";
import { tokenConfig } from "./ActAuths";

export const getClinicAppointmentCount = () => (dispatch, getState) => {
  dispatch({ type: APOINTMENTS_LOADING });
  try {
    const url = __BASE__URL + "/api/allClinicAppointments";
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: CLINIC_COUNT, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getGroomAppointmentCount = () => (dispatch, getState) => {
  dispatch({ type: APOINTMENTS_LOADING });
  try {
    const url = __BASE__URL + "/api/allGroomAppointments";
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: GROOM_COUNT, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getApointments = () => (dispatch, getState) => {
  dispatch({ type: APOINTMENTS_LOADING });
  try {
    const url = __BASE__URL + "/api/apointments/";
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: APOINTMENTS_LOADED, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

/*This method returns the aviabled times in a specific date */
export const getDaySchedule = (inDate, veterinary) => (dispatch, getState) => {
  dispatch({ type: DAYSCHEDULE_LOADING });

  const body = JSON.stringify({ inDate, veterinary });
  console.log(body);
  const url = __BASE__URL + "/api/apointments/schedule";
  try {
    axios
      .post(url, body, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: DAYSCHEDULE_LOADED, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

/*THIs method returns the apointments on a specific date, it will store it at RedApoint */
export const getDayApointments =
  (inDate, veterinary) => async (dispatch, getState) => {
    dispatch({ type: DAYAPOINTMENTS_LOADING });
    const body = JSON.stringify({ inDate, veterinary });
    const url = __BASE__URL + "/api/apointments/day";
    try {
      await axios
        .post(url, body, tokenConfig(getState))
        .then((res) => {
          dispatch({ type: DAYAPOINTMENTS_LOADED, payload: res.data });
          console.log(res);
        })
        .catch((err) => {
          dispatch(returnErrors(err?.response?.data, err?.response?.data));
        });
    } catch (error) {
      console.log(error);
    }
  };

export const addApointment = (apointment) => (dispatch, getState) => {
  const url = __BASE__URL + "/api/apointments/";
  const body = JSON.stringify({
    ...apointment,
  });

  axios
    .post(url, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_APOINTMENT });
      dispatch({ type: ADD_APOINTMENTERROR });
      createMessage({
        appointmentEnded: "Cita agendada",
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
      dispatch({ type: ADD_APOINTMENTERROR });
    });
};

/*This method is returning all the aviabled apointments on a certain date */
export const getAllApointments = () => (dispatch, getState) => {
  dispatch({ type: APOINTMENTS_LOADING });
  try {
    const url = __BASE__URL + "/api/secretary/apointments/";
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: APOINTMENTS_LOADED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

/*This method wil only return grooming dates */
export const getAllGroomApointments = () => (dispatch, getState) => {
  dispatch({ type: APOINTMENTS_LOADING });
  try {
    const url = __BASE__URL + "/api/secretary/apointments";
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GROOMAPPOINTMENTS_LOADED,
          payload: res.data.filter((cita) => cita.doctor.id === 484),
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getAppointmentsByRange = (start, end, state) => (dispatch, getState) => {
  dispatch({ type: APOINTMENTS_LOADING });
  try {
    const url = `${__BASE__URL}/api/apointmentsByRange?start_date=${start}&end_date=${end}&state=${state}`;
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: APOINTMENTS_LOADED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  }
  catch (error) {
    console.log(error);
  }
};
/*This method will only return NON grooming dates */
export const getAllOtherApointments = () => (dispatch, getState) => {
  dispatch({ type: APOINTMENTS_LOADING });
  try {
    const url = __BASE__URL + "/api/secretary/apointments";
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: NONGROOMAPPOINTMENTS_LOADED,
          payload: res.data.filter((cita) => cita.doctor.id !== 484),
        });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

/*THIS WILL SET THE STATUS SEND BY PARAMETER TO AN APPOINTMENT IN ORDER TO MANAGE THE DIFERENT STATES THAY THEY CAN BE */
export const updateAppointment = (info, id) => (dispatch, getState) => {
  dispatch({ type: APOINTMENTS_LOADING });
  try {
    const url = `${__BASE__URL}/api/apointment/update/${id}/`;
    console.log(url);
    axios
      .patch(url, info, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: UPDATE_APPOINTMENT });
        dispatch(
          createMessage({
            apointmentsOf: " Actualiza la página para ver estos cambios.",
          })
        );
      })
      .catch((err) => {
        // dispatch(returnErrors(err?.response?.data, err?.response?.data));
        console.log("Error ocurrido");
        console.log(err);
        dispatch(
          createMessage({
            nullPicture: "Oops, ha ocurrido un error, intentalo de nuevo.",
          })
        );
      });
  } catch (error) {
    console.log(error);
  }
};

export const archiveAppointment = (info, id) => (dispatch, getState) => {
  dispatch({ type: CLEAN_APPOINTMENTS });
  dispatch({ type: APOINTMENTS_LOADING });
  try {
    const url = `${__BASE__URL}/api/apointment/archive/${id}/`;
    console.log(url);
    axios
      .patch(url, info, tokenConfig(getState))
      .then((res) => {
        dispatch(
          createMessage({
            appointmentEnded: "¡La cita ha sido movida al archivo!",
          })
        );
        dispatch({ type: HISTORY_FILE_FINISH });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getPetHistory = (id) => (dispatch, getState) => {
  dispatch({ type: APOINTMENTS_LOADING });
  try {
    const url = `${__BASE__URL}/api/apointment/pethistory/${id}/`;
    console.log(url);
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch(
          createMessage({
            appointmentEnded: "Los archivos clinicos han sido cargados",
          })
        );
        dispatch({ type: APOINTMENTS_LOADED, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getOnDeliveryAppointment = () => (dispatch, getState) => {
  dispatch({ type: CLEAN_APPOINTMENTS});
  dispatch({ type: DAYAPOINTMENTS_LOADING });
  try {
    const url = `${__BASE__URL}/api/apointment/deliver/`;
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: DAYAPOINTMENTS_LOADED, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getHistory = () => (dispatch, getState) => {
  dispatch({ type: DAYAPOINTMENTS_LOADING });
  try {
    console.log("Getting history");
    const url = `${__BASE__URL}/api/apointment/history/`;
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: DAYAPOINTMENTS_LOADED, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getDoctorHistory = (id) => (dispatch, getState) => {
  dispatch({ type: DAYAPOINTMENTS_LOADING });
  try {
    const url = `${__BASE__URL}/api/admin/history/${id}/`;
    axios
      .get(url, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: DAYAPOINTMENTS_LOADED, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getAvailableDays = (id) => async (dispatch, getState) => {
  dispatch({ type: LOADING_AVAILABLE_DAYS });
  try {
    const url = `${__BASE__URL}/api/availabledays/${id}/`;
    await axios
      .get(url, id, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: AVAILABLE_DAYS_LOADED, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const updateAvailableDays = (info, id) => (dispatch, getState) => {
  dispatch({ type: LOADING_AVAILABLE_DAYS });
  try {
    const url = `${__BASE__URL}/api/availabledays/${id}/`;
    axios
      .patch(url, info, tokenConfig(getState))
      .then((res) => {
        dispatch(
          createMessage({
            appointmentEnded: "¡Dias de trabajo actualizados!",
          })
        );
        dispatch({ type: AVAILABLE_DAYS_LOADED, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};
