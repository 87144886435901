import axios from "axios";
import { returnErrors } from "./ActMessages";
import { CLIENTS_LOADING, CLIENTS_LOADED, __BASE__URL } from "./types";
import { tokenConfig } from "./ActAuths";

export const getClients = () => (dispatch, getState) => {
  dispatch({ type: CLIENTS_LOADING });
  try {
    const url = __BASE__URL + "/api/clients";
    axios
      .get(url, tokenConfig(getState))
      .then(res => {
        dispatch({ type: CLIENTS_LOADED, payload: res.data });
      })
      .catch(error =>
        dispatch(returnErrors(error.response.data, error.response.status))
      );
  } catch (error) {
    console.log(error);
  }
};


export const searchClients = query => (dispatch, getState) => {
    try {
        const url = __BASE__URL + "/api/clients/search";
        dispatch({ type: CLIENTS_LOADING });
        axios
            .get(url, {
                params: {
                    "query": query
                }
            }, tokenConfig(getState))
            .then(res => {
                dispatch({ type: CLIENTS_LOADED, payload: res.data });
            }).catch(err => {
                dispatch(returnErrors(err?.response?.data, err?.response?.data));
            });
    } catch (error) {
        console.log(error);
    }
}

export const getClientPets = () => (dispatch, getState) => {
  console.log("Getting client pets");
};
