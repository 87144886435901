import React from "react";
/*Components */
/*import Header from "./components/layout/Header";*/
import Paths from "./components/common/Paths";
/*Redux */
import { Provider } from "react-redux";
import store from "./store";
/*React alert */
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Alerts from "./components/common/Alerts";
/*React router dom */
import { HashRouter as Router } from "react-router-dom";
/*Auth loading */
import { loadUser } from "./actions/ActAuths";
import Sidebar from "./components/layout/sidebar";

//Alert Options
const alertOptions = {
  timeout: 3000,
  postition: "bottom center"
};

class App extends React.Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }
  render() {
    return (
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Router>
            <React.Fragment>
              <Sidebar />
              <Alerts />
              <Paths />
            </React.Fragment>
          </Router>
        </AlertProvider>
      </Provider>
    );
  }
}

export default App;
