import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  GET_CATEGORIES,
  DELETE_CATEGORIES,
  UPDATE_CATEGORIES,
  ADD_CATEGORIES,
  __BASE__URL
} from "./types";
import { tokenConfig } from "./ActAuths";

//GET CATEGORIES
export const getCategories = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/categories/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_CATEGORIES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//ADD CATEGORY
export const addCategory = category => (dispatch, getState) => {
  const url = __BASE__URL + "/api/categories/";
  axios
    .post(url, category, tokenConfig(getState))
    .then(res => {
      dispatch(createMessage({ addCategory: "Categoria Agregada con Exito!" }));
      dispatch({
        type: ADD_CATEGORIES
        //payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//DELETE CATEGORY
export const deleteCategory = id => (dispatch, getState) => {
  const url = __BASE__URL + "/api/categories/";
  axios
    .delete(url + id, tokenConfig(getState))
    .then(res => {
      //mensahe
      dispatch(
        createMessage({ deleteCategory: "Categoria Eliminada con Exito!" })
      );
      dispatch({
        type: DELETE_CATEGORIES,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const updateCategory = (id, name, description) => (
  dispatch,
  getState
) => {
  const body = JSON.stringify({
    id,
    name,
    description
  });
  //console.log(body);

  const url = __BASE__URL + `/api/categories/updateCategory/${id}/`;
  try {
    axios
      .put(url, body, tokenConfig(getState))
      .then(res => {
        dispatch(
          createMessage({ updatecategory: "Categoria Actualizada con exito!" })
        );
        dispatch({
          type: UPDATE_CATEGORIES,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};
