import "./styles/Cards.css";

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ScheduleIcon from "../../assets/icons/schedule.svg";
import { createMessage } from "../../actions/ActMessages";
import {
  getDaySchedule,
  updateAppointment,
} from "../../actions/ActApointments";
/*import Calendar from "react-calendar";*/
import Dateresume from "../common/Dateresume";
import MaterialUIPickers from "../common/Calendar";

import { times } from "../../config/config";

var my_times = times;

/*var clinictimes = [
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
];
var groomtimes = [
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
];*/

class DateDetail extends Component {
  state = {
    redirect: false,
    reagendar: false,
    show: false,
    appointmentToAttend: null,
    rol: null,
    newDate: null,
  };

  static propTypes = {
    createMessage: PropTypes.func.isRequired,
    getDaySchedule: PropTypes.func.isRequired,
    updateAppointment: PropTypes.func.isRequired,
    addTracingFile: PropTypes.func.isRequired,
  };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handeCalendarChange = (date) => {
    let newDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ newDate });
    this.props.createMessage({
      apointmentsOf: "Horarios disponibles para el" + newDate,
    });
    this.props.getDaySchedule(newDate, this.props.user.id);
  };

  handleChange = (e) => {
    // eslint-disable-next-line
    if ([e.target.name] == "apointment") {
      let selectedoption = document.getElementById("exampleFormControlSelect1");
      this.setState({
        time: selectedoption.value,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleDateChange = (date) => {
    //console.log(e);

    let newDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ newDate });
    this.props.createMessage({
      apointmentsOf: "Horarios disponibles para el" + newDate,
    });
    this.setState({ newDate });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname:
              this.props.user.last_name === "veterinario"
                ? "/clinic/appointment"
                : "/grooming/appointment",
            appointmentToAttend: this.state.appointmentToAttend,
          }}
        />
      );
    }

    return (
      <React.Fragment>
        {this.state.show ? (
          <Modal
            show={this.state.show}
            handleClose={this.hideModal}
            style={{ maxWidth: "100%" }}
          >
            <h4>Reagendar cita</h4>
            <MaterialUIPickers
              date={this.state.newDate}
              handleDateChange={this.handleDateChange}
            ></MaterialUIPickers>
            {this.state.newDate ? (
              this.props.user.last_name === "veterinario" ? (
                <React.Fragment>
                  <Dateresume
                    veterinary={this.props.user.first_name}
                    date={this.state.newDate}
                    time={this.props.appointment.time}
                    onChange={this.handleChange}
                    times={times}
                  />
                  <button
                    className="btn btn-info"
                    onClick={(e) => {
                      e.preventDefault();
                      if (this.state.time) {
                        this.props.updateAppointment(
                          { time: this.state.time, date: this.state.newDate },
                          this.props.appointment.id
                        );
                        this.setState({ reagendar: true });
                      } else {
                        this.props.createMessage({
                          errorMessage: "Oops! Debes seleccionar un horario",
                        });
                      }
                    }}
                  >
                    Reagendar
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Dateresume
                    veterinary={this.props.appointment.doctor.first_name}
                    pet={this.props.appointment.pet.name}
                    date={this.state.newDate}
                    time="08:00"
                    onChange={this.handleChange}
                    times={times}
                  />
                  <button
                    className="btn btn-info"
                    onClick={(e) => {
                      e.preventDefault();
                      if (this.state.time) {
                        this.props.updateAppointment(
                          { time: this.state.time, date: this.state.newDate },
                          this.props.appointment.id
                        );
                        this.setState({ reagendar: true });
                      } else {
                        this.props.createMessage({
                          errorMessage: "Oops! Debes seleccionar un horario",
                        });
                      }
                    }}
                  >
                    Reagendar
                  </button>
                </React.Fragment>
              )
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        {this.state.cancelShow ? (
          <Modal
            show={this.state.cancelShow}
            handleClose={(e) => {
              e.preventDefault();
              this.setState({ cancelShow: false });
            }}
            style={{ maxWidth: "100%" }}
          >
            <div className="container-fluid">
              {/*<img src={notFountIcon} alt="" style={{ width: "75%" }} />*/}
              <br />
              <h4>Eliminar cita</h4>
              <p>¡Aguarda!... ¿Seguro?... ¡No tendra remedio!</p>
              <button
                className="btn btn-block btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  if (this.props.appointment.type === "SEGUIMIENTO") {
                    this.props.updateAppointment(
                      { status: "ABIERTA" },
                      this.props.appointment.id
                    );
                  } else {
                    this.props.updateAppointment(
                      { status: "CANCELADA" },
                      this.props.appointment.id
                    );
                  }

                  this.props.createMessage({
                    apointmentsOf:
                      "La cita ha sido cancelada, el cliente notificado",
                  });
                }}
              >
                ¡Estoy seguro!
              </button>
            </div>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {this.state.inasistenciaShow ? (
          <Modal
            show={this.state.inasistenciaShow}
            handleClose={(e) => {
              e.preventDefault();
              this.setState({ inasistenciaShow: false });
            }}
            style={{ maxWidth: "100%" }}
          >
            <div className="container-fluid">
              {/*<img src={notFountIcon} alt="" style={{ width: "75%" }} />*/}
              <br />
              <h4>Marcar inasistencia</h4>
              <p>¡Aguarda!... ¿Seguro?... ¡No tendra remedio!</p>
              <small>
                Una cita de seguimiento sera marcada como inasistencia, un
                examen dental sera catalogada como cancelada
              </small>
              <button
                className="btn btn-block btn-warning"
                onClick={(e) => {
                  e.preventDefault();
                  if (this.props.appointment.type === "SEGUIMIENTO") {
                    let comment = "INASISTENTE";
                    let treatment = this.props.appointment.treatment_file.id;
                    let tracingappointment = { comment, treatment };
                    console.log(tracingappointment);
                    this.props.addTracingFile(tracingappointment);
                    this.props.updateAppointment(
                      { status: "ABIERTA" },
                      this.props.appointment.id
                    );
                  } else {
                    this.props.updateAppointment(
                      { status: "CANCELADA" },
                      this.props.appointment.id
                    );
                  }

                  this.props.createMessage({
                    apointmentsOf: "Inasistencia marcada",
                  });
                }}
              >
                ¡Estoy seguro!
              </button>
            </div>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <div className="date-detail">
          <div className="info">
            <small>{this.props.appointment.time && this.props.appointment.time}</small>
            <small>{this.props.appointment.cliente.first_name && this.props.appointment.cliente.first_name}</small>
            <small>{this.props.appointment.profile.phone && this.props.appointment.profile.phone}</small>
            <small>{this.props.appointment.profile.phone2 && this.props.appointment.profile.phone2}</small>
            <small><strong>{this.props.appointment.cliente.email && this.props.appointment.cliente.email}</strong></small>
          </div>
          <div className="details">
            <p>
              {"Hola me llamo " +
                this.props.appointment.pet.name +
                ",soy un " +
                this.props.appointment.pet.species +
                " de raza " +
                this.props.appointment.pet.race +
                ", " +
                this.props.appointment.notes || "El cliente no especifica"}
            </p>
            <div className="date-actions">
              <button
                className="goToAttendAppointment"
                onClick={() => {
                  console.log("POST TO MODIFY STATUS OF APPOINTMENT");
                  this.setState({
                    redirect: true,
                    appointmentToAttend: this.props.appointment,
                  });
                }}
              ></button>
              <button
                className="changeDate"
                onClick={() => {
                  this.setState({ inasistenciaShow: true });
                }}
              ></button>
              <button
                className="delete"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ cancelShow: true });
                }}
              ></button>

              <button
                className="dontShow"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ show: true });
                }}
              ></button>
            </div>
          </div>
          {/* <div className="date-header">
          </div> */}
          {/*  */}
        </div>
      </React.Fragment>
    );
  }
}

class DateDetailList extends Component {
  state = {
    redirectToNewDate: false,
    selectedTime: null,
    actualDate: null,
  };
  static propTypes = {
    user: PropTypes.object.isRequired,
    getDaySchedule: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    updateAppointment: PropTypes.func.isRequired,
    addTracingFile: PropTypes.func.isRequired,
  };

  render() {
    if (this.state.redirectToNewDate) {
      return (
        <Redirect
          to={{
            pathname: "/decide",
            actualDate: this.state.actualDate,
            selectedTime: this.state.selectedTime,
          }}
        />
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "90%",
        }}
      >
        <React.Fragment>
          {/* <div className="date-detail border">
            <div
              className="date-header"
              style={{ justifyContent: "spaceEvenly" }}
            >
              <p>HORA</p>
              <p>NOMBRE</p>
              <p>TELEFONO</p>
              <p>TELEFONO</p>
              <p>EMAIL</p>
              <p>MOTIVO CITA Y DETALLES</p>
            </div>
          </div> */}
          {my_times.map((horario) => {
            let tmp = this.props.apointments.filter(
              (apointment) => apointment.time === horario
            );
            if (tmp.length > 0) {
              return tmp.map((temp_ap) => {
                return (
                  <DateDetail
                    appointment={temp_ap}
                    onClick={this.props.onClick}
                    user={this.props.user}
                    createMessage={this.props.createMessage}
                    getDaySchedule={this.props.getDaySchedule}
                    updateAppointment={this.props.updateAppointment}
                    addTracingFile={this.props.addTracingFile}
                  ></DateDetail>
                );
              });
            } else {
              return (
                <div className="date-detail d-flex justify-content-between">
                  <p>
                    <strong>{horario}</strong>
                  </p>
                  <p>Agendar cita para esta fecha y hora</p>
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => {
                      this.setState({
                        selectedTime: horario,
                        actualDate: this.props.actualDate,
                      });
                      this.setState({ redirectToNewDate: true });
                    }}
                  >+</button>
                  {/* <div className="date-header">
                    
                    <p>
                    </p>
                  </div> */}
                </div>
              );
            }
          })}
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.RedAuth.user,
  /*Bajar a props el atributo TIPO USUARIO PARA DECIDIR QUE DASHBOARD DESPLEGAR */
});

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="calendar-modal clients-frame">
        <img src={ScheduleIcon} alt="" />
        <span className="circle" onClick={handleClose}></span>
        {children}
      </section>
    </div>
  );
};

export default connect(mapStateToProps, {
  createMessage,
  getDaySchedule,
  updateAppointment,
})(DateDetailList);
