import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_UPDATED,
  SECRETARY_REGISTER_ACHIVED,
  SECRETARY_REGISTER_ERROR,
  SECRETARY_REGISTER_WAITING
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  rol: null,
  achive: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SECRETARY_REGISTER_ACHIVED:
      return {
        ...state,
        achive: true
      };

    case SECRETARY_REGISTER_ERROR:
      return {
        ...state,
        achive: false
      };
    case SECRETARY_REGISTER_WAITING:
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
        rol: action.payload.last_name
      };

    case USER_UPDATED:
      return {
        ...state,
        isLoading: false
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      localStorage.removeItem("token");
      return {
        /*...state,
        ...action.payload,
        token: null,
        rol: null,
        user: null,
        isAuthenticated: false,
        isLoading: false*/
        state: undefined
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        user: action.payload.user,
        isAuthenticated: true,
        rol: action.payload.user.last_name,
        isLoading: false
      };

    default:
      return state;
  }
}
