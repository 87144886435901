import MaterialTable from "material-table";
/*import LoadingDog from "../common/Loading";
import NewClientProfile from "../Forms/NewClientProfile";*/

import { getClients, searchClients } from "../../actions/ActClients";
import { getGroomers } from "../../actions/ActGrooms";
import React, { Component } from "react";
import Calendar from "react-calendar";
import Loading from "../common/Loading";
/*import PetAvatarList from "../common/PetAvatar";
import VetAvatarList from "../common/VetAvatar";*/
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getVets } from "../../actions/ActVets";
import { getDaySchedule } from "../../actions/ActApointments";
/*import "./styles/DateManagement.css";*/
import Dateresume from "../common/Dateresume";
import { createMessage } from "../../actions/ActMessages";
import { addApointment } from "../../actions/ActApointments";
import { getUserPets } from "../../actions/ActPets";
import { Link } from "react-router-dom";
/*import clientDecide from "../../components/common/WichApointment";*/
import { Redirect } from "react-router-dom";
import "./scripts/scripts";

var times = [
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
];

class ApointmentsPage extends Component {
  state = {
    step: 1,
    appointmentType: 0,
    redirectToReferrer: false,
    date: new Date(),
  };

  static propTypes = {
    pets: PropTypes.array.isRequired,
    vets: PropTypes.array.isRequired,
    getVets: PropTypes.func.isRequired,
    getDaySchedule: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    addApointment: PropTypes.func.isRequired,
    cliente: PropTypes.number.isRequired,
    achived: PropTypes.bool.isRequired,
    loadingUsers: PropTypes.bool.isRequired,
    clients: PropTypes.array.isRequired,
    getUserPets: PropTypes.func.isRequired,
    getGroomers: PropTypes.func.isRequired,
    searchClients: PropTypes.func.isRequired,
  };

  handleChange = (e) => {
    // eslint-disable-next-line
    if ([e.target.name] == "apointment") {
      let selectedoption = document.getElementById("exampleFormControlSelect1");
      this.setState({
        [e.target.name]: selectedoption.value,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handeCalendarChange = (date) => {
    let inDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.setState({ inDate });
    this.props.createMessage({
      selectedDate:
        "Cita programada para el dia " +
        inDate +
        " selecciona una hora en el resumen.",
    });
    if (this.state.veterinary) {
      this.props.getDaySchedule(inDate, this.state.veterinary);
    }
    this.nextStep();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.apointment) {
      this.setState({ disableButton: true });
      let { inDate, veterinary, pet, notes, apointment, clientId } = this.state;
      let info = {
        date: inDate,
        doctor: veterinary,
        cliente: clientId,
        time: apointment,
        pet,
        notes,
      };
      this.props.addApointment(info);
    } else {
      this.props.createMessage({
        mustSelectDate: "Debe seleccionar una hora valida para la cita.",
      });
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    if (e.target.name === "pet") {
      this.props.pets.filter((p) => {
        // eslint-disable-next-line
        if (p.id == e.target.alt) {
          this.props.createMessage({
            selectedPet: "La cita sera para " + p.name,
          });
          this.setState({ petname: p.name });
        }
        return <React.Fragment></React.Fragment>;
      });
      this.setState({ [e.target.name]: e.target.alt });
    } else if (e.target.name === "veterinary") {
      this.props.vets.filter((veterinario) => {
        // eslint-disable-next-line
        if (veterinario.id == e.target.alt) {
          this.setState({ vetname: veterinario.first_name });
          this.props.createMessage({
            selectedVet: "Te atendera " + veterinario.first_name,
          });
        }
        return <React.Fragment></React.Fragment>;
      });
      this.setState({ [e.target.name]: e.target.alt });
    }
  };

  //Proceed to next step
  //Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    if (step === 3 && this.props.location.actualDate != null) {
      this.setState({
        step: 5,
        inDate: this.props.location.actualDate,
      });
    } else {
      this.setState({
        step: step + 1,
      });
    }
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  componentDidMount() {
    this.props.getClients();
    this.props.getVets();
    this.props.getGroomers();
  }

  callPets(info) {
    this.props.getUserPets(info);
  }

  render() {
    if (this.props.achived === true) {
      this.props.createMessage({ addApointment: "Cita agendada" });
      return <Redirect to="/dashboard" />;
    }
    switch (this.state.step) {
      case 1:
        return (
          <React.Fragment>
            <div className="step-form" style={{ width: "100%" }}>
              <div className="row h-100 ">
                <div className="col-12  text-center my-auto">
                  <div className="social-icons">
                    <button className="social-icon instagram">
                      <i className="fas fa-user-md" />
                    </button>
                    <h5>1. Selecciona un cliente</h5>
                  </div>
                  <div className="col-12 d-flex pb-0 pt-0 justify-content-center align-items-center">
                    <form className="row row-cols-lg-auto g-3 align-items-center m-2" onSubmit={e=>{
                      e.preventDefault();
                      this.props.searchClients(document.getElementById("SearchQuery").value);
                    }}>
                      <div className="col-8">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="fa fa-search"></i>
                          </div>
                          <input type="text" className="form-control" placeholder="Nombre, Apellido, Telefono, Correo"
                          id="SearchQuery" name="query"/>
                        </div>
                      </div>
                      <div className="col-4">
                        <button type="submit" className="btn btn-primary">Buscar</button>
                      </div>
                    </form>
                  </div>
                  {/*tabla de clientes */}
                  {
                    this.props.clients.length>0 ?
                    <MaterialTable
                    localization={{
                      toolbar: {
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Cliente",
                      },
                      body: {
                        emptyDataSourceMessage:
                          "No se encuentran coincidencias.",
                      },
                      header: {
                        actions: "Info",
                      },
                    }}
                    options={{
                      pageSize: 10,
                      headerStyle: {
                        backgroundColor: "#01579b",
                        color: "white",
                        paddingLeft: "20px",
                      },
                      /*actionsColumnIndex: -1,*/
                      detailPanelColumnAlignment: "right",
                    }}
                    columns={[
                      /*{
                        title: "ID",
                        field: "id",
                        cellStyle: {
                          maxWidth: "5px"
                        }
                      },*/

                      {
                        title: "Cliente",
                        field: "first_name",
                      },
                      {
                        title: "Username",
                        field: "username",
                      },
                    ]}
                    data={this.props.clients}
                    title="Lista de clientes"
                    actions={[
                      {
                        icon: "add",
                        tooltip: "Seleccionar cliente",
                        onClick: (event, rowData) => {
                          event.preventDefault();
                          /*console.log(rowData.id);*/
                          this.props.createMessage({
                            selectedClienteForApointment:
                              "La cita sera para " + rowData.first_name,
                          });
                          this.setState({ clientId: rowData.id });
                          let info = { clientId: rowData.id };
                          this.callPets(info);
                          this.nextStep();
                        },
                      },
                    ]}
                    onRowClick={(event, rowData) => {
                      event.preventDefault();
                      /*console.log(rowData.id);*/
                      this.props.createMessage({
                        selectedClienteForApointment:
                          "La cita sera para " + rowData.first_name,
                      });
                      this.setState({ clientId: rowData.id });
                      let info = { clientId: rowData.id };
                      this.callPets(info);
                      this.nextStep();
                    }}
                  />
                  :
                  <h1>Sin datos para mostrar</h1>
                  }
                  <div className="d-flex justify-content-center pt-2">
                    <Link
                      className="btn btn-success float-right"
                      to="/secretary/new/client"
                    >
                      Registrar nuevo cliente
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <div className="step-form">
              <div className="row h-100 ">
                <div className="col text-center my-auto">
                  <div className="social-icons">
                    <button className="social-icon instagram">
                      <i class="fas fa-paw"></i>
                    </button>
                    <h5>2. Selecciona una mascota</h5>
                  </div>

                  <MaterialTable
                    localization={{
                      toolbar: {
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Cliente",
                      },
                      body: {
                        emptyDataSourceMessage:
                          "No se encuentran coincidencias.",
                      },
                      header: {
                        actions: "Info",
                      },
                    }}
                    options={{
                      pageSize: 3,
                      headerStyle: {
                        backgroundColor: "#01579b",
                        color: "white",
                        paddingLeft: "20px",
                      },
                      /*actionsColumnIndex: -1,*/
                      detailPanelColumnAlignment: "right",
                    }}
                    columns={[
                      /*{
                        title: "ID",
                        field: "id",
                        cellStyle: {
                          maxWidth: "5px"
                        }
                      },*/

                      {
                        title: "Nombre",
                        field: "name",
                      },
                      {
                        title: "Edad",
                        field: "age",
                      },
                    ]}
                    data={this.props.pets}
                    title="Lista de mascotas"
                    actions={[
                      {
                        icon: "add",
                        tooltip: "Selecciona una mascota",
                        onClick: (event, rowData) => {
                          event.preventDefault();
                          /*console.log(rowData.id);*/
                          this.props.createMessage({
                            selectedApointmentPet:
                              "La cita sera para " + rowData.name,
                          });
                          this.setState({
                            pet: rowData.id,
                            petname: rowData.name,
                          });
                          this.nextStep();
                        },
                      },
                    ]}
                    onRowClick={(event, rowData) => {
                      event.preventDefault();
                      /*console.log(rowData.id);*/
                      this.props.createMessage({
                        selectedApointmentPet:
                          "La cita sera para " + rowData.name,
                      });
                      this.setState({
                        pet: rowData.id,
                        petname: rowData.name,
                      });
                      this.nextStep();
                    }}
                  />
                  <div className="d-flex justify-content-center pt-2">
                    <button
                      className="btn btn-warning justify-content-end  mr-2"
                      onClick={this.prevStep}
                    >
                      Regresar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <div className="step-form">
              <div className="row h-100 ">
                <div className="col text-center my-auto">
                  <div className="social-icons">
                    <button className="social-icon instagram">
                      <i class="fas fa-paw"></i>
                    </button>
                    <h5>3. Selecciona un doctor</h5>
                  </div>

                  <MaterialTable
                    localization={{
                      toolbar: {
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Cliente",
                      },
                      body: {
                        emptyDataSourceMessage:
                          "No se encuentran coincidencias.",
                      },
                      header: {
                        actions: "Info",
                      },
                    }}
                    options={{
                      pageSize: 3,
                      headerStyle: {
                        backgroundColor: "#01579b",
                        color: "white",
                        paddingLeft: "20px",
                      },
                      /*actionsColumnIndex: -1,*/
                      detailPanelColumnAlignment: "right",
                    }}
                    columns={[
                      /*{
                        title: "ID",
                        field: "id",
                        cellStyle: {
                          maxWidth: "5px"
                        }
                      },*/

                      {
                        title: "Nombre",
                        field: "first_name",
                      },
                      {
                        title: "Username",
                        field: "username",
                      },
                    ]}
                    data={this.props.vets}
                    title="Lista de veterinarios"
                    actions={[
                      {
                        icon: "add",
                        tooltip: "Seleccionar",
                        onClick: (event, rowData) => {
                          event.preventDefault();
                          /*console.log(rowData.id);*/
                          this.props.createMessage({
                            selectedApointmentPet:
                              "Atendera " + rowData.first_name,
                          });

                          this.setState({
                            veterinary: rowData.id,
                            vetname: rowData.first_name,
                          });
                          this.nextStep();
                        },
                      },
                    ]}
                    onRowClick={(event, rowData) => {
                      event.preventDefault();
                      /*console.log(rowData.id);*/
                      this.props.createMessage({
                        selectedApointmentPet: "Atendera " + rowData.first_name,
                      });

                      this.setState({
                        veterinary: rowData.id,
                        vetname: rowData.first_name,
                      });
                      this.nextStep();
                    }}
                  />
                  <br></br>
                  <div className="d-flex justify-content-center pt-2">
                    <button
                      className="btn btn-warning justify-content-end  mr-2"
                      onClick={this.prevStep}
                    >
                      Regresar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <div className="step-form">
              <div className="row h-100 ">
                <div className="col text-center my-auto">
                  <div className="social-icons">
                    <button className="social-icon behance">
                      <i className="far fa-calendar-alt" />
                    </button>
                    <h5>4. Acordemos un día</h5>
                  </div>
                  {this.props.VetState.loading ? (
                    <Loading />
                  ) : (
                    <React.Fragment>
                      <div className="text-center justify-content-around d-flex pb-5">
                        <Calendar
                          onChange={this.handeCalendarChange}
                          minDate={this.state.date}
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <br></br>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-warning justify-content-end  mr-2"
                      onClick={this.prevStep}
                    >
                      Regresar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case 5:
        return (
          <React.Fragment>
            <div className="step-form">
              <div className="row h-100">
                <div className="col text-center my-auto">
                  <div className="social-icons">
                    <button className="social-icon behance">
                      <i className="far fa-calendar-alt" />
                    </button>
                    <h5>5. Horario y Detalles</h5>
                  </div>
                  <Dateresume
                    veterinary={this.state.vetname}
                    pet={this.state.petname}
                    date={this.state.inDate}
                    time={this.state.time}
                    onChange={this.handleChange}
                    times={times}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    Cuentanos el motivo de tu vistia, podremos atenderte mejor
                    de esta manera.
                  </small>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Notas</span>
                    </div>
                    <textarea
                      name="notes"
                      className="form-control"
                      aria-label="Notas"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-warning justify-content-end  mt-2 mr-2"
                      onClick={this.prevStep}
                    >
                      Regresar
                    </button>
                    <form onSubmit={this.handleSubmit}>
                      {this.state.disableButton ? (
                        <button
                          type="submit"
                          className="btn btn-block btn-secondary mt-2"
                          disabled
                        >
                          Notificando cita...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-block btn-success mt-2"
                        >
                          Solicitar cita
                        </button>
                      )}
                    </form>
                  </div>

                  <small id="emailHelp" className="form-text text-muted">
                    Al solicitar cita estas aceptando los terminos y condiciones
                    de nuestro servicio.
                  </small>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <h2>Hola Defauls</h2>
          </React.Fragment>
        );
    }
  }
}

const mapStateToProps = (state) => ({
  pets: state.RedPets.pets,
  vets: state.RedVets.vets,
  grooms: state.RedGroomers.grooms,
  VetState: state.RedVets,
  cliente: state.RedAuth.user.id,
  achived: state.RedApoint.achive,
  loadingUsers: state.RedClients.loading,
  clients: state.RedClients.clients,
});
export default connect(mapStateToProps, {
  getVets,
  getDaySchedule,
  createMessage,
  addApointment,
  getClients,
  getUserPets,
  getGroomers,
  searchClients
})(ApointmentsPage);
