import React, { Component } from "react";

export default class ClinicAppointmentDetails extends Component {
  render() {
    return (
      <div className="container">
        <div
          className="row"
          style={{
            margin: "2rem",
            flexFlow: "row wrap"
          }}
        >
          <div className="form-group">
            <label>Peso en Kg</label>
            <input
              type="text"
              className="form-control"
              id="inputName"
              placeholder="Peso"
              value={this.props.state.peso || ""}
              name="peso"
              onChange={this.props.onChange}
            />
          </div>
          <div className="form-group">
            <label>Condicion corporal</label>
            <input
              type="text"
              className="form-control"
              id="inputName"
              placeholder="condicion corporal"
              value={this.props.state.verboseCC || ""}
              onClick={this.props.show}
              readOnly
              /*name="condicionc"*/
              /*onChange={this.props.onChange}*/
            />
          </div>
          <div className="form-group">
            <label>Temperatura en °C</label>
            <input
              type="text"
              className="form-control"
              id="inputName"
              placeholder="Temperatura"
              value={this.props.state.temperatura || ""}
              name="temperatura"
              /*step=".01"*/
              onChange={this.props.onChange}
            />
          </div>
          <div className="form-group">
            <label>Frecuencia cardiaca</label>
            <input
              type="Number"
              className="form-control"
              id="inputName"
              placeholder="90-100ppm"
              value={this.props.state.fc1 || ""}
              name="fc1"
              onChange={this.props.onChange}
            />
          </div>
          <div className="form-group">
            <label>Presión arterial</label>
            <input
              type="Text"
              className="form-control"
              id="inputName"
              placeholder="Presión arterial"
              value={this.props.state.presionArterial || ""}
              name="presionArterial"
              onChange={this.props.onChange}
            />
          </div>
          <div className="form-group">
            <label>Frecuencia respiratoria</label>
            <input
              type="Number"
              className="form-control"
              id="inputName"
              placeholder="10-30rpm"
              value={this.props.state.fr || ""}
              name="fr"
              onChange={this.props.onChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
