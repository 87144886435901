import {
  NUMBER_SALES_ACTUAL_DAY,
  TOTAL_SALES_TODAY,
  TOTAL_PRODUCTS_SALE_TODAY,
  LOADED_TODAY,
  TOTAL_CASH,
  TOTAL_CARD,
  TOTAL_SALES_GROOM,
  TOTAL_SALES_SERVICES,
  DETAIL_GROOMING,
  DETAIL_SERVICES,
  TOTAL_DEV_CARD,
  TOTAL_DEV_CASH,
  TOTAL_DEV_DETAILS,
  DETAIL_PHARM,
  TOTAL_SALES_PHARM
} from "../actions/types";

const initialState = {
  totalNumSales: 0,
  totalSales: {},
  details: [],
  totalcashSales: {},
  totalcardSales: {},
  loadingNumber: false,
  loadingTotal: false,
  loadingDetails: false,
  loadingcash: false,
  loadingcard: false,

  totalGroom: [],
  totalServices: [],
  loadingTotalGroom: false,
  loadingTotalServices: false,

  detailsGroom: [],
  loadingDetailGroom: false,

  detailServ: [],
  loadingDetailService: false,

  totalCashDev: {},
  loadingTotalDevCash: false,

  totalCardDev: {},
  loadingTotalDevCard: false,

  detailsDev: [],
  loadingdetailDev: false,

  totalPharm: [],
  loadingTotalPharm: false,
  detailsPharm: [],
  loadingDetailPharm: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NUMBER_SALES_ACTUAL_DAY: //numero de ventas de hoy
      return {
        ...state,
        totalNumSales: action.payload,
        loadingNumber: true
      };
    case TOTAL_SALES_TODAY: //total de dinero en ventas de hoy
      return {
        ...state,
        totalSales: action.payload,
        loadingTotal: true
      };
    case TOTAL_PRODUCTS_SALE_TODAY: // detalle de productos vendidos hoy
      return {
        ...state,
        details: action.payload,
        loadingDetails: true
      };
    case LOADED_TODAY:
      return {
        ...state,
        loadingNumber: false,
        loadingTotal: false,
        loadingDetails: false,
        loadingcash: false,
        loadingcard: false,
        loadingTotalGroom: false,
        loadingTotalServices: false,
        loadingDetailGroom: false,
        loadingDetailService: false,
        loadingTotalDevCard: false,
        loadingTotalDevCash: false,
        loadingdetailDev: false,
        loadingTotalPharm: false,
        loadingDetailPharm: false
      };
    case TOTAL_CASH:
      return {
        ...state,
        totalcashSales: action.payload,
        loadingcash: true
      };
    case TOTAL_CARD:
      return {
        ...state,
        totalcardSales: action.payload,
        loadingcard: true
      };
    case TOTAL_SALES_GROOM:
      return {
        ...state,
        totalGroom: action.payload,
        loadingTotalGroom: true
      };
    case TOTAL_SALES_SERVICES:
      return {
        ...state,
        totalServices: action.payload,
        loadingTotalServices: true
      };
    case DETAIL_GROOMING:
      return {
        ...state,
        detailsGroom: action.payload,
        loadingDetailGroom: true
      };
    case DETAIL_SERVICES:
      return {
        ...state,
        detailServ: action.payload,
        loadingDetailService: true
      };
    case TOTAL_DEV_CARD:
      return {
        ...state,
        totalCardDev: action.payload,
        loadingTotalDevCard: true
      };
    case TOTAL_DEV_CASH:
      return {
        ...state,
        totalCashDev: action.payload,
        loadingTotalDevCash: true
      };
    case TOTAL_DEV_DETAILS:
      return {
        ...state,
        detailsDev: action.payload,
        loadingdetailDev: true
      };
    case TOTAL_SALES_PHARM:
      return {
        ...state,
        totalPharm: action.payload,
        loadingTotalPharm: true
      };
    case DETAIL_PHARM:
      return {
        ...state,
        detailsPharm: action.payload,
        loadingDetailPharm: true
      };
    default:
      return state;
  }
}
