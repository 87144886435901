import { GET_PETS, DELETE_PET, ADD_PET, LOADING_PETS } from "../actions/types";

const initialState = {
    loadingpets: false,
    pets: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOADING_PETS:
            return {
                ...state,
                loadingpets: true,
                pets: []
            };
        case GET_PETS:
            return {
                ...state,
                loadingpets: false,
                pets: action.payload
            };
        case DELETE_PET:
            return {
                ...state,
                pets: state.pets.filter(pet => pet.id !== action.payload)
            };
        case ADD_PET:
            return {
                ...state,
                pets: [...state.pets, action.payload]
            };
        default:
            return state;
    }
}