import {GET_CATEGORIES,DELETE_CATEGORIES,UPDATE_CATEGORIES,ADD_CATEGORIES }from '../actions/types';

const initialState = {
    categories: [],
    loading:false
  };

  export default function(state = initialState, action) {
    switch (action.type) {
      case GET_CATEGORIES:
        return {
          ...state,
          categories: action.payload,
          loading:false
        }
      case ADD_CATEGORIES:
        return {
          ...state,
          /*categories:[...state.categories,
                    action.payload]*/
        }
      case DELETE_CATEGORIES:
        return{
          ...state,
          categories: state.categories.filter(category => category.id !== action.payload)
        }
      case UPDATE_CATEGORIES:
        return{
          ...state,
          categories:[...state.categories],
          loading:true
        } 
  
  
      default:
        return state;
    }
  }