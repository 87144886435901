import React, { Component } from "react";
import dogCare from "../../assets/icons/dogCare.png";
import ClinicAppointment from "../Forms/ClinicAppointment";
import { updateAppointment, getPetHistory } from "../../actions/ActApointments";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createMessage } from "../../actions/ActMessages";
import { Redirect } from "react-router-dom";
import Question from "../../assets/icons/info.svg";
import BeforeClinicAppointment from "../Forms/BeforeClinicAppointment";
import {
  addGroomFile,
  updatePictures,
  getGroomFile,
} from "../../actions/ActGroomfile";

import {
  addClinicFile,
  getClinicFile,
  finishClinicFile,
} from "../../actions/ActClinicfile";
import AfterClinicAppointment from "../Forms/AfterClinicAppointment";
import ClinicAppointmentDetails from "../Forms/ClinicAppointmentDetails";
import PetHistoryDetail from "../common/PetHistoryDetail";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

class AttendingClinicAppointment extends Component {
  state = {
    show: false,

    appointmentFinished: false,
    showClinicHistory: false,
    anamnesis: null,
    verboseCC: "Demasiado Delgado",
    alimentacion: null,
    md: null,
    mp: null,
    vacunado: "DUDOSO",
    desparacitado: "DUDOSO",
    temperatura: null,
    fc1: null,
    fr: null,
    peso: null,
    condicionc: 1,
    piel: null,
    ojos: null,
    oidos: null,
    nariz: null,
    boca: null,
    cardio: null,
    respiratorio: null,
    abdomen: null,
    gastro: null,
    urogenital: null,
    linfaticos: null,
    musculos: null,
    neuro: null,
    ad: null,
    diagnostico: null,
    tratamiento: null,
    observaciones: null,
    appointment: this.props.location.appointmentToAttend.id,
    presionArterial: null,
  };

  handleChange = (e) => {
    // eslint-disable-next-line
    if ([e.target.name][0] == "condicionc") {
      if (e.target.value < 4) {
        this.setState({ verboseCC: "Demasiado delgado" });
      } else if (e.target.value < 6) {
        this.setState({ verboseCC: "Condicion ideal" });
      } else {
        this.setState({ verboseCC: "Sobre peso - obesidad" });
      }
    }
    this.setState({
      /*...this.state,*/
      [e.target.name]: e.target.value,
    });
  };

  static propTypes = {
    updateAppointment: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    finishClinicFile: PropTypes.func.isRequired,
    getClinicFile: PropTypes.func.isRequired,
    addClinicFile: PropTypes.func.isRequired,
    getPetHistory: PropTypes.func.isRequired,
    apointments: PropTypes.array.isRequired,
    file: PropTypes.object,
  };

  async componentDidMount() {
    try {
      this.setState({
        appointmentId: this.props.location.appointmentToAttend.id,
      });
      await this.props.getClinicFile(
        this.props.location.appointmentToAttend.id
      );
    } catch (error) {}
    if (isEmpty(this.props.file)) {
      console.log("inicializando");
      var initializer = new FormData();
      initializer.append(
        "appointment",
        this.props.location.appointmentToAttend.id
      );
      this.props.addClinicFile(initializer);
    }
  }

  handleSubmit() {
    this.setState({ disabledButton: true });
    let info = { ...this.state };
    var formInfo = new FormData();
    for (var key in info) {
      if (key === "show" || key === "verboseCC") {
      } else {
        if (info[key]) {
          formInfo.append(key, true);
        } else {
          formInfo.append(key, false);
        }
      }
    }

    this.props.finishClinicFile(info, this.props.file.id);
    this.props.updateAppointment(
      { status: "COMPLETADA" },
      this.props.location.appointmentToAttend.id
    );

    this.setState({ appointmentFinished: true });
  }

  render() {
    if (this.state.appointmentFinished) {
      setTimeout(
        function () {
          document.location.reload();
          this.setState({ appointmentFinished: false });
          return <Redirect to="/dashboard"></Redirect>;
        }.bind(this),
        1000
      );
    }
    const {
      aggressive,
      date,
      nervous,
      notes,
      time,
    } = this.props.location.appointmentToAttend;
    const {
      first_name,
      username,
      id: clientId,
    } = this.props.location.appointmentToAttend.cliente;
    const {
      age,
      color,
      name,
      race,
      reproductive_state,
      species,
    } = this.props.location.appointmentToAttend.pet;

    const inLineStyles = this.props.file
      ? {
          alignItems: "center",
          paddingTop: "2rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }
      : {
          display: "none",
        };
    return (
      <React.Fragment>
        {this.state.show ? (
          <Modal
            show={() => {
              this.setState({ show: true });
            }}
            handleClose={() => {
              this.setState({ show: false });
            }}
          >
            <div className="text-center">
              <label htmlFor="customRange2">
                Condición corporal {this.state.condicionc}{" "}
                {this.state.condicionc < 4 ? (
                  <div className="text-warning">
                    <h4>Demasiado delgado</h4>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {this.state.condicionc > 3 && this.state.condicionc < 6 ? (
                  <div className="text-success">
                    <h4>Condicion ideal</h4>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {this.state.condicionc > 5 && this.state.condicionc < 11 ? (
                  <div className="text-danger">
                    <h4>Sobrepeso-obesidad</h4>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </label>
            </div>
            <input
              type="range"
              class="custom-range"
              min="1"
              max="10"
              value={this.state.condicionc}
              id="customRange2"
              name="condicionc"
              onChange={this.handleChange}
            />
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        {this.state.showClinicHistory ? (
          <Modal
            show={() => {
              this.setState({ showClinicHistory: true });
            }}
            handleClose={() => {
              this.setState({ showClinicHistory: false });
              this.props.getClinicFile(
                this.props.location.appointmentToAttend.id
              );
            }}
          >
            <PetHistoryDetail
              inLineStyles={inLineStyles}
              petID={this.props.location.appointmentToAttend.pet.id}
            ></PetHistoryDetail>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <div className="container-fluid">
          <div
            className="row"
            style={{
              /*height: "6rem",*/
              color: "black",
              backgroundColor: "#dfe6e9",
              marginLef: "0px",
              justifyContent: "center",
              alignItems: "center",
              padding: "2em",
              marginBottom: "2em",
            }}
          >
            <div className="col text-center" style={{ display: "box" }}>
              <h4>
                Ficha clinica: {date} {time}{" "}
              </h4>
              {/*<div className="badge bg-danger">{status}</div>*/}
              <img src={dogCare} alt="" style={{ maxWidth: "5em" }} />
            </div>
          </div>
          <div className="container">
            <div
              className="row"
              style={{ marginTop: "10rem", marginBottom: "2rem" }}
            >
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div className="row justify-content-center">
                  <div className="col text-center">
                    <span className="badge bg-info">Información cliente</span>
                    <h5>{first_name}</h5>
                    <h5>{username}</h5>
                    <h5>{clientId}</h5>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col text-center">
                    <span className="badge bg-info">
                      Información sobre esta cita
                    </span>
                    {aggressive ? (
                      <p>Agresivo: Sí</p>
                    ) : (
                      <p>Agresivo: No especifica</p>
                    )}
                    {nervous ? (
                      <p>Nervioso: Sí</p>
                    ) : (
                      <p>Nervioso: No especifica</p>
                    )}
                  </div>
                  <div className="col text-center">
                    <span className="badge bg-info">Más sobre {name}</span>
                    <p>
                      Soy {name} tengo {age} soy un {species} {race} de color{" "}
                      {color} estoy {reproductive_state}
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className="col text-center">
                    {notes ? (
                      <p>Motivos de la cita: {notes}</p>
                    ) : (
                      <p>Motivos de la cita: No especifica</p>
                    )}
                  </div>
                </div>
                <div className="row justify-content-center align-items-center">
                  <div className="col text-center">
                    <button
                      className="btn btn-block btn-info"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ showClinicHistory: true });
                        /*this.props.getPetHistory();*/
                      }}
                    >
                      Mostrar historial Clínico
                    </button>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row justify-content-center">
                  <div className="col text-center">
                    <h4>
                      Completemos un poco de información antes de comenzar
                    </h4>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                    <BeforeClinicAppointment
                      state={this.state}
                      onChange={this.handleChange}
                    ></BeforeClinicAppointment>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <ClinicAppointmentDetails
                onChange={this.handleChange}
                state={this.state}
                show={() => {
                  this.setState({ show: true });
                }}
                hide={() => {
                  this.setState({ show: false });
                }}
              ></ClinicAppointmentDetails>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 text-center headerasdasdasdsa"
              style={{
                /*boxShadow: "0px 1px 10px 0px black",*/
                padding: "1rem",
                backgroundColor: "#8395a7",
                marginBottom: "1rem",
                color: "black",
              }}
            >
              <strong>
                <h4>Anamnesis de {name}</h4>
              </strong>
            </div>
            <div className="col-12">
              <div
                className="row"
                style={{
                  /*height: "30%",*/
                  alignItems: "center",
                  paddingTop: "2rem",
                  paddingBottom: "2rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <div className="col-2">
                  <img src={Question} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="anamnesis"
                    value={this.state.anamnesis || ""}
                    rows="3"
                    onChange={this.handleChange}
                  >
                    {this.state.anamnesis}
                  </textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "0.5rem" }}>
            <div
              className="col-12 text-center headerasdasdasdsa"
              style={{
                /*boxShadow: "0px 1px 10px 0px black",*/
                padding: "1rem",
                backgroundColor: "#576574",
                marginBottom: "1rem",
                color: "white",
              }}
            >
              <strong>
                <h4>¡ Atendamos a {name} !</h4>
              </strong>
            </div>
            <div
              className="col"
              style={{
                height: "50vh",
                overflowY: "scroll",
              }}
            >
              <ClinicAppointment
                state={this.state}
                onChange={this.handleChange}
              ></ClinicAppointment>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "0.5rem" }}>
            <div
              className="col-12 text-center headerasdasdasdsa"
              style={{
                /*boxShadow: "0px 1px 10px 0px black",*/
                padding: "1rem",
                backgroundColor: "#2f3542",
                marginBottom: "1rem",
                color: "white",
              }}
            >
              <strong>
                <h4>Completemos la cita</h4>
              </strong>
            </div>
            <div
              className="col"
              style={{
                height: "50vh",
                overflowY: "scroll",
              }}
            >
              <AfterClinicAppointment
                state={this.state}
                onChange={this.handleChange}
              ></AfterClinicAppointment>
            </div>
          </div>

          <div
            className="row justify-content-center align-items-center"
            style={{
              flexDirection: "row",
              height: "10rem",
              backgroundColor: "#222f3e",
              color: "white",
            }}
          >
            <div className="col-12 text-center">
              <h4>Completemos el servicio</h4>
              {this.state.disabledButton ? (
                <button
                  type="submit"
                  onSubmit={this.handleSubmit}
                  className="btn btn-secondary text-dark"
                  disabled
                >
                  Archivando ficha...
                </button>
              ) : (
                <button
                  type="submit"
                  onSubmit={this.handleSubmit}
                  className="btn btn-success text-dark"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleSubmit();
                  }}
                >
                  ¡Terminar servicio!
                </button>
              )}
              <br />
              <small>
                Una vez enviado el informe, no podra ser modificado de ninguna
                manera asi que verifica los datos antes de enviarlos.
              </small>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      {" "}
      <section className="modal-main clients-frame">
        {children}
        <i class="material-icons" onClick={handleClose}>
          close
        </i>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  file: state.History.file,
  apointments: state.RedApoint.apointments,
});

export default connect(mapStateToProps, {
  updateAppointment,
  createMessage,
  addGroomFile,
  updatePictures,
  getGroomFile,
  finishClinicFile,
  addClinicFile,
  getClinicFile,
  getPetHistory,
})(AttendingClinicAppointment);
