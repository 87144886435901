import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addCategory } from "../../../actions/Categories";


export class CategoriesForm extends Component {
  state = {
    name: "",
    description: ""
  };
  static propTypes = {
    addCategory: PropTypes.func.isRequired
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onChangeName = e => this.setState({ name: e.target.value.toUpperCase() });
  onSubmit = e => {
    e.preventDefault();
    //Las categorias tienen un nombre unico por lo que para evitar comprobaciones
    //se convierte toda la cadena en mayusculas ya que en la bases de datos
    //ya esta definido como unique pero es case insensitive

    const { name, description } = this.state;
    const category = { name, description };
    this.props.addCategory(category);
    this.setState({
      name: "",
      description: ""
    });
  };

  render() {
    const { name, description } = this.state;

    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit} noValidate>
          <div className="card card-body mt-4 mb-4">
            <h1 className="text-center text-primary">Nueva Categoria</h1>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Nombre de Categoria</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nombre"
                    name="name"

                    onChange={this.onChangeName}

                    value={name}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Descripcion</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Descripcion"
                    name="description"
                    onChange={this.onChange}
                    value={description}
                  />
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Agregar Categoria
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { addCategory }
)(CategoriesForm);
