import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  RECORDS_LOADING,
  RECORDS_LOADED,
  DRECORDS_LOADED,
  __BASE__URL,
  ADD_RECORD,
  ADD_DRECORD
} from "./types";
import { tokenConfig } from "./ActAuths";

export const getVaccines = id => (dispatch, getState) => {
  const url = __BASE__URL + `/api/vaccine/${id}`;
  dispatch({ type: RECORDS_LOADING });
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({ type: RECORDS_LOADED, payload: res.data });
      createMessage({ addPet: "Vacunas cargadas" });
    })
    .catch(err => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};

export const addVaccine = info => (dispatch, getState) => {
  const url = __BASE__URL + "/api/vaccine/";

  axios
    .post(url, info, tokenConfig(getState))
    .then(res => {
      dispatch(createMessage({ addLead: "Vacuna agregada al control" }));
      dispatch({ type: ADD_RECORD, payload: res.data });
    })
    .catch(err => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};

export const getDewormers = id => (dispatch, getState) => {
  const url = __BASE__URL + `/api/dewormer/${id}`;
  dispatch({ type: RECORDS_LOADING });
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({ type: DRECORDS_LOADED, payload: res.data });
      createMessage({ addPet: "Desparacitantes cargados" });
    })
    .catch(err => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};

export const addDewormer = info => (dispatch, getState) => {
  const url = __BASE__URL + "/api/dewormer/";

  axios
    .post(url, info, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessage({ addLead: "Desparacitante agregada al control" })
      );
      dispatch({ type: ADD_DRECORD, payload: res.data });
    })
    .catch(err => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};
