import React, { Component } from "react";
import Calendar from "react-calendar";
import DateDetailList from "../common/DateDetail";
/*import Calendar from "react-calendar";*/
import MaterialTable from "material-table";
import { createMessage } from "../../actions/ActMessages";
import {
  getAllApointments,
  updateAppointment,
  getDayApointments,
  getAllGroomApointments,
  getAllOtherApointments,
  getAppointmentsByRange,
} from "../../actions/ActApointments";
import { getCertainProfile } from "../../actions/ActProfile";
import { searchPets } from "../../actions/ActPets";
import { connect } from "react-redux";
import AppointmentIcon from "../../assets/icons/shipping.svg";
import PropTypes from "prop-types";
import Person from "../../assets/person.svg";
import "./styles/frames.css";
/*import LoadingDog from "../common/Loading";*/
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import NewClientProfile from "../Forms/NewClientProfile";
import Loading from "../common/Loading";

const APPOINTMENT_STATUS = "PENDIENTE";
class Secretary_Dashboard extends Component {
  state = {
    userToGetProfile: null,
    show: false,
    clientToLoadOnModal: null,
    clientToLoadUsername: null,
    rol: null,
    start_date: null,
    end_date: null,
  };
  static propTypes = {
    getAllApointments: PropTypes.func.isRequired,
    getAllGroomApointments: PropTypes.func.isRequired,
    getAllOtherApointments: PropTypes.func.isRequired,
    getAppointmentsByRange: PropTypes.func.isRequired,
    getDayApointments: PropTypes.func.isRequired,
    updateAppointment: PropTypes.func.isRequired,
    getCertainProfile: PropTypes.func.isRequired,
    searchPets: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    profileLoading: PropTypes.bool.isRequired,
    apointments: PropTypes.array.isRequired,
    nongroomappointments: PropTypes.array.isRequired,
    groomappointments: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
  };

  componentDidMount() {
    // Get today's date
    const today = new Date();

    // Calculate the date 5 days ahead
    const endDate = new Date();
    endDate.setDate(today.getDate() + 5);

    const formattedStartDate = today.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    let month = new Date().getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day = new Date().getDate();
    if (day < 10) {
      day = "0" + day;
    }
    
    this.setState(
      {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        rol: "secretaria",
        inDate: new Date().getFullYear() + "-" + month + "-" + day,
      },
      this.props.getAppointmentsByRange(formattedStartDate, formattedEndDate, APPOINTMENT_STATUS)
    );
  }

  makeApiCallToGetProfile() {
    if (this.state.userToGetProfile) {
      this.props.getCertainProfile(this.state.userToGetProfile);
      /*this.setState({ loadedProfile: this.props.profile });*/
    }
  }

  handleClick(clientId) {
    this.props.getCertainProfile(clientId);
    this.props.createMessage({
      profileInformationLoaded:
        "La información se ha cargado, despliegue el menu nuevamente.",
    });
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRangeChange = (e) => {
    const formattedStartDate = this.state.start_date;
    const formattedEndDate = this.state.end_date;
    this.props.getAppointmentsByRange(formattedStartDate, formattedEndDate, APPOINTMENT_STATUS);
  };

  handeCalendarChange = (date) => {
    let inDate =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    this.setState({ inDate });
    this.props.createMessage({
      apointmentsOf: "Mostrando citas del dia " + inDate,
    });
    this.props.getAppointmentsByRange(inDate, inDate, APPOINTMENT_STATUS);
    // if (this.props.user.id) {
    //   this.props.getDayApointments(inDate, this.props.user.id);
    // }
    this.setState({ show: false });
  };

  render() {
    if (!this.state.rol) {
      return <Loading></Loading>;
    }
    const apointments = this.props.apointments;
    var parsedDict = { dataArray: [] };
    for (var {
      id,
      date,
      time,
      cliente: { first_name: clientName },
      cliente: { username: clientEmail },
      cliente: { id: clientId },
      pet: { name: petName },
      doctor: { first_name: attendantName },
    } of apointments) {
      let parsedApointment = {
        apointmentId: id,
        clientId,
        apointmentDate: date,
        apointmentTime: time,
        clientName,
        pacientName: petName,
        whoAttends: attendantName,
        email: clientEmail,
      };
      // .filter((cita) => cita.doctor.id !== 484)) {
      //   };
      parsedDict.dataArray.push(parsedApointment);
    }

    /*let groomAppointments = "";
    groomAppointments = this.props.apointments.filter(
      (cita) => cita.doctor.id === 484,
      this.setState({ citasGrooming: groomAppointments })
    );*/

    /*console.log(parsedDict);*/

    return (
      <React.Fragment>
        {this.state.show ? (
          <Modal show={this.state.show} handleClose={this.hideModal}>
            <NewClientProfile
              clientId={this.state.clientToLoadOnModal}
              username={this.state.clientToLoadUsername}
            ></NewClientProfile>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <div className="container-fluid" style={{ height: "100vh" }}>
          <div
            className="row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              color: "black",
              height: "8rem",
            }}
          >
            <p style={{ alignSelf: "center" }}>Panel de control Secretaria</p>
          </div>
          <div className="row d-flex justify-content-center aling-items-center">
              <Calendar
                onChange={this.handeCalendarChange}
                minDate={new Date()}
              />
          </div>
          {this.props.loading ? (
            <Loading></Loading>
          ) : (
            <React.Fragment>
              <div className="row p-4">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {this.props.loading ? (
                    <Loading></Loading>
                  ) : (
                    <React.Fragment>
                      <strong>Citas de grooming</strong>
                      <div>
                        <DateDetailList
                          apointments={this.props.apointments.filter(
                            (cita) =>
                              cita.date === this.state.inDate &&
                              cita.doctor.id === 484
                          )}
                          actualDate={this.state.inDate}
                        ></DateDetailList>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {this.props.loading ? (
                    <Loading></Loading>
                  ) : (
                    <React.Fragment>
                      <strong>Citas clínicas</strong>
                      <div>
                        <DateDetailList
                          apointments={this.props.apointments.filter(
                            (cita) =>
                              cita.date === this.state.inDate &&
                              cita.doctor.id !== 484
                          )}
                          actualDate={this.state.inDate}
                        ></DateDetailList>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="row">
            <div className="col-12 d-flex pb-3 pt-5 justify-content-center">
              <img src={AppointmentIcon} alt="" className="header-icon" />
              <br />
              <p className="pl-5">
                Las citas pendientes son mostradas acontinuación, tanto clínicas
                como grooming.
              </p>
            </div>
          </div>
          <div
            className="row"
            style={{ padding: "0px 20px", marginBottom: "20px" }}
          >
            <div className="col-5">
              <label>{"Inicio"}</label>
              <input
                type="date"
                className="form-control"
                name="start_date"
                value={this.state.start_date}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-5">
              <label>{"Final"}</label>
              <input
                type="date"
                name="end_date"
                className="form-control"
                value={this.state.end_date}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-2 d-flex justify-content-center align-items-end">
              <button
                className="btn btn-primary"
                onClick={this.handleRangeChange}
              >
                {"Buscar"}
              </button>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col">
              <div className="col-12">
                {parsedDict.dataArray && (
                  <MaterialTable
                    localization={{
                      toolbar: {
                        searchTooltip: "Buscar",
                        searchPlaceholder:
                          "Cliente, Doctor, Mascota, Fecha y Hora",
                      },
                      body: {
                        emptyDataSourceMessage:
                          "No hay citas, dia tranquilo al parecer",
                        editRow: {
                          deleteText: "Esta seguro de cancelar esta cita",
                        },
                      },

                      header: {
                        actions: "Acciones",
                      },
                    }}
                    options={{
                      pageSize: 20,
                      headerStyle: {
                        backgroundColor: "#01579b",
                        color: "white",
                        paddingLeft: "20px",
                      },
                      exportButton: false,
                      actionsColumnIndex: -1,
                      detailPanelColumnAlignment: "right",
                    }}
                    columns={[
                      { title: "Fecha", field: "apointmentDate" },
                      { title: "Hora", field: "apointmentTime" },
                      {
                        title: "Cliente",
                        field: "clientName",
                      },
                      {
                        title: "Paciente",
                        field: "pacientName",
                      },
                      {
                        title: "doctor",
                        field: "whoAttends",
                      },
                    ]}
                    data={parsedDict.dataArray}
                    title="Citas pendientes"
                    actions={[
                      {
                        icon: "add",
                        tooltip: "Mostrar información",
                        onClick: (event, rowData) => {
                          event.preventDefault();
                          this.showModal();
                          this.setState({
                            clientToLoadOnModal: rowData.clientId,
                            clientToLoadUsername: rowData.email,
                          });
                        },
                      },
                    ]}
                    editable={{
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          setTimeout(() => {
                            {
                              let data = parsedDict.dataArray;
                              const index = data.indexOf(oldData);
                              data.splice(index, 1);

                              this.props.updateAppointment(
                                { status: "CANCELADA" },
                                oldData.apointmentId
                              );

                              setTimeout(() => {
                                document.location.reload();
                              }, 100);
                            }
                            resolve();
                          }, 1000);
                        }),
                    }}
                    onRowClick={(event, rowData) => {
                      event.preventDefault();
                      this.showModal();
                      this.setState({
                        clientToLoadOnModal: rowData.clientId,
                        clientToLoadUsername: rowData.email,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section
        className="modal-main clients-frame"
        style={{ overflow: "scroll" }}
      >
        <img src={Person} alt="" />
        <span className="circle" onClick={handleClose}></span>
        {children}
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.RedAuth.isAuthenticated,
  apointments: state.RedApoint.apointments,
  gmappointments: state.RedApoint.groomappointments,
  otherappointments: state.RedApoint.nongroomappointments,
  loading: state.RedApoint.loading,
  profileLoading: state.Profile.loading,
  profile: state.Profile.profileInfo,
  user: state.RedAuth.user,
});
export default connect(mapStateToProps, {
  getAllApointments,
  getCertainProfile,
  createMessage,
  getAllGroomApointments,
  getAllOtherApointments,
  getAppointmentsByRange,
  updateAppointment,
  getDayApointments,
  searchPets,
})(Secretary_Dashboard);
