import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  ADD_COMMISSION,
  GET_COMMISSION,
  COMMISSION_LOADED,
  TOTAL_BASIC_COMMISSION,
  GET_SURGERY_COMMISSION,
  TOTAL_SURGERY_COMMISSION,
  GET_OTHER_COMMISSION,
  TOTAL_OTHER_COMMISION,
  GET_COMMISSION_RANK,
  LOADED_COMMISSION_RANK,
  TOTAL_COMMISSION_RANK,
  GET_SURGERY_COM_RANK,
  TOTAL_SURGERY_COM_RANK,
  GET_OTHER_COM_RANK,
  TOTAL_OTHER_COM_RANK,
  GET_PHARMACY_COMMISSION,
  GET_TOTAL_PHARMACY_COMMISSION,
  GET_PHARMACY_COMMISSION_RANK,
  GET_TOTAL_PHARMACY_COMMISSION_RANK,
  DEACTIVATE_COMMISSION,
  UPDATE_COMMISSION,
  __BASE__URL,
  DEACTIVATE_COMMISSION_RANK
} from "./types";
import { tokenConfig } from "./ActAuths";

//ACTUALIZAR ESTADO COMISSION
export const updateStateComission = (id, estado_comision) => (
  dispatch,
  getState
) => {
  const body = JSON.stringify({
    id,
    estado_comision
  });
  const url = __BASE__URL + `/api/commission/updatesCom/${id}/`;
  try {
    axios
      .put(url, body, tokenConfig(getState))
      .then(res => {
        /*dispatch(
          createMessage({ updatecom: "Comision desactivada con exito!" })
        );*/
        dispatch({
          type: UPDATE_COMMISSION,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

//ADD COMMISSION
export const addCommission = commission => (dispatch, getState) => {
  const url = __BASE__URL + "/api/commission/";
  axios
    .post(url, commission, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessage({ addCommission: "Comision Agregada con exito!" })
      );
      dispatch({
        type: ADD_COMMISSION
        //payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//--------------------------------------Basic Today----------------------------------------------------------------

//GET BASIC COMMISSIONS
export const getCommisionsToday = doctor => (dispatch, getState) => {
  const url = __BASE__URL + "/api/commissionActualDay";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalBasicCommisionsToday = doctor => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalComssionTodayBasic";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_BASIC_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};
//---------------------------------Surgery Actual Day-------------------------------------------------
//get surgery comission today
export const getSurgeryCommisionsToday = doctor => (dispatch, getState) => {
  const url = __BASE__URL + "/api/SurgerycommissionActualDay";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_SURGERY_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalSurgeryCommisionsToday = doctor => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/TotalSurgeryComssionTodayBasic";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SURGERY_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//-------------------------------------Other Actual Day---------------------------------------------------------------

//get other commission today
export const getOtherCommisionsToday = doctor => (dispatch, getState) => {
  const url = __BASE__URL + "/api/commissionOtherActualDay";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_OTHER_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//get total other commission today
export const getTotalOtherCommisionsToday = doctor => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalOtherComssionToday";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_OTHER_COMMISION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//-------------------------------Basic Actual Month------------------------------------------------------------

export const getCommisionsMonth = doctor => (dispatch, getState) => {
  const url = __BASE__URL + "/api/commissionActualMonth";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalBasicCommisionsMonth = doctor => (dispatch, getState) => {
  const url = __BASE__URL + "/api/TotalcommissionActualMonthBasic";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_BASIC_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//----------------------------Surgery Actual Month---------------------------------------------------------------

export const getSurgeryCommisionsMonth = doctor => (dispatch, getState) => {
  const url = __BASE__URL + "/api/SurgerycommissionActualMonth";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_SURGERY_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalSurgeryBasicCommisionsMonth = doctor => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/TotalSurgerycommissionActualMonthBasic";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SURGERY_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//-----------------------------------Other Actual MOnth----------------------------------
export const getOtherCommisionsActualMonth = doctor => (dispatch, getState) => {
  const url = __BASE__URL + "/api/OthercommissionActualMonth";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_OTHER_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalOtherCommisionsActualMonth = doctor => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/TotalOthercommissionActualMonth";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_OTHER_COMMISION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//----------------------------------By Rank-----------------------------------------------------------------
export const getCommisionsBasicRank = (doctor, inicio, final) => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/getCommissionBasicRank";
  const body = JSON.stringify({
    doctor,
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_COMMISSION_RANK,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalCommissionRank = (doctor, inicio, final) => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/TotalComssionRank";
  const body = JSON.stringify({
    doctor,
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_COMMISSION_RANK,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getCommisionsSurgeryRank = (doctor, inicio, final) => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/getCommissionSurgeryRank";
  const body = JSON.stringify({
    doctor,
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_SURGERY_COM_RANK,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalSurgeryCommissionRank = (doctor, inicio, final) => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/TotalComssionSurgeryRank";
  const body = JSON.stringify({
    doctor,
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_SURGERY_COM_RANK,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getCommisionsOtherRank = (doctor, inicio, final) => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/getCommissionOtherRank";
  const body = JSON.stringify({
    doctor,
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_OTHER_COM_RANK,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalOtherCommissionRank = (doctor, inicio, final) => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/TotalComssionOtherRank";
  const body = JSON.stringify({
    doctor,
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TOTAL_OTHER_COM_RANK,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

/*----------------------------------FARMACIA------------------------------------------------------*/
export const getPharmacyCommisionsToday = doctor => (dispatch, getState) => {
  const url = __BASE__URL + "/api/commissionParhmacyActualDay";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_PHARMACY_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalPharmacyCommisionsToday = doctor => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/TotalPharmacyComssionToday";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_TOTAL_PHARMACY_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getPharmacyCommisionsActualMonth = doctor => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/PharmacycommissionActualMonth";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_PHARMACY_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalPharmacyCommisionsActualMonth = doctor => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/TotalPharmacycommissionActualMonth";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_TOTAL_PHARMACY_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getCommisionsPharmacyRank = (doctor, inicio, final) => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/getCommissionPharmacyRank";
  const body = JSON.stringify({
    doctor,
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_PHARMACY_COMMISSION_RANK,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getTotalPharmacyCommissionRank = (doctor, inicio, final) => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/TotalComssionPharmacyRank";
  const body = JSON.stringify({
    doctor,
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_TOTAL_PHARMACY_COMMISSION_RANK,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//-----------------------------------Comisiones desactivadas--------------------------------------------

export const getDeactivateCommisionsActualDay = doctor => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/deactivateToday";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DEACTIVATE_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDeactivateCommisionsActualMonth = doctor => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/deactivateMonth";
  const body = JSON.stringify({
    doctor
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DEACTIVATE_COMMISSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const getDeativateCommissionRank = (doctor, inicio, final) => (
  dispatch,
  getState
) => {
  const url = __BASE__URL + "/api/deactivateRank";
  const body = JSON.stringify({
    doctor,
    inicio,
    final
  });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DEACTIVATE_COMMISSION_RANK,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//-----------------------------------------------------------------------------------------
export const LoadedCommission = () => dispatch => {
  //solo sirve para indicar que las commissiones ya se cargaron
  dispatch({ type: COMMISSION_LOADED });
};

export const LoadedCommissionRank = () => dispatch => {
  //solo sirve para indicar que las commissiones ya se cargaron
  dispatch({ type: LOADED_COMMISSION_RANK });
};
