import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  USER_LOADED,
  USER_LOADING,
  USER_UPDATED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REMOVE_PROFILE,
  SECRETARY_REGISTER_ACHIVED,
  SECRETARY_REGISTER_ERROR,
  SECRETARY_REGISTER_WAITING,
  __BASE__URL,
} from "./types";

//CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
  //USER loading
  dispatch({ type: USER_LOADING });
  //GET token from state
  try {
    axios
      .get(__BASE__URL + "/api/auth/user", tokenConfig(getState))
      .then((res) => {
        dispatch({ type: USER_LOADED, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
        dispatch({ type: AUTH_ERROR });
      });
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = (body, id) => async (dispatch, getState) => {
  /*const body = JSON.stringify({ info });*/
  dispatch({ type: USER_LOADING });
  try {
    const url = `${__BASE__URL}/api/auth/updateuser/${id}/`;
    await axios
      .patch(url, body, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: USER_UPDATED, payload: res.data });
      })
      .catch((err) => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

//LOgin user
//Username, for this project the username == to email for client... for doctors and grooming will do something diferent
export const login = (username, password) => (dispatch) => {
  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //Request Body
  const body = JSON.stringify({
    username,
    password,
  });

  axios
    .post(__BASE__URL + "/api/auth/login", body, config)
    .then((res) => {
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
      dispatch({ type: LOGIN_FAIL });
    });
};

//Logout user
export const logout = () => (dispatch, getState) => {
  //GET token from state

  axios
    .post(__BASE__URL + "/api/auth/logout/", null, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: LOGOUT_SUCCESS });
      dispatch({ type: REMOVE_PROFILE });
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};

//Setup config with Token, this is a helper function,Function to retrive the adding authorization header for any protected route
export const tokenConfig = (getState) => {
  //GET token from state
  /*console.log();*/
  const token = getState().RedAuth.token;
  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

//register user
//Register recibe un objecto usuario (newUser) Destructuramos obteniendo los campos que nos sirven para poder utilizarlos
//dentro del metodo
export const register = ({
  username,
  email,
  first_name,
  password,
  last_name,
}) => (dispatch) => {
  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //Request Body
  const body = JSON.stringify({
    username,
    email,
    first_name,
    password,
    last_name,
  });

  console.log(body);

  axios
    .post(__BASE__URL + "/api/auth/register", body, config)
    .then((res) => {
      dispatch({ type: REGISTER_SUCCESS, payload: res.data });
      dispatch(
        createMessage({
          registerNewUserSuccess: "Usuario nuevo registrado con exito",
        })
      );
    })
    .catch((err) => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
      dispatch({ type: REGISTER_FAIL });
    });
};

export const registerNewUser = ({
  username,
  first_name,
  email,
  password,
  last_name,
}) => (dispatch) => {
  //Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //Request Body
  const body = JSON.stringify({
    username,
    first_name,
    email,
    password,
    last_name,
  });

  dispatch({ type: SECRETARY_REGISTER_WAITING });
  axios
    .post(__BASE__URL + "/api/auth/register/newUser", body, config)
    .then((res) => {
      dispatch({ type: SECRETARY_REGISTER_ACHIVED });
      dispatch(
        createMessage({
          registerNewUserSuccess: "Usuario nuevo registrado con exito",
        })
      );
      dispatch({ type: SECRETARY_REGISTER_ERROR });
    })
    .catch((err) => {
      dispatch({ type: SECRETARY_REGISTER_ERROR });
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};
