import {
  VETS_LOADING,
  VETS_LOADED
  /*VETS_APOINTMENT_LOADING,
  VETS_APOINTMENT_LOADED,
  DAYAPOINTMENTS_LOADING,
  DAYAPOINTMENTS_LOADED*/
} from "../actions/types";

const initialState = {
  loading: false,
  vets: []
  /*apointments: []*/
};

export default function(state = initialState, action) {
  switch (action.type) {
    case VETS_LOADING:
      return {
        ...state,
        loading: true
      };

    case VETS_LOADED:
      return {
        ...state,
        vets: action.payload,
        loading: false
      };

    /*case VETS_APOINTMENT_LOADING:
    case DAYAPOINTMENTS_LOADING:
      return {
        ...state,
        loading: true
      };

    case VETS_APOINTMENT_LOADED:
    case DAYAPOINTMENTS_LOADED:
      return {
        ...state,
        apointments: action.payload,
        loading: false
      };*/

    default:
      return state;
  }
}
