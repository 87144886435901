import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  __BASE__URL,
  HISTORY_PICTURE_UPLOADING,
  HISTORY_PICTURE_UPLOADED
} from "./types";
import { tokenConfig } from "./ActAuths";
import { HISTORY_FILE_LOADING, HISTORY_FILE_LOADED } from "./types";

export const addGroomFile = GroomFilePicture => (dispatch, getState) => {
  const url = __BASE__URL + "/api/grooming/appointment/detail/";
  /*const body = JSON.stringify({
    ...GroomFile
  });*/
  dispatch({ type: HISTORY_FILE_LOADING });
  axios
    .post(url, GroomFilePicture, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessage({
          GroomFileCreated: "Se ha registrado una ficha nueva y reluciente"
        })
      );
      dispatch({ type: HISTORY_FILE_LOADED, payload: res.data });
    })
    .catch(err => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};

export const getGroomFile = id => (dispatch, getState) => {
  const url = `${__BASE__URL}/api/grooming/appointment/detail/${id}`;
  dispatch({ type: HISTORY_FILE_LOADING });
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({ type: HISTORY_FILE_LOADED, payload: res.data });
    });
};

export const updatePictures = (GroomFilePicture, id) => async (
  dispatch,
  getState
) => {
  const url = `${__BASE__URL}/api/grooming/appointment/after/${id}/`;
  dispatch({ type: HISTORY_PICTURE_UPLOADING });
  dispatch({ type: HISTORY_FILE_LOADING });
  const token = getState().RedAuth.token;
  //Headers
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Token ${token}`
    },
  };
  
  console.log(GroomFilePicture);
  await axios
    .patch(url, GroomFilePicture, config)
    .then(res => {
      console.log(res);
      dispatch(createMessage({ GroomFileCreated: "¡Foto actualizada!" }));
      dispatch({ type: HISTORY_PICTURE_UPLOADED });
      dispatch({ type: HISTORY_FILE_LOADED, payload: res.data });
    })
    .catch(err => {
      dispatch({ type: HISTORY_PICTURE_UPLOADED });
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};

export const finishGroomFile = (GroomFilePicture, id) => async (
  dispatch,
  getState
) => {
  const url = `${__BASE__URL}/api/grooming/appointment/after/${id}/`;
  dispatch({ type: HISTORY_FILE_LOADING });
  await axios
    .patch(url, GroomFilePicture, tokenConfig(getState))
    .then(res => {
      dispatch(createMessage({ GroomFileCreated: "¡Foto actualizada!" }));
      dispatch({ type: HISTORY_FILE_LOADED, payload: res.data });
    })
    .catch(err => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};
