import React, { Component } from "react";
import Skin from "../../assets/icons/sking.svg";
import Eyes from "../../assets/icons/eye.svg";
import Ears from "../../assets/icons/ear.svg";
import Nose from "../../assets/icons/nose.svg";
import Mouth from "../../assets/icons/boca.png";
import Cardio from "../../assets/icons/cario.svg";
import Breathing from "../../assets/icons/respiratorio.svg";
import Chest from "../../assets/icons/chest.svg";
import DefaultDog from "../../assets/icons/defaultdog.svg";
class ClinicAppointment extends Component {
  render() {
    const {
      piel,
      ojos,
      oidos,
      nariz,
      boca,
      cardio,
      respiratorio,
      abdomen,
      gastro,
      urogenital,
      linfaticos,
      musculos,
      neuro
    } = this.props.state;
    return (
      <React.Fragment>
        <div className="container">
          <form>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                PIEL
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={Skin} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="piel"
                    value={piel || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                OJOS
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={Eyes} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="ojos"
                    value={ojos || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                OIDOS
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={Ears} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="oidos"
                    value={oidos || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                NARIZ
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={Nose} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="nariz"
                    value={nariz || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                BOCA
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={Mouth} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="boca"
                    value={boca || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                CARDIO
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={Cardio} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="cardio"
                    value={cardio || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                RESPIRATORIO
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={Breathing} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="respiratorio"
                    value={respiratorio || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                ABDOMEN
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={Chest} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="abdomen"
                    value={abdomen || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                GASTRO
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={DefaultDog} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="gastro"
                    value={gastro || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                UROGENITAL
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={DefaultDog} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="urogenital"
                    value={urogenital || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                LINFÁTICOS
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={DefaultDog} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="linfaticos"
                    value={linfaticos || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                MÚSCULOS
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={DefaultDog} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="musculos"
                    value={musculos || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                NEURO
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={DefaultDog} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="neuro"
                    value={neuro || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {oidos}
                  </textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default ClinicAppointment;
