import React, { Component } from 'react';
import './AppointmentCard.css'; // Import CSS file for styling

class AppointmentCard extends Component {
    render() {
        const { appointment } = this.props;
        const { id, status, date, notes, time, cliente, pet, doctor, aggressive, nervous, profile, profile_doctor, clinic_file } = appointment;

        return (
            <div className="appointment-card">
                <div className="header">
                    <h2>Appointment Details</h2>
                    <p>
                        <strong>ID: </strong> {id} <br />
                        <strong>Estado de la cita: </strong> {status} <br />
                        <strong>Fecha: </strong> {date} <br />
                        <strong>Hora: </strong> {time} <br />
                    </p>
                </div>
                <div className="body">
                    <div className='row'>
                        <div className='col-6'>
                            <p>Médico: {doctor.first_name} {doctor.last_name}</p>
                        </div>
                        <div className='col-6'>
                            <p>Cliente: {cliente.first_name} {cliente.last_name}</p>
                            <p>Paciente: {pet.name}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <h3>Notes: </h3>
                            <p>{notes}</p>
                        </div>
                    </div>
                    {clinic_file ? (
                        <div>
                            <h3>Detalle de la cita</h3>
                            {clinic_file.anamnesis && <p><strong>Anamnesis: </strong><span className="text-muted">{clinic_file.anamnesis}</span></p>}
                            {clinic_file.alimentacion && <p><strong>Alimentacion: </strong><span className="text-muted">{clinic_file.alimentacion}</span></p>}
                            {clinic_file.md && <p><strong>Medio ambiente: </strong><span className="text-muted">{clinic_file.md}</span></p>}
                            {clinic_file.mp && <p><strong>Medicamentos previos: </strong><span className="text-muted">{clinic_file.mp}</span></p>}
                            {clinic_file.vacunado && <p><strong>Vacunado: </strong><span className="text-muted">{clinic_file.vacunado}</span></p>}
                            {clinic_file.desparacitado && <p><strong>Desparacitado: </strong><span className="text-muted">{clinic_file.desparacitado}</span></p>}
                            {clinic_file.temperatura && <p><strong>Temperatura: </strong><span className="text-muted">{clinic_file.temperatura}</span></p>}
                            {clinic_file.fc1 && <p><strong>Frecuencia cardiaca: </strong><span className="text-muted">{clinic_file.fc1}</span></p>}
                            {clinic_file.presionArterial && <p><strong>Presion arterial: </strong><span className="text-muted">{clinic_file.presionArterial}</span></p>}
                            {clinic_file.fr && <p><strong>Frecuencia respiratoria: </strong><span className="text-muted">{clinic_file.fr}</span></p>}
                            {clinic_file.peso && <p><strong>Peso: </strong><span className="text-muted">{clinic_file.peso}</span></p>}
                            {clinic_file.condicionc && <p><strong>Condicion corporal: </strong><span className="text-muted">{clinic_file.condicionc}</span></p>}
                            {clinic_file.piel && <p><strong>Piel: </strong><span className="text-muted">{clinic_file.piel}</span></p>}
                            {clinic_file.ojos && <p><strong>Ojos: </strong><span className="text-muted">{clinic_file.ojos}</span></p>}
                            {clinic_file.oidos && <p><strong>Oidos: </strong><span className="text-muted">{clinic_file.oidos}</span></p>}
                            {clinic_file.nariz && <p><strong>Nariz: </strong><span className="text-muted">{clinic_file.nariz}</span></p>}
                            {clinic_file.boca && <p><strong>Boca: </strong><span className="text-muted">{clinic_file.boca}</span></p>}
                            {clinic_file.cardio && <p><strong>Cardio: </strong><span className="text-muted">{clinic_file.cardio}</span></p>}
                            {clinic_file.respiratorio && <p><strong>Respiratorio: </strong><span className="text-muted">{clinic_file.respiratorio}</span></p>}
                            {clinic_file.abdomen && <p><strong>Abdomen: </strong><span className="text-muted">{clinic_file.abdomen}</span></p>}
                            {clinic_file.gastro && <p><strong>Gastro: </strong><span className="text-muted">{clinic_file.gastro}</span></p>}
                            {clinic_file.urogenital && <p><strong>Urogenital: </strong><span className="text-muted">{clinic_file.urogenital}</span></p>}
                            {clinic_file.linfaticos && <p><strong>Linfaticos: </strong><span className="text-muted">{clinic_file.linfaticos}</span></p>}
                            {clinic_file.musculos && <p><strong>Musculos: </strong><span className="text-muted">{clinic_file.musculos}</span></p>}
                            {clinic_file.neuro && <p><strong>Neuro: </strong><span className="text-muted">{clinic_file.neuro}</span></p>}
                            {clinic_file.ad && <p><strong>Ayudas diagnosticas: </strong><span className="text-muted">{clinic_file.ad}</span></p>}
                            {clinic_file.diagnostico && <p><strong>Diagnostico: </strong><span className="text-muted">{clinic_file.diagnostico}</span></p>}
                            {clinic_file.tratamiento && <p><strong>Tratamiento: </strong><span className="text-muted">{clinic_file.tratamiento}</span></p>}
                            {clinic_file.observaciones && <p><strong>Observaciones: </strong><span className="text-muted">{clinic_file.observaciones}</span></p>}
                        </div>
                    ) : (
                        <p>No clinic file available</p>
                    )}
                </div>
            </div>
        );
    }
}

export default AppointmentCard;
