import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getBrands, deleteBrand, updateBrand } from "../../../actions/Brands";
import MaterialTable from "material-table";

export class AllBrands extends Component {
  static propTypes = {
    brands: PropTypes.array.isRequired,
    getBrands: PropTypes.func.isRequired,
    deleteBrand: PropTypes.func.isRequired,
    updateBrand: PropTypes.func.isRequired,
    loading: PropTypes.bool
  };

  componentDidMount() {
    this.props.getBrands();
  }

  state = {
    columns: [
      { title: "Nombre", field: "name" },
      { title: "Descripcion", field: "description" }
    ]
  };

  render() {
    if (this.props.loading) {
      this.props.getBrands();
    }

    var allbrans = this.props.brands.map(brand => ({
      key: brand.id,
      name: brand.name,
      description: brand.description
    }));
    return (
      <React.Fragment>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <MaterialTable
          title="MARCAS"
          columns={this.state.columns}
          data={allbrans}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  {
                    const data = allbrans;
                    const index = data.indexOf(oldData);
                    data[index] = newData;
                    this.props.updateBrand(
                      newData.key,
                      newData.name.toUpperCase(),
                      newData.description
                    );
                  }
                  resolve();
                }, 2000);
              })
          }}
          actions={[
            {
              icon: "delete",
              tooltip: "eliminar",
              onClick: (event, rowData) => {
                this.props.deleteBrand(rowData.key);
              }
            }
          ]}
          options={{
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF"
            },
            /*rowStyle: {
              backgroundColor: "#EEE"
            },*/
            exportButton: true,
            actionsColumnIndex: -1
          }}
          localization={{
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar"
            },
            body: {
              emptyDataSourceMessage: "No se encontro ninguna Marca Relacionada"
            },
            header: {
              actions: "Acciones"
            }
          }}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  brands: state.Brands.brands,
  loading: state.Brands.loading
});

export default connect(
  mapStateToProps,
  { getBrands, deleteBrand, updateBrand }
)(AllBrands);
