import React, { Component } from "react";
import { withAlert } from "react-alert";

/*Redux */
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class Alerts extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error, alert, message } = this.props;
    if (error !== prevProps.error) {
      if (error.msg.name) alert.error(`Name:${error.msg.name.join()}`);
      if (error.msg.email) alert.error(`email:${error.msg.email.join()}`);
      if (error.msg.message) alert.error(`message:${error.msg.message.join()}`);
      if (error.msg.non_field_errors)
        alert.error(`${error.msg.non_field_errors.join()}`);
      if (error.msg.username) alert.error(`${error.msg.username.join()}`);
      if (error.msg.doctor) alert.error(`Debes seleccionar un doctor`);
      if (error.msg.pet) alert.error(`Debes seleccionar una mascota`);
      if (error.msg.date) alert.error(`Debes escoger una fecha`);
      if (message.GroomFileCreated) alert.success(message.GroomFileCreated);
    }
    if (message !== prevProps.message) {
      if (message.personalInfoUpdated)
        alert.success(message.personalInfoUpdated);
      if (message.nullPicture) alert.error(message.nullPicture);
      if (message.missingPictures) alert.error(message.missingPictures);
      if (message.GroomFileCreated) alert.success(message.GroomFileCreated);
      if (message.appointmentEnded) alert.success(message.appointmentEnded);
      if (message.pictureLoaded) alert.success(message.pictureLoaded);
      if (message.deleteLead) alert.success(message.deleteLead);
      if (message.addLead) alert.success(message.addLead);
      if (message.mustSelectDate) alert.error(message.mustSelectDate);
      if (message.emailDoNotMatch) alert.error(message.emailDoNotMatch);
      if (message.passwordDoNotMatch) alert.error(message.passwordDoNotMatch);
      if (message.profileUpdated) alert.success(message.profileUpdated);
      if (message.addPet) alert.success(message.addPet);
      if (message.profileInformationLoaded)
        alert.success(message.profileInformationLoaded);
      if (message.selectedPet) alert.success(message.selectedPet);
      if (message.selectedVet) alert.success(message.selectedVet);
      if (message.selectedDate) alert.success(message.selectedDate);
      if (message.addApointment) alert.success(message.addApointment);
      if (message.apointmentsOf) alert.success(message.apointmentsOf);
      if (message.petCreated) alert.success(message.petCreated);
      if (message.registerNewUserSuccess)
        alert.success(message.registerNewUserSuccess);
      if (message.addApointmentError) alert.error(message.addApointmentError);
      if (message.allFieldsRequiredOnPetForm)
        alert.error(message.allFieldsRequiredOnPetForm);
      if (message.registerNewUserError)
        alert.error(message.registerNewUserError);
      if (message.selectedClienteForApointment)
        alert.success(message.selectedClienteForApointment);

      if (message.selectedApointmentPet)
        alert.success(message.selectedApointmentPet);
      if (message.addBrand) alert.success(message.addBrand);
      if (message.deleteBrand) alert.success(message.deleteBrand);
      if (message.addCategory) alert.success(message.addCategory);
      if (message.deleteCategory) alert.success(message.deleteCategory);
      if (message.addproduct) alert.success(message.addproduct);
      if (message.updateproduct) alert.success(message.updateproduct);
      if (message.updatecategory) alert.success(message.updatecategory);
      if (message.updatebrand) alert.success(message.updatebrand);
      if (message.addreceipt) alert.success(message.addreceipt);
      if (message.addCommission) alert.success(message.addCommission);
      if (message.addrefund) alert.success(message.addrefund);
      if (message.updatecom) alert.success(message.updatecom);
      if (message.deleteSale) alert.success(message.deleteSale);
      if (message.updatestatus) alert.success(message.updatestatus);
    }
  }
  render() {
    return <React.Fragment />;
  }
}

const mapStateToProps = state => ({
  error: state.RedErrors,
  message: state.RedMessages
});
export default connect(mapStateToProps)(withAlert()(Alerts));
