import React, { Component } from "react";

export default class Loading extends Component {
  render() {
    return (
      <div className="overlayloading">
        <div
          className="spinner-border text-warning"
          role="status"
          style={{ alignSelf: "center" }}
        >
          <span className="sr-only">Loading...</span>
        </div>
        <h4 className="text-dark" style={{ alignSelf: "center" }}>
          Cargando...
        </h4>
      </div>
    );
  }
}
