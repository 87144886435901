import React, { Component } from "react";

import PropTypes from "prop-types";

class Form extends Component {
  races = [
    { name: "Sin definir" },
    { name: "Afgano" },
    { name: "American bully" },
    { name: "Airedale Terrier" },
    { name: "Akita" },
    { name: "Alaskan Malamute" },
    { name: "American Staffordshire Terrier" },
    { name: "Australian Terrier" },
    { name: "Basenji" },
    { name: "Basset hound" },
    { name: "Beagle" },
    { name: "Bichón Frisé" },
    { name: "Booldhound" },
    { name: "Border Collie" },
    { name: "Border Terrier" },
    { name: "Boston Terrier" },
    { name: "Bouviwe des flandres" },
    { name: "Boxer" },
    { name: "Bull Terrrier" },
    { name: "Bulldog Francés" },
    { name: "Bulldog Inglés" },
    { name: "Bullmastiff" },
    { name: "Chihuahua" },
    { name: "Chow Chow" },
    { name: "Cocker spaniel" },
    { name: "Collie" },
    { name: "Corgi" },
    { name: "Crestado chino" },
    { name: "Dachshund" },
    { name: "Doberman" },
    { name: "Doberman Pinscher" },
    { name: "Dálmata" },
    { name: "English cocker spaniel" },
    { name: "Esquimal Americano" },
    { name: "Fox Terrier" },
    { name: "Golden retriever" },
    { name: "Gran Danés" },
    { name: "Greyhoud" },
    { name: "Griffon de Bruselas" },
    { name: "Husky Siberiano" },
    { name: "Labrador retriever" },
    { name: "Lhasa Apso" },
    { name: "Malinois Belga" },
    { name: "Maltés" },
    { name: "Mastiff" },
    { name: "Mestizo" },
    { name: "Papillon" },
    { name: "Pastor Alemán" },
    { name: "Pastor Australiano" },
    { name: "Pastor belga" },
    { name: "Pastor Shetland" },
    { name: "Pequinés" },
    { name: "Pinscher miniatura" },
    { name: "Pitbull" },
    { name: "Pointer" },
    { name: "Pomeranian" },
    { name: "Poodle" },
    { name: "Pug" },
    { name: "Rhodesian Ridgeback" },
    { name: "Rottweiler" },
    { name: "Samoyedo" },
    { name: "Schnauzer" },
    { name: "Scottish Terrier" },
    { name: "Setter inglés" },
    { name: "Shar pei" },
    { name: "Shiba Inu" },
    { name: "Shih Tzu" },
    { name: "Silky Terrier" },
    { name: "Staffordshire Bull Terrier" },
    { name: "Viejo pastor inglés" },
    { name: "Weimaraner" },
    { name: "West Highland White Terrier" },
    { name: "Whippet" },
    { name: "Yorkshire Terrier" }
  ];

  state = {
    name: "",
    age: "",
    species: "",
    race: "",
    color: "",
    reproductive_state: ""
  };

  static propTypes = {
    addPet: PropTypes.func.isRequired
  };

  render() {
    const { name, age, color, age_months } = this.props.formValues;

    return (
      <React.Fragment>
        <form onSubmit={this.props.onSubmit}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Nombre"
                  value={name}
                  name="name"
                  onChange={this.props.onChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Años</label>
                <input
                  type="Number"
                  className="form-control"
                  id="inputName"
                  placeholder="Edad en números"
                  value={age}
                  name="age"
                  onChange={this.props.onChange}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>meses</label>
                <input
                  type="Number"
                  className="form-control"
                  id="inputName"
                  placeholder="0"
                  value={age_months}
                  name="age_months"
                  onChange={this.props.onChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Especie</label>
                <div class="input-group mb-3">
                  <select
                    class="custom-select"
                    id="inputGroupSelect01"
                    name="species"
                    onChange={this.props.onChange}
                    required
                  >
                    <option selected value="default">
                      Elíge una opción...
                    </option>
                    <option value="Gato">Gato</option>
                    <option value="Perro">Perro</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="options-container">
                <div className="form-group">
                  <label>Raza</label>
                  <div class="input-group mb-3">
                    <select
                      class="custom-select"
                      id="inputGroupSelect01"
                      name="race"
                      onChange={this.props.onChange}
                      required
                    >
                      <option selected value="default">
                        Elíge una opción...
                      </option>
                      {this.races.map(l => {
                        return (
                          <option value={l.name} key={l.name}>
                            {l.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 mt-2">
              <div className="form-group">
                <label>Color</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputPhone"
                  placeholder="Color de pelo"
                  value={color}
                  name="color"
                  onChange={this.props.onChange}
                  required
                />
              </div>
            </div>
            <div className="col-6 mt-2">
              <div className="form-group">
                <label>Estado reproductivo</label>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio1"
                    name="reproductive_state"
                    value="EST"
                    className="custom-control-input"
                    onChange={this.props.onChange}
                    required
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio1"
                  >
                    Esterilizado
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio2"
                    name="reproductive_state"
                    className="custom-control-input"
                    value="ENT"
                    onChange={this.props.onChange}
                    required
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio2"
                  >
                    Entero
                  </label>
                </div>
              </div>
            </div>
          </div>

          {this.state.disabledButton ? (
            <button
              type="submit"
              className="btn btn-success btn-block"
              onClick={e => {
                e.preventDefault();
                this.setState({ disabledButton: true });
              }}
              disabled
            >
              Mascota registrada, cierra la venta.
            </button>
          ) : (
            <button type="submit" className="btn btn-success btn-block">
              Registrar mascota
            </button>
          )}
        </form>
      </React.Fragment>
    );
  }
}

/*const mapStateToProps = state => ({
  user: state.RedAuth.user,
  profile: state.Profile
});*/

export default Form;
/*export default Form;*/
