import React, { Component } from "react";

export const CheckBox = props => {
  return (
    <React.Fragment>
      <div className="custom-control custom-checkbox m-1">
        <input
          type="checkbox"
          className="custom-control-input"
          id={`checkBox${props.name}`}
          name={props.name}
          onChange={props.onChange}
          checked={
            props.name === "lunes"
              ? props.value.lunes
                ? "checked"
                : ""
              : props.name === "martes"
              ? props.value.martes
                ? "checked"
                : ""
              : props.name === "miercoles"
              ? props.value.miercoles
                ? "checked"
                : ""
              : props.name === "jueves"
              ? props.value.jueves
                ? "checked"
                : ""
              : props.name === "viernes"
              ? props.value.viernes
                ? "checked"
                : ""
              : props.name === "sabado"
              ? props.value.sabado
                ? "checked"
                : ""
              : props.name === "domingo"
              ? props.value.domingo
                ? "checked"
                : ""
              : null
          }
        />
        <label
          className="custom-control-label"
          htmlFor={`checkBox${props.name}`}
        >
          {props.label}
        </label>
      </div>

      {/*  <div class="input-group mb-3">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="nerviosoUncheck"
                        onChange={this.handleCheckBoxChange}
                        name="nervioso"
                      />
                      <label class="custom-control-label" for="nerviosoUncheck">
                        ¿Es tu perro nervioso?
                      </label>
                    </div> */}
    </React.Fragment>
  );
};

export class CheckBoxList extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.data.map(cbox => {
          return (
            <CheckBox
              name={cbox.name}
              label={cbox.label}
              onChange={this.props.onChange}
              value={this.props.context}
              key={cbox.name}
            ></CheckBox>
          );
        })}
      </React.Fragment>
    );
  }
}
