import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from "../../actions/ActAuths";
import { createMessage } from "../../actions/ActMessages";
import formPerson from "../../assets/icons/formPerson.svg";
class Register extends Component {
  state = {
    first_name: "",
    username: "",
    email: "",
    password: "",
    password2: "",
    last_name: "cliente",
  };

  static propTypes = {
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = (e) => {
    e.preventDefault();
    const {
      first_name,
      username,
      email,
      password,
      password2,
      last_name,
    } = this.state;

    if (password !== password2) {
      this.props.createMessage({ passwordDoNotMatch: "Password do not match" });
    } else {
      const newUser = { username, first_name, password, email, last_name };
      this.props.register(newUser);
      return <Redirect to="/dashboard" />;
    }
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }
    const { first_name, username, email, password, password2 } = this.state;
    return (
      <div className="container">
        <div className="row align-items-center pt-5 h-100">
          <div className="col-lg-6 offset-lg-3 pt-5">
            <div className="card card-body pt-5">
              <img
                src={formPerson}
                alt=""
                style={{
                  width: "80%",
                  position: "absolute",
                  top: "2%",
                  left: " 2%",
                }}
              />
              <h2 className="text-center">Registro</h2>

              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label>Escoge un nombre de usuario</label>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    onChange={this.onChange}
                    value={username}
                  />
                </div>
                <div className="form-group">
                  <label>Dinos tu nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    onChange={this.onChange}
                    value={first_name}
                  />
                </div>
                <div className="form-group">
                  <label>Email para entregarte notificaciones</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    onChange={this.onChange}
                    value={email}
                  />
                </div>
                <div className="form-group">
                  <label>Teléfono ( Será utilizado como contraseña )</label>
                  <input
                    type="text"
                    className="form-control"
                    name="password"
                    onChange={this.onChange}
                    maxLength="8"
                    value={password}
                  />
                </div>
                <div className="form-group">
                  <label>Confirma tu teléfono</label>
                  <input
                    type="text"
                    className="form-control"
                    name="password2"
                    onChange={this.onChange}
                    maxLength="8"
                    value={password2}
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block">
                    Register
                  </button>
                </div>
                <p className="text-center">
                  ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.RedAuth.isAuthenticated,
  loader: state.RedAuth.isLoading,
});
export default connect(mapStateToProps, { register, createMessage })(Register);
