import $ from "jquery";

$(document).ready(function() {
  var trigger = $(".hamburger"),
    overlay = $(".overlay"),
    isClosed = false;

  trigger.click(function() {
    if (isClosed) {
      overlay.hide();
      isClosed = false;
    } else {
      overlay.show();
      isClosed = true;
    }
  });

  overlay.click(function() {
    if (isClosed) {
      overlay.hide();
      isClosed = false;
    } else {
      overlay.show();
      isClosed = true;
    }
  });

  $('[data-toggle="offcanvas"]').click(function() {
    $("#wrapper").toggleClass("toggled");
  });
});
