import React, { Component } from "react";
import "./styles/PetCard.css";


export class ApointmentCard extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="apointment-card mt-1 mb-1">
          <div className="apointment-card-header">
            <p>Fecha: {this.props.date}</p>
            <p>Hora: {this.props.time}</p>
          </div>

          <div className="apointment-card-info">
            <div className="text-center">
              <h6>{this.props.pet.name}</h6>
            </div>
            <p>{this.props.notes}</p>
            <small className="pull-right">
              Atiende: {this.props.doctor.first_name}
            </small>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class ApointmentCardList extends Component {
  render() {
    return (
      <div className="row">
        {this.props.apointments.map(apointment => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-12" key={apointment.id}>
              <ApointmentCard {...apointment} />
            </div>
          );
        })}
      </div>
    );
  }
}

export default ApointmentCardList;
