import {
  GET_COMMISSION_RANK,
  LOADED_COMMISSION_RANK,
  TOTAL_COMMISSION_RANK,
  GET_SURGERY_COM_RANK,
  TOTAL_SURGERY_COM_RANK,
  GET_OTHER_COM_RANK,
  TOTAL_OTHER_COM_RANK,
  GET_PHARMACY_COMMISSION_RANK,
  GET_TOTAL_PHARMACY_COMMISSION_RANK,
  DEACTIVATE_COMMISSION_RANK
} from "../actions/types";

const initialState = {
  commisionsBasicRank: [],
  loadingBasicRank: false,
  totalBasicRank: {},
  loadingTotalBasic: false,

  commissionSurgeryRank: [],
  loadingSurgeryRank: false,
  totalSurgeryRank: {},
  loadingTotalSurgery: false,

  commissionOtherRank: [],
  loadingOtherRank: false,
  totalOtherRank: {},
  loadingTotalOther: false,

  commissionPharRank: [],
  totalPharmRank: {},
  loadingPharmRank: false,
  loadingtotalPharmRank: false,

  deactivateComRank: [],
  loadingDeactivateRank: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_COMMISSION_RANK:
      return {
        ...state,
        commisionsBasicRank: action.payload,
        loadingBasicRank: true
      };
    case LOADED_COMMISSION_RANK:
      return {
        ...state,
        loadingBasicRank: false,
        loadingTotalBasic: false,
        loadingSurgeryRank: false,
        loadingTotalSurgery: false,
        loadingOtherRank: false,
        loadingTotalOther: false,
        loadingPharmRank: false,
        loadingtotalPharmRank: false,
        loadingDeactivateRank:false
      };
    case TOTAL_COMMISSION_RANK:
      return {
        ...state,
        totalBasicRank: action.payload,
        loadingTotalBasic: true
      };
    case GET_SURGERY_COM_RANK:
      return {
        ...state,
        commissionSurgeryRank: action.payload,
        loadingSurgeryRank: true
      };
    case TOTAL_SURGERY_COM_RANK:
      return {
        ...state,
        totalSurgeryRank: action.payload,
        loadingTotalSurgery: true
      };
    case GET_OTHER_COM_RANK:
      return {
        ...state,
        commissionOtherRank: action.payload,
        loadingOtherRank: true
      };
    case TOTAL_OTHER_COM_RANK:
      return {
        ...state,
        totalOtherRank: action.payload,
        loadingTotalOther: true
      };
    case GET_PHARMACY_COMMISSION_RANK:
      return {
        ...state,
        commissionPharRank: action.payload,
        loadingPharmRank: true
      };
    case GET_TOTAL_PHARMACY_COMMISSION_RANK: {
      return {
        ...state,
        totalPharmRank: action.payload,
        loadingtotalPharmRank: true
      };
    }
    case DEACTIVATE_COMMISSION_RANK:
      return {
        ...state,
        deactivateComRank: action.payload,
        loadingDeactivateRank: true
      };
    default:
      return state;
  }
}
