import React, { Component } from "react";
import NewRefund from "./NewRefund/NewRefund"
import ShowAllRefund from "./AllRefund/ShowAllRefund"
export class Refund extends Component {
  state = { result: "" };

  pushNewRefund = e => {
    e.preventDefault();
    this.setState({ result: "NuevaDevolucion" });
  };

  pushAllRefunds = e => {
    e.preventDefault();
    this.setState({ result: "AllRefunds" });
  };

  componentDidMount() {
    this.setState({ result: "NuevaDevolucion" });
  }
  render() {
    const result = this.state.result;
    function Verify() {
        if (result === "NuevaDevolucion") {
            return <NewRefund />;
        } else if (result === "AllRefunds") {
            return <ShowAllRefund />;
        }
        return "";
      }
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div
            className="row"
            style={{
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgba(136, 78, 160,0.6)",
              color: "black",
              height: "8rem"
            }}
          >
            <p style={{ alignSelf: "center" }}>Devoluciones</p>
          </div>
          <div className="row pt-5">
            <div className="col-md-12 col-sm-12 col-lg-12 text-center">
              <div className="card text-center">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <button className="nav-link" onClick={this.pushNewRefund}>Nueva Devolucion</button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link" onClick={this.pushAllRefunds}>
                        Todas las Devoluciones
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card-body text-center">
                    <Verify />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Refund;
