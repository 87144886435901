import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getVets } from "../../../actions/ActVets";
import Select from "react-select";
import SaleofProducts from "./SaleofProducts";
import { Redirect } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
export class NewSalePharmacy extends Component {
  static propTypes = {
    vets: PropTypes.array.isRequired,
    getVets: PropTypes.func.isRequired,
    loading: PropTypes.bool
  };

  state = {
    selectOptionDoctor: "",
    nitInput: "",
    result: "",
    newSale: "",
    finalConsumer: "",
    client: "",
    porcentaje: 8,
    doctorerror: "",
    porcentajeError: ""
  };
  pushNewSale = e => {
    e.preventDefault();
    this.setState({ result: "NuevaVenta" });
  };

  validate = () => {
    let iserror = false;
    const errors = {
      doctorerror: "",
      porcentajeError: ""
    };

    if (this.state.selectOptionDoctor === "") {
      iserror = true;
      errors.doctorerror =
        "Debe seleccionar un doctor para agregar la comision";
    }
    if (this.state.porcentaje <= 0) {
      iserror = true;
      errors.porcentajeError = "El porcentaje de Comision debe ser mayor a 0";
    }
    this.setState({
      ...errors
    });
    return iserror;
  };
  pushFinalConsumer = e => {
    e.preventDefault();
    this.setState({ result: "ConsumidorFinal" });
  };

  pushEnviar = e => {
    e.preventDefault();
    const err = this.validate();
    if (!err) {
      this.setState({ result: "enviar" });
      document.getElementById("namereceipt").disabled = true;
      document.getElementById("porcentaje").disabled = true;
    }
  };

  componentDidMount() {
    this.props.getVets();
    this.nitInput.focus();
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  handleChangeOptionDoctor = e => {
    try {
      this.setState({
        selectOptionDoctor: e.value
      });
    } catch (error) {
      this.setState({ selectOptionDoctor: "" });
    }
  };

  render() {
    const result = this.state.result;
    const nombre = this.state.nitInput;
    const doc = this.state.selectOptionDoctor;
    const porce = this.state.porcentaje;
    const doctores = this.props.vets.map(doc => ({
      value: doc.id,
      label: doc.first_name
    }));
    function Verify() {
      if (nombre === "" && result === "enviar") {
        const consumidor = "C/F";
        return (
          <SaleofProducts
            nameCustomer={consumidor}
            tipo={"Farmacia"}
            doctor={doc}
            porcentaje={porce}
          />
        );
      } else if (nombre !== "" && result === "enviar") {
        return (
          <SaleofProducts
            nameCustomer={nombre}
            tipo={"Farmacia"}
            doctor={doc}
            porcentaje={porce}
          />
        );
      } else if (result === "NuevaVenta") {
        return <Redirect to="/sales" result={"NuevaVenta"} />;
      }
      return "";
    }
    return (
      <React.Fragment>
        <div className="card card-body mt-4 mb-4">
          <form onSubmit={this.onSubmit} noValidate>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-8 col-sm-12 text-center">
                <div className="form-group">
                  <label>Nombre de Recibo</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Consumidor final"
                    name="nitInput"
                    id="namereceipt"
                    onChange={this.onChange}
                    ref={input => {
                      this.nitInput = input;
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-8 col-sm-12 text-center">
                <div className="form-group">
                  <label>selecciona al doctor</label>
                  <Select
                    defaultValue={{
                      label: "Selecciona doctor",
                      value: 0
                    }}
                    onChange={this.handleChangeOptionDoctor}
                    options={doctores}
                    isSearchable={false}
                    id="seleccionComision"
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <FormHelperText error={true}>
                {this.state.doctorerror}
              </FormHelperText>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-8 col-sm-12 text-center">
                <div className="form-group">
                  <label>Porcentaje de comision por producto</label>
                  <Input
                    className="form-control"
                    type="Number"
                    placeholder="porcentaje de comision por producto"
                    name="porcentaje"
                    id="porcentaje"
                    onChange={this.onChange}
                    defaultValue={8}
                    startAdornment={
                      <InputAdornment position="start">%</InputAdornment>
                    }
                    error={
                      this.state.porcentajeError.length === 0 ? false : true
                    }
                  />
                  <FormHelperText error={true}>
                    {this.state.porcentajeError}
                  </FormHelperText>
                </div>
              </div>
            </div>
            <div className="row justify-content-center pt-2">
              <div className="col-lg-6 col-md-4 col-sm-12 text-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  onClick={this.pushEnviar}
                >
                  Enviar
                </button>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <button
                  type="button"
                  className="btn btn-danger btn-block"
                  onClick={this.pushNewSale}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <Verify />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  vets: state.RedVets.vets,
  loading: state.RedVets.loading
});

export default connect(
  mapStateToProps,
  { getVets }
)(NewSalePharmacy);
