import $ from "jquery";

$(document).ready(function() {
  $("#hide").click(function() {
    if (this.innerHTML === "Ocultar calendario") {
      $("#CalendarDiv").hide(1000);
      $("#hide").html("Mostrar calendario");
    } else {
      $("#CalendarDiv").show(1000);
      $("#hide").html("Ocultar calendario");
    }
  });
});
