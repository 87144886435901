import React, { Component } from "react";
import NuevaComision from "../../assets/NuevaComision.svg";
import Select from "react-select";
import ReporteComsion from "../../assets/reporteComision.svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NewCommission from "./NewCommission/NewCommission";
import CommissionToday from "./AllCommissions/CommissionToday";
import NewSurgeryCommission from "./NewCommission/NewSurgeryCommission";
import NewCommissionOther from "./NewCommission/NewCommissionOther";
import CommissionRank from "./AllCommissions/CommissionRank";

export class CommissionDoctor extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  state = {
    
    selectOption: "",
    opciones: [
      { label: "HOY", value: "Hoy" },
      { label: "MES ACTUAL", value: "Mes" },
      { label: "RANGO", value: "Rango" }
    ],
    tipo: "",
    opcionesComision: [
      { label: "Basico", value: "Basico" },
      { label: "Cirugia", value: "Cirugia" },
      { label: "Otros", value: "Otros" }
    ],
    result: "",
    isSearchable: false
  };

  handleChangeOption = e => {
    try {
      this.setState({
        selectOption: e.value,
        result: e.value
      });
    } catch (error) {
      this.setState({ tipo: "" });
    }
  };

  handleChangeOptionCommission = e => {
    try {
      this.setState({
        tipo: e.value,
        result: e.value
      });
    } catch (error) {
      this.setState({ tipo: "" });
    }
  };

  render() {
    const isSearchable = this.state.isSearchable;
    const result = this.state.result;
    const doctor = this.props.auth.user.id;
    const nombreDoc = this.props.auth.user.first_name
    
    function Verify() {
      if (result === "Basico") {
        document.getElementById("detalle").scrollIntoView();
        return <NewCommission tipo={"Basico"} doctor={doctor}/>;
      } else if (result === "Cirugia") {
        document.getElementById("detalle").scrollIntoView();
        return <NewSurgeryCommission tipo={"Cirugia"} doctor={doctor} />;
      } else if (result === "Otros") {
        document.getElementById("detalle").scrollIntoView();
        return <NewCommissionOther tipo={"Otros"} doctor={doctor} />;
      } else if (result === "Hoy") {
        document.getElementById("detalle").scrollIntoView();
        return <CommissionToday fecha={"hoy"} doctor={doctor} namedoc={nombreDoc}  />;
      } else if (result === "Mes") {
        document.getElementById("detalle").scrollIntoView();
        return <CommissionToday fecha={"mes"} doctor={doctor} namedoc={nombreDoc} />;
      } else if (result==="Rango"){
        document.getElementById("detalle").scrollIntoView();
        return <CommissionRank doctor={doctor} namedoc={nombreDoc} />
      }
      return "";
    }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className="row"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "rgba(30, 132, 73,0.6)",
                  color: "black",
                  height: "8rem"
                }}
              >
               <p style={{ alignSelf: "center" }}>Comisiones</p>
              </div>

              <div
                className="row pl-3 pr-3"
                style={{
                  height: "100vh"
                }}
              >
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div
                    className="row pt-5"
                    style={{
                      flexDirection: "column",
                      justifyContent: "center"
                    }}
                  >
                    <div className="card  border-success">
                      <img
                        src={NuevaComision}
                        className="card-img-top"
                        width="100"
                        height="100"
                        alt="programar"
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          Agrega tu nueva comision
                        </h5>

                        <div className="row pt-4">
                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <Select
                              defaultValue={{
                                label: "Selecciona",
                                value: 0
                              }}
                              onChange={this.handleChangeOptionCommission}
                              options={this.state.opcionesComision}
                              isSearchable={isSearchable}
                              id="seleccionComision"
                            />
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-12 col-sm-12 col-lg-12"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row pt-5"
                    style={{
                      flexDirection: "column",
                      justifyContent: "center"
                    }}
                  >
                    <div className="card  border-success">
                      <img
                        src={ReporteComsion}
                        className="card-img-top"
                        width="100"
                        height="100"
                        alt="analisis"
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          Ver mis Comisiones
                        </h5>

                        <div className="row pt-4">
                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <Select
                              defaultValue={{
                                label: "Selecciona",
                                value: 0
                              }}
                              onChange={this.handleChangeOption}
                              options={this.state.opciones}
                              isSearchable={isSearchable}
                              id="seleccion"
                            />
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-12 col-sm-12 col-lg-12"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-8 col-lg-8 col-md-12 col-sm-12 h-100"
                  id="detalle"
                >
                  <div className="row justify-content-center  h-100 pt-5">
                    <div className="col text-center">
                      <Verify />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.RedAuth
});

export default connect(mapStateToProps)(CommissionDoctor);
