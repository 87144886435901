import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import dogConnect from "../../assets/icons/dogConnect.svg";
import { CheckBoxList } from "../common/formComponents";
import { whatWeFound, whatWeRecomend } from "../../config/config";
import { updateAppointment } from "../../actions/ActApointments";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createMessage } from "../../actions/ActMessages";
import Loading from "../common/Loading";
import {
  addGroomFile,
  updatePictures,
  getGroomFile,
  finishGroomFile,
} from "../../actions/ActGroomfile";
class AttendingGroomAppointment extends Component {
  state = {
    before: null,
    finished: false,
    after: null,
    nudos: null,
    pielirritada: null,
    heridas: null,
    raspones: null,
    pelomaltratado: null,
    pielypelosucio: null,
    pelohumedo: null,
    unaslargas: null,
    unasencarnadas: null,
    pulgas: null,
    garrapatas: null,
    sarrodental: null,
    acaros: null,
    dientesflojos: null,
    ptialistmo: null,
    hematomaad: null,
    hematomaai: null,
    otitis: null,
    conjuntivitis: null,
    odi: null,
    oii: null,
    ode: null,
    oie: null,
    odh: null,
    oih: null,
    pols: null,
    ehc: null,
    scmv: null,
    cfpcend: null,
    apga: null,
    ld: null,
    cfuepieu: null,
    dmjepd: null,
    dtco: null,
    dtceo: null,
    laole: null,
    dtchaoc: null,
    appointmentId: null,
    observations: "",
  };

  handleImageChange = (e) => {
    /*this.setState({ picture: e.target.files[0] });*/
    const width = 250;
    /*const width = 600;
     
     elem.width = width;
     
      */
    //const height = 120;
    const fileName = e.target.files[0].name;
    const reader = new FileReader();
    const img = new Image();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event) => {
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement("canvas");
        const scaleFactor = width / img.width;
        elem.width = width;
        //elem.height = height;
        elem.height = img.height * scaleFactor;
        const ctx = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        //ctx.drawImage(img, 0, 0, width, height);
        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
        ctx.canvas.toBlob(
          (blob) => {
            const file = new File([blob], fileName, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            this.setState({ before: file });
          },
          "image/jpeg",
          1
        );
      };
      reader.onerror = (error) => console.log(error);
    };
  };

  static propTypes = {
    updateAppointment: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    addGroomFile: PropTypes.func.isRequired,
    updatePictures: PropTypes.func.isRequired,
    finishGroomFile: PropTypes.func.isRequired,
    getGroomFile: PropTypes.func.isRequired,
    file: PropTypes.object,
  };

  handleChecBoxChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.checked);
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  componentDidMount() {
    this.setState({
      appointmentId: this.props.location.appointmentToAttend.id,
    });
    this.props.getGroomFile(this.props.location.appointmentToAttend.id);
    var GroomFileInitializer = new FormData();
    GroomFileInitializer.append(
      "appointment",
      this.props.location.appointmentToAttend.id
    );
    this.props.addGroomFile(GroomFileInitializer);
  }

  handleSubmit() {
    this.setState({
      before: undefined,
      after: undefined,
      disabledButton: true,
    });
    let info = { ...this.state, status: "ATENDIDO" };
    var formInfo = new FormData();
    for (var key in info) {
      if (key === "after" || key === "before") {
        console.log("discarting pictures");
      } else {
        if (key === "observations") {
          formInfo.append(key, this.state.observations);
        } else if (info[key]) {
          formInfo.append(key, true);
        } else {
          formInfo.append(key, false);
        }
      }
    }

    this.props.finishGroomFile(formInfo, this.props.file.id);
    this.props.updateAppointment(
      { status: "ATENDIDO" },
      this.props.location.appointmentToAttend.id
    );
    this.setState({ finished: true });
  }

  onChangeText = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.state.finished) {
      // this.setState({ finished: false });
      return <Redirect to="/dashboard" />;
    }
    const {
      // aggressive,
      date,
      /*id: appointmentId,*/
      // nervous,
      notes,
      status,
      time,
    } = this.props.location.appointmentToAttend;
    const {
      first_name,
      username,
      id: clientId,
    } = this.props.location.appointmentToAttend.cliente;
    const {
      age,
      color,
      /*id: petId,*/
      name,
      race,
      reproductive_state,
      species,
    } = this.props.location.appointmentToAttend.pet;
    const { observations } = this.state.observations;
    return (
      <React.Fragment>
        {this.props.loading ? (
          <React.Fragment>
            {" "}
            <Loading></Loading>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        <div className="container-fluid">
          <div
            className="row"
            style={{
              height: "6rem",
              color: "black",
              backgroundColor: "#dfe6e9",
              marginLef: "0px",
              justifyContent: "center",
              alignItems: "center",
              padding: "2em",
              marginBottom: "2em",
            }}
          >
            <div className="col text-center" style={{ display: "box" }}>
              <h4>
                Ficha grooming: {date} {time}{" "}
              </h4>
              <div className="badge bg-danger">{status}</div>
              <img src={dogConnect} alt="" style={{ maxWidth: "5em" }} />
            </div>
          </div>

          <div
            className="row"
            style={{ marginTop: "10rem", marginBottom: "2rem" }}
          >
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="row justify-content-center">
                <div className="col text-center">
                  <span className="badge bg-info">Información cliente</span>
                  <h5>{first_name}</h5>
                  <h5>{username}</h5>
                  <h5>{clientId}</h5>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col text-center">
                  <span className="badge bg-info">
                    Información sobre esta cita
                  </span>
                  {/* {aggressive ? (
                    <p>Agresivo: Sí</p>
                  ) : (
                    <p>Agresivo: No especifica</p> */}
                  )}
                  {/* {nervous ? (
                    <p>Nervioso: Sí</p>
                  ) : (
                    <p>Nervioso: No especifica</p>
                  )} */}
                </div>
                <div className="col text-center">
                  <span className="badge bg-info">Más sobre {name}</span>
                  <p>
                    Soy {name} tengo {age} soy un {species} {race} de color{" "}
                    {color} estoy {reproductive_state}
                  </p>
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col text-center">
                  {notes ? (
                    <p>Comentarios: {notes}</p>
                  ) : (
                    <p>Comentarios: No especifica</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <div className="row justify-content-center pt-5">
                <div className="col text-center">
                  {this.props.file ? (
                    <img
                      src={this.props.file.before}
                      alt="Foto antes"
                      style={{ maxWidth: "20em" }}
                    />
                  ) : (
                    <img src="" alt="Foto antes" style={{ maxWidth: "20em" }} />
                  )}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-4">
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="beforeImage"
                        aria-describedby="inputGroupFileAddon01"
                        onChange={this.handleImageChange}
                        name="before"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="beforeImage"
                      >
                        <i className="fas fa-camera-retro"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center align-items-center">
                {this.disabledButton ? (
                  <button className="btn btn-secondary" disabled>
                    Subiendo la foto
                  </button>
                ) : (
                  <button
                    className="btn btn-info"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!this.state.before) {
                        this.props.createMessage(
                          "nullPicture",
                          "No has seleccionado ninguna imagen"
                        );
                        return "";
                      }
                      var before = new FormData();
                      before.append("before", this.state.before);
                      this.props.updatePictures(before, this.props.file.id);
                      this.setState({ disabledButton: false });
                    }}
                  >
                    Sube la foto
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "0.5rem" }}>
            <div
              className="col-12 text-center headerasdasdasdsa"
              style={{
                /*boxShadow: "0px 1px 10px 0px black",*/
                padding: "1rem",
                backgroundColor: "#57606f",
                marginBottom: "1rem",
                color: "white",
              }}
            >
              <h4>¡ Atendamos a {name} !</h4>
            </div>
            <div
              className="col"
              style={{
                height: "50vh",
                overflowY: "scroll",
              }}
            >
              <div className="row justify-content-center">
                <div className="col text-center">
                  <h4>Esto es lo que encontramos en {name}:</h4>{" "}
                </div>
              </div>
              <div className="row" style={{ flexFlow: "row wrap" }}>
                <div className="col">
                  <CheckBoxList
                    onChange={this.handleChecBoxChange}
                    data={whatWeFound}
                  ></CheckBoxList>
                </div>
              </div>
            </div>
            <div
              className="col"
              style={{
                height: "50vh",
                overflowY: "scroll",
              }}
            >
              <div className="row justify-content-center">
                <div className="col text-center">
                  <h4>Esto recomendamos:</h4>{" "}
                </div>
              </div>
              <div className="row" style={{ flexFlow: "row wrap" }}>
                <div className="col">
                  <CheckBoxList
                    onChange={this.handleChecBoxChange}
                    data={whatWeRecomend}
                  ></CheckBoxList>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row justify-content-center align-items-center"
            style={{
              flexDirection: "row",
              height: "5rem",
              backgroundColor: "#2f3542",
              color: "white",
              paddingRight: "8rem",
              paddingLeft: "8rem",
            }}
          >
            <label>Otras Observaciones</label>
            <textarea
              className="form-control"
              type="text"
              placeholder="observaciones"
              name="observations"
              value={observations}
              onChange={this.onChangeText}
            />
          </div>

          <div
            className="row pt-5 justify-content-center align-items-center"
            style={{
              flexDirection: "row",
              backgroundColor: "#2f3542",
              color: "white",
            }}
          >
            <div className="col-12 text-center">
              <div className="row justify-content-center">
                <div className="col text-center">
                  {this.props.file ? (
                    <img
                      src={this.props.file.after}
                      alt="Foto antes"
                      style={{ maxWidth: "20em" }}
                    />
                  ) : (
                    <img src="" alt="Foto antes" style={{ maxWidth: "20em" }} />
                  )}
                </div>
              </div>
              <div className="row justify-content-center pt-2">
                <div className="col-2">
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="afterImage"
                        aria-describedby="inputGroupFileAddon01"
                        onChange={this.handleImageChange}
                        name="after"
                      />
                      <label className="custom-file-label" htmlFor="afterImage">
                        <i className="fas fa-camera-retro"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                {this.state.disabledButton ? (
                  <button className="btn btn-secondary text-center" disabled>
                    Subiendo la foto...
                  </button>
                ) : (
                  <button
                    className="btn btn-warning text-center"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!this.state.before) {
                        this.props.createMessage(
                          "nullPicture",
                          "No has seleccionado ninguna imagen"
                        );
                        return "";
                      }
                      var after = new FormData();
                      after.append("after", this.state.before);
                      this.props.updatePictures(after, this.props.file.id);
                      this.setState({ disabledButton: false });
                    }}
                  >
                    Sube la foto
                  </button>
                )}
              </div>
            </div>
          </div>

          <div
            className="row justify-content-center align-items-center"
            style={{
              flexDirection: "row",
              height: "15rem",
              backgroundColor: "#2f3542",
              color: "white",
            }}
          >
            <div className="col-12 text-center">
              <h4>Completemos el servicio</h4>
              {this.state.disabledButton ? (
                <button
                  type="submit"
                  onSubmit={this.handleSubmit}
                  className="btn btn-secondary text-dark"
                >
                  ¡Ficha Archivada! Recarga la pagina
                </button>
              ) : (
                <button
                  type="submit"
                  onSubmit={this.handleSubmit}
                  className="btn btn-success text-dark"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleSubmit();
                  }}
                >
                  ¡Terminar servicio!
                </button>
              )}
              <br />
              <small>
                Una vez enviado el informe, no podra ser modificado de ninguna
                manera asi que verifica los datos antes de enviarlos.
              </small>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  file: state.History.file,
  loading: state.History.loading,
});

export default connect(mapStateToProps, {
  updateAppointment,
  createMessage,
  addGroomFile,
  updatePictures,
  getGroomFile,
  finishGroomFile,
})(AttendingGroomAppointment);
