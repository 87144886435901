import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCategories } from "../../../actions/Categories";
import { getBrands } from "../../../actions/Brands";
import Select from "react-select";
//la advertencia significa que estoy usabdo una biblioteca que usa el ciclo de vida heredado
import { addproduct } from "../../../actions/Products";
import TextField from "@material-ui/core/TextField";
import { Redirect } from "react-router-dom";

export class ProductsForm extends Component {
  static propTypes = {
    brands: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    getCategories: PropTypes.func.isRequired,
    getBrands: PropTypes.func.isRequired,
    addproduct: PropTypes.func.isRequired,
    products: PropTypes.object.isRequired
  };

  state = {
    varcode: "",
    selectedCategory: "",
    selectedBrand: "",

    isClearable: true,
    isDisabled: false,
    isLoading: false,
    isSearchable: true,

    name: "",
    stock: "",
    purchase_price: "",
    sale_price: "",
    description: "",

    nameError: "",
    stockError: "",
    purchashe_price_Error: "",
    sale_price_Error: "",
    category_Error: "",
    brand_error: "",
    barcode_generate: "Propio",
    redirect: false
  };

  handleChangeCat = e => {
    try {
      this.setState({ selectedCategory: e.value });
    } catch (error) {
      this.setState({ selectedCategory: "" });
    }

    //console.log("option selected", e);
  };

  handleChangeBrand = e => {
    try {
      this.setState({ selectedBrand: e.value });
    } catch (error) {
      this.setState({ selectedBrand: "" });
    }

    //console.log("option selected", e);
  };

  componentDidMount() {
    this.props.getCategories();
    this.props.getBrands();
    document.getElementById("barcode").disabled = true;
    this.setState({ varcode: this.props.varcode });
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  validate = () => {
    let iserror = false;

    const errors = {
      nameError: "",
      stockError: "",
      purchashe_price_Error: "",
      sale_price_Error: "",
      category_Error: "",
      brand_error: ""
    };

    if (this.state.name === "") {
      iserror = true;
      errors.nameError = "Debe de ingresar el numero del producto";
    } else if (this.state.stock === "" || this.state.stock < 0) {
      iserror = true;
      errors.stockError = "Ingrese una cantidad correcta de productos";
    } else if (
      this.state.purchase_price === "" ||
      this.state.purchase_price < 0
    ) {
      iserror = true;
      errors.purchashe_price_Error =
        "Ingrese una cantidad correcta de precio de compra";
    } else if (this.state.sale_price === "" || this.state.sale_price < 0) {
      iserror = true;
      errors.sale_price_Error =
        "Ingrese una cantidad correcta de precio de venta";
    } else if (
      this.state.selectedCategory === "" ||
      this.state.selectedCategory === null
    ) {
      iserror = true;
      errors.category_Error = "Debe escoger una Categoria para el producto";
    } else if (
      this.state.selectedBrand === "" ||
      this.state.selectedBrand === null
    ) {
      iserror = true;
      errors.brand_error = "Debe de escoger una Marca para el producto";
    }

    this.setState({
      ...errors
    });
    return iserror;
  };

  onSubmit = e => {
    e.preventDefault(); //descarta comportamientos predeterminados del evento onSubmit
    //this.setState({barcode_generate:"Propio"});
    const err = this.validate();
    if (!err) {
      const {
        name,
        stock,
        purchase_price,
        sale_price,
        description,
        varcode,
        selectedCategory,
        selectedBrand,
        barcode_generate
      } = this.state;

      //console.log(selectedBrand + "value marca");
      //console.log(selectedCategory + "value categoria");
      const product = {
        name,
        purchase_price,
        sale_price,
        description,
        stock,
        varcode,
        selectedCategory,
        selectedBrand,
        barcode_generate
      };
      //console.log(product);
      this.props.addproduct(product);

      this.setState({
        name: "",
        purchase_price: "",
        sale_price: "",
        description: "",
        stock: "",
        varcode: "",
        selectedCategory: "",
        selectedBrand: ""
      });
      this.setState({ redirect: true });
    } //fin de comprobacion del error
  };

  render() {
    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      name,
      stock,
      purchase_price,
      sale_price,
      description
    } = this.state;

    if (this.state.redirect === true) {
      return <Redirect to="/products" result={"varcode"} />;
    }

    return (
      <React.Fragment>
        <div className="card card-body mt-4 mb-4">
          <form onSubmit={this.onSubmit} noValidate>
            <h4 className="text-center text-success">Nuevo Producto</h4>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Nombre de Producto</label>
                  <TextField
                    className="form-control"
                    type="text"
                    placeholder="Nombre de Producto"
                    name="name"
                    value={name}
                    onChange={this.onChange}
                    helperText={this.state.nameError}
                    error={this.state.nameError.length === 0 ? false : true}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Cantidad en Stock</label>
                  <TextField
                    className="form-control"
                    type="Number"
                    placeholder="cantidad de producto"
                    name="stock"
                    value={stock}
                    onChange={this.onChange}
                    helperText={this.state.stockError}
                    error={this.state.stockError.length === 0 ? false : true}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Precio de Compra</label>
                  <TextField
                    className="form-control"
                    type="Number"
                    placeholder="Precio de Compra"
                    name="purchase_price"
                    onChange={this.onChange}
                    value={purchase_price}
                    helperText={this.state.purchashe_price_Error}
                    error={
                      this.state.purchashe_price_Error.length === 0
                        ? false
                        : true
                    }
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Precio de Venta</label>
                  <TextField
                    className="form-control"
                    type="Number"
                    placeholder="Precio de Venta"
                    name="sale_price"
                    onChange={this.onChange}
                    value={sale_price}
                    helperText={this.state.sale_price_Error}
                    error={
                      this.state.sale_price_Error.length === 0 ? false : true
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label>Codigo de Barras</label>
                  <input
                    className="form-control text-light bg-dark"
                    type="text"
                    id="barcode"
                    value={this.props.varcode}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Categoria</label>

                  <Select
                    defaultValue={{
                      label: "Seleccciona la Categoria",
                      value: 0
                    }}
                    onChange={this.handleChangeCat}
                    options={this.props.categories.map(category => ({
                      value: category.id,
                      label: category.name
                    }))}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    name="category"
                  />
                  <small className="alert-danger">
                    {this.state.category_Error}
                  </small>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Marca</label>
                  <Select
                    defaultValue={{ label: "Seleccciona la Marca", value: 0 }}
                    onChange={this.handleChangeBrand}
                    options={this.props.brands.map(brand => ({
                      value: brand.id,
                      label: brand.name
                    }))}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    name="brand"
                  />
                  <small className="alert-danger">
                    {this.state.brand_error}
                  </small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label>Descripcion</label>
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="Descripcion"
                    name="description"
                    value={description}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Agregar Producto
            </button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  categories: state.Categories.categories,
  brands: state.Brands.brands,
  products: state.Products
});

export default connect(mapStateToProps, {
  getCategories,
  getBrands,
  addproduct
})(ProductsForm);
