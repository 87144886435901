import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NewSale from "./NewSales/NewSale";
import ShowAllSales from "./AllSales/ShowAllSales";
import NewSalePharmacy from "./NewSales/NewSalePharmacy";
import { finishUpdate } from "../../actions/Products";

export class Sales extends Component {
  static propTypes = {
    finishUpdate: PropTypes.func
  };

  state = {
    result: "",
    newSale: "",
    Allsales: ""
  };

  pushNewSale = e => {
    e.preventDefault();
    this.setState({ result: "NuevaVenta" });
  };

  pushAllSales = e => {
    e.preventDefault();
    this.setState({ result: "AllSales" });
  };

  pushNewPharmacy = e => {
    e.preventDefault();
    this.setState({ result: "Pharmacy" });
  };

  componentDidMount() {
    this.props.finishUpdate();
    this.setState({ result: "NuevaVenta" });
  }
  render() {
    const result = this.state.result;
    function Verify() {
      if (result === "NuevaVenta") {
        return <NewSale />;
      } else if (result === "AllSales") {
        return <ShowAllSales />;
      } else if (result === "Pharmacy") {
        return <NewSalePharmacy />;
      }
      return "";
    }
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div
            className="row"
            style={{
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              color: "black",
              height: "8rem"
            }}
          >
            <p style={{ alignSelf: "center" }}>Administración de ventas</p>
          </div>
          <div className="row pt-5">
            <div className="col-md-12 col-sm-12 col-lg-12 text-center">
              <div className="card text-center">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <button className="nav-link" onClick={this.pushNewSale}>
                        Nueva Venta
                      </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link" onClick={this.pushAllSales}>
                        Todas las Ventas
                      </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link" onClick={this.pushNewPharmacy}>
                        Venta de Farmacia
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card-body text-center"></div>
                <Verify />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { finishUpdate }
)(Sales);
