import React, { Component } from 'react';
import './GroomingDetails.css'; // Import CSS file for styling

class GroomingDetails extends Component {
    render() {
        const {
            id,
            before,
            after,
            userSign,
            nudos,
            pielirritada,
            heridas,
            raspones,
            pelomaltratado,
            pielypelosucio,
            pelohumedo,
            unaslargas,
            unasencarnadas,
            pulgas,
            garrapatas,
            sarrodental,
            acaros,
            dientesflojos,
            ptialistmo,
            hematomaad,
            hematomaai,
            otitis,
            conjuntivitis,
            odi,
            oii,
            ode,
            oie,
            odh,
            oih,
            pols,
            ehc,
            scmv,
            cfpcend,
            apga,
            ld,
            cfuepieu,
            dmjepd,
            dtco,
            dtceo,
            laole,
            dtchaoc,
            date,
            observations,
            appointment
        } = this.props.grooming;

        return (
            <div className="grooming-details">
                <h2>Grooming Details</h2>
                <div className="image-container row">
                    <div className='col-6'>
                        <img src={before} alt="Before Grooming" className="grooming-image" />
                        <small className='text-muted'>Si tienes problemas visualizando la imagen <a href={`${before}`}>haz click aquí.</a></small>
                    </div>
                    <div className='col-6'>
                        <img src={after} alt="After Grooming" className="grooming-image" />
                        <small className='text-muted'>Si tienes problemas visualizando la imagen <a href={`${after}`}>haz click aquí.</a></small>
                    </div>
                </div>
                <div className="detail">
                    <span className="label">ID:</span>
                    <span className="value">{id}</span>
                </div>
                <div className="detail">
                    <span className="label">Date:</span>
                    <span className="value">{date}</span>
                </div>
                <div className="detail">
                    <span className="label">Durante el servicios notamos lo siguiente: </span>
                    <ol>
                        {nudos && <li>Nudos</li>}
                        {pielirritada && <li>Piel irritada</li>}
                        {heridas && <li>Heridas</li>}
                        {raspones && <li>Raspones</li>}
                        {pelomaltratado && <li>Pelo maltratado</li>}
                        {pielypelosucio && <li>Piel y pelo sucio</li>}
                        {pelohumedo && <li>Pelo humedo</li>}
                        {unaslargas && <li>Uñas largas</li>}
                        {unasencarnadas && <li>Uñas encarnadas</li>}
                        {pulgas && <li>Pulgas</li>}
                        {garrapatas && <li>Garrapatas</li>}
                        {sarrodental && <li>Sarro dental</li>}
                        {acaros && <li>Ácaros</li>}
                        {dientesflojos && <li>Dientes flojos</li>}
                        {ptialistmo && <li>Ptialismo</li>}
                        {hematomaad && <li>Hematoma en el oido derecho</li>}
                        {hematomaai && <li>Hematoma en el oido izquierdo</li>}
                        {otitis && <li>Otitis</li>}
                        {conjuntivitis && <li>Conjuntivitis</li>}
                    </ol>
                    <span className="value">
                        <strong>Firma: </strong>
                        <img src={`${userSign}`} alt="User Signature" className="signature" />
                        <small className='text-muted'>Si tienes problemas visualizando la imagen <a href={`${userSign}`}>haz click aquí.</a></small>
                    </span>
                </div>
                <div className="detail">
                    <span className="label">Observations:</span>
                    <span className="value">{observations}</span>
                </div>
            </div>
        );
    }
}

export default GroomingDetails;
