import React, { Component } from "react";
import ShowAllRefund from "./ShowAllRefund";
export class ShowRefundAdmin extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className="row"
                style={{
                  backgroundColor: "rgba(93, 173, 226)",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "7rem"
                }}
              >
                <div className="col text-center">
                  <h4>Todas las Devoluciones</h4>
                </div>
              </div>
              <div
                className="row pt-5"
                style={{
                  height: "100vh"
                }}
              >
                <div className="col-12">
                  <ShowAllRefund />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ShowRefundAdmin;
