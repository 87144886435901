import {
  HISTORY_FILE_LOADING,
  HISTORY_FILE_LOADED,
  HISTORY_FILE_FINISH,
  HISTORY_PICTURE_UPLOADING,
  HISTORY_PICTURE_UPLOADED
} from "../actions/types.js";

const initialState = {
  responseStatus: null,
  loading: false,
  file: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case HISTORY_PICTURE_UPLOADING:
    case HISTORY_FILE_LOADING:
      return {
        ...state,
        loading: true,
        file: null
      };
    case HISTORY_PICTURE_UPLOADED:
    case HISTORY_FILE_LOADED:
      console.log(action);
      return {
        ...state,
        loading: false,
        file: action.payload
      };

    case HISTORY_FILE_FINISH:
      return {
        ...state,
        loading: false,
        file: null
      };
    default:
      return {
        ...state
      };
  }
}
