import React, { Component } from "react";
import DefaultDog from "../../assets/icons/defaultdog.svg";
class AfterClinicAppointment extends Component {
  render() {
    const { ad, diagnostico, tratamiento, observaciones } = this.props.state;
    return (
      <React.Fragment>
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                Ayuda Diagnóstica
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={DefaultDog} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="ad"
                    value={ad || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {ad}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                Diagnóstico presuntivo
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={DefaultDog} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="diagnostico"
                    value={diagnostico || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {diagnostico}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                Tratamiento
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={DefaultDog} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="tratamiento"
                    value={tratamiento || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {tratamiento}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-center text-dark"
              >
                Observaciones
              </label>
              <div className="row" style={{ flexFlow: "row no-wrap" }}>
                <div className="col-2">
                  <img src={DefaultDog} alt="" style={{ maxWidth: "5rem" }} />
                </div>
                <div className="col-10" style={{ border: "2px solid black" }}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="observaciones"
                    value={observaciones || ""}
                    rows="3"
                    onChange={this.props.onChange}
                  >
                    {observaciones}
                  </textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default AfterClinicAppointment;
