import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import { __BASE__URL } from "./types";
import { tokenConfig } from "./ActAuths";
import {
  HISTORY_FILE_LOADING,
  HISTORY_FILE_LOADED,
  HISTORY_FILE_FINISH
} from "./types";

export const addClinicFile = body => (dispatch, getState) => {
  const url = __BASE__URL + "/api/clinic/appointment/detail/";
  dispatch({ type: HISTORY_FILE_LOADING });
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      dispatch(
        createMessage({
          GroomFileCreated:
            "Se ha registrado una ficha clinica nueva y reluciente"
        })
      );
      dispatch({ type: HISTORY_FILE_LOADED, payload: res.data });
    })
    .catch(err => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};

export const getClinicFile = id => async (dispatch, getState) => {
  const url = `${__BASE__URL}/api/clinic/appointment/detail/${id}`;
  dispatch({ type: HISTORY_FILE_LOADING });
  await axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({ type: HISTORY_FILE_LOADED, payload: res.data });
    })
    .catch(err => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};

export const finishClinicFile = (info, id) => async (dispatch, getState) => {
  const body = JSON.stringify({
    ...info
  });
  console.log(body);
  const url = `${__BASE__URL}/api/clinic/appointment/after/${id}/`;
  dispatch({ type: HISTORY_FILE_LOADING });
  await axios
    .patch(url, body, tokenConfig(getState))
    .then(res => {
      dispatch(createMessage({ GroomFileCreated: "¡Cita terminada!" }));
      /*dispatch({ type: HISTORY_FILE_LOADED, payload: res.data });*/
      dispatch({ type: HISTORY_FILE_FINISH });
    })
    .catch(err => {
      dispatch(returnErrors(err?.response?.data, err?.response?.data));
    });
};

export const cleanFile = () => dispatch => {
  dispatch({ type: HISTORY_FILE_FINISH });
};
