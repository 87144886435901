import {
  APOINTMENTS_LOADING,
  APOINTMENTS_LOADED,
  ADD_APOINTMENTERROR,
  ADD_APOINTMENT,
  DAYAPOINTMENTS_LOADING,
  NONGROOMAPPOINTMENTS_LOADED,
  GROOMAPPOINTMENTS_LOADED,
  DAYAPOINTMENTS_LOADED,
  CLEAN_APPOINTMENTS,
  DAYSCHEDULE_LOADING,
  DAYSCHEDULE_LOADED,
  CLINIC_COUNT,
  GROOM_COUNT,
  UPDATE_APPOINTMENT,
  LOADING_AVAILABLE_DAYS,
  AVAILABLE_DAYS_LOADED,
} from "../actions/types";

const initialState = {
  loading: false,
  apointments: [],
  nonGroomappointments: [],
  groomAppointments: [],
  schedule: null,
  availabledays: null,
  achive: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAN_APPOINTMENTS:
      return {
        ...state,
        apointments: [],
      };
    case UPDATE_APPOINTMENT:
      return {
        ...state,
        loading: false,
      };

    case CLINIC_COUNT:
      return {
        ...state,
        clinicCount: action.payload,
      };

    case GROOM_COUNT:
      return {
        ...state,
        groomCount: action.payload,
      };
    case LOADING_AVAILABLE_DAYS:
    case DAYAPOINTMENTS_LOADING:
    case APOINTMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DAYAPOINTMENTS_LOADED:
    case APOINTMENTS_LOADED:
      return {
        ...state,
        apointments: action.payload,
        loading: false,
      };

    case NONGROOMAPPOINTMENTS_LOADED:
      return {
        ...state,
        nongroomappointments: action.payload,
        loading: false,
      };
    case GROOMAPPOINTMENTS_LOADED:
      return {
        ...state,
        groomappointments: action.payload,
        loading: false,
      };

    case ADD_APOINTMENT:
      return {
        ...state,
        achive: true,
      };
    case ADD_APOINTMENTERROR:
      return {
        ...state,
        achive: false,
      };

    case DAYSCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DAYSCHEDULE_LOADED:
      return {
        ...state,
        loading: false,
        schedule: action.payload.apointments,
      };

    case AVAILABLE_DAYS_LOADED:
      return {
        ...state,
        loading: false,
        availabledays: action.payload,
      };

    default:
      return state;
  }
}
