import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import RefundofProdduct from "./RefundofProdduct";
export class NewRefund extends Component {
  state = {
    nitInput: "",
    result: "",
    newSale: "",
    finalConsumer: "",
    client: ""
  };

  pushNewRefund = e => {
    e.preventDefault();
    this.setState({ result: "NuevaDevolucion" });
  };

  pushFinalConsumer = e => {
    e.preventDefault();
    this.setState({ result: "ConsumidorFinal" });
  };

  pushEnviar = e => {
    e.preventDefault();
    this.setState({ result: "enviar" });
    document.getElementById("namereceipt").disabled = true;
  };
  componentDidMount() {
    this.nitInput.focus();
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const result = this.state.result;
    const nombre = this.state.nitInput;
    function Verify() {
      if (nombre === "" && result === "enviar") {
        const consumidor = "C/F";
        return <RefundofProdduct nameCustomer={consumidor}/>;
      } else if (nombre !== "" && result === "enviar") {
        return <RefundofProdduct nameCustomer={nombre}/>;
      } else if (result === "NuevaDevolucion") {
        return <Redirect to="/refunds" result={"NuevaDevolucion"} />;
      }
      return "";
    }
    return (
      <React.Fragment>
        <div className="card card-body mt-4 mb-4">
          <form onSubmit={this.onSubmit} noValidate>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-8 col-sm-12 text-center">
                <div className="form-group">
                  <label>Nombre de Cliente</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Consumidor final"
                    name="nitInput"
                    id="namereceipt"
                    onChange={this.onChange}
                    ref={input => {
                      this.nitInput = input;
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-4 col-sm-12 text-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  onClick={this.pushEnviar}
                >
                  Enviar
                </button>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <button
                  type="button"
                  className="btn btn-danger btn-block"
                  onClick={this.pushNewRefund}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <Verify />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewRefund;
