import React, { Component, Fragment } from "react";

import { CheckBoxList } from "../common/formComponents";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Loading from "../common/Loading";
import "../common/scripts/Scripts";
import dogConnect from "../../assets/icons/dogConnect.svg";
import ScheduleIcon from "../../assets/icons/schedule.svg";
import { createMessage } from "../../actions/ActMessages";
import {
  getApointments,
  getDayApointments,
  getAvailableDays,
  updateAvailableDays,
} from "../../actions/ActApointments";
/*import { getApointments } from "../../actions/ActApointments";*/
import Profileinfo from "../Forms/ProfileInfo";
import DateDetailList from "../common/DateDetail";
import Calendar from "react-calendar";
import "./styles/Vet_Dashboard.css";

const daysToCheckBoxes = [
  {
    /*Nudos: false,*/
    name: "lunes",
    label: "Lunes",
  },
  {
    /*PielIrritada: false,*/
    name: "martes",
    label: "Martes",
  },
  {
    /*Heridas: false,*/
    name: "miercoles",
    label: "Miercoles",
  },
  {
    /*Heridas: false,*/
    name: "jueves",
    label: "Jueves",
  },
  {
    /*Heridas: false,*/
    name: "viernes",
    label: "Viernes",
  },
  {
    /*Heridas: false,*/
    name: "sabado",
    label: "Sabado",
  },
  {
    /*Heridas: false,*/
    name: "domingo",
    label: "Domingo",
  },
];
class Groom_Dashboard extends Component {
  state = {
    show: false,
    rol: null,
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false,
  };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    getDayApointments: PropTypes.func.isRequired,
    getAvailableDays: PropTypes.func.isRequired,
    updateAvailableDays: PropTypes.func.isRequired,
    availabledays: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    apointments: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
  };
  handleChecBoxChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
    console.log("hika");
  };

  async componentDidMount() {
    try {
      let date = new Date();
      let inDate =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
      this.setState({ inDate });
      await this.props.getDayApointments(inDate, this.props.user.id);
      // await this.props.getAvailableDays(this.props.user.id);
    } catch (error) {
      console.log(error);
    }
    this.setState({
      rol:
        this.props.user.last_name === "veterinario"
          ? "veterinario"
          : "groomista",
    });
    // this.setState({
    //   context: {
    //     lunes: this.props.availabledays.lunes,
    //     martes: this.props.availabledays.martes,
    //     miercoles: this.props.availabledays.miercoles,
    //     jueves: this.props.availabledays.jueves,
    //     viernes: this.props.availabledays.viernes,
    //     sabado: this.props.availabledays.sabado,
    //     domingo: this.props.availabledays.domingo,
    //   },
    // });
  }

  handeCalendarChange = (date) => {
    let inDate =
      // date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      date.getMonth() + 1 < 10 && date.getDate() < 10
        ? date.getFullYear() +
          "-0" +
          (date.getMonth() + 1) +
          "-0" +
          date.getDate()
        : date.getMonth() + 1 < 10 && date.getDate() === 10
        ? date.getFullYear() +
          "-0" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate()
        : date.getMonth() + 1 > 10 && date.getDate() < 10
        ? date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-0" +
          date.getDate()
        : date.getMonth() + 1 < 10 && date.getDate() > 10
        ? date.getFullYear() +
          "-0" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate()
        : date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
    this.setState({ inDate });
    if (this.props.user.id) {
      this.props.getDayApointments(inDate, this.props.user.id);
      this.props.createMessage({
        apointmentsOf: "Mostrando citas del dia" + inDate,
      });
    }
    this.setState({ show: false });
  };

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login" />;
    } else if (
      this.state.rol !== "veterinario" &&
      this.state.rol !== "groomista"
    ) {
      return <Loading></Loading>;
    } else if (this.props.loading) {
      return <Loading></Loading>;
    }
    return (
      <Fragment>
        {this.state.show ? (
          <Modal show={this.state.show} handleClose={this.hideModal}>
            <Calendar
              onChange={this.handeCalendarChange}
              minDate={new Date()}
              /*
              tileDisabled={({ date }) =>
                date.getDay() === 0 || date.getDay() === 6
              }*/
            ></Calendar>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <div className="container-fluid">
          <div
            className="row"
            style={{
              /*height: "6rem",*/
              color: "black",
              backgroundColor: "#dfe6e9",
              marginLef: "0px",
              justifyContent: "center",
              alignItems: "center",
              padding: "2em",
              marginBottom: "2em",
            }}
          >
            <div className="col text-center">
              <p>Panel de control</p>
              <img src={dogConnect} alt="" style={{ maxWidth: "3rem" }} />
            </div>
          </div>
          <div className="row h-100 h-100 align-items-center">
            <div
              className="col-lg-5 col-md-4 col-sm-12 d-flex"
              style={{
                alignSelf: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Calendar
                  onChange={this.handeCalendarChange}
                  minDate={new Date()}
                  /*
              tileDisabled={({ date }) =>
                date.getDay() === 0 || date.getDay() === 6
              }*/
                ></Calendar>
                <Profileinfo />
              </div>
            </div>
            <div className="col-lg-7 col-md-8 col-sm-12">
              <div className="row">
                <div className="col text-center">
                  <h5 className="text-muted">
                    ¡Hola! <strong>{this.state.inDate}</strong> tienes{" "}
                    <strong>{this.props.apointments.length}</strong> citas
                  </h5>
                </div>
              </div>
              {/* <div className="row text-center">
                <div className="col mx-auto">
                  <button className="btn btn-primary" onClick={this.showModal}>
                    <i className="far fa-calendar-alt"></i> Seleccionar fecha
                  </button>
                </div>
              </div> */}
              <div
                className="row"
                style={{ flexWrap: "nowrap", overflowX: "scroll" }}
              >
                {this.props.loading ? (
                  <Loading></Loading>
                ) : (
                  <React.Fragment>
                    <DateDetailList
                      apointments={this.props.apointments}
                      actualDate={this.state.inDate}
                    ></DateDetailList>
                  </React.Fragment>
                )}
              </div>
              <div className="row align-content-center justify-content-center">
                <div className="col-4 text-center">
                  <h5 className="text-center self-align-center text-muted">
                    Dias de trabajo
                  </h5>
                </div>
              </div>
              <div className="row align-content-center justify-content-center">
                <div
                  className="col-8 text-center"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {this.state.context ? (
                    <CheckBoxList
                      onChange={this.handleChecBoxChange}
                      data={daysToCheckBoxes}
                      context={this.state.context}
                    ></CheckBoxList>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="calendar-modal clients-frame">
        <img src={ScheduleIcon} alt="" />
        <span class="circle" onClick={handleClose}></span>
        {children}
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.RedAuth.isAuthenticated,
  apointments: state.RedApoint.apointments,
  loading: state.RedApoint.loading,
  availabledays: state.RedApoint.availabledays,
  user: state.RedAuth.user,
});
export default connect(mapStateToProps, {
  getApointments,
  getDayApointments,
  createMessage,
  getAvailableDays,
  updateAvailableDays,
})(Groom_Dashboard);
