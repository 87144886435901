import axios from "axios";
import { returnErrors } from "./ActMessages";
import {
  VETS_LOADING,
  VETS_LOADED,
  VETS_APOINTMENT_LOADING,
  VETS_APOINTMENT_LOADED,
  DAYAPOINTMENTS_LOADING,
  DAYAPOINTMENTS_LOADED,
  __BASE__URL
} from "./types";
import { tokenConfig } from "./ActAuths";
/*import { VetAvatarList } from "../components/common/VetAvatar";*/

export const getVets = () => (dispatch, getState) => {
  dispatch({ type: VETS_LOADING });
  try {
    const url = __BASE__URL + "/api/vets";
    axios
      .get(url, tokenConfig(getState))
      .then(res => {
        dispatch({ type: VETS_LOADED, payload: res.data });
      })
      .catch(error =>
        dispatch(returnErrors(error.response.data, error.response.status))
      );
  } catch (error) {
    console.log(error);
  }
};

export const getApointments = () => (dispatch, getState) => {
  dispatch({ type: VETS_APOINTMENT_LOADING });
  try {
    const url = __BASE__URL + "/api/doctor/apointments";
    axios
      .get(url, tokenConfig(getState))
      .then(res => {
        dispatch({ type: VETS_APOINTMENT_LOADED, payload: res.data });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getDayApointments = (inDate, veterinary) => (
  dispatch,
  getState
) => {
  dispatch({ type: DAYAPOINTMENTS_LOADING });
  const body = JSON.stringify({ inDate, veterinary });
  const url = __BASE__URL + "/api/apointments/day";
  try {
    axios
      .post(url, body, tokenConfig(getState))
      .then(res => {
        dispatch({ type: DAYAPOINTMENTS_LOADED, payload: res.data });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};
