import React, { Component } from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import moment from "moment";
import {getAllRefundsHeader} from "../../../actions/Refunds"
import ShowDetail from "./ShowDetail";
export class ShowAllRefund extends Component {
    static propTypes = {
        refunds: PropTypes.array.isRequired,
        getAllRefundsHeader: PropTypes.func.isRequired
      };
      componentDidMount() {
        this.props.getAllRefundsHeader();
      }
      state = {
        columns: [
          { title: "#Devolucion", field: "id", editable: "never", type: "numeric" },
          { title: "Fecha", field: "date", editable: "never" },
          { title: "Nombre Cliente", field: "customer", editable: "never" },
          { title: "Efectivo", field: "totalcash", editable: "never" },
          { title: "Tarjeta", field: "totalcard", editable: "never" },
          { title: "Tipo de Pago", field: "typeofpay", editable: "never" },
          { title: "Total", field: "total", editable: "never", type: "numeric" }
        ]
      };
      
    render() {
        const datos = this.props.refunds.map(devolucion => ({
            id: devolucion.id,
            date: moment(devolucion.date).format("DD/MM/YYYY"),
            customer: devolucion.customer,
            totalcash: devolucion.totalcash,
            totalcard: devolucion.totalcard,
            typeofpay: devolucion.typeofpay,
            total: devolucion.total
          }));
        return (
            <React.Fragment>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <div className="pt-5">
          <MaterialTable
            title="DEVOLCIONES"
            columns={this.state.columns}
            data={datos}
            options={{
              headerStyle: {
                backgroundColor: "rgba(40,116,166,0.8)",
                color: "#FFF"
              },
              /*rowStyle: {
                backgroundColor: "#EEE"
              },*/
              exportButton: true,
              exportFileName: "Encabezados Recibos",
              exportAllData: true,
              sorting:true
              // actionsColumnIndex: -1,
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar"
              },
              body: {
                emptyDataSourceMessage:
                  "No se encontro ningun recibo relacionado."
              },
              header: {
                actions: "Acciones"
              }
            }}
            detailPanel={[
              {
                render: rowData => {
                  return (
                    <div className="row justify-content-center">
                      <div
                        className="col-lg-8 col-md-8 col-sm-8 "
                        style={{ alignSelf: "center" }}
                      >
                        <ShowDetail 
                        id_refund={rowData.id}
                        fecha={rowData.date}
                        nameCustomer={rowData.customer}
                        total={rowData.total}
                        totalcash={rowData.totalcash}
                        totalcard={rowData.totalcard}/>
                      </div>
                    </div>
                  );
                }
              }
            ]}
          />
        </div>
      </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    refunds : state.Refund.refunds
  });
export default connect(mapStateToProps,{getAllRefundsHeader})(ShowAllRefund)
