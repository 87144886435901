import React, { Component } from "react";
import { addCommission } from "../../../actions/Commissions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
export class NewSurgeryCommission extends Component {
  static propTypes = {
    addCommission: PropTypes.func.isRequired
  };

  state = {
    tipo: "",
    doctor: "",
    namecommission: "",
    precio: 0,
    commission_percentage: 0,
    description: "",
    total_commission: 0,
    size: "",
    type_of_anesthesia: "",
    total_anesthesia: 0,
    commission_percentage_anesthesia: 0,
    opciones: [
      { label: "Boca", value: "1" },
      { label: "Ocular", value: "2" },
      { label: "Oído", value: "3" },
      { label: "Digestivo", value: "4" },
      { label: "Genitourinario", value: "5" },
      { label: "Ortopedia", value: "6" }
    ],
    sizeOption: [
      { label: "S", value: "S" },
      { label: "M", value: "M" },
      { label: "L", value: "L" }
    ],
    anesthesiaOptions: [
      { label: "inhalada", value: "inhalada" },
      { label: "inyectada", value: "inyectada" }
    ],
    subOpciones: [],
    mostrarSubtipos: false,
    subtipos: "",
    mostrarTamanios: false,
    mostrarAnestesia: false,
    mostarFormulario: false,
    tamanio: "",
    isSearchable: false,

    precioError: "",
    commission_percentageError: "",
    total_anesthesiaError: "",
    commission_percentage_anesthesiaError: ""
  };

  componentDidMount() {
    this.setState({ tipo: this.props.tipo, doctor: this.props.doctor });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidUpdate() {
    if (
      this.state.precio * (this.state.commission_percentage / 100) +
        this.state.total_anesthesia *
          (this.state.commission_percentage_anesthesia / 100) !==
      this.state.total_commission
    ) {
      this.setState({
        total_commission:
          this.state.precio * (this.state.commission_percentage / 100) +
          this.state.total_anesthesia *
            (this.state.commission_percentage_anesthesia / 100)
      });
    }
    if (this.state.tamanio !== this.state.size && this.state.tamanio !== "") {
      this.comprovacionPrecios();
    }
  }

  handleChangeSubOpciones = e => {
    try {
      if (
        e.value === "Limpieza dental" ||
        e.value === "Tumores orales" ||
        e.value === "Cherry eye" ||
        e.value === "OVH Felina" ||
        e.value === "OVH Perra" ||
        e.value === "Piometra" ||
        e.value === "Orquiectomia"
      ) {
        this.setState({
          mostrarTamanios: true,
          mostrarAnestesia: false,
          mostarFormulario: false,
          namecommission: e.value
        });
      } else {
        this.setState({
          mostrarTamanios: false,
          mostrarAnestesia: true,
          mostarFormulario: false,
          namecommission: e.value
        });
      }
    } catch (error) {}
  };

  handleChangeTamanio = e => {
    try {
      this.setState({ size: e.value, mostrarAnestesia: true });
    } catch (error) {}
  };

  handleChangeAnestesia = e => {
    try {
      if (e.value === "inhalada") {
        this.setState({
          type_of_anesthesia: e.value,
          total_anesthesia: 450,
          commission_percentage_anesthesia: 0,
          mostarFormulario: true,
          precioError: "",
          commission_percentageError: "",
          total_anesthesiaError: "",
          commission_percentage_anesthesiaError: ""
        });
        this.comprovacionPrecios();
      } else {
        this.setState({
          type_of_anesthesia: e.value,
          total_anesthesia: 250,
          commission_percentage_anesthesia: 40,
          mostarFormulario: true,
          precioError: "",
          commission_percentageError: "",
          total_anesthesiaError: "",
          commission_percentage_anesthesiaError: ""
        });
        this.comprovacionPrecios();
      }
    } catch (error) {}
  };

  handleChangeOptionCommission = e => {
    try {
      if (e.value === "1") {
        let subopc = [
          { label: "Limpieza dental", value: "Limpieza dental" },
          { label: "Extraccion deciduos", value: "Extraccion deciduos" },
          { label: "Extraccion permanentes", value: "Extraccion permanentes" },
          { label: "Tumores orales", value: "Tumores orales" }
        ];
        this.setState({
          subOpciones: subopc,
          mostrarSubtipos: true,
          mostrarAnestesia: false,
          mostrarTamanios: false,
          mostarFormulario: false,
          subtipos: "Tipos de cirugia de boca",
          precioError: "",
          commission_percentageError: "",
          total_anesthesiaError: "",
          commission_percentage_anesthesiaError: ""
        });
      } else if (e.value === "2") {
        let subopc = [
          { label: "Entropion/Ectropion", value: "Entropion/Ectropion" },
          { label: "Cherry eye", value: "Cherry eye" },
          { label: "Enucleacion", value: "Enucleacion" }
        ];
        this.setState({
          subOpciones: subopc,
          mostrarSubtipos: true,
          mostrarAnestesia: false,
          mostrarTamanios: false,
          mostarFormulario: false,
          subtipos: "Tipos de cirugia ocular",
          precioError: "",
          commission_percentageError: "",
          total_anesthesiaError: "",
          commission_percentage_anesthesiaError: ""
        });
      } else if (e.value === "3") {
        let subopc = [
          { label: "Otohematoma", value: "Otohematoma" },
          {
            label: "Ablacion total oido externo",
            value: "Ablacion total oido externo"
          },
          {
            label: "Reseccion del cartílago vertical",
            value: "Reseccion del cartílago vertical"
          }
        ];
        this.setState({
          subOpciones: subopc,
          mostrarSubtipos: true,
          mostrarAnestesia: false,
          mostrarTamanios: false,
          mostarFormulario: false,
          subtipos: "Tipos de cirugia de Oido",
          precioError: "",
          commission_percentageError: "",
          total_anesthesiaError: "",
          commission_percentage_anesthesiaError: ""
        });
      } else if (e.value === "4") {
        let subopc = [
          {
            label: "Laparotomia exploratoria",
            value: "Laparotomia exploratoria"
          },
          {
            label: "Hernia umbilical",
            value: "Hernia umbilical"
          },
          {
            label: "Hernia inguinal",
            value: "Hernia inguinal"
          },
          { label: "Hernia perianal", value: "Hernia perianal" },
          { label: "Gastropexia", value: "Gastropexia" },
          { label: "Gastrotomia", value: "Gastrotomia" },
          { label: "Enterotomia", value: "Enterotomia" },
          { label: "Enteroanastomosis", value: "Enteroanastomosis" }
        ];
        this.setState({
          subOpciones: subopc,
          mostrarSubtipos: true,
          mostrarAnestesia: false,
          mostrarTamanios: false,
          mostarFormulario: false,
          subtipos: "Tipos de cirugia Digestivo",
          precioError: "",
          commission_percentageError: "",
          total_anesthesiaError: "",
          commission_percentage_anesthesiaError: ""
        });
      } else if (e.value === "5") {
        let subopc = [
          {
            label: "Cistotomia",
            value: "Cistotomia"
          },
          {
            label: "Uretrostomia",
            value: "Uretrostomia"
          },
          {
            label: "OVH Felina",
            value: "OVH Felina"
          },
          { label: "OVH Perra", value: "OVH Perra" },
          { label: "Piometra", value: "Piometra" },
          { label: "Parto distocico", value: "Parto distocico" },
          { label: "Cesarea", value: "Cesarea" },
          {
            label: "Reduccion de prolapso uterino",
            value: "Reduccion de prolapso uterino"
          },
          { label: "Prolapso Vaginal", value: "Prolapso Vaginal" },
          { label: "Hiperplasia Vaginal", value: "Hiperplasia Vaginal" },
          { label: "Orquiectomia", value: "Orquiectomia" },
          { label: "Citologia Vaginal", value: "Citologia Vaginal" },
          { label: "Quimioterapia", value: "Quimioterapia" }
        ];
        this.setState({
          subOpciones: subopc,
          mostrarSubtipos: true,
          mostrarAnestesia: false,
          mostrarTamanios: false,
          mostarFormulario: false,
          subtipos: "Tipos de cirugia Genitourinario",
          precioError: "",
          commission_percentageError: "",
          total_anesthesiaError: "",
          commission_percentage_anesthesiaError: ""
        });
      } else if (e.value === "6") {
        let subopc = [
          { label: "Amputacion Completa", value: "Amputacion Completa" },
          { label: "Amputacion Digitos", value: "Amputacion Digitos" }
        ];
        this.setState({
          subOpciones: subopc,
          mostrarSubtipos: true,
          mostrarAnestesia: false,
          mostrarTamanios: false,
          mostarFormulario: false,
          subtipos: "Tipos de Ortopedia",
          precioError: "",
          commission_percentageError: "",
          total_anesthesiaError: "",
          commission_percentage_anesthesiaError: ""
        });
      }
    } catch (error) {}
  };

  comprovacionPrecios() {
    if (
      this.state.namecommission === "Limpieza dental" &&
      this.state.size === "S"
    ) {
      this.setState({
        precio: 400,
        commission_percentage: 40,
        tamanio: "S"
      });
    } else if (
      this.state.namecommission === "Limpieza dental" &&
      this.state.size === "M"
    ) {
      this.setState({
        precio: 600,
        commission_percentage: 40,
        tamanio: "M"
      });
    } else if (
      this.state.namecommission === "Limpieza dental" &&
      this.state.size === "L"
    ) {
      this.setState({
        precio: 800,
        commission_percentage: 40,
        tamanio: "L"
      });
    } else if (this.state.namecommission === "Extraccion deciduos") {
      this.setState({
        precio: 350,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Extraccion permanentes") {
      this.setState({
        precio: 500,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (
      this.state.namecommission === "Tumores orales" &&
      this.state.size === "S"
    ) {
      this.setState({
        precio: 600,
        commission_percentage: 40,
        tamanio: "S"
      });
    } else if (
      this.state.namecommission === "Tumores orales" &&
      this.state.size === "M"
    ) {
      this.setState({
        precio: 700,
        commission_percentage: 40,
        tamanio: "M"
      });
    } else if (
      this.state.namecommission === "Tumores orales" &&
      this.state.size === "L"
    ) {
      this.setState({
        precio: 800,
        commission_percentage: 40,
        tamanio: "L"
      });
    } else if (this.state.namecommission === "Entropion/Ectropion") {
      this.setState({
        precio: 1500,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (
      this.state.namecommission === "Cherry eye" &&
      this.state.size === "S"
    ) {
      this.setState({
        precio: 1200,
        commission_percentage: 40,
        tamanio: "S"
      });
    } else if (
      this.state.namecommission === "Cherry eye" &&
      this.state.size === "M"
    ) {
      this.setState({
        precio: 1400,
        commission_percentage: 40,
        tamanio: "M"
      });
    } else if (
      this.state.namecommission === "Cherry eye" &&
      this.state.size === "L"
    ) {
      this.setState({
        precio: 1600,
        commission_percentage: 40,
        tamanio: "L"
      });
    } else if (this.state.namecommission === "Enucleacion") {
      this.setState({
        precio: 1800,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Otohematoma") {
      this.setState({
        precio: 1200,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Ablacion total oido externo") {
      this.setState({
        precio: 2500,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (
      this.state.namecommission === "Reseccion del cartílago vertical"
    ) {
      this.setState({
        precio: 1800,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Laparotomia exploratoria") {
      this.setState({
        precio: 1800,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Hernia umbilical") {
      this.setState({
        precio: 1500,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Hernia inguinal") {
      this.setState({
        precio: 1800,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Hernia perianal") {
      this.setState({
        precio: 2200,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Gastropexia") {
      this.setState({
        precio: 2500,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Gastrotomia") {
      this.setState({
        precio: 2500,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Enterotomia") {
      this.setState({
        precio: 2200,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Enteroanastomosis") {
      this.setState({
        precio: 2500,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (
      this.state.namecommission === "Cistotomia" ||
      this.state.namecommission === "Uretrostomia"
    ) {
      this.setState({
        precio: 2200,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (
      this.state.namecommission === "OVH Felina" &&
      this.state.size === "S"
    ) {
      this.setState({
        precio: 700,
        commission_percentage: 40,
        tamanio: "S"
      });
    } else if (
      this.state.namecommission === "OVH Felina" &&
      this.state.size === "M"
    ) {
      this.setState({
        precio: 900,
        commission_percentage: 40,
        tamanio: "M"
      });
    } else if (
      this.state.namecommission === "OVH Felina" &&
      this.state.size === "L"
    ) {
      this.setState({
        precio: 900,
        commission_percentage: 40,
        tamanio: "L"
      });
    } else if (
      this.state.namecommission === "OVH Perra" &&
      this.state.size === "S"
    ) {
      this.setState({
        precio: 900,
        commission_percentage: 40,
        tamanio: "S"
      });
    } else if (
      this.state.namecommission === "OVH Perra" &&
      this.state.size === "M"
    ) {
      this.setState({
        precio: 1000,
        commission_percentage: 40,
        tamanio: "M"
      });
    } else if (
      this.state.namecommission === "OVH Perra" &&
      this.state.size === "L"
    ) {
      this.setState({
        precio: 1200,
        commission_percentage: 40,
        tamanio: "L"
      });
    } else if (
      this.state.namecommission === "Piometra" &&
      this.state.size === "S"
    ) {
      this.setState({
        precio: 2000,
        commission_percentage: 40,
        tamanio: "S"
      });
    } else if (
      this.state.namecommission === "Piometra" &&
      this.state.size === "M"
    ) {
      this.setState({
        precio: 2200,
        commission_percentage: 40,
        tamanio: "M"
      });
    } else if (
      this.state.namecommission === "Piometra" &&
      this.state.size === "L"
    ) {
      this.setState({
        precio: 2400,
        commission_percentage: 40,
        tamanio: "L"
      });
    } else if (
      this.state.namecommission === "Orquiectomia" &&
      this.state.size === "S"
    ) {
      this.setState({
        precio: 600,
        commission_percentage: 40,
        tamanio: "S"
      });
    } else if (
      this.state.namecommission === "Orquiectomia" &&
      this.state.size === "M"
    ) {
      this.setState({
        precio: 700,
        commission_percentage: 40,
        tamanio: "M"
      });
    } else if (
      this.state.namecommission === "Orquiectomia" &&
      this.state.size === "L"
    ) {
      this.setState({
        precio: 800,
        commission_percentage: 40,
        tamanio: "L"
      });
    } else if (this.state.namecommission === "Parto distocico") {
      this.setState({
        precio: 1200,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Cesarea") {
      this.setState({
        precio: 2500,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Reduccion de prolapso uterino") {
      this.setState({
        precio: 2000,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (
      this.state.namecommission === "Prolapso Vaginal" ||
      this.state.namecommission === "Hiperplasia Vaginal"
    ) {
      this.setState({
        precio: 1800,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (
      this.state.namecommission === "Citologia Vaginal" ||
      this.state.namecommission === "Quimioterapia"
    ) {
      this.setState({
        precio: 250,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Amputacion Completa") {
      this.setState({
        precio: 2800,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else if (this.state.namecommission === "Amputacion Digitos") {
      this.setState({
        precio: 1200,
        commission_percentage: 40,
        tamanio: "",
        size: ""
      });
    } else {
      this.setState({
        precio: 0,
        commission_percentage: 0,
        tamanio: ""
      });
    }
  }

  validate = () => {
    let iserror = false;
    const errors = {
      precioError: "",
      commission_percentageError: "",
      total_anesthesiaError: "",
      commission_percentage_anesthesiaError: ""
    };

    if (this.state.precio <= 0) {
      iserror = true;
      errors.precioError = "El Precio debe ser mayor a 0";
    }
    if (
      this.state.commission_percentage < 0 ||
      this.state.commission_percentage > 100
    ) {
      iserror = true;
      errors.commission_percentageError =
        "El porcentaje de comision tiene que estar entre 0% y 100%";
    }
    if (this.state.total_anesthesia < 0) {
      iserror = true;
      errors.total_anesthesiaError =
        "El precio de la anestecia debe ser mayor a o igual a 0";
    }
    if (
      this.state.commission_percentage_anesthesia < 0 ||
      this.state.commission_percentage_anesthesia > 100
    ) {
      iserror = true;
      errors.commission_percentage_anesthesiaError =
        "El porcentaje de comision tiene que estar entre 0% y 100%";
    }
    this.setState({
      ...errors
    });
    return iserror;
  };

  onSubmit = e => {
    e.preventDefault();
    const err = this.validate();
    if (!err) {
      const {
        namecommission,
        tipo,
        precio,
        doctor,
        commission_percentage,
        description,
        total_commission,
        size,
        type_of_anesthesia,
        total_anesthesia,
        commission_percentage_anesthesia
      } = this.state;

      const comision = {
        namecommission,
        tipo,
        precio,
        doctor,
        commission_percentage,
        description,
        total_commission,
        size,
        type_of_anesthesia,
        total_anesthesia,
        commission_percentage_anesthesia
      };
      this.props.addCommission(comision);
      //console.log(comision);
      this.setState({
        namecommission: "",
        precio: 0,
        commission_percentage: 0,
        description: "",
        total_commission: 0,
        size: "",
        type_of_anesthesia: "",
        total_anesthesia: 0,
        commission_percentage_anesthesia: 0,
        mostrarSubtipos: false,
        mostrarAnestesia: false,
        mostrarTamanios: false,
        mostarFormulario: false
      });
    }
  };

  render() {
    const isSearchable = this.state.isSearchable;
    const getOpciones = (
      <div className="row pt-3">
        <div className="col-12">
          <h5>{this.state.subtipos}</h5>
          <Select
            defaultValue={{
              label: "Selecciona",
              value: 0
            }}
            options={this.state.subOpciones}
            onChange={this.handleChangeSubOpciones}
            isSearchable={isSearchable}
            id="seleccionsubtipos"
          />
        </div>
      </div>
    );

    const getTamanios = (
      <div className="row pt-3">
        <div className="col-12">
          <h5>Tamaño de la Mascota</h5>
          <Select
            defaultValue={{
              label: "Selecciona",
              value: 0
            }}
            onChange={this.handleChangeTamanio}
            options={this.state.sizeOption}
            isSearchable={isSearchable}
            id="seleccionTamanio"
          />
        </div>
      </div>
    );

    const getTipoAnestesia = (
      <div className="row pt-3">
        <div className="col-12">
          <h5>Tipo de Anestesia</h5>
          <Select
            defaultValue={{
              label: "Selecciona",
              value: 0
            }}
            options={this.state.anesthesiaOptions}
            onChange={this.handleChangeAnestesia}
            isSearchable={isSearchable}
            id="seleccionAnestesia"
          />
        </div>
      </div>
    );

    const getFormulario = (
      <div className="row justify-content-center pt-3">
        <form onSubmit={this.onSubmit} noValidate>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>{this.state.namecommission}</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="precio de consulta"
                  name="precio"
                  value={this.state.precio}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  error={this.state.precioError.length === 0 ? false : true}
                />
                <FormHelperText error={true}>
                  {this.state.precioError}
                </FormHelperText>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Porcentage de Comision %</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="porcentage de comision"
                  name="commission_percentage"
                  value={this.state.commission_percentage}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  error={
                    this.state.commission_percentageError.length === 0
                      ? false
                      : true
                  }
                />
                <FormHelperText error={true}>
                  {this.state.commission_percentageError}
                </FormHelperText>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Total Anestesia</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="total de anestesia"
                  name="total_anesthesia"
                  value={this.state.total_anesthesia}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  error={
                    this.state.total_anesthesiaError.length === 0 ? false : true
                  }
                />
                <FormHelperText error={true}>
                  {this.state.total_anesthesiaError}
                </FormHelperText>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Porcentage de Comision de Anestesia%</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="porcentage de comision de anestesia"
                  name="commission_percentage_anesthesia"
                  value={this.state.commission_percentage_anesthesia}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  error={
                    this.state.commission_percentage_anesthesiaError.length ===
                    0
                      ? false
                      : true
                  }
                />
                <FormHelperText error={true}>
                  {this.state.commission_percentage_anesthesiaError}
                </FormHelperText>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Total de comision</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="total de comision"
                  name="total_commission"
                  value={this.state.total_commission}
                  readOnly
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Descripcion</label>
                <textarea
                  className="form-control"
                  type="Number"
                  placeholder="Descripcion"
                  name="description"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div className="row pb-3">
            <button type="submit" className="btn btn-success btn-block">
              Agregar Comision
            </button>
          </div>
        </form>
      </div>
    );

    return (
      <React.Fragment>
        <div className="container-fluid pt-5">
          <div className="row">
            <div className="col-12">
              <h4>Selecciona el tipo de Cirugia</h4>
              <Select
                defaultValue={{
                  label: "Selecciona",
                  value: 0
                }}
                onChange={this.handleChangeOptionCommission}
                options={this.state.opciones}
                isSearchable={isSearchable}
                id="seleccion"
              />
            </div>
          </div>
          {this.state.mostrarSubtipos ? getOpciones : ""}
          {this.state.mostrarTamanios ? getTamanios : ""}
          {this.state.mostrarAnestesia ? getTipoAnestesia : ""}
          {this.state.mostarFormulario ? getFormulario : ""}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { addCommission }
)(NewSurgeryCommission);
