/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getVarcodeProduct,
  LoadedProduct,
  updateStockProduct,
  finishUpdate,
  get_products,
} from "../../../actions/Products";
import {
  addreceipt,
  getReceiptHeader,
  LoadedHeader,
  addreceiptdetail,
} from "../../../actions/Sales";
import MaterialTable from "material-table";
import { Redirect } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import Logo from "../../../assets/logo.png";
import { addCommission } from "../../../actions/Commissions";

export class SaleofProducts extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    getVarcodeProduct: PropTypes.func.isRequired,
    LoadedProduct: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    updateStockProduct: PropTypes.func.isRequired,

    addreceipt: PropTypes.func.isRequired,
    getReceiptHeader: PropTypes.func.isRequired,
    receipts: PropTypes.array.isRequired,
    loadHeader: PropTypes.bool,

    LoadedHeader: PropTypes.func.isRequired,
    headerloadends: PropTypes.bool,

    addreceiptdetail: PropTypes.func.isRequired,

    finishUpdate: PropTypes.func.isRequired,

    addCommission: PropTypes.func.isRequired,

    get_products: PropTypes.func.isRequired,
    loadingA: PropTypes.bool,
  };

  state = {
    columns: [
      { title: "Nombre", field: "name", editable: "never" },
      { title: "Marca", field: "brand", editable: "never" },
      { title: "Descripcion", field: "description", editable: "never" },
      {
        title: "Cantidad",
        field: "amount",
        type: "numeric",
        editable: "onUpdate",
      },
      {
        title: "Precio",
        field: "sale_price",
        type: "numeric",
        editable: "never",
      },
    ],
    columnsP: [
      { title: "Nombre", field: "name" },
      { title: "Precio compra", field: "purchase_price", type: "numeric" },
      { title: "Precio Venta", field: "sale_price", type: "numeric" },
      { title: "Cantidad", field: "stock", type: "numeric" },
      { title: "Codigo de barras", field: "varcode" },
      { title: "Detalle Codigo", field: "barcode_generate" },
      { title: "Categoria", field: "category" },
      { title: "Marca", field: "brand" },
      { title: "Descripcion", field: "description" },
    ],
    data: [],
    barcodeInput: "",
    total: 0,
    customer: "",
    redirect: false,
    saleserrors: "",
    opciones: [
      { label: "Efectivo", value: "1" },
      { label: "Tarjeta", value: "2" },
      { label: "Fraccionado", value: "3" },
    ],
    selectOption: "1",
    totalcash: "",
    totalcard: "",
    totalcasherros: "",
    totalcarderrors: "",
    sumaFraccionadoerror: "",

    tipo: "",
    doctor: "",
    namecommission: "",
    precio: 0,
    commission_percentage: 0,
    description: "",
    total_commission: 0,
    amount: 0,
    type_of_anesthesia: "",
    size: "",
  };

  handleChangeOption = (e) => {
    try {
      this.setState({
        selectOption: e.value,
        sumaFraccionadoerror: "",
      });
    } catch (error) {
      this.setState({ selectOption: "" });
    }
  };

  componentDidMount() {
    if (this.props.tipo === "Farmacia") {
      this.setState({
        tipo: this.props.tipo,
        doctor: this.props.doctor,
        namecommission: "Farmacia",
        commission_percentage: this.props.porcentaje,
      });

      //console.log(this.props.porcentaje)
    }

    this.barcodeInput.focus();
    this.setState({ customer: this.props.nameCustomer });
    this.props.get_products();
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  validate() {
    let iserror = false;

    const errors = {
      saleserrors: "",
      totalcasherros: "",
      totalcarderrors: "",
    };

    if (this.state.data.length === 0) {
      // no tiene nada el detalle
      iserror = true;
      errors.saleserrors = "La venta no tiene productos agregados";
    }
    for (let i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].amount > this.state.data[i].stock) {
        iserror = true;
        errors.saleserrors = `La cantidad de ${this.state.data[i].name} 
        es mayor de lo que hay en inventario solo queda en Inventario ${this.state.data[i].stock} unidades`;
      }
    }
    if (this.state.selectOption === "3") {
      if (this.state.totalcash === "") {
        iserror = true;
        errors.totalcasherros = "Ingrese la cantidad en efectivo";
      } else if (this.state.totalcard === "") {
        iserror = true;
        errors.totalcarderrors = "Ingrese la cantidad en tarjeta";
      } else {
        const totalcash = parseFloat(this.state.totalcash);
        const totalcard = parseFloat(this.state.totalcard);
        const sumatoria = totalcash + totalcard;
        const total = parseFloat(this.state.total);
        if (totalcash <= 0) {
          iserror = true;
          errors.totalcasherros =
            "La cantidad de efectivo debe ser mayor a cero";
        }
        if (totalcard <= 0) {
          iserror = true;
          errors.totalcarderrors =
            "La cantidad en tarjeta debe ser mayor a cero";
        }
        if (sumatoria !== total) {
          iserror = true;
          errors.sumaFraccionadoerror =
            "La suma de Ambas cantidades debe ser igual al total verifique";
        } else {
          this.setState({ sumaFraccionadoerror: "" });
        }
      }
    }

    this.setState({
      ...errors,
    });
    return iserror;
  }

  EndSale = (e) => {
    e.preventDefault();
    const err = this.validate();
    if (!err) {
      if (this.state.selectOption === "1") {
        const { customer, total } = this.state;
        const totalcash = total;
        const totalcard = 0;
        const receipt = {
          customer,
          total,
          totalcash,
          totalcard,
        };
        this.props.addreceipt(receipt);
        //console.log("Pago en Efectivo");
      } else if (this.state.selectOption === "2") {
        const { customer, total } = this.state;
        const totalcard = total;
        const totalcash = 0;
        const typeofpay = "Tarjeta";
        const receipt = {
          customer,
          total,
          totalcash,
          totalcard,
          typeofpay,
        };
        this.props.addreceipt(receipt);
        //console.log("Pago con Tarjeta");
      } else if (this.state.selectOption === "3") {
        const { customer, total, totalcash, totalcard } = this.state;
        const typeofpay = "Fraccionado";
        const receipt = {
          customer,
          total,
          totalcash,
          totalcard,
          typeofpay,
        };
        this.props.addreceipt(receipt);
        //console.log("Pago en Fracciones");
      }

      this.setState({
        customer: "",
        total: "",
      });
    }
  };

  onEnterPress = (e) => {
    //cuando el lecto termina de leer da un salto de linea
    //el shift no debe de estar precionado por que saldrian otros caracteres
    if (e.keyCode === 13 /* && e.shiftKey === false*/) {
      e.preventDefault();
      let varcode = this.barcodeInput.value.toLowerCase();
      this.setState({ varcode });
      this.props.getVarcodeProduct(varcode);
      this.barcodeInput.value = "";
    }
  };

  calculoTotal() {
    let sumatoria = 0;
    for (let i = 0; i < this.state.data.length; i++) {
      sumatoria += this.state.data[i].amount * this.state.data[i].sale_price;
    }
    this.setState({ total: sumatoria });
    //console.log(sumatoria);
  }

  foco() {
    //document.getElementById("barcodeInput").focus();
    this.barcodeInput.focus();
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.barcodeInput.scrollIntoView();
  };

  componentDidUpdate() {
    let sumatoria = 0;
    for (let i = 0; i < this.state.data.length; i++) {
      sumatoria += this.state.data[i].amount * this.state.data[i].sale_price;
    }
    if (this.state.total !== sumatoria) {
      this.setState({
        total: sumatoria,
        saleserrors: "",
        sumaFraccionadoerror: "",
      });
    }

    if (this.props.loadHeader) {
      //este es el loading despues que se agrega el encabezado se vuelve true
      this.props.getReceiptHeader();
    }
    if (this.props.headerloadends) {
      const recibo = this.props.receipts;
      let id_sale;
      let fecha;
      let total;
      let nombre;
      let totalcash;
      let totalcard;
      recibo.map((header) => {
        const newLocal =
          ((id_sale = header.id),
          (fecha = header.date),
          (total = header.total),
          (nombre = header.customer),
          (totalcash = header.totalcash),
          (totalcard = header.totalcard));
        return newLocal;
      });

      var pdf = new jsPDF();
      pdf.setFontSize(14);
      pdf.text("Recibo#: " + id_sale, 14, 45);
      pdf.text(moment(fecha).format("DD/MM/YYYY"), 170, 20);
      pdf.text("ANIMAL HOME", 90, 30);
      var logo = new Image();
      logo.src = Logo;
      pdf.addImage(logo, "JPEG", 14, 0, 35, 35);
      pdf.text("Nombre: " + nombre, 14, 55);
      //pdf.save("a4.pdf");
      pdf.setFontStyle("Arial");
      var columns = ["Nombre", "Marca", "Descripcion", "Cantidad", "precio"];
      var filas = [];
      for (let i = 0; i < this.state.data.length; i++) {
        let amount = this.state.data[i].amount;
        let id_product = this.state.data[i].key;
        let sale_price = this.state.data[i].sale_price;
        const detail = {
          amount,
          id_sale,
          id_product,
          sale_price,
        };
        /*PARA EL REPORTE*/

        let fila = [
          this.state.data[i].name,
          this.state.data[i].brand,
          this.state.data[i].description,
          this.state.data[i].amount,
          this.state.data[i].sale_price,
        ];
        filas.push(fila);

        let newStock =
          parseInt(this.state.data[i].stock) -
          parseInt(this.state.data[i].amount);
        this.props.updateStockProduct(id_product, newStock);
        this.props.addreceiptdetail(detail);

        if (this.props.tipo === "Farmacia") {
          const {
            namecommission,
            tipo,
            doctor,
            commission_percentage,
            type_of_anesthesia,
            size,
          } = this.state;
          let precio = this.state.data[i].sale_price;
          let description =
            this.state.data[i].name +
            " " +
            this.state.data[i].brand +
            " " +
            this.state.data[i].description;
          let total_commission =
            parseFloat(this.state.data[i].sale_price) *
            parseFloat(this.state.data[i].amount) *
            (this.props.porcentaje / 100);
          let amount = this.state.data[i].amount;
          const comision = {
            namecommission,
            tipo,
            precio,
            doctor,
            commission_percentage,
            description,
            total_commission,
            amount,
            type_of_anesthesia,
            size,
          };

          this.props.addCommission(comision);
        }
      }

      // pdf.autoTable(columns, filas, {
      //   margin: { top: 65 },
      //   pageBreak: "avoid",
      //   headStyles: { fillColor: [39, 174, 96] },
      //   theme: "grid",
      // });

      pdf.setFontSize(9);

      pdf.autoTable(columns, filas, {
        margin: { top: 65 },
        pageBreak: "avoid",
        headStyles: { fillColor: [18, 73, 128], fontSize: 7 },
        bodyStyles: { fontSize: 7 },
        theme: "grid",
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: 30 },
          2: { cellWidth: 70 },
          3: { cellWidth: 20 },
          4: { cellWidth: 20 },
        },
      });

      pdf.text("EFECTIVO: Q" + totalcash, 14, pdf.autoTable.previous.finalY + 10);
      pdf.text("TARJETA: Q" + totalcard, 14, pdf.autoTable.previous.finalY + 14);
      pdf.setFontSize(14);
      pdf.text("TOTAL: Q" + total, 14, pdf.autoTable.previous.finalY + 19);
      pdf.save("Recibo.pdf");
      //console.log(id);
      this.setState({
        data: [],
        barcodeInput: "",
        total: 0,
        customer: "",
        redirect: true,
      });
      this.props.finishUpdate(); //vacia el array
      this.props.LoadedHeader(); // vuelve falsa la condicion
    }
  }

  render() {
    const obj = this.props.products;
    var data = this.state.data;
    var total = this.state.total;
    if (this.state.redirect) {
      return <Redirect to="/sales" result={"NuevaVenta"} />;
    }

    if (this.props.loading) {
      var id;
      var nombre;
      var cantidadProduc;
      var precioVenta;
      var descripcion;
      var nombreMarca;
      obj.map((pro) => {
        return (
          (id = pro.id_product),
          (nombre = pro.name),
          (cantidadProduc = pro.stock),
          (precioVenta = pro.sale_price),
          (descripcion = pro.description),
          (nombreMarca = pro.brand.name)
        );
      });
      const info = {
        name: nombre,
        brand: nombreMarca,
        description: descripcion,
        amount: 1,
        sale_price: precioVenta,
        key: id,
        stock: cantidadProduc,
      };
      var searchProduct = false;
      if (info.name !== undefined) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].key === info.key) {
            data[i].amount = data[i].amount + 1;
            searchProduct = true;
            break;
          }
        }
        if (searchProduct === false) {
          data.push(info);
        }
      }
      this.foco();
      this.props.LoadedProduct();
    }

    const getFraccionado = (
      <div className="row pt-2">
        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="form-group">
            <label>Efectivo</label>
            <TextField
              className="form-control"
              type="Number"
              placeholder="Cantidad en Efectivo"
              name="totalcash"
              onChange={this.onChange}
              helperText={this.state.totalcasherros}
              error={this.state.totalcasherros.length === 0 ? false : true}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="form-group">
            <label>Tarejeta</label>
            <TextField
              className="form-control"
              type="Number"
              placeholder="Cantidad en Tarjeta"
              name="totalcard"
              onChange={this.onChange}
              helperText={this.state.totalcarderrors}
              error={this.state.totalcarderrors.length === 0 ? false : true}
            />
          </div>
        </div>
      </div>
    );

    const da = this.props.productsA.map((pro) => ({
      name: pro.name,
      purchase_price: pro.purchase_price,
      sale_price: pro.sale_price,
      stock: pro.stock,
      varcode: pro.varcode,
      category: pro.category.name,
      idcategory: pro.category.id,
      brand: pro.brand.name,
      idbrand: pro.brand.id,
      description: pro.description,
      barcode_generate: pro.barcode_generate,
      key: pro.id_product,
    }));

    return (
      <React.Fragment>
        <div className="card border-success card-body mt-4 mb-4 pt-5">
          <MaterialTable
            title="INVENTARIO"
            columns={this.state.columnsP}
            data={da}
            actions={[
              {
                icon: "add",
                tooltip: "Agregar a la Venta",
                onClick: (event, rowData) => {
                  this.props.getVarcodeProduct(rowData.varcode);
                },
              },
            ]}
            options={{
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF",
              },
              pageSize: 3,
              /*rowStyle: {
                backgroundColor: "#EEE"
              },*/
              //exportButton: true,
              //exportFileName: "Inventario",
              //exportAllData: true
              /*exportCsv: (columns, data) => {
                
              }*/
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage:
                  "No se encontro ningun producto relacionado.",
                editRow: {
                  deleteText:
                    "Esta seguro de eliminar este producto? (esta accion es irreversible)",
                },
              },
              header: {
                actions: "Acciones",
              },
            }}
          />
        </div>

        <div className="card border-success card-body mt-4 mb-4 pt-5">
          <MaterialTable
            title="NUEVA VENTA"
            /*columns={this.state.columns}*/
            columns={[
              { title: "Nombre", field: "name", editable: "never" },
              { title: "Marca", field: "brand", editable: "never" },
              { title: "Descripcion", field: "description", editable: "never" },
              {
                title: "Cantidad",
                field: "amount",
                type: "numeric",
                editable: "onUpdate",
              },
              {
                title: "Precio",
                field: "sale_price",
                type: "numeric",
                editable: "never",
              },
            ]}
            data={this.state.data}
            options={{
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF",
              },
              search: false,
              actionsColumnIndex: -1,
              paging: false,
              //paginationType:"stepped"
              //pageSize: 7,
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No hay ningun producto en la Venta ",
                editRow: {
                  deleteText: "Esta seguro de eliminar este producto",
                },
              },
              header: {
                actions: "Acciones",
              },
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      var data = this.state.data;
                      var index;

                      for (let i = 0; i < data.length; i++) {
                        if (data[i].key === oldData.key) {
                          index = i;
                          break;
                        }
                      }
                      let cantidad = parseInt(newData.amount);
                      if (cantidad <= 0) {
                        this.foco();
                        this.setState({
                          saleserrors:
                            "La cantidad de producto debe ser mayor a 0",
                        });
                      } else {
                        //console.log(newData);
                        data[index] = newData;
                        this.calculoTotal();
                        this.foco();
                        this.setState({ data }, () => resolve());
                      }
                    }
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      let data = this.state.data;
                      var index;

                      for (let i = 0; i < data.length; i++) {
                        if (data[i].key === oldData.key) {
                          index = i;
                          break;
                        }
                      }
                      data.splice(index, 1);
                      this.calculoTotal();
                      this.foco();
                      this.setState({ data }, () => resolve());
                    }
                    resolve();
                  }, 1000);
                }),
            }}
          />

          <div className="row pt-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h5 className="font-weight-bold">TOTAL</h5>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  className="form-control text-dark bg-warning"
                  type="Number"
                  name="total"
                  value={total}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="row pt-2">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <input
                  className="form-control text-light bg-dark"
                  type="text"
                  placeholder="Codigo de Barras"
                  name="barcodeInput"
                  id="barcodeInput"
                  onKeyDown={this.onEnterPress}
                  ref={(input) => {
                    this.barcodeInput = input;
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row pt-2">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={this.EndSale}
              >
                Terminar
              </button>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <Select
                defaultValue={{
                  label: "Efectivo",
                  value: "1",
                }}
                onChange={this.handleChangeOption}
                options={this.state.opciones}
                id="seleccion"
              />
            </div>
            <small className="alert-danger">{this.state.saleserrors}</small>
          </div>
          {this.state.selectOption === "3" ? getFraccionado : <div></div>}
          <small className="alert-danger">
            {this.state.sumaFraccionadoerror}
          </small>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.FindProduct.products,
  loading: state.FindProduct.loading,

  receipts: state.Sales.receipts,
  loadHeader: state.Sales.loading,
  headerloadends: state.Sales.loadedHeader,
  productsA: state.Products.products,
  loadingA: state.Products.loading,
});

export default connect(mapStateToProps, {
  getVarcodeProduct,
  LoadedProduct,
  addreceipt,
  getReceiptHeader,
  LoadedHeader,
  addreceiptdetail,
  updateStockProduct,
  finishUpdate,
  addCommission,
  get_products,
})(SaleofProducts);
/* eslint-enable */
