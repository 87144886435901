import {
  GET_PRODUCTS,
  ADD_PRODUCTS,
  UPDATE_PRODUCT,
  UPDATE_STOCK_PRODUCT,
  UPDATE_STATUS_PRODUCT
} from "../actions/types";

const initialState = {
  products: [],
  loading: false,
  loading_status:false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
        loading_status:false
      };
    case ADD_PRODUCTS:
      return {
        ...state
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: [...state.products],
        loading: true
      };
     case UPDATE_STOCK_PRODUCT:
       return{
        ...state,
        products: [...state.products]
       }
     case UPDATE_STATUS_PRODUCT:
        return{
         ...state,
         products: [...state.products],
         loading_status:true
      }  
    default:
      return state;
  }
}
