import { CLIENTS_LOADING, CLIENTS_LOADED } from "../actions/types";

const initialState = {
  loading: false,
  clients: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CLIENTS_LOADING:
      return {
        ...state,
        loading: true
      };

    case CLIENTS_LOADED:
      return {
        ...state,
        clients: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
