import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get_products,updateStatusProduct } from "../../../actions/Products";
import MaterialTable from "material-table";
import JsBarcode from "jsbarcode";
import jsPDF from "jspdf";
//import Popup from "reactjs-popup";
import UpdateProduct from "./UpdateProduct";
//import BlockIcon from '@material-ui/icons/Block';


export class ShowInventory extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    get_products: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    updateStatusProduct: PropTypes.func.isRequired,
    loading_status: PropTypes.bool
  };

  componentDidMount() {
    this.props.get_products();
  }

  state = {
    columns: [
      { title: "Nombre", field: "name" },
      { title: "Precio compra", field: "purchase_price", type: "numeric" },
      { title: "Precio Venta", field: "sale_price", type: "numeric" },
      { title: "Cantidad", field: "stock", type: "numeric" },
      { title: "Codigo de barras", field: "varcode" },
      { title: "Detalle Codigo", field: "barcode_generate" },
      { title: "Categoria", field: "category" },
      { title: "Marca", field: "brand" },
      { title: "Descripcion", field: "description" }
    ]
  };

  render() {
    if (this.props.loading || this.props.loading_status) {
      this.props.get_products();
    }

    const da = this.props.products.map(pro => ({
      name: pro.name,
      purchase_price: pro.purchase_price,
      sale_price: pro.sale_price,
      stock: pro.stock,
      varcode: pro.varcode,
      category: pro.category.name,
      idcategory: pro.category.id,
      brand: pro.brand.name,
      idbrand: pro.brand.id,
      description: pro.description,
      barcode_generate: pro.barcode_generate,
      key: pro.id_product
    }));

    return (
      <React.Fragment>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <div className="pt-5">
          <MaterialTable
            title="INVENTARIO"
            columns={this.state.columns}
            data={da}
            actions={[
              {
                icon: "print",
                tooltip: "imprimir codigo de barras",
                onClick: (event, rowData) => {
                  //const varcode = rowData.varcode;

                  const canvas = this.refs.canvas;
                  JsBarcode(this.refs.canvas, /* "AH" +*/ rowData.varcode, {
                    width: 2,
                    height: 50,
                    fontSize: 10
                  });
                  var imgData = canvas.toDataURL("image/png", 1.0); // el 1 es para que salga HD
                  var pdf = new jsPDF();
                  for (let i = 10; i <= 250; i = i + 25) {
                    //Y
                    for (let j = 10; j <= 150; j = j + 80) {
                      //X
                      pdf.addImage(imgData, "PNG", j, i);
                    }
                  }
                  pdf.save("download.pdf");
                }
              },
              /*{
                icon: BlockIcon,
                tooltip: "Desactivar Producto",
                onClick: (event, rowData) => {
                  let id_product = rowData.key;
                  let estado_actual = 'Desactivo';
                  this.props.updateStatusProduct(id_product,estado_actual);
                }
              }*/
            ]}
            options={{
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF"
              },
              /*rowStyle: {
                backgroundColor: "#EEE"
              },*/
              exportButton: true,
              exportFileName: "Inventario",
              exportAllData: true
              /*exportCsv: (columns, data) => {
                
              }*/
            }}
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar"
              },
              body: {
                emptyDataSourceMessage:
                  "No se encontro ningun producto relacionado.",
                  editRow: {
                    deleteText: "Esta seguro de eliminar este producto? (esta accion es irreversible)"
                  }
              },
              header: {
                actions: "Acciones"
              }
            }}
            detailPanel={[
              {
                icon: "edit",
                tooltip: "Editar",
                render: rowData => {
                  return (
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <UpdateProduct
                          id_product={rowData.key}
                          name={rowData.name}
                          varcode={rowData.varcode}
                          stock={rowData.stock}
                          purchase_price={rowData.purchase_price}
                          sale_price={rowData.sale_price}
                          description={rowData.description}
                          idCategory={rowData.idcategory}
                          nameCategory={rowData.category}
                          idbrand={rowData.idbrand}
                          nameBrand={rowData.brand}
                        />
                      </div>
                    </div>
                  );
                }
              }
            ]}
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                  let id_product = oldData.key;
                  let estado_actual = 'Desactivo';
                  this.props.updateStatusProduct(id_product,estado_actual);
                    }
                    resolve();
                  }, 1000);
                })
            }}
          />
        </div>
        <canvas ref="canvas" width="200" height="50" />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  products: state.Products.products,
  loading: state.Products.loading,
  loading_status: state.Products.loading_status
});

export default connect(
  mapStateToProps,
  { get_products,updateStatusProduct }
)(ShowInventory);
