import React, { Component } from 'react';
// import ApointmentCard from './ApointmentCard.js';
// import GroomingDetails from './GroomingDetails.js';
import AppointmentCard from './AppointmentCard';
import GroomingDetails from './GroomingDetails';

class ApointmentCardCard extends Component {
    render() {
        const { data } = this.props;
        if(!data) return null;
        // Check if the data has the 'appointment' key to determine which details component to render
        const isAppointment = data.hasOwnProperty('profile_doctor');

        return (
            <div>
                {isAppointment ? (
                    <AppointmentCard appointment={data} />
                ) : (
                    <GroomingDetails grooming={data} />
                )}
            </div>
        );
    }
}

export default ApointmentCardCard;
