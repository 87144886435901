import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateProfile } from "../../actions/ActProfile";
import { getCertainProfile } from "../../actions/ActProfile";
import { updateUser } from "../../actions/ActAuths";
import { createMessage } from "../../actions/ActMessages";
import Loading from "../common/Loading";

class NewClientProfile extends Component {
  state = {
    username: "",
    first_name: "",
    DPI: "",
    email: "",
    address: "",
    phone: "",
    phone2: ""
  };

  static propTypes = {
    user: PropTypes.object.isRequired,
    profile: PropTypes.object,
    updateProfile: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    getCertainProfile: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired
  };

  async componentDidMount() {
    try {
      await this.props.getCertainProfile(this.props.clientId);
    } catch (error) {
      console.log(error);
    }
    this.setState({ ...this.props.profile });
    this.setState({
      username: this.props.username,
      first_name: this.props.first_name,
      email: this.props.email
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    try {
      let { first_name, address, DPI, phone, phone2, email } = this.state;
      let info = { first_name, address, DPI, phone, phone2, email };
      this.props.updateProfile(info, this.props.profile.id);
      this.props.updateUser(info, this.props.clientId);
      this.props.createMessage({
        profileUpdated: "¡Perfil actualizado!"
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { address, DPI, phone2, phone, first_name, email } = this.state;
    return (
      <React.Fragment>
        {this.props.profileLoading ? (
          <React.Fragment>
            <Loading></Loading>
          </React.Fragment>
        ) : (
          <div className="row">
            <div className="col">
              <h4 className="text-center">Perfil {this.props.username}</h4>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Nombre</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputDPI"
                        placeholder="Nombre"
                        value={first_name}
                        name="first_name"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>DPI</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputDPI"
                        placeholder="DPI"
                        value={DPI}
                        name="DPI"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputDPI"
                        placeholder="Email"
                        value={email}
                        name="email"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Contacto principal</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPhone2"
                        placeholder="Teléfono 2"
                        value={phone}
                        name="phone"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Contacto opcional</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPhone2"
                        placeholder="Teléfono 2"
                        value={phone2}
                        name="phone2"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Dirección</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress"
                    placeholder="Dirección"
                    value={address}
                    name="address"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Completar perfil
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.RedAuth.user,
  profileLoading: state.Profile.loading,
  profile: state.Profile.profileInfo
});

export default connect(mapStateToProps, {
  updateProfile,
  getCertainProfile,
  createMessage,
  updateUser
})(NewClientProfile);
/*export default Form;*/
