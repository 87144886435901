import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { registerNewUser } from "../../actions/ActAuths";
import formPerson from "../../assets/icons/formPerson.svg";
import { createMessage } from "../../actions/ActMessages";
class AddNewClient extends Component {
  state = {
    first_name: "",
    username: "",
    email: "",
    email2: "",
    password: "",
    password2: "",
    last_name: "cliente",
  };

  static propTypes = {
    registerNewUser: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    loader: PropTypes.bool,
    achived: PropTypes.bool.isRequired,
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = (e) => {
    e.preventDefault();
    const {
      first_name,
      username,
      email,
      email2,
      password,
      password2,
      last_name,
    } = this.state;

    if (password !== password2) {
      this.props.createMessage({
        passwordDoNotMatch: "Las contraseñas no coinciden",
      });
    } else {
      if (email !== email2) {
        this.props.createMessage({
          passwordDoNotMatch: "El email no coincide",
        });
      } else {
        const newUser = { username, first_name, password, email, last_name };
        try {
          this.props.registerNewUser(newUser);
        } catch (error) {
          createMessage({ registerNewUserError: "Verifica los campos" });
        }
      }
    }
    // createMessage({
    //   registerNewUserSuccess: "Usuario nuevo registrado con exito",
    // });
  };

  render() {
    if (this.props.achived === true) {
      // this.props.createMessage({
      //   registerNewUserSuccess: "Usuario registrado con exito",
      // });
      setTimeout(function () {
        return <Redirect to="/dashboard" />;
      }, 3000);
    }
    const {
      first_name,
      username,
      email,
      email2,
      password,
      password2,
    } = this.state;
    return (
      <div className="card card-body pt-5">
        <img
          src={formPerson}
          alt=""
          style={{
            width: "100px",
            position: "absolute",
            top: "2%",
            left: " 2%",
          }}
        />
        <h2 className="text-center">Registro</h2>
        <form onSubmit={this.onSubmit}>
          <div className="form-row">
            <div className="col-6">
              <div className="form-group">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  onChange={this.onChange}
                  value={username}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Nombre cliente</label>
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  onChange={this.onChange}
                  value={first_name}
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-6">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  onChange={this.onChange}
                  value={email}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Confirma tu email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email2"
                  onChange={this.onChange}
                  value={email2}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-6">
              <div className="form-group">
                <label>Teléfono</label>
                <input
                  type="text"
                  className="form-control"
                  name="password"
                  onChange={this.onChange}
                  value={password}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Confirma tu teléfono</label>
                <input
                  type="text"
                  className="form-control"
                  name="password2"
                  onChange={this.onChange}
                  value={password2}
                  required
                />
              </div>
            </div>
            <small>Este campo sera utilizado como contraseña</small>
          </div>
          <div className="form-group">
            {this.props.loader ? (
              <div class="spinner-border text-warning" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : this.props.achived ? (
              <button
                type="submit"
                className="btn btn-warning btn-block"
                disabled
              >
                Cliente registrado
              </button>
            ) : (
              <button type="submit" className="btn btn-warning btn-block">
                Registrar Nuevo Cliente
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.RedAuth.isAuthenticated,
  loader: state.RedAuth.isLoading,
  achived: state.RedAuth.achive,
});
export default connect(mapStateToProps, { registerNewUser, createMessage })(
  AddNewClient
);
