import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getDeatailsOfHeader, loadedDetails,deletedetailsSales,deleteSales  } from "../../../actions/Sales";
import MaterialTable from "material-table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../../assets/logo.png";
//import { Redirect } from "react-router-dom";

export class ShowDetail extends Component {
  static propTypes = {
    details: PropTypes.array.isRequired,
    getDeatailsOfHeader: PropTypes.func.isRequired,
    loadedDetails: PropTypes.func.isRequired,
    loadingDetail: PropTypes.bool,
    auth: PropTypes.object.isRequired,
    deletedetailsSales:PropTypes.func.isRequired,
    deleteSales :PropTypes.func.isRequired
  };

  componentDidMount() {
    this.setState({ id_sale: this.props.sale_id });
    this.props.getDeatailsOfHeader(this.props.sale_id);
  }

  state = {
    columns: [
      { title: "Nombre", field: "name", editable: "never" },
      { title: "Marca", field: "brand", editable: "never" },
      { title: "Descripcion", field: "description", editable: "never" },
      {
        title: "Cantidad",
        field: "amount",
        type: "numeric"
      },
      {
        title: "Precio",
        field: "sale_price",
        type: "numeric",
        editable: "never"
      }
    ],
    id_sale: "",
    datos: [],
    redirect:false,
    mensajeEncabezado:""
  };

  componentDidUpdate() {
    if (this.state.id_sale !== "" && this.props.loadingDetail) {
      const data = this.props.details.map(detalle => ({
        id: detalle.id,
        name: detalle.product.name,
        brand: detalle.product.brand.name,
        description: detalle.product.description,
        amount: detalle.amount,
        sale_price: detalle.sale_price
      }));
      this.setState({ id_sale: "", datos: data });
      this.props.loadedDetails();

    }
    /*if(this.state.redirect === true){
      this.props.deleteSales(this.props.sale_id);
    }*/

  }

  GenerearPDF = e => {
    e.preventDefault();
    var pdf = new jsPDF();
    pdf.setFontSize(14);
    pdf.text("Recibo#: " + this.props.sale_id, 14, 45);
    pdf.text(this.props.fecha, 170, 20);
    pdf.text("ANIMAL HOME", 90, 30);
    var logo = new Image();
    logo.src = Logo;
    pdf.addImage(logo, "JPEG", 14, 0, 35, 35);
    pdf.setFontStyle("Arial");
    pdf.text("Nombre: " + this.props.nameCustomer, 14, 55);
    var columns = ["Nombre", "Marca", "Descripcion", "Cantidad", "Precio"];
    var filas = [];
    // var pageSize = pdf.internal.pageSize;
    // var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    // pdf.text("Report", 100, pageHeight-20);
    for (let i = 0; i < this.state.datos.length; i++) {
      let fila = [
        this.state.datos[i].name,
        this.state.datos[i].brand,
        this.state.datos[i].description,
        this.state.datos[i].amount,
        this.state.datos[i].sale_price
      ];
      filas.push(fila);
    }
    pdf.setFontSize(9);

    pdf.autoTable(columns, filas, {
      margin: { top: 65 },
      pageBreak: "avoid",
      headStyles: { fillColor: [18, 73, 128], fontSize: 7 },
      bodyStyles: { fontSize: 7 },
      theme: "grid",
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 30 },
        2: { cellWidth: 70 },
        3: { cellWidth: 20 },
        4: { cellWidth: 20 },
      },
    });

    
    pdf.text(
      "EFECTIVO: Q" + this.props.totalcash,
      14,
      pdf.autoTable.previous.finalY + 10
    );
    pdf.text(
      "TARJETA: Q" + this.props.totalcard,
      14,
      pdf.autoTable.previous.finalY + 14
    );
    pdf.setFontSize(14);
    pdf.text(
      "TOTAL: Q" + this.props.total,
      14,
      pdf.autoTable.previous.finalY + 19
    );
    pdf.save("Recibo.pdf");
  };

  EliminarVenta = e => {
    e.preventDefault();

          for(let i = 0;i<this.state.datos.length;i++){
            this.props.deletedetailsSales(this.state.datos[i].id) 
          }
          this.setState({redirect:true,mensajeEncabezado:""});
  }

  EliminarEncabezado = e => {
    e.preventDefault();
    if(this.props.details.length>0){
      this.setState({mensajeEncabezado:"Debe eliminar el detalle primero"})
    }
    this.props.deleteSales(this.props.sale_id);
  }

  render() {
    const Elmininar=(<div className="row justify-content-center pt-2">
    <div className="col-8">
      <button
        type="button"
        className="btn btn-warning btn-block"
        onClick={this.EliminarVenta}
      >
        1. Eliminar Detalle
      </button>
    </div>
  </div>);

const ElmininarVenta=(
<div className="row justify-content-center pt-2">
<div className="col-8">
  <button
    type="button"
    className="btn btn-danger btn-block"
    onClick={this.EliminarEncabezado}
  >
    2. Eliminar Encabezado
  </button>
</div>
<div>
<small className="text-danger">{this.state.mensajeEncabezado}</small>
</div>
 
</div>);

    const Tabla = (
      <div className="pt-5">
      <MaterialTable
        title="PRODUCTOS"
        columns={this.state.columns}
        data={this.state.datos}
        options={{
          headerStyle: {
            backgroundColor: "rgba(220, 118, 51,0.8)",
            color: "#FFF"
          },
          search: false,
          paging: false
          // actionsColumnIndex: -1,
        }}
        localization={{
          toolbar: {
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar"
          },
          body: {
            emptyDataSourceMessage:
              "No se encontro ningun recibo relacionado."
          },
          header: {
            actions: "Acciones"
          }
        }}
      />
    </div>
    );


    return (
      
      <React.Fragment>
        
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        {this.state.redirect === true
                ? ''
                : Tabla }   

        <div className="row justify-content-center pt-2">
          <div className="col-8">
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={this.GenerearPDF}
            >
              GENERAR PDF
            </button>
          </div>
        </div>
        {this.props.auth.rol === "administradora"
        ? Elmininar
        : ''}
        {this.props.auth.rol === "administradora"
        ? ElmininarVenta
        : ''}
       
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  details: state.Sales.details,
  loadingDetail: state.Sales.loadingDetail,
  auth: state.RedAuth
});

export default connect(
  mapStateToProps,
  { getDeatailsOfHeader, loadedDetails,deletedetailsSales,deleteSales  }
)(ShowDetail);
