import {
  RECORDS_LOADING,
  RECORDS_LOADED,
  DRECORDS_LOADED,
  ADD_RECORD,
  ADD_DRECORD
} from "../actions/types.js";

const initialState = {
  records: [],
  dewormerRecords: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RECORDS_LOADING:
      return {
        ...state,
        loading: true
      };
    case DRECORDS_LOADED:
      return {
        ...state,
        loading: false,
        dewormerRecords: action.payload
      };
    case RECORDS_LOADED:
      return {
        ...state,
        loading: false,
        records: action.payload
      };

    case ADD_RECORD:
      return {
        ...state,
        records: [...state.records, action.payload]
      };
    case ADD_DRECORD:
      return {
        ...state,
        dewormerRecords: [...state.dewormerRecords, action.payload]
      };
    default:
      return {
        ...state
      };
  }
}
