export const GET_LEADS = "GET_LEADS";
export const DELETE_LEAD = "DELETE_LEAD";
export const ADD_LEAD = "ADD_LEAD";

export const GET_ERRORS = "GET_ERRORS";

export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const USER_UPDATED = "USER_UPDATED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCES";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_LOADED = "PROFILE_LOADED";
export const GET_PROFILE = "GET_PROFILE";
export const REMOVE_PROFILE = "REMOVE_PROFILE";
export const LOADING_TO_TABLE = "LOADING_TO_TABLE";

export const GET_PETS = "GET_PETS";
export const DELETE_PET = "DELETE_PET";
export const ADD_PET = "ADD_PET";
export const LOADING_PETS = "LOADING_PETS";

export const VETS_LOADING = "VETS_LOADING";
export const VETS_LOADED = "VETS_LOADED";
export const VETS_APOINTMENT_LOADING = "VETS_APOINTMENT_LOADING";
export const VETS_APOINTMENT_LOADED = "VETS_APOINTMENT_LOADED";

export const APOINTMENTS_LOADING = "APOINTMENTS_LOADING";
export const APOINTMENTS_LOADED = "APOINTMENTS_LOADED";
export const ADD_APOINTMENT = "ADD_APOINTMENT";
export const ADD_APOINTMENTERROR = "ADD_APOINTMENTERROR";
export const DAYAPOINTMENTS_LOADING = "DAYAPOINTMENTS_LOADING";
export const DAYAPOINTMENTS_LOADED = "DAYAPOINTMENTS_LOADED";
export const CLINIC_COUNT = "CLINIC_COUNT";
export const GROOM_COUNT = "GROOM_COUNT";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const LOADING_AVAILABLE_DAYS = "LOADING_AVAILABLE_DAYS";
export const AVAILABLE_DAYS_LOADED = "AVAILABLE_DAYS_LOADED";
export const NONGROOMAPPOINTMENTS_LOADED = "NONGROOMAPPOINTMENTS_LOADED";
export const GROOMAPPOINTMENTS_LOADED = "GROOMAPPOINTMENTS_LOADED";
export const CLEAN_APPOINTMENTS = "CLEAN_APPOINTMENTS";

export const DAYSCHEDULE_LOADED = "DAYSCHEDULE_LOADED";
export const DAYSCHEDULE_LOADING = "DAYSCHEDULE_LOADING";

export const GET_BRANDS = "GET_BRANDS";
export const DELETE_BRANDS = "DELETE_BRANDS";
export const UPDATE_BRANDS = "UPDATE_BRANDS";
export const ADD_BRANDS = "ADD_BRANDS";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const DELETE_CATEGORIES = "DELETE_CATEGORIES";
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const ADD_CATEGORIES = "ADD_CATEGORIES";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const GET_PRODUCTSVARCODE = "GET_PRODUCTSVARCODE";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const LOADED_PRODUCT = "LOADED_PRODUCT";
export const UPDATE_STOCK_PRODUCT = "UPDATE_STOCK_PRODUCT";
export const FINISH_UPDATE = "FINISH_UPDATE";
export const UPDATE_STATUS_PRODUCT = "UPDATE_STATUS_PRODUCT";

export const ADD_RECEIPT = "ADD_RECEIPT";
export const ADD_RECEIPT_DETAIL = "ADD_RECEIPT_DETAIL";
export const GET_RECEIPT_HEADER = "GET_RECEIPT_HEADER";
export const LOADED_RECEIPT_HEADER = "LOADED_RECEIPT_HEADER";
export const GET_ALLRECEIPT_HEADER = "GET_ALLRECEIPT_HEADER";
export const GET_DETAILS_HEADER = "GET_DETAILS_HEADER";
export const GET_ALLDETAILS_HEADER = "GET_ALLDETAILS_HEADER";
export const LOADED_DETAILS = "LOADED_DETAILS";

export const GROOMS_LOADING = "GROOMS_LOADING";
export const GROOMS_LOADED = "GROOMS_LOADED";
export const GROOMS_APOINTMENT_LOADING = "GROOMS_APOINTMENT_LOADING";
export const GROOMS_APOINTMENT_LOADED = "GROOMS_APOINTMENT_LOADED";

export const CLIENTS_LOADED = "CLIENTS_LOADED";
export const CLIENTS_LOADING = "CLIENTS_LOADING";

export const SECRETARY_REGISTER_ACHIVED = "SECRETARY_REGISTER_ACHIVED";
export const SECRETARY_REGISTER_ERROR = "SECRETARY_REGISTER_ERROR";
export const SECRETARY_REGISTER_WAITING = "SECRETARY_REGISTER_ACHIVED";
export const REPORTS_SALES_TODAY = "REPORTS_SALES_TODAY";

export const NUMBER_SALES_ACTUAL_DAY = "NUMBER_SALES_ACTUAL_DAY";
export const TOTAL_SALES_TODAY = "TOTAL_SALES_TODAY";
export const TOTAL_PRODUCTS_SALE_TODAY = "TOTAL_PRODUCTS_SALE_TODAY";
export const LOADED_TODAY = "LOADED_TODAY";

export const TOTAL_CASH = "TOTAL_CASH";
export const TOTAL_CARD = "TOTAL_CARD";

export const HISTORY_FILE_LOADING = "HISTORY_FILE_LOADING";
export const HISTORY_FILE_LOADED = "HISTORY_FILE_LOADED";
export const HISTORY_FILE_FINISH = "HISTORY_FILE_FINISH";

export const ADD_COMMISSION = "ADD_COMMISSION";
export const GET_COMMISSION = "GET_COMMISSION";
export const COMMISSION_LOADED = "COMMISSION_LOADED";
export const TOTAL_BASIC_COMMISSION = "TOTAL_BASIC_COMMISSION";
export const GET_SURGERY_COMMISSION = "GET_SURGERY_COMMISSION";
export const TOTAL_SURGERY_COMMISSION = "TOTAL_SURGERY_COMMISSION";

export const GET_OTHER_COMMISSION = "GET_OTHER_COMMISSION";
export const TOTAL_OTHER_COMMISION = "TOTAL_OTHER_COMMISION";

export const GET_COMMISSION_RANK = "GET_COMMISSION_RANK";
export const TOTAL_COMMISSION_RANK = "TOTAL_COMMISSION_RANK";
export const GET_SURGERY_COM_RANK = "GET_SURGERY_COM_RANK";
export const TOTAL_SURGERY_COM_RANK = "TOTAL_SURGERY_COM_RANK";
export const GET_OTHER_COM_RANK = "GET_OTHER_COM_RANK";
export const TOTAL_OTHER_COM_RANK = "TOTAL_OTHER_COM_RANK";
export const LOADED_COMMISSION_RANK = "LOADED_COMMISSION_RANK";

export const PICTURE_UPLOADED = "PICTURE_UPLOADED";
export const HISTORY_PICTURE_UPLOADED = "HISTORY_PICTURE_UPLOADED";
export const PICTURE_UPLOADING = "PICTURE_UPLOADING";
export const HISTORY_PICTURE_UPLOADING = "HISTORY_PICTURE_UPLOADING";

export const ADD_RECORD = "ADD_RECORD";
export const RECORDS_LOADING = "RECORDS_LOADING";
export const RECORDS_LOADED = "RECORDS_LOADED";

export const ADD_DRECORD = "ADD_DRECORD";
export const DRECORDS_LOADED = "DRECORDS_LOADED";

export const TOTAL_SALES_GROOM = "TOTAL_SALES_GROOM";
export const TOTAL_SALES_SERVICES = "TOTAL_SALES_SERVICES";
export const DETAIL_GROOMING = "DETAIL_GROOMING";
export const DETAIL_SERVICES = "DETAIL_SERVICES";

export const ADD_REFUND = "ADD_REFUND";
export const GET_REFUND_HEADER = "GET_REFUND_HEADER";
export const LOADED_REFUND_HEADER = "LOADED_REFUND_HEADER";
export const ADD_REFUND_DETAIL = "ADD_REFUND_DETAIL";
export const GET_DETAIL_REFUND = "GET_DETAIL_REFUND";
export const LOADED_REFUND_DETAIL = "LOADED_REFUND_DETAIL";
export const GET_ALL_REFUNDS_HEADER = "GET_ALL_REFUNDS_HEADER";

export const TOTAL_DEV_CARD = "TOTAL_DEV_CARD";
export const TOTAL_DEV_CASH = "TOTAL_DEV_CASH";
export const TOTAL_DEV_DETAILS = "TOTAL_DEV_DETAILS";

export const GET_PHARMACY_COMMISSION = "GET_PHARMACY_COMMISSION";
export const GET_TOTAL_PHARMACY_COMMISSION = "GET_TOTAL_PHARMACY_COMMISSION";
export const GET_PHARMACY_COMMISSION_RANK = "GET_PHARMACY_COMMISSION_RANK";
export const GET_TOTAL_PHARMACY_COMMISSION_RANK =
  "GET_TOTAL_PHARMACY_COMMISSION_RANK";

export const TOTAL_SALES_PHARM = "TOTAL_SALES_PHARM";
export const DETAIL_PHARM = "DETAIL_PHARM";
export const UPDATE_COMMISSION = "UPDATE_COMMISSION";
export const DEACTIVATE_COMMISSION = "DEACTIVATE_COMMISSION";
export const DEACTIVATE_COMMISSION_RANK = "DEACTIVATE_COMMISSION_RANK";

export const DELETE_DETAILS_SALES = "DELETE_DETAILS_SALES";
export const DELETE_SALE = "DELETE_SALE";

export const DELETE_DETAILS_REFUNDS = "DELETE_DETAILS_REFUNDS";
export const DELETE_REFUND = "DELETE_REFUND";
/* "http://192.168.0.20:8000"; */
/* "https://api.animalhomegt.com";*/
const DEBUG = false;
export const __BASE__URL = DEBUG
  ? "http://192.168.0.7:8000"
  : "https://apiv2.animalhomegt.com"; //api.animalhomegt.com";
