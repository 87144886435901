import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getProfile, updateProfile } from "../../actions/ActProfile";
import { updateUser } from "../../actions/ActAuths";
import { createMessage } from "../../actions/ActMessages";
import Loading from "../common/Loading";
class Form extends Component {
  state = {
    username: "",
    first_name: "",
    DPI: "",
    email: "",
    address: "",
    phone: "",
    phone2: ""
  };

  static propTypes = {
    user: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    getProfile: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired
  };

  async componentDidMount() {
    try {
      await this.props.getProfile();
    } catch (error) {
      console.log(error);
    }
    this.setState({
      DPI: this.props.profile.DPI,
      address: this.props.profile.address,
      phone: this.props.profile.phone,
      phone2: this.props.profile.phone2,
      username: this.props.user.username,
      email: this.props.user.email,
      first_name: this.props.user.first_name
    });
  }

  /*static propTypes = {
    addLead: PropTypes.func.isRequired
  };*/

  handleSubmit = e => {
    e.preventDefault();
    try {
      let { first_name, address, DPI, phone, phone2, email } = this.state;
      let info = { first_name, address, DPI, phone, phone2, email };
      /*console.log(info);
      console.log(this.props.profile.id);*/
      this.props.updateProfile(info, this.props.profile.id);
      this.props.updateUser(info, this.props.user.id);
      this.props.createMessage({
        personalInfoUpdated: "Tu información personal ha sido actualizada."
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { username } = this.props.user;
    const {
      DPI,
      address,
      phone2,
      phone,
      isCompleted,
      first_name,
      email
    } = this.state;
    /*const { isCompleted } = this.props.profile;
    const { username, first_name, address, DPI, phone, phone2 } = this.state;*/
    return (
      <React.Fragment>
        {this.props.profile.loading ? (
          <React.Fragment>
            {" "}
            <Loading></Loading>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!isCompleted ? (
              <div className="alert alert-warning" role="alert">
                <p>¡Completa tu información para poder atenderte mejor!</p>
              </div>
            ) : (
              <div />
            )}
            <form onSubmit={this.handleSubmit}>
              <p className="text-center text-primary">Información Personal</p>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputName"
                      placeholder="Nombre"
                      value={username}
                      name="username"
                      onChange={this.handleChange}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputName"
                      placeholder="Nombre"
                      value={first_name}
                      name="first_name"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>DPI</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputDPI"
                      placeholder="DPI"
                      value={DPI}
                      name="DPI"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmai"
                    placeholder="Email"
                    value={email}
                    name="email"
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Dirección</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  placeholder="Dirección"
                  value={address}
                  name="address"
                  onChange={this.handleChange}
                />
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Teléfono</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputPhone"
                      placeholder="Teléfono"
                      value={phone}
                      name="phone"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Télefono 2</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputPhone2"
                      placeholder="Teléfono 2"
                      value={phone2}
                      name="phone2"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block">
                Actualizar información
              </button>
              <div className="row mt-2">
                <div className="col-lg-6 col-sm-12 col-12">
                  <Link
                    className="btn btn-warning btn-block"
                    to="/client/petmanagment"
                  >
                    Mascotas
                  </Link>
                </div>
                <div className="col-lg-6 col-sm-12 col-12 mt-1">
                  <Link
                    className="btn btn-warning btn-block"
                    to="/client/dates"
                  >
                    Citas
                  </Link>
                </div>
              </div>
            </form>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.RedAuth.user,
  profile: state.Profile
});

export default connect(mapStateToProps, {
  getProfile,
  updateProfile,
  createMessage,
  updateUser
})(Form);
/*export default Form;*/
