import {
  GET_PRODUCTSVARCODE,
  LOADED_PRODUCT,
  FINISH_UPDATE
} from "../actions/types";

const initialState = {
  products: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTSVARCODE:
      return {
        ...state,
        products: action.payload,
        loading: true
      };
    case LOADED_PRODUCT:
      return {
        ...state,
        loading: false
      };
    case FINISH_UPDATE:
      return {
        ...state,
        loading:false,
        products: []
      };
    default:
      return state;
  }
}
