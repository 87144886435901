import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCategories } from "../../../actions/Categories";
import { getBrands } from "../../../actions/Brands";
import Select from "react-select";
//la advertencia significa que estoy usabdo una biblioteca que usa el ciclo de vida heredado
import { addproduct, getVarcodeProduct } from "../../../actions/Products";
import TextField from "@material-ui/core/TextField";
import JsBarcode from "jsbarcode";
import jsPDF from "jspdf";
import { Redirect } from "react-router-dom";

export class ProductsWithoutBarcode extends Component {
  static propTypes = {
    brands: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    getCategories: PropTypes.func.isRequired,
    getBrands: PropTypes.func.isRequired,
    addproduct: PropTypes.func.isRequired,
    //products: PropTypes.object.isRequired,

    products: PropTypes.array,
    getVarcodeProduct: PropTypes.func.isRequired,
    loading: PropTypes.bool
  };

  state = {
    varcode: "",
    selectedCategory: "",
    selectedBrand: "",
    name: "",
    stock: "",
    purchase_price: "",
    sale_price: "",
    description: "",

    nameError: "",
    stockError: "",
    purchashe_price_Error: "",
    sale_price_Error: "",
    category_Error: "",
    brand_error: "",
    barcode_error: "",
    barcode_generate: "Generado",
    redirect: false,

    load: false
  };

  componentDidMount() {
    this.props.getCategories();
    this.props.getBrands();
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  handleChangeCat = e => {
    try {
      this.setState({ selectedCategory: e.value });
    } catch (error) {
      this.setState({ selectedCategory: "" });
    }
  };

  handleChangeBrand = e => {
    try {
      this.setState({ selectedBrand: e.value });
    } catch (error) {
      this.setState({ selectedBrand: "" });
    }
  };

  validate = () => {
    let iserror = false;

    const errors = {
      nameError: "",
      stockError: "",
      purchashe_price_Error: "",
      sale_price_Error: "",
      category_Error: "",
      brand_error: "",
      barcode_error: ""
    };

    if (this.state.name === "") {
      iserror = true;
      errors.nameError = "Debe de ingresar el numero del producto";
    } else if (this.state.stock === "" || this.state.stock < 0) {
      iserror = true;
      errors.stockError = "Ingrese una cantidad correcta de productos";
    } else if (
      this.state.purchase_price === "" ||
      this.state.purchase_price < 0
    ) {
      iserror = true;
      errors.purchashe_price_Error =
        "Ingrese una cantidad correcta de precio de compra";
    } else if (this.state.sale_price === "" || this.state.sale_price < 0) {
      iserror = true;
      errors.sale_price_Error =
        "Ingrese una cantidad correcta de precio de venta";
    } else if (this.props.loading) {
      const obj = this.props.products;
      var id;
      obj.map(pro => {
        return (id = pro.id_product);
      });
      if (id !== undefined) {
        iserror = true;
        errors.barcode_error =
          "Lo sentimos este codigo ya esta asignado a un producto PRESIONE NUEVAMENTE PARA GENERAR OTRO";
      }
    } else if (this.state.varcode === "") {
      iserror = true;
      errors.barcode_error = "Debe de generar un codigo de barras";
    } else if (
      this.state.selectedCategory === "" ||
      this.state.selectedCategory === null
    ) {
      iserror = true;
      errors.category_Error = "Debe escoger una Categoria para el producto";
    } else if (
      this.state.selectedBrand === "" ||
      this.state.selectedBrand === null
    ) {
      iserror = true;
      errors.brand_error = "Debe de escoger una Marca para el producto";
    }

    this.setState({
      ...errors
    });
    return iserror;
  };

  onSubmit = e => {
    e.preventDefault();
    //console.log(this.state.varcode);
    const err = this.validate();
    if (!err) {
      const {
        name,
        stock,
        purchase_price,
        sale_price,
        description,
        varcode,
        selectedCategory,
        selectedBrand,
        barcode_generate
      } = this.state;

      const product = {
        name,
        purchase_price,
        sale_price,
        description,
        stock,
        varcode,
        selectedCategory,
        selectedBrand,
        barcode_generate
      };
      this.props.addproduct(product);

      var imgData = this.refs.canvas.toDataURL("image/png", 1.0); // el 1 es para que salga HD
      var pdf = new jsPDF();
      for (let i = 10; i <= 250; i = i + 25) {
        for (let j = 10; j <= 150; j = j + 80) {
          pdf.addImage(imgData, "PNG", j, i);
        }
      }
      pdf.save("download.pdf");

      this.setState({
        name: "",
        purchase_price: "",
        sale_price: "",
        description: "",
        stock: "",
        varcode: "",
        selectedCategory: "",
        selectedBrand: "",
        redirect: true
      });
    }
  };

  Random() {
    var min = 1000000000;
    var max = 9999999999;
    const rand = parseInt(min + Math.random() * (max - min));
    var codigo = "g" + rand;
    return codigo;
  }

  onGenerateCode = e => {
    e.preventDefault();
    const varcode = this.Random();
    //const varcode = "b50240035401";
    this.props.getVarcodeProduct(varcode);
    this.setState({ varcode, load: false });
    const canvas = this.refs.canvas;
    JsBarcode(canvas, /*"AH" +*/ varcode, {
      width: 2,
      height: 50,
      fontSize: 10
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/products" result={"inventario"} />;
    }
    var {
      name,
      stock,
      purchase_price,
      sale_price,
      description,
      varcode
    } = this.state;

    return (
      <React.Fragment>
        <script src="../scripts/JsBarcode.all.min.js"></script>
        <div className="row pt-5">
          <div className="col text-center text-uppercase">
            <h4 className="text-center text-primary">
              Ingreso de Productos Sin Codigo de Barras
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="alert alert-success" role="alert">
              Para los productos que no poseen código de barras se generara un
              código único que podrá imprimir, asegúrese de que al producto no
              se le haya generado un código anteriormente para que no se le
              genere uno nuevo, puede hacer la búsqueda en la sección de
              inventarios
            </div>
          </div>
        </div>
        <div className="card card-body mt-4 mb-4">
          <form onSubmit={this.onSubmit} noValidate>
            <h4 className="text-center text-success">Nuevo Producto</h4>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Nombre de Producto</label>
                  <TextField
                    className="form-control"
                    type="text"
                    placeholder="Nombre de Producto"
                    name="name"
                    value={name}
                    onChange={this.onChange}
                    helperText={this.state.nameError}
                    error={this.state.nameError.length === 0 ? false : true}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Cantidad en Stock</label>
                  <TextField
                    className="form-control"
                    type="Number"
                    placeholder="cantidad de producto"
                    name="stock"
                    value={stock}
                    onChange={this.onChange}
                    helperText={this.state.stockError}
                    error={this.state.stockError.length === 0 ? false : true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Precion de Compra</label>
                  <TextField
                    className="form-control"
                    type="Number"
                    placeholder="Precio de Compra"
                    name="purchase_price"
                    onChange={this.onChange}
                    value={purchase_price}
                    helperText={this.state.purchashe_price_Error}
                    error={
                      this.state.purchashe_price_Error.length === 0
                        ? false
                        : true
                    }
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Precion de Venta</label>
                  <TextField
                    className="form-control"
                    type="Number"
                    placeholder="Precio de Venta"
                    name="sale_price"
                    onChange={this.onChange}
                    value={sale_price}
                    helperText={this.state.sale_price_Error}
                    error={
                      this.state.sale_price_Error.length === 0 ? false : true
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <canvas ref="canvas" width="200" height="50" />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Codigo de Barras</label>
                  <input
                    className="form-control text-light bg-dark"
                    type="text"
                    id="barcode"
                    value={varcode}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Genere el codigo de barras</label>
                <button
                  type="button"
                  className="btn btn-success btn-block"
                  onClick={this.onGenerateCode}
                >
                  Generar Codigo
                </button>
                <small className="alert-danger">
                  {this.state.barcode_error}
                </small>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Categoria</label>

                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={{
                      label: "Seleccciona la Categoria",
                      value: 0
                    }}
                    onChange={this.handleChangeCat}
                    options={this.props.categories.map(category => ({
                      value: category.id,
                      label: category.name
                    }))}
                    name="category"
                  />
                  <small className="alert-danger">
                    {this.state.category_Error}
                  </small>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Marca</label>
                  <Select
                    defaultValue={{ label: "Seleccciona la Marca", value: 0 }}
                    onChange={this.handleChangeBrand}
                    options={this.props.brands.map(brand => ({
                      value: brand.id,
                      label: brand.name
                    }))}
                    name="brand"
                  />
                  <small className="alert-danger">
                    {this.state.brand_error}
                  </small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label>Descripcion</label>
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="Descripcion"
                    name="description"
                    value={description}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Agregar Producto
            </button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.Categories.categories,
  brands: state.Brands.brands,
  //products: state.Products,
  products: state.FindProduct.products,
  loading: state.FindProduct.loading
});

export default connect(
  mapStateToProps,
  { getCategories, getBrands, addproduct, getVarcodeProduct }
)(ProductsWithoutBarcode);
