import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/ActAuths";
import hola from "../../assets/icons/login.svg";

export class Login extends Component {
  state = {
    username: "",
    password: "",
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    loader: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }
    const { username, password } = this.state;
    return (
      <div className="container" style={{ height: "100vh" }}>
        <div className="row align-items-center h-100">
          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
            <div className="card card-body pt-5">
              <img
                src={hola}
                alt=""
                style={{
                  width: "50px",
                  position: "absolute",
                  top: "2%",
                  left: " 2%",
                  zIndex: "1",
                }}
              />
              <h2 className="text-center">Inicia Sesión</h2>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    onChange={this.onChange}
                    value={username}
                  />
                </div>

                <div className="form-group">
                  <label>Número de telefono</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    onChange={this.onChange}
                    value={password}
                  />
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block">
                    Ingresar
                  </button>
                </div>
                {/* <p className="text-center">
                  ¿No tienes una cuenta? <Link to="/register">Registrate</Link>
                </p> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.RedAuth.isAuthenticated,
  loader: state.RedAuth.isLoading,
});
export default connect(mapStateToProps, { login })(Login);
