import React, { Component } from "react";
import ApointmentCardList from "../common/ApointmentCard";
import { connect } from "react-redux";
import { getApointments } from "../../actions/ActApointments";
import { Link } from "react-router-dom";
import "./styles/Client_Apointments.css";
import PropTypes from "prop-types";
import Loading from "../common/Loading";

class Client_Apointments extends Component {
  static propTypes = {
    getApointments: PropTypes.func.isRequired,
    apointments: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
  };

  componentDidMount() {
    this.props.getApointments();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row ">
            <div className="col">
              <p className="text-center text-primary mb-1 p-5">
                Citas agendadas
              </p>
            </div>
          </div>
          <Link className="btn btn-warning btn-block mt-5" to="/decide">
            ¡Agenda una cita!
          </Link>
          {this.props.loading ? (
            <Loading />
          ) : (
            <ApointmentCardList apointments={this.props.apointments} />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  apointments: state.RedApoint.apointments,
  loading: state.RedApoint.loading
});

export default connect(mapStateToProps, { getApointments })(Client_Apointments);
