import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  GET_BRANDS,
  DELETE_BRANDS,
  ADD_BRANDS,
  UPDATE_BRANDS,
  __BASE__URL
} from "./types";
import { tokenConfig } from "./ActAuths";

//GET BRANDS
export const getBrands = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/brands/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_BRANDS,
        payload: res.data
      });
    })
    .catch(
      err => dispatch(returnErrors(err?.response?.data, err?.response?.data))
      //console.log('error')
    );
};

//ADD BRAND
export const addBrand = brand => (dispatch, getState) => {
  const url = __BASE__URL + "/api/brands/";
  axios
    .post(url, brand, tokenConfig(getState))
    .then(res => {
      dispatch(createMessage({ addBrand: "Marca Agregada con Exito!" }));
      dispatch({
        type: ADD_BRANDS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const deleteBrand = id => (dispatch, getState) => {
  const url = __BASE__URL + "/api/brands/";
  axios
    .delete(url + id, tokenConfig(getState))
    .then(res => {
      //mensahe
      dispatch(createMessage({ deleteBrand: "Marca Eliminada con Exito!" }));
      dispatch({
        type: DELETE_BRANDS,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const updateBrand = (id, name, description) => (dispatch, getState) => {
  const body = JSON.stringify({
    id,
    name,
    description
  });
  //console.log(body);

  const url = __BASE__URL + `/api/brands/updateBrand/${id}/`;
  try {
    axios
      .put(url, body, tokenConfig(getState))
      .then(res => {
        dispatch(
          createMessage({ updatebrand: "Marca Actualizada con exito!" })
        );
        dispatch({
          type: UPDATE_BRANDS,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};
