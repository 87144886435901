/*Components */
import Login from "../accounts/Login";
import Register from "../accounts/Register";

import {
  /*HashRouter as Router,*/
  Route,
  Switch,
  // Redirect,
  /*Redirect*/
} from "react-router-dom";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
// import Route from "../common/Route";
import PropTypes from "prop-types";
/*Dashboards */
import Clients_Dashboard from "../clients/Clients_Dashboard";

import Secretary_Dashboard from "../ClinicalWorker/Secretary_Dashboard";
/*import Admin_Dashboard from "../ClinicalWorker/AdminDashboard";*/

import PetsManagment from "../clients/PetsManagment";
import DateManagement from "../clients/DateManagement";
import GroomingDateManagement from "../clients/GroomingDateManagment";
import ApointmentCard from "./ApointmentCard";
import Client_Apointments from "../clients/Client_Apointments";
import ClientsPage from "../ClinicalWorker/ClientsPage";
import SecretaryPets from "../ClinicalWorker/SecretaryPets";
import Products from "../inventory/Products";
import Categories from "../inventory/Categories";
import Sales from "../sales/Sales";
import ReportsSales from "../Reports/ReportsSales";
import Brands from "../inventory/Brands";
import AddProduct from "../inventory/Products/AddProduct";
import ShowInventory from "../inventory/Products/ShowInventory";
import CommissionDoctor from "../Commissions/CommissionDoctor";
import AdminCommission from "../Commissions/AdminCommission/AdminCommission";
import ShowSalesAdmin from "../sales/AllSales/ShowSalesAdmin";
import Refund from "../Refund/Refund";
import ShowRefundAdmin from "../Refund/AllRefund/ShowRefundAdmin";

import WichApointment from "./WichApointment";
import NewClientsPage from "../ClinicalWorker/NewClientsPage";
import ApointmentsPage from "../ClinicalWorker/ApointmentsPage";

import GroomAppointment from "../ClinicalWorker/GroomAppointment";
import AttendingGroomAppointment from "../ClinicalWorker/AttendingGroomAppointment";
import AttendingClinicAppointment from "../ClinicalWorker/AttendingClinicAppointment";
import DeliverPage from "./DeliverPage";
import History from "./History";
import ClinicAppointmentHistory from "./ClinicAppointmentHistory";
import PetHistoryPage from "./PetHistoryPage";
import UserDashboard from "../ClinicalWorker/UserDashboard";
import VacunacionYDesparacitacion from "../ClinicalWorker/VacunacionYDesparacitacion";
import AllPetsManagement from "./AllPetsManagement";
import PromotionsPage from "./PromotionsPage";

//import ReportsLayout from "../Reports/ReportsLayout";
class Paths extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    /*returnComponent: PropTypes.func.isRequired*/
  };

  render() {
    var DecideComponent;
    if (this.props.auth.rol === "cliente") {
      DecideComponent = Clients_Dashboard;
    } else if (this.props.auth.rol === "veterinario") {
      DecideComponent = UserDashboard;
    } else if (this.props.auth.rol === "groomista") {
      DecideComponent = UserDashboard;
    } else if (this.props.auth.rol === "secretaria") {
      DecideComponent = Secretary_Dashboard;
    } else if (this.props.auth.rol === "administradora") {
      /*DecideComponent = Admin_Dashboard;*/
      DecideComponent = Secretary_Dashboard;
      /*DecideComponent = PromotionsPage;*/
    } else {
      console.log("Don't do anything weird");
      DecideComponent = Login;
    }

    try {
      return (
        <Fragment>
          <Switch>
            <Route exact path="/dashboard" component={DecideComponent} />
            <Route exact path="/" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/apointment-card" component={ApointmentCard} />
            <Route
              exact
              path="/grooming/appointment"
              component={AttendingGroomAppointment}
            ></Route>
            <Route
              exact
              path="/clinic/appointment"
              component={AttendingClinicAppointment}
            ></Route>
            <Route
              exact
              path="/vacydesp"
              component={VacunacionYDesparacitacion}
            ></Route>
            <Route exact path="/petdelivery" component={DeliverPage}></Route>
            <Route exact path="/pethistory" component={PetHistoryPage}></Route>
            <Route exact path="/history" component={History}></Route>
            <Route exact path="/promociones" component={PromotionsPage}></Route>
            <Route
              exact
              path="/chistory"
              component={ClinicAppointmentHistory}
            ></Route>
            <Route
              exact
              path="/secretary/groomingdatemanagement"
              component={GroomAppointment}
            ></Route>
            <Route
              exact
              path="/secretary/clinicaldatemanagement"
              component={ApointmentsPage}
            ></Route>
            <Route
              exact
              path="/secretary/clients"
              component={ClientsPage}
            ></Route>
            <Route
              exact
              path="/secretary/new/client"
              component={NewClientsPage}
            ></Route>
            <Route
              exact
              path="/secretary/apointments"
              component={ApointmentsPage}
            ></Route>
            <Route exact path="/secretary/pets" component={SecretaryPets}></Route>
            <Route exact path="/client/petmanagment" component={PetsManagment} />
            <Route
              exact
              path="/client/petmanagment/:petId"
              component={PetsManagment}
            />
            <Route exact path="/allpets" component={AllPetsManagement} />
            <Route
              exact
              path="/client/clinicaldatemanagement"
              component={DateManagement}
            />
            <Route
              exact
              path="/client/groomingdatemanagement"
              component={GroomingDateManagement}
            />
  
            <Route exact path="/decide" component={WichApointment} />
  
            <Route exact path="/client/dates" component={Client_Apointments} />
  
            <Route exact path="/products" component={Products} />
            <Route exact path="/products/categories" component={Categories} />
            <Route exact path="/sales" component={Sales} />
            <Route exact path="/sales/reports" component={ReportsSales} />
            <Route exact path="/products/brands" component={Brands} />
            <Route exact path="/products/addProduct" component={AddProduct} />
            <Route exact path="/products/addProduct" component={AddProduct} />
            <Route exact path="/products/inventory" component={ShowInventory} />
            <Route exact path="/commissions" component={CommissionDoctor} />
            <Route
              exact
              path="/AdministrarComisiones"
              component={AdminCommission}
            />
            <Route exact path="/SalesAdmin" component={ShowSalesAdmin} />
            <Route exact path="/refunds" component={Refund} />
            <Route exact path="/refundsAdmin" component={ShowRefundAdmin} />
          </Switch>
        </Fragment>
      ); 
    } catch (error) {
      console.log(error);
      return (
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-conent-center align-items-center">
              <h1 className="text-center">Error</h1>
              <h2 className="text-center">
                Comparta esta captura de pantalla al encargado del sistema y{" "}
                <strong>recargue la página</strong>
              </h2>
              <small>{JSON.stringify(error)}</small>
            </div>
          </div>
        </div>
      ); 
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.RedAuth,
});
export default connect(mapStateToProps, {})(Paths);
