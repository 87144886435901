import {
  ADD_COMMISSION,
  GET_COMMISSION,
  COMMISSION_LOADED,
  TOTAL_BASIC_COMMISSION,
  GET_SURGERY_COMMISSION,
  TOTAL_SURGERY_COMMISSION,
  GET_OTHER_COMMISSION,
  TOTAL_OTHER_COMMISION,
  GET_PHARMACY_COMMISSION,
  GET_TOTAL_PHARMACY_COMMISSION,
  UPDATE_COMMISSION,
  DEACTIVATE_COMMISSION
} from "../actions/types";

const initialState = {
  commisions: [],
  totalBasic: {},
  loading: false,
  loadingBasic: false,

  commisionsSur: [],
  totalSurgery: {},
  loadingComSur: false,
  loadingtotalS: false,

  commissionOther: [],
  totalOther: {},
  loadingComOther: false,
  loadingtotalOther: false,

  commissionPhar: [],
  totalPharm: {},
  loadingPharm: false,
  loadingtotalPharm: false,

  deactivateCom:[],
  loadingDeactivate:false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_COMMISSION:
      return {
        ...state
      };
    case GET_COMMISSION:
      return {
        ...state,
        commisions: action.payload,
        loading: true
      };
    case TOTAL_BASIC_COMMISSION:
      return {
        ...state,
        totalBasic: action.payload,
        loadingBasic: true
      };
    case COMMISSION_LOADED:
      return {
        ...state,
        loading: false,
        loadingBasic: false,
        loadingComSur: false,
        loadingtotalS: false,
        loadingComOther: false,
        loadingtotalOther: false,
        loadingPharm: false,
        loadingtotalPharm: false,
        loadingDeactivate:false
      };
    case GET_SURGERY_COMMISSION:
      return {
        ...state,
        commisionsSur: action.payload,
        loadingComSur: true
      };
    case TOTAL_SURGERY_COMMISSION:
      return {
        ...state,
        totalSurgery: action.payload,
        loadingtotalS: true
      };
    case GET_OTHER_COMMISSION:
      return {
        ...state,
        commissionOther: action.payload,
        loadingComOther: true
      };
    case TOTAL_OTHER_COMMISION:
      return {
        ...state,
        totalOther: action.payload,
        loadingtotalOther: true
      };
    case GET_PHARMACY_COMMISSION:
      return {
        ...state,
        commissionPhar: action.payload,
        loadingPharm: true
      };
    case GET_TOTAL_PHARMACY_COMMISSION: 
      return {
        ...state,
        totalPharm: action.payload,
        loadingtotalPharm: true
      };
    case UPDATE_COMMISSION:
      return{
        ...state
      }  
    case DEACTIVATE_COMMISSION:
      return{
        ...state,
        deactivateCom:action.payload,
        loadingDeactivate:true
      }
    default:
      return state;
  }
}
