import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import dogOnWindow from "../../assets/icons/dogOnWindow.svg";
import MaterialTable from "material-table";
import SignatureCanvas from "react-signature-canvas";
import { getOnDeliveryAppointment } from "../../actions/ActApointments";
import { getGroomFile, updatePictures } from "../../actions/ActGroomfile";
import Loading from "./Loading";
import { archiveAppointment } from "../../actions/ActApointments";
import { Redirect } from "react-router-dom";
import { cleanFile } from "../../actions/ActClinicfile";
import "./styles/DeliverPage.css";
import { uid } from 'uid';

class DeliverPage extends Component {
  state = {
    selectedPetTodeliver: null,
    petDelivered: false,
    parsedDict: { dataArray: [] },
  };
  static propTypes = {
    getOnDeliveryAppointment: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    getGroomFile: PropTypes.func.isRequired,
    updatePictures: PropTypes.func.isRequired,
    apointments: PropTypes.array.isRequired,
    archiveAppointment: PropTypes.func.isRequired,
    cleanFile: PropTypes.func.isRequired,
    user: PropTypes.object,
    file: PropTypes.object,
  };

  componentDidMount() {
    try {
      this.props.getOnDeliveryAppointment();
      this.props.cleanFile();
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(prevProps) {
    if(!this.props.apointments) return null;
    if (this?.props?.apointments !== prevProps.apointments) {
      this.setState({
        parsedDict: {
          dataArray: this?.props?.apointments.map(
            ({
              id,
              date,
              time,
              cliente: { first_name: clientName },
              cliente: { username: clientEmail },
              cliente: { id: clientId },
              pet: { name: petName },
              doctor: { first_name: attendantName },
            }) => ({
              apointmentId: id,
              clientId,
              apointmentDate: date,
              apointmentTime: time,
              clientName,
              pacientName: petName,
              whoAttends: attendantName,
              email: clientEmail,
            })
          ),
        },
      });
    }
  }

  descriptionDictionary = (key) => {
    if(typeof key !== "string") return null;
    const dict = {
      nudos: "Nudos",
      pielirritada: "Piel irritada",
      heridas: "Heridas",
      raspones: "Raspones",
      pelomaltratado: "Pelo maltratado",
      pielypelosucio: "Piel y pelo sucio",
      pelohumedo: "Pelo húmedo",
      unaslargas: "Uñas largas",
      unasencarnadas: "Uñas encarnadas",
      pulgas: "Pulgas",
      garrapatas: "Garrapatas",
      sarrodental: "Sarro dental",
      acaros: "Ácaros",
      dientesflojos: "Dientes flojos",
      ptialistmo: "Ptialismo",
      hematomaad: "Hematoma en el oido derecho",
      hematomaai: "Hematoma en el oido izquierdo",
      otitis: "Otitis",
      conjuntivitis: "Conjuntivitis",
      odi: "Ojo derecho irritado",
      oii: "Ojo izquierdo irritado",
      ode: "Ojo derecho edematizado",
      oie: "Ojo izquierdo edematizado",
      odh: "Ojo derecho con hematoma",
      oih: "Ojo izquierdo con hematoma",
      pols: "Pelo oxidado por lagrima seca",
      ehc: "Edemas o hematomas en el cuerpo",
      scmv: "Solicitar cita con médico veterinario",
      cfpcend:
        "Corte frecuente de pelo y cepillado para evitar nudos y dermatitis",
      apga: "Administrar pulguicidas, garrapaticidas y acaricidas",
      ld: "Limpieza dental",
      cfuepieu: "Corte frecuente de uñas para evitar problemas infecciosos por encarnación de uñas",
      dmjepd: "Dar a su mascota juguetes especiales para limpiar los dientes",
      dtco: "Dar tratamiento contra la otitis",
      dtceo: "Dar tratamiento contra edema ocular",
      laole: "Limpie en el aréa de los ojos con un líquido especial.",
      dtchaoc: "Dar tratamiento contra hematomas; auriculares, oculares y coporales.",
    };
    try {
      return dict[key]; 
    } catch (error) {
      return `No se encontró descripción para ${key}`;
    }
  };

  ListItem = ({ children }) => {
    return <li>{children}</li>;
  };

  FileCheckList = ({ items, className, title }) => {
    if(!this.props.file) return null;
    // check if the file has valid values for the keys listed in items array
    // const valid = items.every((item) => this.props.file[item]);
    // { "heridas": false }
    // is valid only if there is at least one true value
    console.log('items', this.props.file);
    const valid = items.some((item) => this.props.file[item] === true);
    // if valid is true, then the file has all the required keys
    if (!valid)
      return (
        <div className={className}>
          <strong>{title}: No se registro información</strong>
        </div>
      );

    return (
      <div className={className}>
        <strong className="custom-success-badge">{title}</strong>
        {items.map((item) => {
          if (!this.props.file[item]) return null;
          return (
            <li key={uid()} className="d-flex justify-content-between">
              <span>{this.descriptionDictionary(item)}</span>
            </li>
            // <this.ListItem key={uid()}>
            //   {this.descriptionDictionary(item)}
            // </this.ListItem>
          );
        })}
      </div>
    );
  };

  ImageRendered = ({ src }) => {
    if (!src) {
      return (
        <img
          src={dogOnWindow}
          alt="Foto procesada"
          className="text-center"
          style={{ maxWidth: "25rem" }}
        />
      );
    }
    return (
      <img
        src={src}
        alt="Foto procesada"
        className="text-center"
        style={{ maxWidth: "25rem" }}
      />
    );
  };

  handleClick = (e) => {
    e.preventDefault();

    var canvas = document.getElementById("CanvasSignature");
    var image = new Image();
    image.src = canvas.toDataURL("image/png");
    console.log(image.src);

    var userSign = new FormData();
    userSign.append("userSign", image.src);

    this.props.updatePictures({ userSign: `${image.src}` }, this?.props?.file?.id);

    this.props.archiveAppointment(
      { status: "ENTREGADA" },
      this?.props?.file?.appointment
    );
    // // on 3000ms reload the page
    setTimeout(
      function () {
        this.props.getOnDeliveryAppointment();
        window.location.reload();
      }.bind(this),
      3000
    );
  };

  handleGetGroomFile(appointmentId){
    try {
      this.props.getGroomFile(appointmentId);
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    // const { parsedDict } = this.state;
    if(!this?.state?.parsedDict) return null;
    const parsedDict = this?.state?.parsedDict;
    if (this?.state?.petDelivered) {
      this.props.cleanFile();
      return <Redirect to="/dashboard"></Redirect>;
    }

    return (
      <Fragment>
        {this.props.loading ? <Loading></Loading> : <React.Fragment />}
        <div className="container-fluid pet-delivery-status print-container">
          <div className="row">
            <div className="col">
              <div
                className="row"
                style={{
                  color: "black",
                  backgroundColor: "rgb(255, 145, 77)",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2em",
                  marginBottom: "2em",
                }}
              >
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center print-title">
                  <h1>Entrega de mascotas</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 h-100">
                  <div
                    className="row h-100"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: "1rem",
                    }}
                  >
                    <MaterialTable
                      localization={{
                        toolbar: {
                          searchTooltip: "Buscar",
                          searchPlaceholder: "Cliente, Mascota",
                        },
                        body: {
                          emptyDataSourceMessage:
                            "No hay mascotas para entregar",
                        },
                        header: {
                          actions: "Acciones",
                        },
                      }}
                      options={{
                        defaultExpanded: ()=>{},
                        pageSize: 20,
                        headerStyle: {
                          backgroundColor: "#f39c12",
                          color: "white",
                          paddingLeft: "20px",
                        },
                        exportButton: false,
                        actionsColumnIndex: -1,
                        detailPanelColumnAlignment: "right",
                      }}
                      columns={[
                        {
                          title: "Cliente",
                          field: "clientName",
                        },
                        {
                          title: "Paciente",
                          field: "pacientName",
                        },
                      ]}
                      data={parsedDict?.dataArray}
                      title="Mascotas alojadas"
                      actions={[
                        {
                          icon: "add",
                          tooltip: "Mostrar información",
                          onClick: (event, rowData) => {
                            event.preventDefault();
                            this.handleGetGroomFile(rowData.apointmentId);
                          },
                        },
                      ]}
                      onRowClick={(event, rowData) => {
                        event.preventDefault();
                        this.handleGetGroomFile(rowData.apointmentId);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 print-content">
                  <strong className="text-primary text-center">
                    <h3>Resumen de la cita</h3>
                  </strong>
                  {this.props.file ? (
                    <React.Fragment>
                      <div className="row justify-content-start align-items-start">
                        <div className="col-12">
                          <div className="row mb-3">
                            <div className="col-2">
                              <button
                                onClick={() => {
                                  window.print();
                                }}
                                className="print-btn btn btn-danger"
                              >
                                {" "}
                                Imprimir{" "}
                              </button>
                            </div>
                            <div className="col-10">
                              <small className="text-muted">
                                Imprime el documento si deseas registrar una
                                copia fisica de la entrega de este servicio, en
                                caso de no ser necesario, puedes omitir esa
                                acción.
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row justify-content-center align-items-center ">
                            <this.FileCheckList
                              title="Hallazgos durante la cita"
                              items={[
                                "nudos",
                                "pielirritada",
                                "heridas",
                                "raspones",
                                "pelomaltratado",
                                "pielypelosucio",
                                "pelohumedo",
                                "unaslargas",
                              ]}
                              className="col-lg-12 col-md-12 col-sm-6 mt-3 info-printable-card"
                            />
                            <this.FileCheckList
                              title="Acciones recomendadas"
                              items={[
                                "scmv",
                                "cfpcend",
                                "apga",
                                "ld",
                                "cfuepieu",
                                "dmjepd",
                                "dtco",
                                "dtceo",
                                "laole",
                                "dtchaoc",
                              ]}
                              className="col-lg-12 col-md-12 col-sm-6 mt-3 info-printable-card"
                            />
                            <div className="col-lg-12 col-md-12 col-sm-6 mt-3 info-printable-card">
                              <strong className="custom-success-badge">
                                Observaciones
                              </strong>
                              <p>
                                {this?.props?.file?.observations ||
                                  "No se registraron observaciones"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-start align-items-start">
                        <div
                          className="col"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <strong>Foto tomada antes del servicio: </strong>
                          <this.ImageRendered src={this?.props?.file?.before} />
                        </div>
                        <div
                          className="col"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <strong>Foto tomada despues del servicio: </strong>
                          <this.ImageRendered src={this?.props?.file?.after} />
                        </div>
                      </div>
                      <div
                        className="row align-items-center justify-content-center"
                        style={{
                          flexDirection: "column",
                          paddingBottom: "2rem",
                        }}
                      >
                        <div
                          className="col-10"
                          style={{
                            overflow: "hidden",
                            margin: "0px",
                            padding: "0px",
                            border: "5px solid black",
                          }}
                        >
                          <SignatureCanvas
                            penColor="#2c3e50"
                            backgroundColor="white"
                            canvasProps={{
                              width: 1000,
                              height: 200,
                              className: "sigCanvas",
                              id: "CanvasSignature",
                            }}
                          />
                        </div>
                        <p>
                          <i className="fas fa-signature text-danger"></i>Firma
                          cliente
                        </p>
                        <p>
                          Al firmar afirmo que verifique el estado y condiciones
                          de mi mascota y la recibi en buenas condiciones.
                        </p>
                        <button
                          className="btn btn-success text-dark"
                          onClick={this.handleClick}
                        >
                          Recibi conforme
                        </button>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                      <strong>
                        Selecciona una ficha para realizar la entrega
                      </strong>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  apointments: state.RedApoint.apointments,
  loading: state.RedApoint.loading,
  user: state.RedAuth.user,
  file: state.History.file,
});

export default connect(mapStateToProps, {
  getOnDeliveryAppointment,
  archiveAppointment,
  getGroomFile,
  cleanFile,
  updatePictures,
})(DeliverPage);
