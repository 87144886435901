
import {
  GET_BRANDS,
  DELETE_BRANDS,
  UPDATE_BRANDS,
  ADD_BRANDS
} from "../actions/types";

const initialState = {
  brands: [],
  loading: false

};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_BRANDS:
      return {
        ...state,

        brands: action.payload,
        loading: false
      };
    case ADD_BRANDS:
      return {
        ...state,
        brands: [...state.brands, action.payload]
      };
    case DELETE_BRANDS:
      return {
        ...state,
        brands: state.brands.filter(brand => brand.id !== action.payload)
      };

    case UPDATE_BRANDS:
      return {
        ...state,
        brands: [...state.brands],
        loading: true
      };

    default:
      return state;
  }
}
