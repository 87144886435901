import { combineReducers } from "redux";
import RedErrors from "./ErrorsReducer";
import RedMessages from "./MessagesReducer";
import RedAuth from "./AuthReducers.js";
import Profile from "./ProfileReducer";
import RedPets from "./PetsReducer";
import RedVets from "./VetsReducers";
import RedClients from "./ClientsReducer";
import RedGroomers from "./GroomsReducers";
import RedApoint from "./ApointmentReducers";

import Brands from "./BrandsReducers";
import Categories from "./CategoriesReducers";
import Products from "./ProductsReducers";
import FindProduct from "./FindProductReducers";
import Sales from "./SalesReducers";
import Reports from "./ReportReducers";
import History from "./HistoryReducers";
import Commission from "./CommissionReducers";
import CommissionRank from "./CommissionRankReducer";
import RedRecords from "./ControlsReducer";
import Refund from "./RefundReducer";
export default combineReducers({
  RedAuth,
  Profile,
  RedClients,
  RedPets,
  RedVets,
  RedGroomers,
  RedApoint,
  RedErrors,
  RedMessages,
  Brands,
  Categories,
  Products,
  FindProduct,
  Sales,
  Reports,
  History,
  Commission,
  CommissionRank,
  RedRecords,
  Refund
});
