import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/ActAuths";
import { Link } from "react-router-dom";
import "./style.css";
import "./script";
import MainMenuLogo from "../../assets/mainMenuLogo.png";
import PropTypes from "prop-types";

class Sidebar extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;

    const guestLinks = (
      <React.Fragment>
        <li>
          <Link to="/">
            <i className="fas fa-sign-in-alt"></i>
            Login
          </Link>
        </li>
        <li>
          <Link to="/register">
            <i className="fas fa-user-plus"></i>
            Register
          </Link>
        </li>
        <li>
          <a href="http://www.animalhomegt.com">
            <i className="fa fa-fw fa-home"></i>
            Animal Home Website
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/Cl%C3%ADnica-Veterinaria-Animal-Home-187855935252270/">
            <i className="fab fa-facebook-square"></i> Facebook
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/animal_homegt/">
            <i className="fab fa-instagram"></i> Instagram
          </a>
        </li>
      </React.Fragment>
    );

    const ClientLinks = (
      <React.Fragment>
        <li>
          <Link to="/">
            <i className="fa fa-fw fa-home"></i> Home
          </Link>
        </li>
        <li>
          <Link to="/client/petmanagment">
            <i className="fas fa-dog"></i> Mascotas
          </Link>
        </li>
        <li>
          <Link to="/client/dates">
            <i className="far fa-calendar-alt"></i> Citas agendadas
          </Link>
        </li>
        <li>
          <Link to="/decide">
            <i className="far fa-clock"></i> Solicitar cita
          </Link>
        </li>
        <li>
          <Link to="/" onClick={this.props.logout}>
            <i className="fas fa-sign-out-alt"></i> Salir
          </Link>
        </li>
        <li>
          <a href="https://www.facebook.com/Cl%C3%ADnica-Veterinaria-Animal-Home-187855935252270/">
            <i className="fab fa-facebook-square"></i> Facebook
          </a>
        </li>

        <li>
          <a href="https://www.instagram.com/animal_homegt/">
            <i className="fab fa-instagram"></i> Instagram
          </a>
        </li>
      </React.Fragment>
    );

    const VetLinks = (
      <React.Fragment>
        <li>
          <Link to="/">
            <i className="fa fa-fw fa-home"></i> Home
          </Link>
        </li>
        <li>
          <Link to="/decide">
            <i className="far fa-calendar-check"></i> Agendar Cita
          </Link>
        </li>
        <li>
          <Link to="/petdelivery">
            <i className="fas fa-paw"></i> Entregar mascota
          </Link>
        </li>
        <li>
          <Link to="/vacydesp">
            <i className="far fa-clock"></i> Vacunación y Desparasitación
          </Link>
        </li>
        <li>
          <Link to="/chistory">
            <i className="fas fa-book"></i> Historial de citas
          </Link>
        </li>
        <li>
          <Link to="/commissions">
            <i className="fas fa-hand-holding-usd"></i> Mis Comisiones
          </Link>
        </li>

        <li>
          <Link to="/pethistory">
            <i className="fas fa-book"></i> Historial clinico
          </Link>
        </li>
        <li>
          <Link to="/secretary/new/client">
            <i className="fas fa-user-plus"></i> Nuevo cliente
          </Link>
        </li>
        <li>
          <Link to="/secretary/clients">
            <i className="fas fa-user-check"></i> Clientes
          </Link>
        </li>
        <li>
          <Link to="/allpets">
            <i className="fas fa-paw"></i> Mascotas
          </Link>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1i644oFGIsj0IhZ6uJ62LpJ3GzizheKU8/view">
            Manual
          </a>
        </li>

        <li>
          <Link to="/" onClick={this.props.logout}>
            <i className="fas fa-sign-out-alt"></i> Salir
          </Link>
        </li>
      </React.Fragment>
    );

    const SecreLinks = (
      <React.Fragment>
        <li>
          <Link to="/">
            <i className="fa fa-fw fa-home"></i> Home
          </Link>
        </li>
        <li>
          <Link to="/secretary/new/client">
            <i className="fas fa-user-plus"></i> Nuevo cliente
          </Link>
        </li>
        <li>
          <Link to="/secretary/clients">
            <i className="fas fa-user-check"></i> Clientes
          </Link>
        </li>
        <li>
          <Link to="/petdelivery">
            <i className="fas fa-paw"></i> Entregar mascota
          </Link>
        </li>
        <li>
          <Link to="/history">
            <i className="fas fa-book"></i> Historial de citas
          </Link>
        </li>
        <li>
          <Link to="/decide">
            <i className="far fa-calendar-check"></i> Agendar Cita
          </Link>
        </li>
        <li>
          <Link to="/products">
            <i className="fas fa-bone"></i> Control de inventario
          </Link>
        </li>
        <li>
          <Link to="/sales">
            <i className="fas fa-shopping-cart"></i> Ventas
          </Link>
        </li>
        <li>
          <Link to="/refunds">
            <i className="fas fa-file-invoice-dollar"></i> Devoluciones
          </Link>
        </li>
        <li>
          <Link to="/sales/reports">
            <i className="fas fa-chart-line"></i> Reportes
          </Link>
        </li>
        <li>
          <Link to="/allpets">
            <i className="fas fa-paw"></i> Mascotas
          </Link>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1mEfaV_9UQgRV9Q0LMOXL-1nN8uAch81m/view">
            MANUAL
          </a>
        </li>
        <li>
          <Link to="/" onClick={this.props.logout}>
            <i className="fas fa-sign-out-alt"></i> Salir
          </Link>
        </li>
      </React.Fragment>
    );

    const AdminLinks = (
      <React.Fragment>
        <li>
          <Link to="/">
            <i className="fa fa-fw fa-home"></i> Home
          </Link>
        </li>
        <li>
          <Link to="/secretary/clients">
            <i className="fas fa-user-check"></i> Clientes
          </Link>
        </li>
        <li>
          <Link to="/secretary/new/client">
            <i className="fas fa-user-plus"></i> Nuevo cliente
          </Link>
        </li>
        <li>
          <Link to="/decide">
            <i className="far fa-calendar-check"></i> Agendar Cita
          </Link>
        </li>
        <li>
          <Link to="/products">
            <i className="fas fa-bone"></i> Control de inventario
          </Link>
        </li>
        <li>
          <Link to="/SalesAdmin">
            <i className="fas fa-shopping-cart"></i> Ventas
          </Link>
        </li>
        <li>
          <Link to="/refundsAdmin">
            <i className="fas fa-file-invoice-dollar"></i> Devoluciones
          </Link>
        </li>
        <li>
          <Link to="/sales/reports">
            <i className="fas fa-chart-line"></i> Reportes
          </Link>
        </li>
        <li>
          <Link to="/AdministrarComisiones">
            <i className="fas fa-hand-holding-usd"></i> Comisiones
          </Link>
        </li>
        <li className="bg-primary">
          <Link to="/promociones">
            <i class="fas fa-ad"></i> Promociones
          </Link>
        </li>
        <li>
          <Link to="/allpets">
            <i className="fas fa-paw"></i> Mascotas
          </Link>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1fr9bRTyRYzYvgwsHE2nO4DKW4jI2zhQF/view">
            MANUAL
          </a>
        </li>
        <li>
          <Link to="/" onClick={this.props.logout}>
            <i className="fas fa-sign-out-alt"></i> Salir
          </Link>
        </li>
      </React.Fragment>
    );

    const GroomLinks = (
      <React.Fragment>
        <li>
          <Link to="/">
            <i className="fa fa-fw fa-home"></i> Home
          </Link>
        </li>
        <li>
          <Link to="/secretary/clients">
            <i className="fas fa-user-check"></i> Clientes
          </Link>
        </li>
        <li>
          <Link to="/secretary/new/client">
            <i className="fas fa-user-plus"></i> Nuevo cliente
          </Link>
        </li>
        <li>
          <Link to="/decide">
            <i className="far fa-calendar-check"></i> Agendar Cita
          </Link>
        </li>
        <li>
          <Link to="/petdelivery">
            <i className="fas fa-paw"></i> Entregar mascota
          </Link>
        </li>
        <li>
          <Link to="/history">
            <i className="fas fa-book"></i> Historial de citas
          </Link>
        </li>
        <li>
          <Link to="/allpets">
            <i className="fas fa-paw"></i> Mascotas
          </Link>
        </li>
        <li>
          <Link to="/" onClick={this.props.logout}>
            <i className="fas fa-sign-out-alt"></i> Salir
          </Link>
        </li>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <div id="wrapper">
          <div className="overlay" data-toggle="offcanvas"></div>

          <nav
            className="navbar navbar-inverse navbar-fixed-top"
            id="sidebar-wrapper"
            role="navigation"
          >
            <ul className="nav sidebar-nav">
              <li className="sidebar-brand">
                <Link to="/dashboard">
                  <img
                    src={MainMenuLogo}
                    alt="main menu logo"
                    style={{ maxWidth: "100%" }}
                  />
                </Link>
              </li>
              {isAuthenticated
                ? user.last_name === "cliente"
                  ? ClientLinks
                  : user.last_name === "veterinario"
                  ? VetLinks
                  : user.last_name === "groomista"
                  ? GroomLinks
                  : user.last_name === "secretaria"
                  ? SecreLinks
                  : user.last_name === "administradora"
                  ? AdminLinks
                  : guestLinks
                : guestLinks}
            </ul>
          </nav>

          {/*<div id="page-content-wrapper">*/}
          <button
            type="button"
            className="hamburger is-closed"
            data-toggle="offcanvas"
          >
            <span>
              <i
                className="fas fa-bars"
                style={{ fontSize: "2rem", color: "#2c3e50" }}
              ></i>
            </span>
          </button>

          {/*// eslint-disable-next-line*/}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.RedAuth,
});
export default connect(mapStateToProps, { logout })(Sidebar);
