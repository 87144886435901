import React, { Component } from "react";
import { addCommission } from "../../../actions/Commissions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
export class NewCommissionOther extends Component {
  static propTypes = {
    addCommission: PropTypes.func.isRequired
  };
  state = {
    tipo: "",
    doctor: "",
    namecommission: "",
    precio: 0,
    commission_percentage: 0,
    description: "",
    total_commission: 0,
    size: "",
    type_of_anesthesia: "",
    total_anesthesia: 0,
    commission_percentage_anesthesia: 0,
    anesthesiaOptions: [
      { label: "Sin Anestesia", value: "" },
      { label: "Inhalada", value: "inhalada" },
      { label: "Inyectada", value: "inyectada" }
    ],
    precioError: "",
    commission_percentageError: "",
    total_anesthesiaError: "",
    commission_percentage_anesthesiaError: "",
    selectio_AnesthesiaError: "",
    isSearchable: false
  };

  validate = () => {
    let iserror = false;
    const errors = {
      precioError: "",
      commission_percentageError: "",
      total_anesthesiaError: "",
      commission_percentage_anesthesiaError: "",
      selectio_AnesthesiaError: ""
    };

    if (this.state.precio <= 0) {
      iserror = true;
      errors.precioError = "El Precio debe ser mayor a 0";
    }
    if (
      this.state.commission_percentage < 0 ||
      this.state.commission_percentage > 100
    ) {
      iserror = true;
      errors.commission_percentageError =
        "El porcentaje de comision tiene que estar entre 0% y 100%";
    }
    if (this.state.total_anesthesia < 0) {
      iserror = true;
      errors.total_anesthesiaError =
        "El precio de la anestecia debe ser mayor a o igual a 0";
    }
    if (
      this.state.commission_percentage_anesthesia < 0 ||
      this.state.commission_percentage_anesthesia > 100
    ) {
      iserror = true;
      errors.commission_percentage_anesthesiaError =
        "El porcentaje de comision tiene que estar entre 0% y 100%";
    }
    if (
      this.state.total_anesthesia !== 0 &&
      this.state.type_of_anesthesia === ""
    ) {
      iserror = true;
      errors.selectio_AnesthesiaError = "Debe seleccionar un tipo de Anestesia";
    }
    this.setState({
      ...errors
    });
    return iserror;
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    this.setState({
      tipo: this.props.tipo,
      doctor: this.props.doctor,
      namecommission: "otros"
    });
  }

  componentDidUpdate() {
    if (
      this.state.precio * (this.state.commission_percentage / 100) +
        this.state.total_anesthesia *
          (this.state.commission_percentage_anesthesia / 100) !==
      this.state.total_commission
    ) {
      this.setState({
        total_commission:
          this.state.precio * (this.state.commission_percentage / 100) +
          this.state.total_anesthesia *
            (this.state.commission_percentage_anesthesia / 100)
      });
    }
  }

  handleChangeAnestesia = e => {
    try {
      if (e.value === "") {
        this.setState({
          total_anesthesia: 0,
          commission_percentage_anesthesia: 0,
          type_of_anesthesia: e.value
        });
      } else if (e.value === "inhalada") {
        this.setState({
          total_anesthesia: 450,
          commission_percentage_anesthesia: 0,
          type_of_anesthesia: e.value
        });
      } else if (e.value === "inyectada") {
        this.setState({
          total_anesthesia: 250,
          commission_percentage_anesthesia: 40,
          type_of_anesthesia: e.value
        });
      }
    } catch (error) {}
  };

  onSubmit = e => {
    e.preventDefault();
    const err = this.validate();
    if (!err) {
      const {
        namecommission,
        tipo,
        precio,
        doctor,
        commission_percentage,
        description,
        total_commission,
        size,
        type_of_anesthesia,
        total_anesthesia,
        commission_percentage_anesthesia
      } = this.state;

      const comision = {
        namecommission,
        tipo,
        precio,
        doctor,
        commission_percentage,
        description,
        total_commission,
        size,
        type_of_anesthesia,
        total_anesthesia,
        commission_percentage_anesthesia
      };
      this.props.addCommission(comision);
      //console.log(comision);
      this.setState({
        //namecommission: "",
        precio: 0,
        commission_percentage: 0,
        description: "",
        total_commission: 0,
        size: "",
        type_of_anesthesia: "",
        total_anesthesia: 0,
        commission_percentage_anesthesia: 0
      });
    }
  };

  render() {
    const isSearchable = this.state.isSearchable;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row justify-content-center pt-3">
            <form onSubmit={this.onSubmit} noValidate>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>Precio del Servicio</label>
                    <Input
                      className="form-control"
                      type="Number"
                      placeholder="precio de consulta"
                      name="precio"
                      value={this.state.precio}
                      onChange={this.onChange}
                      error={this.state.precioError.length === 0 ? false : true}
                      startAdornment={
                        <InputAdornment position="start">Q</InputAdornment>
                      }
                    />
                    <FormHelperText error={true}>
                      {this.state.precioError}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>Porcentage de Comision %</label>
                    <Input
                      className="form-control"
                      type="Number"
                      placeholder="porcentage de comision"
                      name="commission_percentage"
                      value={this.state.commission_percentage}
                      onChange={this.onChange}
                      startAdornment={
                        <InputAdornment position="start">%</InputAdornment>
                      }
                      error={
                        this.state.commission_percentageError.length === 0
                          ? false
                          : true
                      }
                    />
                    <FormHelperText error={true}>
                      {this.state.commission_percentageError}
                    </FormHelperText>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5>Tipo de Anestesia</h5>
                  <Select
                    defaultValue={{
                      label: "Sin Anestesia",
                      value: ""
                    }}
                    options={this.state.anesthesiaOptions}
                    onChange={this.handleChangeAnestesia}
                    id="seleccionAnestesia"
                    isSearchable={isSearchable}
                  />
                  <FormHelperText error={true}>
                    {this.state.selectio_AnesthesiaError}
                  </FormHelperText>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>Total Anestesia</label>
                    <Input
                      className="form-control"
                      type="Number"
                      placeholder="total de anestesia"
                      name="total_anesthesia"
                      value={this.state.total_anesthesia}
                      onChange={this.onChange}
                      startAdornment={
                        <InputAdornment position="start">Q</InputAdornment>
                      }
                      error={
                        this.state.total_anesthesiaError.length === 0
                          ? false
                          : true
                      }
                    />
                    <FormHelperText error={true}>
                      {this.state.total_anesthesiaError}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>Porcentage de Comision de Anestesia%</label>
                    <Input
                      className="form-control"
                      type="Number"
                      placeholder="porcentage de comision de anestesia"
                      name="commission_percentage_anesthesia"
                      value={this.state.commission_percentage_anesthesia}
                      onChange={this.onChange}
                      startAdornment={
                        <InputAdornment position="start">%</InputAdornment>
                      }
                      error={
                        this.state.commission_percentage_anesthesiaError
                          .length === 0
                          ? false
                          : true
                      }
                    />
                    <FormHelperText error={true}>
                      {this.state.commission_percentage_anesthesiaError}
                    </FormHelperText>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Total de comision</label>
                    <Input
                      className="form-control"
                      type="Number"
                      placeholder="total de comision"
                      name="total_commission"
                      value={this.state.total_commission}
                      readOnly
                      startAdornment={
                        <InputAdornment position="start">Q</InputAdornment>
                      }
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Descripcion</label>
                    <textarea
                      className="form-control"
                      type="Number"
                      placeholder="Descripcion"
                      name="description"
                      onChange={this.onChange}
                      value ={this.state.description}
                    />
                  </div>
                </div>
              </div>
              <div className="row  pb-3">
                <div className="col-12">
                  <button type="submit" className="btn btn-success btn-block">
                    Agregar Comision
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { addCommission }
)(NewCommissionOther);
