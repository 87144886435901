import axios from "axios";
import { returnErrors } from "./ActMessages";
import {
    PROFILE_LOADING,
    PROFILE_LOADED,
    LOADING_TO_TABLE,
    PICTURE_UPLOADING,
    PICTURE_UPLOADED,
    __BASE__URL
} from "./types";
import { tokenConfig } from "./ActAuths";

export const getProfile = () => async(dispatch, getState) => {
    try {
        const url = __BASE__URL + "/api/profile/";
        dispatch({ type: PROFILE_LOADING });
        await axios
            .get(url, tokenConfig(getState))
            .then(res => {
                dispatch({
                    type: PROFILE_LOADED,
                    payload: res.data
                });
            })
            .catch(err =>
                dispatch(returnErrors(err?.response?.data, err?.response?.data))
            );
    } catch (error) {
        console.log(error);
    }
};

export const getProfileTest = id => (dispatch, getState) => {
    const url = __BASE__URL + "/api/profile/";
    dispatch({ type: PROFILE_LOADING });
    axios
        .get(url, tokenConfig(getState))
        .then(res => {
            dispatch({ type: PROFILE_LOADED, payload: res.data });
        })
        .catch(err =>
            dispatch(returnErrors(err?.response?.data, err?.response?.data))
        );
};

/*This wil retrive the profile for displaying info on the table secretary table*/
export const getCertainProfile = id => async(dispatch, getState) => {
    try {
        const body = JSON.stringify({
            user_id: id
        });
        const url = __BASE__URL + "/api/profiles";
        dispatch({ type: PROFILE_LOADING });
        await axios
            .post(url, body, tokenConfig(getState))
            .then(res => {
                /*console.log(res);*/
                dispatch({
                    type: LOADING_TO_TABLE,
                    payload: res.data
                });
            })
            .catch(err =>
                dispatch(returnErrors(err?.response?.data, err?.response?.data))
            );
    } catch (error) {
        console.log(error);
    }
};

export const updateProfile = (body, id) => async(dispatch, getState) => {
    /*const body = JSON.stringify({ info });*/
    try {
        const url = `${__BASE__URL}/api/profile/${id}/`;
        await axios
            .patch(url, body, tokenConfig(getState))
            .then(res => {
                dispatch({
                    type: PROFILE_LOADED,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch(returnErrors(err?.response?.data, err?.response?.data));
            });
    } catch (error) {
        console.log(error);
    }
};

export const updateProfilePicture = (info, id) => async(
    dispatch,
    getState
) => {
    try {
        dispatch({ type: PICTURE_UPLOADING });
        const url = `${__BASE__URL}/api/profilepicture/${id}/`;
        console.log(url);
        await axios
            .patch(url, info, tokenConfig(getState))
            .then(res => {
                dispatch({
                    type: PROFILE_LOADED,
                    payload: res.data
                });
                dispatch({ type: PICTURE_UPLOADED });
            })
            .catch(err => {
                dispatch(returnErrors(err?.response?.data, err?.response?.data));
            });
    } catch (error) {
        console.log(error);
    }
};

/*export const addLead = lead => (dispatch, getState) => {
  const url = "http://localhost:8000/api/leads/"; // site that doesn’t send Access-Control-*
  axios
    .post(url, lead, tokenConfig(getState))
    .then(res => {
      dispatch(createMessage({ addLead: "Lead added" }));
      dispatch({
        type: ADD_LEAD,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};
*/