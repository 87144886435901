import React, { Component } from "react";
import AddNewClient from "../Forms/AddNewClient";
import { Link } from "react-router-dom";
import addClientIcon from "../../assets/icons/addClient.svg";
class NewClientsPage extends Component {
  render() {
    return (
      <React.Fragment>
        {/*
      <div className="container" style={{ height: "100vh" }}>
          <div
            className="row aling-items-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="col">
      */}
        <div className="container-fluid">
          <div
            className="row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              color: "black",
              height: "8rem"
            }}
          >
            <p style={{ alignSelf: "center" }}>Registro de usuarios</p>
          </div>
          <div className="row align-items-center justify-content-center pt-5">
            <div className="col">
              <div className="col-12 d-flex pb-3 pt-5 justify-content-center">
                <img src={addClientIcon} alt="" className="header-icon" />
                <br />
                <h5 className="pl-5">
                  Registra un nuevo cliente, luego podras editarlo desde la
                  página
                  <Link to="/secretary/clients"> clientes.</Link>
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <AddNewClient></AddNewClient>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewClientsPage;
