import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import dogOnWindow from "../../assets/icons/dogOnWindow.svg";
import PetHistoryDetail from "../common/PetHistoryDetail";
import MaterialTable from "material-table";

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getAllPets } from "../../actions/ActPets";
import { getPetHistory } from "../../actions/ActApointments";
import { getClinicFile } from "../../actions/ActClinicfile";
class PetHistoryPage extends Component {
  state = {
    selectedPetTodeliver: null,
    petDelivered: null,
    petID: null,
    selectedPet: null,
  };
  static propTypes = {
    getPetHistory: PropTypes.func.isRequired,
    apointments: PropTypes.array.isRequired,
    pets: PropTypes.array.isRequired,
    getClinicFile: PropTypes.func.isRequired,
    getAllPets: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    file: PropTypes.object,
  };

  componentDidMount() {
    this.props.getAllPets();
  }

  render() {
    if (this.state.petDelivered) {
      return <Redirect to="/dashboard"></Redirect>;
    }
    const inLineStyles = this.state.petID
      ? {
          alignItems: "center",
          paddingTop: "2rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }
      : {
          display: "none",
        };

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className="row"
                style={{
                  /*height: "6rem",*/
                  color: "black",
                  backgroundColor: "#dfe6e9",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2em",
                  marginBottom: "2em",
                }}
              >
                <div className="col-xl-4 col-lg-4 col-md-8 col-sm-8">
                  <div className="col text-center">
                    <h4>Historial clinico por mascota</h4>
                    <img
                      src={dogOnWindow}
                      alt=""
                      style={{ maxWidth: "8rem" }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div
                  className="row"
                  style={{
                    alignItems: "center",
                    padding: "0rem",
                    overflow: "scroll",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ padding: "0rem" }}>
                    <MaterialTable
                      localization={{
                        toolbar: {
                          searchTooltip: "Buscar",
                          searchPlaceholder: "Paciente, Dueño y fecha",
                        },
                        body: {
                          emptyDataSourceMessage: "Ningún registro",
                        },
                        /*header: {
                          actions: "Acciones"
                        }*/
                      }}
                      options={{
                        pageSize: 1,
                        headerStyle: {
                          backgroundColor: "#f39c12",
                          color: "white",
                          paddingLeft: "20px",
                        },
                        exportButton: true,
                        /*actionsColumnIndex: -1,*/
                        detailPanelColumnAlignment: "right",
                      }}
                      columns={[
                        /*{
                          title: "ID",
                          field: "id"
                        },*/
                        {
                          title: "Dueño username",
                          field: "owner.username",
                        },
                        {
                          title: "Dueño nombre",
                          field: "owner.first_name",
                        },
                        {
                          title: "Paciente",
                          field: "name",
                        },
                        {
                          title: "Registrado",
                          field: "registered_on",
                        },
                      ]}
                      data={this.props.pets}
                      title="Mascotas"
                      /*actions={[
                        {
                          icon: "add",
                          tooltip: "Mostrar información",
                          onClick: (event, rowData) => {
                            event.preventDefault();
                            this.setState({ petID: rowData.id });
                            if (rowData.id) {
                              this.props.getPetHistory(rowData.id);
                            }
                            document
                              .getElementById("OnScrollDetail")
                              .scrollIntoView();
                          }
                        }
                      ]}*/
                      onRowClick={(event, rowData) => {
                        event.preventDefault();
                        this.setState({
                          petID: rowData.id,
                          selectedPet: rowData,
                        });
                        if (rowData.id) {
                          this.props.getPetHistory(rowData.id);
                        }
                        document
                          .getElementById("OnScrollDetail")
                          .scrollIntoView();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                id="OnScrollDetail"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  height: "2rem",
                }}
              >
                <p className="text-center">
                  {this.state.selectedPet
                    ? "Historial clínico de " + this.state.selectedPet.name
                    : "Seleccione una mascota"}
                </p>
              </div>
              <div
                className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                style={inLineStyles}
              >
                <PetHistoryDetail
                  inLineStyles={inLineStyles}
                  petID={this.state.petID}
                ></PetHistoryDetail>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  apointments: state.RedApoint.apointments,
  pets: state.RedPets.pets,
  user: state.RedAuth.user,
  file: state.History.file,
});

export default connect(mapStateToProps, {
  getAllPets,
  getClinicFile,
  getPetHistory,
})(PetHistoryPage);
