import React, { Component } from "react";
import "./styles/PetCard.css";
import DogIcon from "../../assets/icons/dog.png";
import CatIcon from "../../assets/icons/cat.svg";
import Pet from "../../components/Forms/PetModify";
export class PetCard extends Component {
  state = {
    showEditModal: false
  };
  render() {
    return (
      <React.Fragment>
        {this.state.showEditModal ? (
          <Modal
            show={() => {
              this.setState({ showEditModal: true });
            }}
            handleClose={() => {
              this.setState({ showEditModal: false });
            }}
          >
            <div className="text-center">
              <label htmlFor="customRange2">
                Condición corporal {this.state.condicionc}{" "}
                {this.state.condicionc < 4 ? (
                  <div className="text-warning">
                    <h4>Demasiado delgado</h4>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {this.state.condicionc > 3 && this.state.condicionc < 6 ? (
                  <div className="text-success">
                    <h4>Condicion ideal</h4>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {this.state.condicionc > 5 && this.state.condicionc < 11 ? (
                  <div className="text-danger">
                    <h4>Sobrepeso-obesidad</h4>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </label>
            </div>
            <Pet {...this.props}></Pet>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <div
          className="col justify-content-center align-items-center text-center"
          key={this.props.id}
        >
          <img
            src={this.props.species === "Perro" ? DogIcon : CatIcon}
            alt=""
            className="mt-3"
          />
          <p className="mb-1">{this.props.name}</p>
        </div>
        <div className="col my-auto">
          <p className="text-center" style={{ fontSize: "75%" }}>
            {this.props.species}
          </p>
          <p className="text-center" style={{ fontSize: "75%" }}>
            {this.props.race}
          </p>
          <p className="text-center" style={{ fontSize: "75%" }}>
            {this.props.age} años con {this.props.age_months} meses
          </p>
          <p className="text-center" style={{ fontSize: "75%" }}>
            {this.props.color}
          </p>
          <p className="text-center" style={{ fontSize: "75%" }}>
            {this.props.reproductive_state}
          </p>
          <button
            className="btn btn-info btn-block"
            onClick={e => {
              e.preventDefault();
              this.setState({ showEditModal: true });
            }}
          >
            Editar
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      {" "}
      <section
        className="modal-main clients-frame"
        style={{ overflow: "scroll" }}
      >
        <span class="circle" onClick={handleClose}></span>
        {children}
      </section>
    </div>
  );
};

class PetCardList extends Component {
  render() {
    return (
      <div className="row">
        {this.props.pets.map(pet => {
          return (
            <div className="col pet-card" key={pet.id}>
              {/*<li key={pet.id}>*/}
              <PetCard {...pet} />
              {/*</li>*/}
            </div>
          );
        })}
      </div>
    );
  }
}

export default PetCardList;
