import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pet from "../Forms/Pet";
import { addPet, getUserPets } from "../../actions/ActPets";
import { createMessage } from "../../actions/ActMessages";
import MaterialTable from "material-table";
import {searchPets} from "../../actions/ActPets";
class SecretaryPets extends Component {
  state = { form: {} };
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    pets: PropTypes.array.isRequired,
    addPet: PropTypes.func.isRequired,
    getUserPets: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    searchPets: PropTypes.func.isRequired,
  };

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    let pet = { ...this.state.form };
    pet = {
      ...pet,
      owner: this.props.clientId
    };
    this.setState({ form: {} });
    this.props.addPet(pet);
    this.props.createMessage({
      apointmentsOf: "Mascota regitrada"
    });
  };

  componentDidMount() {
    let info = { clientId: this.props.clientId };
    this.props.getUserPets(info);
  }

  render() {
    return (
      <div>
        <div className="container ">
          <div className="row">
            <div className="col-lg-6 col-12 col-md-6">
              <Pet
                onChange={this.handleChange}
                formValues={this.state.form}
                onSubmit={this.handleSubmit}
              />
            </div>
            <div className="col-lg-6 col-12 col-md-6">
              <div className="row">
                <div className="col">
                  <MaterialTable
                    localization={{
                      toolbar: {
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Nombre"
                      },
                      body: {
                        emptyDataSourceMessage:
                          "No se encuentran coincidencias."
                      },
                      header: {
                        actions: "Acciones"
                      }
                    }}
                    options={{
                      pageSize: 10,
                      headerStyle: {
                        backgroundColor: "#01579b",
                        color: "white",
                        paddingLeft: "20px"
                      },
                      actionsColumnIndex: -1,
                      detailPanelColumnAlignment: "right"
                    }}
                    columns={[
                      {
                        title: "ID",
                        field: "id",
                        cellStyle: {
                          maxWidth: "5px"
                        }
                      },

                      {
                        title: "Nombre",
                        field: "name"
                      },
                      {
                        title: "Raza",
                        field: "race"
                      }
                    ]}
                    data={this.props.pets}
                    title="Lista de Mascotas"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.RedAuth.isAuthenticated,
  user: state.RedAuth.user,
  pets: state.RedPets.pets
});
export default connect(mapStateToProps, { addPet, getUserPets, createMessage })(
  SecretaryPets,
  searchPets,
);
