import React, { Component } from "react";
import { Link } from "react-router-dom";
import brandsIcon from "../../assets/icons/brands.svg";
import categoryIcon from "../../assets/icons/category.svg";
import products from "../../assets/icons/products.svg";
import BrandsForm from "./Forms/BrandsForm";
import AllBrands from "./Forms/AllBrands";
export class Brands extends Component {
  state = {
    result: "",
    addBrand: "",
    showBrands: ""
  };

  pushAllBrands = e => {
    e.preventDefault();
    this.setState({ result: "AllBrands" });
  };

  pushAddBrand = e => {
    e.preventDefault();
    this.setState({ result: "AddBrand" });
  };

  componentDidMount() {
    this.setState({ result: "AllBrands" });
  }

  render() {
    const result = this.state.result;
    function Verify() {
      if (result === "AllBrands") {
        return <AllBrands />;
      } else if (result === "AddBrand") {
        return <BrandsForm />;
      }
      return "";
    }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div
            className="row"
            style={{
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              color: "black",
              height: "15rem"
            }}
          >
            <p style={{ alignSelf: "center" }}>Administración de inventario</p>
            <div className="row align-items-center justify-content-center">
              <div className="col text-center" style={{ flexDirection: "row" }}>
                <Link to="/products/categories">
                  <img
                    src={categoryIcon}
                    alt="categoryIcon"
                    id="categoryIcon"
                    style={{ maxWidth: "3rem" }}
                  />
                  <br />
                  <label htmlFor="categoryIcon">Categorias</label>
                </Link>
              </div>
              <div className="col text-center" style={{ flexDirection: "row" }}>
                <Link to="/products/brands">
                  <img
                    src={products}
                    alt="productsIcon"
                    id="productsIcon"
                    style={{ maxWidth: "3rem" }}
                  />
                  <br />
                  <label htmlFor="productsIcon">Marcas</label>
                </Link>
              </div>
              <div className="col text-center" style={{ flexDirection: "row" }}>
                <Link to="/products">
                  <img
                    src={brandsIcon}
                    alt="brandIcon"
                    id="brandIcon"
                    style={{ maxWidth: "3rem" }}
                  />
                  <br />
                  <label htmlFor="brandIcon">Productos</label>
                </Link>
              </div>
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-md-12 col-sm-12 col-lg-12">
              <div className="card text-center">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <button className="nav-link" onClick={this.pushAllBrands}>
                        Marcas
                      </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link" onClick={this.pushAddBrand}>
                        Agregar Marca
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Verify />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Brands;
