import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getNumberTotalSalesToday,
  getTotalSalesToday,
  getDetailsProductsSalesToday,
  LoadedReportToday,
  getNumberTotalSalesMonth,
  getTotalSalesMonth,
  getDetailsProductsSalesMonth,
  getNumberTotalSalesYear,
  getTotalSalesYear,
  getDetailsProductsSalesYear,
  getTotalCashSalesToday,
  getTotalCardSalesToday,
  getTotaCashlSalesMonth,
  getTotalCardSalesMonth,
  getSumGroomToday,
  getSumServicesToday,
  getSumGroomActualMonth,
  getSumgServicesActualMonth,
  getDetailGroomToday,
  getDetailGroomActualMonth,
  getDetailServicesToday,
  getDetailServicesActualMonth,
  detailProductsRank,
  totalCardRank,
  totalCashRank,
  totalGroomRank,
  detailGroomRank,
  totalServicesRank,
  detailServicesRank,
  getTotalCashDevToday,
  getTotalCardDevToday,
  getTotalCashDevMonth,
  getTotalCardDevMonth,
  getTotalCashDevRank,
  getTotalCardDevRank,
  getdetailsDevToday,
  getdetailsDevMonth,
  getdetailsDevRank,
  getSumPharmacyToday,
  getSumgPharmacyActualMonth,
  getDetailPharmacyToday,
  getDetailPharmacyActualMonth,
  totalPharmacyRank,
  detailPharmacyRank
} from "../../../actions/Reports";
import MaterialTable from "material-table";
import { Pie } from "react-chartjs-2";
import "./styles.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../../assets/logo.png";
import Cachorro from "../../../assets/cachorro.png";
import Pildora from "../../../assets/pildora.png";
import Dinero from "../../../assets/dinero.png";
import Compra from "../../../assets/compra.png";
import Groom from "../../../assets/tabla.png";
import Devolucion from "../../../assets/cashback.png";
export class ViewReportsByDate extends Component {
  static propTypes = {
    getNumberTotalSalesToday: PropTypes.func.isRequired,
    totalNumSales: PropTypes.number.isRequired,

    getTotalSalesToday: PropTypes.func.isRequired,
    totalSales: PropTypes.object.isRequired,

    getDetailsProductsSalesToday: PropTypes.func.isRequired,
    details: PropTypes.array.isRequired,

    LoadedReportToday: PropTypes.func.isRequired,
    loadingNumber: PropTypes.bool,
    loadingTotal: PropTypes.bool,
    loadingDetails: PropTypes.bool,

    getNumberTotalSalesMonth: PropTypes.func.isRequired,
    getTotalSalesMonth: PropTypes.func.isRequired,
    getDetailsProductsSalesMonth: PropTypes.func.isRequired,

    getNumberTotalSalesYear: PropTypes.func.isRequired,
    getTotalSalesYear: PropTypes.func.isRequired,
    getDetailsProductsSalesYear: PropTypes.func.isRequired,

    getTotalCashSalesToday: PropTypes.func.isRequired,
    totalcashSales: PropTypes.object.isRequired,
    loadingcash: PropTypes.bool,

    getTotalCardSalesToday: PropTypes.func.isRequired,
    totalcardSales: PropTypes.object.isRequired,
    loadingcard: PropTypes.bool,

    getTotaCashlSalesMonth: PropTypes.func.isRequired,
    getTotalCardSalesMonth: PropTypes.func.isRequired,

    getSumGroomToday: PropTypes.func.isRequired,
    getSumServicesToday: PropTypes.func.isRequired,
    totalGroom: PropTypes.array.isRequired,
    totalServices: PropTypes.array.isRequired,
    loadingTotalGroom: PropTypes.bool,
    loadingTotalServices: PropTypes.bool,
    getSumGroomActualMonth: PropTypes.func.isRequired,
    getSumgServicesActualMonth: PropTypes.func.isRequired,

    getDetailGroomToday: PropTypes.func.isRequired,
    detailsGroom: PropTypes.array.isRequired,
    loadingDetailGroom: PropTypes.bool,
    getDetailGroomActualMonth: PropTypes.func.isRequired,

    getDetailServicesToday: PropTypes.func.isRequired,
    detailServ: PropTypes.array.isRequired,
    loadingDetailService: PropTypes.bool,

    getDetailServicesActualMonth: PropTypes.func.isRequired,

    detailProductsRank: PropTypes.func.isRequired,
    totalCardRank: PropTypes.func.isRequired,
    totalCashRank: PropTypes.func.isRequired,
    totalGroomRank: PropTypes.func.isRequired,
    detailGroomRank: PropTypes.func.isRequired,
    totalServicesRank: PropTypes.func.isRequired,
    detailServicesRank: PropTypes.func.isRequired,

    getTotalCashDevToday: PropTypes.func.isRequired,
    getTotalCardDevToday: PropTypes.func.isRequired,
    totalCashDev: PropTypes.object.isRequired,
    loadingTotalDevCash: PropTypes.bool,
    totalCardDev: PropTypes.object.isRequired,
    loadingTotalDevCard: PropTypes.bool,

    getTotalCashDevMonth: PropTypes.func.isRequired,
    getTotalCardDevMonth: PropTypes.func.isRequired,
    getTotalCashDevRank: PropTypes.func.isRequired,
    getTotalCardDevRank: PropTypes.func.isRequired,

    getdetailsDevToday: PropTypes.func.isRequired,
    getdetailsDevMonth: PropTypes.func.isRequired,
    getdetailsDevRank: PropTypes.func.isRequired,
    detailsDev: PropTypes.array.isRequired,
    loadingdetailDev: PropTypes.bool,

    getSumPharmacyToday: PropTypes.func.isRequired,
    getSumgPharmacyActualMonth: PropTypes.func.isRequired,
    totalPharm: PropTypes.array.isRequired,
    loadingTotalPharm: PropTypes.bool,
    detailsPharm: PropTypes.array.isRequired,
    loadingDetailPharm: PropTypes.bool,

    getDetailPharmacyToday: PropTypes.func.isRequired,
    getDetailPharmacyActualMonth: PropTypes.func.isRequired,
    totalPharmacyRank: PropTypes.func.isRequired,
    detailPharmacyRank: PropTypes.func.isRequired
  };

  state = {
    columns: [
      { title: "Nombre", field: "name", editable: "never" },
      { title: "Marca", field: "brand", editable: "never" },
      { title: "Descripcion", field: "description", editable: "never" },
      {
        title: "Cantidad de Compras",
        field: "amount",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Precio de Compra",
        field: "purchase_price",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Precio de Venta",
        field: "sale_price",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Total en Ventas",
        field: "total",
        type: "numeric",
        editable: "never"
      }
    ],
    columnsTotales: [
      { title: "Nombre", field: "name", editable: "never" },
      { title: "Efectivo", field: "cash", type: "numeric" },
      { title: "Tarjeta", field: "card", type: "numeric" },
      { title: "Monto del 7%", field: "cardDiscount", type: "numeric" },
      {
        title: "Tarjeta sin 7%",
        field: "cardDiscountApplied",
        type: "numeric"
      },
      { title: "Total", field: "totalApplied", type: "numeric" }
    ],
    result: "",
    datos: [],
    datosTotales: [],
    charData: {},
    charData2: {},
    totalVentas: 0,
    backgroundColor: [
      "rgba(255,99,132,0.6)",
      "rgba(50, 205, 50,0.6)",
      "rgba(255,206,86,06)",
      "rgba(75,192,192,0.6)",
      "rgba(210, 105, 30,0.6)",
      "rgba(244, 208, 63,0.7)",
      "rgba(186, 85, 211,0.6)"
    ],
    columnsDetalleTotales: [
      { title: "Nombre", field: "name", editable: "never" },
      {
        title: "Total",
        field: "total",
        type: "numeric",
        editable: "never"
      }
    ],
    datosDetalleTotales: [],
    columndDetailGroom: [
      { title: "Nombre", field: "name", editable: "never" },
      { title: "Descripcion", field: "description", editable: "never" },
      {
        title: "Cantidad de Groomings",
        field: "amount",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Precio de Venta",
        field: "sale_price",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Total en Ventas",
        field: "total",
        type: "numeric",
        editable: "never"
      }
    ],
    datosDetailGrooms: [],
    columndDetailServ: [
      { title: "Nombre", field: "name", editable: "never" },
      { title: "Descripcion", field: "description", editable: "never" },
      {
        title: "Cantidad del Servicio",
        field: "amount",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Precio de Venta",
        field: "sale_price",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Total en Ventas",
        field: "total",
        type: "numeric",
        editable: "never"
      }
    ],
    datosDetailServices: [],
    columnsDev: [
      { title: "Nombre", field: "name", editable: "never" },
      { title: "Marca", field: "brand", editable: "never" },
      { title: "Descripcion", field: "description", editable: "never" },
      {
        title: "Cantidad de devoluciones",
        field: "amount",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Precio de Venta",
        field: "sale_price",
        type: "numeric",
        editable: "never"
      },
      {
        title: "Total en Devoluciones",
        field: "total",
        type: "numeric",
        editable: "never"
      }
    ],
    datosDetailDev: [],
    cargaCompleta: false,
    datosFarmacia: []
  };

  componentDidMount() {
    if (this.props.result === "hoy") {
      //this.props.getNumberTotalSalesToday();
      //this.props.getTotalSalesToday();
      this.props.getDetailsProductsSalesToday();
      this.props.getTotalCashSalesToday();
      this.props.getTotalCardSalesToday();
      this.props.getSumGroomToday();
      this.props.getSumServicesToday();
      this.props.getDetailGroomToday();
      this.props.getDetailServicesToday();
      this.props.getTotalCashDevToday();
      this.props.getTotalCardDevToday();
      this.props.getdetailsDevToday();
      this.props.getSumPharmacyToday();
      this.props.getDetailPharmacyToday();
    } else if (this.props.result === "Mes") {
      //this.props.getNumberTotalSalesMonth();
      //this.props.getTotalSalesMonth();
      this.props.getDetailsProductsSalesMonth();
      this.props.getTotaCashlSalesMonth();
      this.props.getTotalCardSalesMonth();
      this.props.getSumGroomActualMonth();
      this.props.getSumgServicesActualMonth();
      this.props.getDetailGroomActualMonth();
      this.props.getDetailServicesActualMonth();
      this.props.getTotalCashDevMonth();
      this.props.getTotalCardDevMonth();
      this.props.getdetailsDevMonth();
      this.props.getSumgPharmacyActualMonth();
      this.props.getDetailPharmacyActualMonth();
    } else if (this.props.result === "rango") {
      this.props.detailProductsRank(this.props.inicio, this.props.final);
      this.props.totalCashRank(this.props.inicio, this.props.final);
      this.props.totalCardRank(this.props.inicio, this.props.final);
      this.props.totalGroomRank(this.props.inicio, this.props.final);
      this.props.totalServicesRank(this.props.inicio, this.props.final);
      this.props.detailGroomRank(this.props.inicio, this.props.final);
      this.props.detailServicesRank(this.props.inicio, this.props.final);
      this.props.getTotalCashDevRank(this.props.inicio, this.props.final);
      this.props.getTotalCardDevRank(this.props.inicio, this.props.final);
      this.props.getdetailsDevRank(this.props.inicio, this.props.final);
      this.props.totalPharmacyRank(this.props.inicio, this.props.final);
      this.props.detailPharmacyRank(this.props.inicio, this.props.final);
    }
  }

  componentDidUpdate() {
    if (
      this.props.loadingDetails &&
      this.props.loadingcash &&
      this.props.loadingcard &&
      this.props.loadingTotalGroom &&
      this.props.loadingTotalServices &&
      this.props.loadingDetailGroom &&
      this.props.loadingDetailService &&
      this.props.loadingTotalDevCash &&
      this.props.loadingTotalDevCard &&
      this.props.loadingdetailDev &&
      this.props.loadingTotalPharm &&
      this.props.loadingDetailPharm
    ) {
      var totalG = { name: "GROOMING", total: 0 };
      var totalS = { name: "SERVICIOS CLINICOS", total: 0 };
      var totalF = { name: "FARMACIA", total: 0 };

      const data = this.props.details.map(detalle => ({
        name: detalle.product__name,
        brand: detalle.product__brand__name,
        description: detalle.product__description,
        sale_price: detalle.sale_price,
        purchase_price: detalle.product__purchase_price,
        amount: detalle.amount__sum,
        total: detalle.total
      }));

      const detailG = this.props.detailsGroom.map(groom => ({
        name: groom.product__name,
        description: groom.product__description,
        amount: groom.amount__sum,
        sale_price: groom.sale_price,
        total: groom.total
      }));

      const detailS = this.props.detailServ.map(serv => ({
        name: serv.product__name,
        description: serv.product__description,
        amount: serv.amount__sum,
        sale_price: serv.sale_price,
        total: serv.total
      }));

      const devs = this.props.detailsDev.map(detalle => ({
        name: detalle.product__name,
        brand: detalle.product__brand__name,
        description: detalle.product__description,
        sale_price: detalle.sale_price,
        amount: detalle.amount__sum,
        total: detalle.total
      }));

      const Farm = this.props.detailsPharm.map(detalle => ({
        name: detalle.product__name,
        brand: detalle.product__brand__name,
        description: detalle.product__description,
        purchase_price: detalle.product__purchase_price,
        sale_price: detalle.sale_price,
        amount: detalle.amount__sum,
        total: detalle.total
      }));

      let name = "Total General sin Devoluciones";
      let cash = this.props.totalcashSales.totalcash__sum;
      if (cash === null) {
        cash = 0;
      }
      let card = this.props.totalcardSales.totalcard__sum;
      if (card === null) {
        card = 0;
      }
      let cardDiscount = parseFloat(card) * 0.07;
      let cardDiscountApplied = card - cardDiscount;
      let totalApplied = parseFloat(cash) + parseFloat(cardDiscountApplied);

      const datatotal = {
        name,
        cash,
        card,
        cardDiscount,
        cardDiscountApplied,
        totalApplied
      };

      this.state.datosTotales.push(datatotal);

      let cashDev = this.props.totalCashDev.totalcash__sum;
      let cardDev = this.props.totalCardDev.totalcard__sum;
      if (cashDev === null) {
        cashDev = 0;
      }
      if (cardDev === null) {
        cardDev = 0;
      }

      let name2 = "Total General con Devoluciones";
      let cash2 = parseFloat(cash) - parseFloat(cashDev);
      let card2 = parseFloat(card) - parseFloat(cardDev);
      let cardDiscount2 = parseFloat(card2) * 0.07;
      let cardDiscountApplied2 = card2 - cardDiscount2;
      let totalApplied2 = parseFloat(cash2) + parseFloat(cardDiscountApplied2);
      const datatotal2 = {
        name: name2,
        cash: cash2,
        card: card2,
        cardDiscount: cardDiscount2,
        cardDiscountApplied: cardDiscountApplied2,
        totalApplied: totalApplied2
      };
      this.state.datosTotales.push(datatotal2);

      if (this.props.totalGroom.length > 0) {
        totalG = {
          name: this.props.totalGroom[0].product__category__name,
          total: this.props.totalGroom[0].total
        };
      }
      this.state.datosDetalleTotales.push(totalG);

      if (this.props.totalServices.length > 0) {
        totalS = {
          name: this.props.totalServices[0].product__category__name,
          total: this.props.totalServices[0].total
        };
      }
      this.state.datosDetalleTotales.push(totalS);

      if (this.props.totalPharm.length > 0) {
        totalF = {
          name: this.props.totalPharm[0].product__category__name,
          total: this.props.totalPharm[0].total
        };
      }
      this.state.datosDetalleTotales.push(totalF);
      this.getCharData(data, Farm);

      const devolucionCash = {
        name: "DEVOLUCION EN EFECTIVO",
        total: cashDev
      };
      const devolucionCard = {
        name: "DEVOLUCION CON TARJETA",
        total: cardDev
      };

      this.state.datosDetalleTotales.push(devolucionCash);
      this.state.datosDetalleTotales.push(devolucionCard);

      this.setState({
        datos: data,
        datosDetailGrooms: detailG,
        datosDetailServices: detailS,
        datosFarmacia: Farm,
        datosDetailDev: devs,
        cargaCompleta: true
      });
      this.props.LoadedReportToday();
    }
  }

  getCharData(datos, datosFarmacia) {
    var labels = [];
    var amounts = [];
    var ganancias = 0;
    var invercion = 0;
    var inversionF = 0;
    for (let i = 0; i < datos.length; i++) {
      labels.push(datos[i].name + " " + datos[i].description);
      amounts.push(parseInt(datos[i].amount));
      invercion +=
        parseFloat(datos[i].amount) * parseFloat(datos[i].purchase_price);
      ganancias += datos[i].total;
    }

    for (let i = 0; i < datosFarmacia.length; i++) {
      inversionF +=
        parseFloat(datosFarmacia[i].amount) *
        parseFloat(datosFarmacia[i].purchase_price);
    }

    const totalRecep = {
      name: "VENTA DE RECEPCION",
      total: ganancias
    };

    this.state.datosDetalleTotales.push(totalRecep);

    const totalInver = {
      name: "TOTAL INVERTIDO RECEPCION",
      total: invercion
    };
    this.state.datosDetalleTotales.push(totalInver);

    const totalInverF = {
      name: "TOTAL INVERTIDO FARMACIA",
      total: inversionF
    };

    this.state.datosDetalleTotales.push(totalInverF);

    this.setState({
      charData: {
        labels: labels,
        datasets: [
          {
            label: "Productos",
            data: amounts,
            backgroundColor: this.state.backgroundColor
          }
        ]
      },
      charData2: {
        labels: labels,
        datasets: [
          {
            label: "Productos",
            data: ganancias,
            backgroundColor: this.state.backgroundColor
          }
        ]
      }
    });
  }

  GenerarPDF = e => {
    e.preventDefault();
    var pdf = new jsPDF();
    pdf.addFont('ArialMS', 'Arial', 'normal');
    pdf.setFont('Arial');
    pdf.setFontType("normal");
    let newDate = new Date()
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      pdf.setFontSize(11);
    if (this.props.result === "hoy") {
      pdf.text(date.toString()+"/"+month.toString()+"/"+year.toString(), 170, 15);
    }else if (this.props.result === "Mes") {
      pdf.text("Reporte de Mes "+month.toString()+"/"+year.toString(), 150, 15);
    }else if (this.props.result === "rango") {
      pdf.text("Reporte Rango "+this.props.inicio+"--"+this.props.final, 125, 15);
    }
    pdf.setFontSize(15);
    pdf.text("REPORTE", 95, 30);
    pdf.setFontSize(10);
    var logo = new Image();
    logo.src = Logo;
    pdf.addImage(logo, "JPEG", 14, 0, 35, 35);

    /*var comlumsTotales = [
      "Nombre",
      "Efectivo",
      "Tarjeta",
      "Monto del 7%",
      "Tarjeta sin el 7%",
      "Total"
    ];*/

    var comlumsTotales = [
      "Nombre",
      "Sin Devoluciones",
      "Con Devoluciones"
    ];

    var filasTotales = [];

   
      let fila = [
        "Efectivo",
        this.state.datosTotales[0].cash,
        this.state.datosTotales[1].cash
        
      ];
      filasTotales.push(fila);

      let fila2 = [
        "Tarjeta",
        this.state.datosTotales[0].card,
        this.state.datosTotales[1].card
        
      ];
      filasTotales.push(fila2);

      let fila3 = [
        "Monto del 7%",
        this.state.datosTotales[0].cardDiscount,
        this.state.datosTotales[1].cardDiscount
        
      ];
      filasTotales.push(fila3);

      let fila4 = [
        "Tarjeta sin el 7%",
        this.state.datosTotales[0].cardDiscountApplied,
        this.state.datosTotales[1].cardDiscountApplied
        
      ];
      filasTotales.push(fila4);

      let fila5 = [
        "Total",
        this.state.datosTotales[0].totalApplied,
        this.state.datosTotales[1].totalApplied
        
      ];
      filasTotales.push(fila5);
    
    /*for (let i = 0; i < this.state.datosTotales.length; i++) {
      let fila = [
        this.state.datosTotales[i].name,
        this.state.datosTotales[i].cash,
        this.state.datosTotales[i].card,
        this.state.datosTotales[i].cardDiscount,
        this.state.datosTotales[i].cardDiscountApplied,
        this.state.datosTotales[i].totalApplied
      ];
      filasTotales.push(fila);
    }*/
    



    pdf.autoTable(comlumsTotales, filasTotales, {
      margin: { top: 55 },
      pageBreak: "avoid",
      headStyles: { fillColor: [19, 141, 117] },
      theme: "grid"
    });
    pdf.addPage();
    var columnsT = ["Nombre", "Total"];
    var filasT = [];
    for (let i = 0; i < this.state.datosDetalleTotales.length; i++) {
      let fila = [
        this.state.datosDetalleTotales[i].name,
        "Q" + this.state.datosDetalleTotales[i].total
      ];
      filasT.push(fila);
    }
    pdf.autoTable(columnsT, filasT, {
      pageBreak: "avoid",
      headStyles: { fillColor: [142, 68, 173] },
      theme: "grid",
      styles: { overflow: "ellipsize", cellWidth: "wrap" },
      willDrawCell: function(data) {
        if (data.row.section === "body") {
          if (
            data.row.index === 4 ||
            data.row.index === 5 ||
            data.row.index === 6 ||
            data.row.index === 7
          ) {
            pdf.setTextColor(231, 76, 60); // Red
            pdf.addFont('ArialMS', 'Arial', 'normal');
            pdf.setFont('Arial');
            pdf.setFontStyle("bold");
          }
        }
      },
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.addFont('ArialMS', 'Arial', 'normal');
        pdf.setFont('Arial');
        pdf.setFontType("normal");
        var dinero = new Image();
        dinero.src = Dinero;
        if (dinero) {
          pdf.addImage(dinero, "PNG", data.settings.margin.left, 15, 10, 10);
        }
        pdf.text("DETALLE DE TOTALES", data.settings.margin.left + 15, 22);
      },
      margin: { top: 30 }
    });

    pdf.addPage();

    var columnsRecepcion = [
      "Nombre",
      "Marca",
      "Descripcion",
      "Cantidad de Compras",
      "Precio de Compra",
      "Precio de Venta",
      "Total"
    ];
    var filasRecepcion = [];
    for (let i = 0; i < this.state.datos.length; i++) {
      let fila = [
        this.state.datos[i].name,
        this.state.datos[i].brand,
        this.state.datos[i].description,
        this.state.datos[i].amount,
        this.state.datos[i].purchase_price,
        this.state.datos[i].sale_price,
        "Q" + this.state.datos[i].total
      ];
      filasRecepcion.push(fila);
    }
    pdf.autoTable(columnsRecepcion, filasRecepcion, {
      pageBreak: "avoid",
      headStyles: { fillColor: [211, 84, 0] },
      theme: "grid",
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.addFont('ArialMS', 'Arial', 'normal');
        pdf.setFont('Arial');
        pdf.setFontType("normal");
        var compra = new Image();
        compra.src = Compra;
        if (compra) {
          pdf.addImage(compra, "PNG", data.settings.margin.left, 15, 10, 10);
        }
        pdf.text("VENTA DE RECEPCION", data.settings.margin.left + 15, 22);
      },
      margin: { top: 30 }
    });
    pdf.addPage();
    var filasFarmacia = [];
    for (let i = 0; i < this.state.datosFarmacia.length; i++) {
      let fila = [
        this.state.datosFarmacia[i].name,
        this.state.datosFarmacia[i].brand,
        this.state.datosFarmacia[i].description,
        this.state.datosFarmacia[i].amount,
        this.state.datosFarmacia[i].purchase_price,
        this.state.datosFarmacia[i].sale_price,
        "Q" + this.state.datosFarmacia[i].total
      ];
      filasFarmacia.push(fila);
    }
    pdf.autoTable(columnsRecepcion, filasFarmacia, {
      pageBreak: "avoid",
      headStyles: { fillColor: [183, 149, 11] },
      theme: "grid",
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.addFont('ArialMS', 'Arial', 'normal');
        pdf.setFont('Arial');
        pdf.setFontType("normal");
        var pildora = new Image();
        pildora.src = Pildora;
        if (pildora) {
          pdf.addImage(pildora, "PNG", data.settings.margin.left, 15, 10, 10);
        }
        pdf.text("FARMACIA", data.settings.margin.left + 15, 22);
      },
      margin: { top: 30 }
    });
    pdf.addPage();
    var columnsG = [
      "Nombre",
      "Descripcion",
      "Cantidad de Groomings",
      "Precio de Venta",
      "Total en Ventas"
    ];
    var filasG = [];
    for (let i = 0; i < this.state.datosDetailGrooms.length; i++) {
      let fila = [
        this.state.datosDetailGrooms[i].name,
        this.state.datosDetailGrooms[i].description,
        this.state.datosDetailGrooms[i].amount,
        this.state.datosDetailGrooms[i].sale_price,
        "Q" + this.state.datosDetailGrooms[i].total
      ];
      filasG.push(fila);
    }
    pdf.autoTable(columnsG, filasG, {
      pageBreak: "avoid",
      headStyles: { fillColor: [93, 173, 226] },
      theme: "grid",
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.addFont('ArialMS', 'Arial', 'normal');
        pdf.setFont('Arial');
        pdf.setFontType("normal");
        var groom = new Image();
        groom.src = Groom;
        if (groom) {
          pdf.addImage(groom, "PNG", data.settings.margin.left, 15, 10, 10);
        }
        pdf.text("GROOMING", data.settings.margin.left + 15, 22);
      },
      margin: { top: 30 }
    });
    pdf.addPage();

    var columnsS = [
      "Nombre",
      "Descripcion",
      "Cantidad de Servicios",
      "Precio de Venta",
      "Total en Ventas"
    ];
    var filasS = [];
    for (let i = 0; i < this.state.datosDetailServices.length; i++) {
      let fila = [
        this.state.datosDetailServices[i].name,
        this.state.datosDetailServices[i].description,
        this.state.datosDetailServices[i].amount,
        this.state.datosDetailServices[i].sale_price,
        "Q" + this.state.datosDetailServices[i].total
      ];
      filasS.push(fila);
    }

    pdf.autoTable(columnsS, filasS, {
      pageBreak: "avoid",
      headStyles: { fillColor: [205, 92, 92] },
      theme: "grid",
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.addFont('ArialMS', 'Arial', 'normal');
        pdf.setFont('Arial');
        pdf.setFontType("normal");
        var cachorro = new Image();
        cachorro.src = Cachorro;
        if (cachorro) {
          pdf.addImage(cachorro, "PNG", data.settings.margin.left, 15, 10, 10);
        }
        pdf.text("SERVICIOS CLINICOS", data.settings.margin.left + 15, 22);
      },
      margin: { top: 30 }
    });
    pdf.addPage();

    var columnsD = [
      "Nombre",
      "Marca",
      "Descripcion",
      "Cantidad de Devolunciones",
      "Precio de Venta",
      "Total"
    ];
    var filasD = [];
    for (let i = 0; i < this.state.datosDetailDev.length; i++) {
      let fila = [
        this.state.datosDetailDev[i].name,
        this.state.datosDetailDev[i].brand,
        this.state.datosDetailDev[i].description,
        this.state.datosDetailDev[i].amount,
        this.state.datosDetailDev[i].sale_price,
        "Q" + this.state.datosDetailDev[i].total
      ];
      filasD.push(fila);
    }

    pdf.autoTable(columnsD, filasD, {
      pageBreak: "avoid",
      headStyles: { fillColor: [231, 76, 60] },
      theme: "grid",
      didDrawPage: function(data) {
        // Header
        pdf.setFontSize(20);
        pdf.setTextColor(40);
        pdf.addFont('ArialMS', 'Arial', 'normal');
        pdf.setFont('Arial');
        pdf.setFontType("normal");
        var devolucion = new Image();
        devolucion.src = Devolucion;
        if (devolucion) {
          pdf.addImage(
            devolucion,
            "PNG",
            data.settings.margin.left,
            15,
            10,
            10
          );
        }
        pdf.text("DEVOLUCIONES", data.settings.margin.left + 15, 22);
      },
      margin: { top: 30 }
    });

    pdf.save("Reporte.pdf");
  };

  render() {
    if (this.state.cargaCompleta === false) {
      return <div className="lds-hourglass"></div>;
    }
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div>
            <MaterialTable
              title="DETALLE VENTAS"
              columns={this.state.columnsTotales}
              data={this.state.datosTotales}
              options={{
                headerStyle: {
                  backgroundColor: "rgba(19, 141, 117,0.7)",
                  color: "#FFF"
                },

                search: false,
                paging: false
                // actionsColumnIndex: -1,
              }}
              localization={{
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar"
                },
                body: {
                  emptyDataSourceMessage:
                    "No se encontro ningun recibo relacionado."
                },
                header: {
                  actions: "Acciones"
                }
              }}
            />
          </div>
          <div className="row pt-2">
            <div className="col-1">
              <button
                type="button"
                className="btn btn-danger btn-lg active"
                onClick={this.GenerarPDF}
              >
                <i className="far fa-file-pdf"></i>
              </button>
            </div>
          </div>
          <div className="pt-5">
            <MaterialTable
              title="DETALLE TOTALES"
              columns={this.state.columnsDetalleTotales}
              data={this.state.datosDetalleTotales}
              options={{
                headerStyle: {
                  backgroundColor: "rgba(142, 68, 173,0.7)",
                  color: "#FFF"
                },
                search: false,
                paging: false,

                rowStyle: rowData => ({
                  backgroundColor:
                    rowData.name === "DEVOLUCION EN EFECTIVO" ||
                    rowData.name === "DEVOLUCION CON TARJETA" ||
                    rowData.name === "TOTAL INVERTIDO RECEPCION" ||
                    rowData.name === "TOTAL INVERTIDO FARMACIA"
                      ? "rgba(241, 148, 138,0.5)"
                      : "#FFF"
                })
                // actionsColumnIndex: -1,
              }}
              localization={{
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar"
                },
                body: {
                  emptyDataSourceMessage:
                    "No se encontro ningun total relacionado."
                },
                header: {
                  actions: "Acciones"
                }
              }}
            />
          </div>

          <div className="pt-5">
            <MaterialTable
              title="VENTA DE RECEPCION"
              columns={this.state.columns}
              data={this.state.datos}
              options={{
                headerStyle: {
                  backgroundColor: "rgba(211,84,0,0.8)",
                  color: "#FFF"
                },
                exportButton: true,

                exportFileName: "Venta Recepcion",
                exportAllData: true
                //search: false,
                //paging: false
                // actionsColumnIndex: -1,
              }}
              localization={{
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar"
                },
                body: {
                  emptyDataSourceMessage:
                    "No se encontro ningun producto relacionado."
                },
                header: {
                  actions: "Acciones"
                }
              }}
            />
          </div>

          <div className="pt-5">
            <MaterialTable
              title="FARMACIA"
              columns={this.state.columns}
              data={this.state.datosFarmacia}
              options={{
                headerStyle: {
                  backgroundColor: "rgba(183, 149, 11,0.9)",
                  color: "#FFF"
                },
                exportButton: true,

                exportFileName: "Venta de Farmacia",
                exportAllData: true
                //search: false,
                //paging: false
                // actionsColumnIndex: -1,
              }}
              localization={{
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar"
                },
                body: {
                  emptyDataSourceMessage:
                    "No se encontro ningun producto relacionado."
                },
                header: {
                  actions: "Acciones"
                }
              }}
            />
          </div>
          <div className="pt-5">
            <MaterialTable
              title="GROOMING"
              columns={this.state.columndDetailGroom}
              data={this.state.datosDetailGrooms}
              options={{
                headerStyle: {
                  backgroundColor: "rgba(93, 173, 226,0.9)",
                  color: "#FFF"
                },
                exportButton: true,

                exportFileName: "Servicio de Grooming",
                exportAllData: true
                //search: false,
                //paging: false
                // actionsColumnIndex: -1,
              }}
              localization={{
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar"
                },
                body: {
                  emptyDataSourceMessage:
                    "No se encontro ningun grooming relacionado."
                },
                header: {
                  actions: "Acciones"
                }
              }}
            />
          </div>
          <div className="pt-5">
            <MaterialTable
              title="SERVICIOS CLINICOS"
              columns={this.state.columndDetailServ}
              data={this.state.datosDetailServices}
              options={{
                headerStyle: {
                  backgroundColor: "rgba(205, 92, 92,0.9)",
                  color: "#FFF"
                },
                exportButton: true,

                exportFileName: "Servicios Clinicos",
                exportAllData: true
                //search: false,
                //paging: false
                // actionsColumnIndex: -1,
              }}
              localization={{
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar"
                },
                body: {
                  emptyDataSourceMessage:
                    "No se encontro ningun servicio relacionado."
                },
                header: {
                  actions: "Acciones"
                }
              }}
            />
          </div>
          <div className="pt-5">
            <MaterialTable
              title="DEVOLUCIONES"
              columns={this.state.columnsDev}
              data={this.state.datosDetailDev}
              options={{
                headerStyle: {
                  backgroundColor: "rgba(231, 76, 60,0.9)",
                  color: "#FFF"
                },
                exportButton: true,

                exportFileName: "Devoluciones",
                exportAllData: true
                //search: false,
                //paging: false
                // actionsColumnIndex: -1,
              }}
              localization={{
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar"
                },
                body: {
                  emptyDataSourceMessage:
                    "No se encontro ningun producto relacionado."
                },
                header: {
                  actions: "Acciones"
                }
              }}
            />
          </div>
          <div className="pt-5 pb-5">
            <Pie
              data={this.state.charData}
              options={{
                title: {
                  display: true,
                  text: "Productos de recepcion Mas Vendidos",
                  fontsize: 25
                },
                legend: { display: true, position: "bottom" }
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  totalNumSales: state.Reports.totalNumSales,
  totalSales: state.Reports.totalSales,
  details: state.Reports.details,

  loadingNumber: state.Reports.loadingNumber,
  loadingTotal: state.Reports.loadingTotal,
  loadingDetails: state.Reports.loadingDetails,

  totalcashSales: state.Reports.totalcashSales,
  totalcardSales: state.Reports.totalcardSales,
  loadingcash: state.Reports.loadingcash,
  loadingcard: state.Reports.loadingcard,

  totalGroom: state.Reports.totalGroom,
  totalServices: state.Reports.totalServices,
  loadingTotalGroom: state.Reports.loadingTotalGroom,
  loadingTotalServices: state.Reports.loadingTotalServices,
  detailsGroom: state.Reports.detailsGroom,
  loadingDetailGroom: state.Reports.loadingDetailGroom,

  detailServ: state.Reports.detailServ,
  loadingDetailService: state.Reports.loadingDetailService,

  totalCashDev: state.Reports.totalCashDev,
  loadingTotalDevCash: state.Reports.loadingTotalDevCash,

  totalCardDev: state.Reports.totalCardDev,
  loadingTotalDevCard: state.Reports.loadingTotalDevCard,
  detailsDev: state.Reports.detailsDev,
  loadingdetailDev: state.Reports.loadingdetailDev,

  totalPharm: state.Reports.totalPharm,
  loadingTotalPharm: state.Reports.loadingTotalPharm,
  detailsPharm: state.Reports.detailsPharm,
  loadingDetailPharm: state.Reports.loadingDetailPharm
});

export default connect(
  mapStateToProps,
  {
    getNumberTotalSalesToday,
    getTotalSalesToday,
    getDetailsProductsSalesToday,
    LoadedReportToday,
    getNumberTotalSalesMonth,
    getTotalSalesMonth,
    getDetailsProductsSalesMonth,
    getNumberTotalSalesYear,
    getTotalSalesYear,
    getDetailsProductsSalesYear,
    getTotalCashSalesToday,
    getTotalCardSalesToday,
    getTotaCashlSalesMonth,
    getTotalCardSalesMonth,
    getSumGroomToday,
    getSumServicesToday,
    getSumGroomActualMonth,
    getSumgServicesActualMonth,
    getDetailGroomToday,
    getDetailGroomActualMonth,
    getDetailServicesToday,
    getDetailServicesActualMonth,
    detailProductsRank,
    totalCardRank,
    totalCashRank,
    totalGroomRank,
    detailGroomRank,
    totalServicesRank,
    detailServicesRank,
    getTotalCashDevToday,
    getTotalCardDevToday,
    getTotalCashDevMonth,
    getTotalCardDevMonth,
    getTotalCashDevRank,
    getTotalCardDevRank,
    getdetailsDevToday,
    getdetailsDevMonth,
    getdetailsDevRank,
    getSumPharmacyToday,
    getSumgPharmacyActualMonth,
    getDetailPharmacyToday,
    getDetailPharmacyActualMonth,
    totalPharmacyRank,
    detailPharmacyRank
  }
)(ViewReportsByDate);
