import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  getProfile,
  updateProfile,
  updateProfilePicture
} from "../../actions/ActProfile";
import { createMessage } from "../../actions/ActMessages";
import Loading from "../common/Loading";

class ProfileInfo extends Component {
  state = {
    username: "",
    phone: "",
    first_name: "",
    picture: null
  };

  static propTypes = {
    user: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    getProfile: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired,
    updateProfilePicture: PropTypes.func.isRequired
  };

  updatePicture() {
    var pictureFormData = new FormData();
    pictureFormData.append("picture", this.state.picture);
    this.props.updateProfilePicture(pictureFormData, this.props.profile.id);
    this.props.createMessage({
      profileUpdated: "¡Actualiza la pagina!"
    });
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  handleImageChange = e => {
    /*this.setState({ picture: e.target.files[0] });*/
    const width = 150;
    /*const width = 600;
    
    elem.width = width;
    
     */
    //const height = 120;
    const fileName = e.target.files[0].name;
    const reader = new FileReader();
    const img = new Image();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = event => {
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement("canvas");
        const scaleFactor = width / img.width;
        elem.width = width;
        //elem.height = height;
        elem.height = img.height * scaleFactor;
        const ctx = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        //ctx.drawImage(img, 0, 0, width, height);
        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
        ctx.canvas.toBlob(
          blob => {
            const file = new File([blob], fileName, {
              type: "image/jpeg",
              lastModified: Date.now()
            });
            this.setState({ picture: file });
          },
          "image/jpeg",
          1
        );
      };
      reader.onerror = error => console.log(error);
    };
  };

  async componentDidMount() {
    await this.props.getProfile();
    this.setState({
      username: this.props.user.username,
      phone: this.props.profile.phone,
      first_name: this.props.user.first_name,
      picture: this.props.profile.picture
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    try {
      var bodyFormData = new FormData();

      bodyFormData.append("phone", this.state.phone);
      bodyFormData.append("first_name", this.state.first_name);
      this.props.updateProfile(bodyFormData, this.props.profile.id);
      this.props.createMessage({
        profileUpdated: "¡Tu informacion fue enviada!"
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { username, phone, first_name, picture } = this.state;
    return (
      <React.Fragment>
        {this.props.profile.loading ? (
          <React.Fragment>
            <Loading></Loading>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <div className="testDiv">
          <img src={picture} alt="" />
          <span className="badge badge-success mb-2 ">
            {this.props.user.first_name}
          </span>

          <form onSubmit={this.handleSubmit}>
            <div className="form-row">
              <div className="col">
                <div className="input-group mb-3">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="profileImage"
                      aria-describedby="inputGroupFileAddon01"
                      onChange={this.handleImageChange}
                      name="picture"
                    />
                    <label className="custom-file-label" htmlFor="profileImage">
                      <i className="fas fa-camera-retro">
                        {this.state.picture ? "Foto subida" : "Foto no subida"}
                      </i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row justify-content-center mb-2">
              <div className="col text-center">
                <button
                  className="btn btn-success"
                  onClick={e => {
                    e.preventDefault();

                    this.updatePicture();
                  }}
                >
                  Actualizar foto
                </button>
              </div>
            </div>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fas fa-universal-access"></i>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                id="inlineFormInputGroup"
                placeholder="Nombre"
                name="first_name"
                value={first_name}
                onChange={this.handleChange}
                disabled
              />
            </div>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">@</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="inlineFormInputGroup"
                name="username"
                placeholder="email"
                value={username}
                onChange={this.handleChange}
                disabled
              />
            </div>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fas fa-phone"></i>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                id="inlineFormInputGroup"
                placeholder="phone"
                name="phone"
                value={phone}
                onChange={this.handleChange}
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Actualizar información
            </button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToprops = state => ({
  user: state.RedAuth.user,
  profile: state.Profile
});

export default connect(
  mapStateToprops,
  { getProfile, updateProfile, createMessage, updateProfilePicture }
)(ProfileInfo);
