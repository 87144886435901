import React, { Component } from "react";
import Select from "react-select";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Veterinario from "../../../assets/veterinario.svg";
import Vacuna from "../../../assets/vacuna.svg";
import Medicina from "../../../assets/medicina.svg";
import Aplicacion from "../../../assets/aplicacion.svg";
import Ultrasonido from "../../../assets/ultrasonido.svg";
import { addCommission } from "../../../actions/Commissions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
export class NewCommission extends Component {
  static propTypes = {
    addCommission: PropTypes.func.isRequired
  };

  state = {
    tipo: "",
    doctor: "",
    opciones: [
      { label: "Consulta General", value: "1" },
      { label: "Vacunacion", value: "2" },
      { label: "Desparasitación", value: "3" },
      { label: "Aplicaciones", value: "4" },
      { label: "Imágenes diagnósticas", value: "5" }
    ],
    resultadoTipo: false, //para que salgan los tipos de vacunacion
    opcionesTipo: [],
    subtipos: "",
    tipoFormulario: "",

    namecommission: "",
    precio: 0,
    commission_percentage: 0,
    description: "",
    total_commission: 0,
    size: "",
    type_of_anesthesia: "",
    sizeOption: [
      { label: "S", value: "S" },
      { label: "M", value: "M" },
      { label: "XL", value: "XL" },
      { label: "XXL", value: "XXL" }
    ],
    aplicacionOpcion: [
      { label: "Ant, analg, med.", value: "Ant, analg, med." },
      { label: "Cerenia", value: "Cerenia" },
      { label: "Convenia", value: "Convenia" },
      { label: "Aplicación", value: "Aplicacion" }
    ],
    imagenesOpcion: [
      { label: "US Animal Home", value: "US Animal Home" },
      { label: "US a domicilio", value: "US a domicilio" },
      { label: "US Colegas Animal Home", value: "US Colegas Animal Home" },
      { label: "US Colegas a domicilio", value: "US Colegas a domicilio" }
    ],

    opcionesDesapar: [],
    tiposDesapara: false,
    tipoDespara2: false,
    tipoApliacion: false,
    tipoImagen: false,
    isSearchable: false,
    precioError: "",
    commission_percentageError: ""
  };

  componentDidMount() {
    this.setState({ tipo: this.props.tipo, doctor: this.props.doctor });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidUpdate() {
    if (
      this.state.precio * (this.state.commission_percentage / 100) !==
        this.state.total_commission &&
      (this.state.tipoFormulario === "Consulta" ||
        this.state.tipoFormulario === "Desparacitacion1" ||
        this.state.tipoFormulario === "Desparacitacion2" ||
        this.state.tipoFormulario === "Aplicacion" ||
        this.state.tipoFormulario === "Imagenes")
    ) {
      this.setState({
        total_commission:
          this.state.precio * (this.state.commission_percentage / 100)
      });
    }
  }

  handleChangeOptionSubTipo = e => {
    try {
      //console.log(e.value);
      this.setState({
        namecommission: e.value,
        tipoFormulario: "Vacunacion",
        total_commission: 30
      });
      if (e.value === "Vacunacion Quintuple") {
        this.setState({ precio: 150 });
      } else if (
        e.value === "Vacunacion Sextuple" ||
        e.value === "Vacunacion KV Bordetella"
      ) {
        this.setState({ precio: 160 });
      } else if (
        e.value === "Vacunacion Triple Felina" ||
        e.value === "Vacunacion Leucemia felina"
      ) {
        this.setState({ precio: 175 });
      } else if (e.value === "Vacunacion Antirrabica") {
        this.setState({ precio: 115 });
      }
    } catch (error) {}
  };

  handleChangeDesparaci = e => {
    try {
      if (e.value === "1ra. Desparasitacion") {
        this.setState({
          namecommission: e.value,
          commission_percentage: 15,
          tipoFormulario: "Desparacitacion1",
          precio: 35,
          total_commission: 35 * 0.15,
          tipoDespara2: false,
          precioError: "",
          commission_percentageError: ""
        });
      } else if (e.value === "2da. Desparasitacion") {
        this.setState({
          namecommission: e.value,
          tipoDespara2: true,
          resultadoTipo: false,
          tipoApliacion: false,
          tipoImagen: false,
          tipoFormulario: "",
          precioError: "",
          commission_percentageError: ""
        });
      }
    } catch (error) {}
  };

  handleChangeSize = e => {
    try {
      this.setState({
        size: e.value,
        tipoFormulario: "Desparacitacion2"
      });
      if (e.value === "S" || e.value === "M") {
        this.setState({
          precio: 50,
          commission_percentage: 15,
          total_commission: 50 * 0.15
        });
      } else if (e.value === "XL" || e.value === "XXL") {
        this.setState({
          precio: 85,
          commission_percentage: 15,
          total_commission: 85 * 0.15
        });
      }
    } catch (error) {}
  };

  handleChangeApliacion = e => {
    try {
      this.setState({ namecommission: e.value, tipoFormulario: "Aplicacion" });
      if (e.value === "Ant, analg, med.") {
        this.setState({
          commission_percentage: 15,
          precio: 75,
          total_commission: 75 * 0.15
        });
      } else if (e.value === "Cerenia") {
        this.setState({
          commission_percentage: 15,
          precio: 100,
          total_commission: 100 * 0.15
        });
      } else if (e.value === "Convenia") {
        this.setState({
          commission_percentage: 15,
          precio: 250,
          total_commission: 250 * 0.15
        });
      } else if (e.value === "Aplicacion") {
        this.setState({
          commission_percentage: 15,
          precio: 50,
          total_commission: 50 * 0.15
        });
      }
    } catch (error) {}
  };

  handleChangeImagenes = e => {
    try {
      this.setState({ namecommission: e.value, tipoFormulario: "Imagenes" });
      if (e.value === "US Animal Home") {
        this.setState({
          commission_percentage: 30,
          precio: 250,
          total_commission: 250 * 0.3
        });
      } else if (e.value === "US a domicilio") {
        this.setState({
          commission_percentage: 30,
          precio: 350,
          total_commission: 350 * 0.3
        });
      } else if (e.value === "US Colegas Animal Home") {
        this.setState({
          commission_percentage: 30,
          precio: 200,
          total_commission: 200 * 0.3
        });
      } else if (e.value === "US Colegas a domicilio") {
        this.setState({
          commission_percentage: 30,
          precio: 250,
          total_commission: 250 * 0.3
        });
      }
    } catch (error) {}
  };

  handleChangeOptionCommission = e => {
    try {
      if (e.value === "1") {
        //consulta general
        this.setState({
          tipoFormulario: "Consulta",
          namecommission: "Consulta General",
          precio: 150,
          commission_percentage: 32,
          total_commission: 150 * 0.32,
          resultadoTipo: false,
          tiposDesapara: false,
          tipoDespara2: false,
          tipoApliacion: false,
          tipoImagen: false,
          description: "",
          size: "",
          precioError: "",
          commission_percentageError: ""
        });
      } else if (e.value === "2") {
        //vacunacion
        let subtipos = [
          { label: "Vacunación Quíntuple", value: "Vacunacion Quintuple" },
          { label: "Vacunación Séxtuple", value: "Vacunacion Sextuple" },
          {
            label: "Vacunación KV Bordetella",
            value: "Vacunacion KV Bordetella"
          },
          {
            label: "Vacunación Triple Felina",
            value: "Vacunacion Triple Felina"
          },
          { label: "Vacunación Antirrábica", value: "Vacunacion Antirrabica" },
          {
            label: "Vacunación Leucemia felina",
            value: "Vacunacion Leucemia felina"
          }
        ];
        this.setState({
          opcionesTipo: subtipos,
          subtipos: "Tipos de Vacuna",
          tipoFormulario: "",
          precio: 0,
          resultadoTipo: true,
          tiposDesapara: false,
          tipoDespara2: false,
          tipoApliacion: false,
          tipoImagen: false,
          commission_percentage: 0,
          description: "",
          size: "",
          precioError: "",
          commission_percentageError: ""
        });
      } else if (e.value === "3") {
        let tiposD = [
          { label: "1ra. Desparasitación", value: "1ra. Desparasitacion" },
          { label: "2da. Desparasitación", value: "2da. Desparasitacion" }
        ];
        this.setState({
          subtipos: "Tipos de Desparasitación",
          opcionesDesapar: tiposD,
          tipoFormulario: "",
          precio: 0,
          resultadoTipo: false,
          tiposDesapara: true,
          tipoDespara2: false,
          tipoApliacion: false,
          tipoImagen: false,
          description: "",
          size: "",
          precioError: "",
          commission_percentageError: ""
        });
      } else if (e.value === "4") {
        this.setState({
          subtipos: "Tipos de Aplicacion",
          tipoFormulario: "",
          precio: 0,
          resultadoTipo: false,
          tiposDesapara: false,
          tipoDespara2: false,
          tipoApliacion: true,
          tipoImagen: false,
          description: "",
          size: "",
          precioError: "",
          commission_percentageError: ""
        });
      } else if (e.value === "5") {
        this.setState({
          subtipos: "Tipos de Imagenes Diagnostas",
          tipoFormulario: "",
          precio: 0,
          resultadoTipo: false,
          tiposDesapara: false,
          tipoDespara2: false,
          tipoApliacion: false,
          tipoImagen: true,
          description: "",
          size: "",
          precioError: "",
          commission_percentageError: ""
        });
      }
    } catch (error) {}
  };

  validate = () => {
    let iserror = false;
    const errors = {
      precioError: "",
      commission_percentageError: ""
    };

    if (this.state.precio <= 0) {
      iserror = true;
      errors.precioError = "El Precio debe ser mayor a 0";
    }
    if (
      this.state.commission_percentage < 0 ||
      this.state.commission_percentage > 100
    ) {
      iserror = true;
      errors.commission_percentageError =
        "El porcentaje de comision tiene que estar entre 0% y 100%";
    }
    this.setState({
      ...errors
    });
    return iserror;
  };

  onSubmit = e => {
    e.preventDefault();
    const err = this.validate();
    if (!err) {
      const {
        namecommission,
        tipo,
        precio,
        doctor,
        commission_percentage,
        description,
        total_commission,
        size,
        type_of_anesthesia
      } = this.state;

      const comision = {
        namecommission,
        tipo,
        precio,
        doctor,
        commission_percentage,
        description,
        total_commission,
        size,
        type_of_anesthesia
      };
      this.props.addCommission(comision);
      //console.log(comision);
      this.setState({
        namecommission: "",
        precio: 0,
        commission_percentage: 0,
        description: "",
        total_commission: 0,
        size: "",
        type_of_anesthesia: "",
        resultadoTipo: false,
        tiposDesapara: false,
        tipoDespara2: false,
        tipoApliacion: false,
        tipoImagen: false,
        tipoFormulario: ""
      });
    }
  };

  render() {
    const isSearchable = this.state.isSearchable;
    //Opciones de Vacunacion
    const getopcionesTipo = (
      <div className="row pt-3">
        <div className="col-12">
          <h5>{this.state.subtipos}</h5>
          <Select
            defaultValue={{
              label: "Selecciona",
              value: 0
            }}
            onChange={this.handleChangeOptionSubTipo}
            options={this.state.opcionesTipo}
            isSearchable={isSearchable}
            id="seleccionsubtipos"
          />
        </div>
      </div>
    );

    //opciones de Desparacitacion
    const getopcionesDespara = (
      <div className="row pt-3">
        <div className="col-12">
          <h5>{this.state.subtipos}</h5>
          <Select
            defaultValue={{
              label: "Selecciona",
              value: 0
            }}
            options={this.state.opcionesDesapar}
            id="selecciondespara"
            onChange={this.handleChangeDesparaci}
            isSearchable={isSearchable}
          />
        </div>
      </div>
    );

    //opciones de Tamanio
    const getopcionesTamanio = (
      <div className="row pt-3">
        <div className="col-12">
          <h5>Tamaño de la Mascota</h5>
          <Select
            defaultValue={{
              label: "Selecciona",
              value: 0
            }}
            options={this.state.sizeOption}
            id="seleccionTamanio"
            onChange={this.handleChangeSize}
            isSearchable={isSearchable}
          />
        </div>
      </div>
    );

    //opciones de Aplicacion
    const getOpcionesAplicacion = (
      <div className="row pt-3">
        <div className="col-12">
          <h5>{this.state.subtipos}</h5>
          <Select
            defaultValue={{
              label: "Selecciona",
              value: 0
            }}
            options={this.state.aplicacionOpcion}
            id="seleccionApliacion"
            onChange={this.handleChangeApliacion}
            isSearchable={isSearchable}
          />
        </div>
      </div>
    );

    //Opciones ImagenesDiagnostas
    const getOpcionesImagenes = (
      <div className="row pt-3">
        <div className="col-12">
          <h5>{this.state.subtipos}</h5>
          <Select
            defaultValue={{
              label: "Selecciona",
              value: 0
            }}
            options={this.state.imagenesOpcion}
            id="seleccionImagen"
            onChange={this.handleChangeImagenes}
            isSearchable={isSearchable}
          />
        </div>
      </div>
    );

    /////////////////////////////consulta/////////////////////////////////////////////
    const getFormConsulta = (
      <div className="row justify-content-center pt-3">
        <img
          src={Veterinario}
          className="card-img-top"
          width="100"
          height="100"
          alt="analisis"
        />
        <form onSubmit={this.onSubmit} noValidate>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Precio de Consulta</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="precio de consulta"
                  name="precio"
                  defaultValue={this.state.precio}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  error={this.state.precioError.length === 0 ? false : true}
                />
                <FormHelperText error={true}>
                  {this.state.precioError}
                </FormHelperText>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Porcentage de Comision %</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="porcentage de comision"
                  name="commission_percentage"
                  defaultValue={this.state.commission_percentage}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  error={
                    this.state.commission_percentageError.length === 0
                      ? false
                      : true
                  }
                />
                <FormHelperText error={true}>
                  {this.state.commission_percentageError}
                </FormHelperText>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Total de comision</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="total de comision"
                  name="total_commission"
                  value={this.state.total_commission}
                  readOnly
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Descripcion</label>
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="Descripcion"
                  name="description"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-success btn-block">
            Agregar Comision
          </button>
        </form>
      </div>
    );
    ///////////////////////////Vacunacion//////////////////////////////////////////
    const getFormVacunacion = (
      <div className="row justify-content-center pt-3">
        <img
          src={Vacuna}
          className="card-img-top"
          width="100"
          height="100"
          alt=""
        />
        <form onSubmit={this.onSubmit} noValidate>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Precio de Vacuna</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="precio de vacuna"
                  name="precio"
                  value={this.state.precio}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  error={this.state.precioError.length === 0 ? false : true}
                />
                <FormHelperText error={true}>
                  {this.state.precioError}
                </FormHelperText>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Total de comision</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="total de comision"
                  name="total_commission"
                  value={this.state.total_commission}
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Descripcion</label>
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="Descripcion"
                  name="description"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-success btn-block">
            Agregar Comision
          </button>
        </form>
      </div>
    );

    /////////////////////Desparacitacion////////////////////////////////
    const getPrimerDesp = (
      <div className="row justify-content-center pt-3">
        <img
          src={Medicina}
          className="card-img-top"
          width="100"
          height="100"
          alt="analisis"
        />
        <form onSubmit={this.onSubmit} noValidate>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Precio de Desparasitación</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="precio de Desparasitación"
                  name="precio"
                  value={this.state.precio}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  error={this.state.precioError.length === 0 ? false : true}
                />
                <FormHelperText error={true}>
                  {this.state.precioError}
                </FormHelperText>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Porcentage de Comision %</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="porcentage de comision"
                  name="commission_percentage"
                  value={this.state.commission_percentage}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  error={
                    this.state.commission_percentageError.length === 0
                      ? false
                      : true
                  }
                />
                <FormHelperText error={true}>
                  {this.state.commission_percentageError}
                </FormHelperText>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Total de comision</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="total de comision"
                  name="total_commission"
                  value={this.state.total_commission}
                  readOnly
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Descripcion</label>
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="Descripcion"
                  name="description"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-success btn-block">
            Agregar Comision
          </button>
        </form>
      </div>
    );

    const getAplicaciones = (
      <div className="row justify-content-center pt-3">
        <img
          src={Aplicacion}
          className="card-img-top"
          width="100"
          height="100"
          alt="analisis"
        />
        <form onSubmit={this.onSubmit} noValidate>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Precio de Aplicacion</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="precio de Apliacion"
                  name="precio"
                  value={this.state.precio}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  error={this.state.precioError.length === 0 ? false : true}
                />
                <FormHelperText error={true}>
                  {this.state.precioError}
                </FormHelperText>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Porcentage de Comision %</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="porcentage de comision"
                  name="commission_percentage"
                  value={this.state.commission_percentage}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  error={
                    this.state.commission_percentageError.length === 0
                      ? false
                      : true
                  }
                />
                <FormHelperText error={true}>
                  {this.state.commission_percentageError}
                </FormHelperText>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Total de comision</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="total de comision"
                  name="total_commission"
                  value={this.state.total_commission}
                  readOnly
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Descripcion</label>
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="Descripcion"
                  name="description"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-success btn-block">
            Agregar Comision
          </button>
        </form>
      </div>
    );
    ////////////////////////////////////////////////////////////////////
    const getImagenes = (
      <div className="row justify-content-center pt-3">
        <img
          src={Ultrasonido}
          className="card-img-top"
          width="100"
          height="100"
          alt="analisis"
        />
        <form onSubmit={this.onSubmit} noValidate>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Precio de Imágenes diagnósticas</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="precio de Imágenes diagnósticas"
                  name="precio"
                  value={this.state.precio}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  error={this.state.precioError.length === 0 ? false : true}
                />
                <FormHelperText error={true}>
                  {this.state.precioError}
                </FormHelperText>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group">
                <label>Porcentage de Comision %</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="porcentage de comision"
                  name="commission_percentage"
                  value={this.state.commission_percentage}
                  onChange={this.onChange}
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  error={
                    this.state.commission_percentageError.length === 0
                      ? false
                      : true
                  }
                />
                <FormHelperText error={true}>
                  {this.state.commission_percentageError}
                </FormHelperText>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Total de comision</label>
                <Input
                  className="form-control"
                  type="Number"
                  placeholder="total de comision"
                  name="total_commission"
                  value={this.state.total_commission}
                  readOnly
                  startAdornment={
                    <InputAdornment position="start">Q</InputAdornment>
                  }
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label>Descripcion</label>
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="Descripcion"
                  name="description"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-success btn-block">
            Agregar Comision
          </button>
        </form>
      </div>
    );

    ////////////////////////////////////////////////////////////////////
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row pt-5">
            <div className="col-12">
              <h4>Selecciona el tipo basico</h4>
              <Select
                defaultValue={{
                  label: "Selecciona",
                  value: 0
                }}
                onChange={this.handleChangeOptionCommission}
                options={this.state.opciones}
                isSearchable={isSearchable}
                id="seleccion"
              />
            </div>
          </div>
          {this.state.resultadoTipo ? getopcionesTipo : ""}
          {this.state.tipoFormulario === "Consulta" ? getFormConsulta : ""}
          {this.state.tipoFormulario === "Vacunacion" ? getFormVacunacion : ""}
          {this.state.tiposDesapara ? getopcionesDespara : ""}
          {this.state.tipoFormulario === "Desparacitacion1"
            ? getPrimerDesp
            : ""}
          {this.state.tipoDespara2 ? getopcionesTamanio : ""}
          {this.state.tipoFormulario === "Desparacitacion2"
            ? getPrimerDesp
            : ""}
          {this.state.tipoApliacion ? getOpcionesAplicacion : ""}
          {this.state.tipoFormulario === "Aplicacion" ? getAplicaciones : ""}
          {this.state.tipoImagen ? getOpcionesImagenes : ""}
          {this.state.tipoFormulario === "Imagenes" ? getImagenes : ""}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { addCommission }
)(NewCommission);
