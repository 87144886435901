import React, { Component } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { getClinicFile } from "../../actions/ActClinicfile";
import { getPetHistory } from "../../actions/ActApointments";
import PropTypes from "prop-types";

class PetHistoryDetail extends Component {
  static propTypes = {
    getClinicFile: PropTypes.func.isRequired,
    getPetHistory: PropTypes.func.isRequired,
    apointments: PropTypes.array.isRequired,
    file: PropTypes.object,
  };

  componentDidMount() {
    this.props.getPetHistory(this.props.petID);
  }
  render() {
    return (
      <React.Fragment>
        <div
          className="container-fluid"
          style={{ overflowY: "scroll", maxHeight: "50vh", flexBasis: "100%" }}
        >
          <MaterialTable
            localization={{
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Fecha",
              },
              body: {
                emptyDataSourceMessage: "No hay citas registradas.",
              },
              header: {
                actions: "Mas",
              },
            }}
            options={{
              pageSize: 3,
              headerStyle: {
                backgroundColor: "#222f3e",
                color: "white",
                paddingLeft: "20px",
              },
              exportButton: false,
              actionsColumnIndex: -1,
              detailPanelColumnAlignment: "right",
            }}
            columns={[
              {
                title: "Cliente",
                field: "cliente.first_name",
              },
              {
                title: "Fecha",
                field: "date",
              },
            ]}
            data={this.props.apointments}
            title="Historial"
            /*actions={[
              {
                icon: "add",
                tooltip: "Mostrar resumen clínico",
                onClick: (event, rowData) => {
                  event.preventDefault();
                  if (rowData) {
                    this.props.getClinicFile(rowData.id);
                  }
                }
              }
            ]}*/
            onRowClick={(event, rowData) => {
              event.preventDefault();
              if (rowData) {
                this.props.getClinicFile(rowData.id);
              }
            }}
          />
          <div
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
            style={this.props.inLineStyles}
          >
            <strong className="text-primary text-center">
              <h3>Detalles de la cita</h3>
            </strong>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    {this.props.file ? (
                      <table className="table">
                        <thead>
                          <tr className="bg-primary">
                            <th scope="col">Nombre</th>
                            <th scope="col">Descripción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.file.anamnesis ? (
                            <tr>
                              <td>Anamnesis</td>
                              <td>{this.props.file.anamnesis}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.alimentacion ? (
                            <tr>
                              <td>Alimentacion</td>
                              <td>{this.props.file.alimentacion}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.md ? (
                            <tr>
                              <td>Medio ambiente</td>
                              <td>{this.props.file.md}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.mp ? (
                            <tr>
                              <td>Medicamentos previos</td>
                              <td>{this.props.file.mp}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.vacunado ? (
                            <tr>
                              <td>Vacunado</td>
                              <td>{this.props.file.vacunado}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.desparacitado ? (
                            <tr>
                              <td>Desparacitado</td>
                              <td>{this.props.file.desparacitado}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.temperatura ? (
                            <tr>
                              <td>Temperatura</td>
                              <td>{this.props.file.temperatura}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.peso ? (
                            <tr>
                              <td>Peso</td>
                              <td>{this.props.file.peso}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.fc1 ? (
                            <tr>
                              <td>Frecuencia cardiaca</td>
                              <td>{this.props.file.fc1}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.presionArterial ? (
                            <tr>
                              <td className="text-danger">Presión arterial</td>
                              <td>{this.props.file.presionArterial}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.fc2 ? (
                            <tr>
                              <td>Frecuencia cardiaca</td>
                              <td>{this.props.file.fc2}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.fr ? (
                            <tr>
                              <td>Frecuencia respiratoria</td>
                              <td>{this.props.file.fr}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.condicionc ? (
                            <tr>
                              <td>Condicion corporal</td>
                              <td>{this.props.file.condicionc}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.piel ? (
                            <tr>
                              <td>Piel</td>
                              <td>{this.props.file.piel}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.ojos ? (
                            <tr>
                              <td>Ojos</td>
                              <td>{this.props.file.ojos}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.oidos ? (
                            <tr>
                              <td>Oidos</td>
                              <td>{this.props.file.oidos}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.nariz ? (
                            <tr>
                              <td>Nariz</td>
                              <td>{this.props.file.nariz}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.boca ? (
                            <tr>
                              <td>Boca</td>
                              <td>{this.props.file.boca}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.cardio ? (
                            <tr>
                              <td>Cardio</td>
                              <td>{this.props.file.cardio}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.respiratorio ? (
                            <tr>
                              <td>Respiratorio</td>
                              <td>{this.props.file.respiratorio}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.abdomen ? (
                            <tr>
                              <td>Abdomen</td>
                              <td>{this.props.file.abdomen}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.gastro ? (
                            <tr>
                              <td>Gastro</td>
                              <td>{this.props.file.gastro}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.urogenital ? (
                            <tr>
                              <td>Urogenital</td>
                              <td>{this.props.file.urogenital}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.linfaticos ? (
                            <tr>
                              <td>Linfaticos</td>
                              <td>{this.props.file.linfaticos}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.musculos ? (
                            <tr>
                              <td>Musculos</td>
                              <td>{this.props.file.musculos}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.neuro ? (
                            <tr>
                              <td>Neuro</td>
                              <td>{this.props.file.neuro}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.ad ? (
                            <tr>
                              <td>Ayuda diagnostica</td>
                              <td>{this.props.file.ad}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.diagnostico ? (
                            <tr>
                              <td>Diagnostico</td>
                              <td>{this.props.file.diagnostico}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}

                          {this.props.file.tratamiento ? (
                            <tr>
                              <td>Tratamiento</td>
                              <td>{this.props.file.tratamiento}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                          {this.props.file.observaciones ? (
                            <tr>
                              <td>Observaciones</td>
                              <td>{this.props.file.observaciones}</td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <ul>
                        <li>Nada fuera de lo común</li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  file: state.History.file,
  apointments: state.RedApoint.apointments,
});

export default connect(mapStateToProps, { getClinicFile, getPetHistory })(
  PetHistoryDetail
);
