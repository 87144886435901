import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCategories } from "../../../actions/Categories";
import { getBrands } from "../../../actions/Brands";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";

import { updateProduct } from "../../../actions/Products";
import { Redirect } from "react-router-dom";

export class UpdateProduct extends Component {
  static propTypes = {
    brands: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    getCategories: PropTypes.func.isRequired,
    getBrands: PropTypes.func.isRequired,
    updateProduct: PropTypes.func.isRequired,
    finishUpdate: PropTypes.func,

    auth: PropTypes.object.isRequired
  };

  state = {
    varcode: "",
    selectedCategory: "",
    selectedBrand: "",

    id_product: "",
    name: "",
    stock: "",
    purchase_price: "",
    sale_price: "",
    description: "",

    nameError: "",
    stockError: "",
    category_Error: "",
    brand_error: "",
    purchase_priceError: "",
    sale_priceError: "",
    Enstock: "",
    EnStockError: "",
    redirect: false
  };

  validate = () => {
    let iserror = false;

    const errors = {
      nameError: "",
      stockError: "",
      category_Error: "",
      brand_error: "",
      purchase_priceError: "",
      sale_priceError: "",
      EnStockError: ""
    };

    if (this.state.name === "") {
      iserror = true;
      errors.nameError = "Debe de ingresar el numero del producto";
    } else if (
      this.state.selectedCategory === "" ||
      this.state.selectedCategory === null
    ) {
      iserror = true;
      errors.category_Error = "Debe escoger una Categoria para el producto";
    } else if (
      this.state.selectedBrand === "" ||
      this.state.selectedBrand === null
    ) {
      iserror = true;
      errors.brand_error = "Debe de escoger una Marca para el producto";
    } else if (this.state.stock < 0) {
      iserror = true;
      errors.stockError =
        "Ingrese una cantidad correcta de productos a agregar";
    } else if (this.state.purchase_price < 0) {
      iserror = true;
      errors.purchase_priceError =
        "Ingrese un precio de compra igual a 0 o mayor";
    } else if (this.state.sale_price < 0) {
      iserror = true;
      errors.sale_priceError = "Ingrese un precio de Venta igual a 0 o mayor";
    } else if (this.state.Enstock < 0) {
      iserror = true;
      errors.EnStockError =
        "La cantidad de Productos debe ser igual a 0 o mayor";
    }

    this.setState({
      ...errors
    });
    return iserror;
  };

  handleChangeCat = e => {
    try {
      this.setState({ selectedCategory: e.value });
    } catch (error) {
      this.setState({ selectedCategory: "" });
    }
  };

  handleChangeBrand = e => {
    try {
      this.setState({ selectedBrand: e.value });
    } catch (error) {
      this.setState({ selectedBrand: "" });
    }
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onSubmit = e => {
    e.preventDefault();

    const err = this.validate();
    if (!err) {
      //SI NO HAY NINGUN ERROR ACTUALIZA
      let id_product = this.state.id_product;
      let name = this.state.name;
      let cantidadTotal;
      let precio_compra;
      let precio_venta;
      if (this.state.stock !== "" && this.props.auth.rol !== "administradora") {
        cantidadTotal = parseInt(this.props.stock) + parseInt(this.state.stock);
      } else if (this.props.auth.rol === "administradora") {
        if (this.state.Enstock === "") {
          if (this.state.stock !== "") {
            cantidadTotal =
              parseInt(this.props.stock) + parseInt(this.state.stock);
          } else {
            cantidadTotal = parseInt(this.props.stock);
          }
        } else {
          if (this.state.stock !== "") {
            cantidadTotal =
              parseInt(this.state.Enstock) + parseInt(this.state.stock);
          } else {
            cantidadTotal = parseInt(this.state.Enstock);
          }
        }
      } else {
        cantidadTotal = parseInt(this.props.stock);
      }
      let categoria = this.state.selectedCategory;
      let marca = this.state.selectedBrand;
      let descripcion = this.state.description;
      if (this.props.auth.rol !== "administradora") {
        precio_compra = this.props.purchase_price;
        precio_venta = this.props.sale_price;
      } else {
        precio_compra = this.state.purchase_price;
        precio_venta = this.state.sale_price;
      }

      this.setState({
        id_product,
        name,
        cantidadTotal,
        categoria,
        marca,
        descripcion,
        precio_compra,
        precio_venta
      });

      this.props.updateProduct(
        id_product,
        name,
        cantidadTotal,
        categoria,
        marca,
        descripcion,
        precio_compra,
        precio_venta
      );

      this.setState({
        name: "",
        purchase_price: "",
        sale_price: "",
        description: "",
        stock: "",
        varcode: "",
        selectedCategory: "",
        selectedBrand: ""
      });
      this.setState({ redirect: true });
    }
  };

  componentDidMount() {
    this.setState({
      id_product: this.props.id_product,
      name: this.props.name,
      selectedCategory: this.props.idCategory,
      selectedBrand: this.props.idbrand,
      purchase_price: this.props.purchase_price,
      sale_price: this.props.sale_price,
      description: this.props.description
    });
    this.props.getCategories();
    this.props.getBrands();
  }

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/products" result={"varcode"} />;
    }

    const stockAmin = (
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="form-group">
          <label>Cantidad en Stock</label>
          <TextField
            className="form-control"
            type="Number"
            name="Enstock"
            onChange={this.onChange}
            defaultValue={this.props.stock}
            helperText={this.state.EnStockError}
            error={this.state.EnStockError.length === 0 ? false : true}
          />
        </div>
      </div>
    );

    const stockSecretaria = (
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="form-group">
          <label>Cantidad en Stock</label>
          <input
            className="form-control text-light bg-dark"
            type="Number"
            name="Enstock"
            value={this.props.stock}
            readOnly
          />
        </div>
      </div>
    );

    const purchasepriceAdmin = (
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="form-group">
          <label>Precion de Compra</label>
          <TextField
            className="form-control"
            type="Number"
            placeholder="Precio de Compra"
            name="purchase_price"
            defaultValue={this.props.purchase_price}
            onChange={this.onChange}
            helperText={this.state.purchase_priceError}
            error={this.state.purchase_priceError.length === 0 ? false : true}
          />
        </div>
      </div>
    );

    const purchasepriceSecre = (
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="form-group">
          <label>Precion de Compra</label>
          <input
            className="form-control text-light bg-dark"
            type="Number"
            placeholder="Precio de Compra"
            name="purchase_price"
            value={this.props.purchase_price}
            readOnly
          />
        </div>
      </div>
    );

    const salepriceAdmin = (
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="form-group">
          <label>Precion de Venta</label>
          <TextField
            className="form-control"
            type="Number"
            placeholder="Precio de Venta"
            name="sale_price"
            defaultValue={this.props.sale_price}
            onChange={this.onChange}
            helperText={this.state.sale_priceError}
            error={this.state.sale_priceError.length === 0 ? false : true}
          />
        </div>
      </div>
    );

    const salepriceSecre = (
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="form-group">
          <label>Precion de Venta</label>
          <input
            className="form-control text-light bg-dark"
            type="Number"
            placeholder="Precio de Venta"
            name="sale_price"
            value={this.props.sale_price}
            readOnly
          />
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div className="card card-body mt-4 mb-4">
          <form onSubmit={this.onSubmit} noValidate>
            <h1 className="text-center text-success">Actualizar Producto</h1>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label>Nombre de Producto</label>
                  <TextField
                    className="form-control"
                    type="text"
                    placeholder="Nombre de Producto"
                    defaultValue={this.props.name}
                    onChange={this.onChange}
                    name="name"
                    helperText={this.state.nameError}
                    error={this.state.nameError.length === 0 ? false : true}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {this.props.auth.rol === "administradora"
                ? stockAmin
                : stockSecretaria}

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Cantidad a agregar en el Stock</label>
                  <TextField
                    className="form-control"
                    type="Number"
                    placeholder="cantidad de producto"
                    name="stock"
                    onChange={this.onChange}
                    helperText={this.state.stockError}
                    error={this.state.stockError.length === 0 ? false : true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {this.props.auth.rol === "administradora"
                ? purchasepriceAdmin
                : purchasepriceSecre}
              {this.props.auth.rol === "administradora"
                ? salepriceAdmin
                : salepriceSecre}
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label>Codigo de Barras</label>
                  <input
                    className="form-control text-light bg-dark"
                    type="text"
                    id="barcode"
                    value={this.props.varcode}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Categoria</label>
                  <Select
                    defaultValue={{
                      label: this.props.nameCategory,
                      value: this.props.idCategory
                    }}
                    options={this.props.categories.map(category => ({
                      value: category.id,
                      label: category.name
                    }))}
                    name="selectedCategory"
                    onChange={this.handleChangeCat}
                  />
                  <small className="alert-danger">
                    {this.state.category_Error}
                  </small>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label>Marca</label>
                  <Select
                    defaultValue={{
                      label: this.props.nameBrand,
                      value: this.props.idbrand
                    }}
                    options={this.props.brands.map(brand => ({
                      value: brand.id,
                      label: brand.name
                    }))}
                    name="selectedBrand"
                    onChange={this.handleChangeBrand}
                  />
                  <small className="alert-danger">
                    {this.state.brand_error}
                  </small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label>Descripcion</label>
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="Descripcion"
                    name="description"
                    defaultValue={this.props.description}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Actualizar Producto
            </button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.Categories.categories,
  brands: state.Brands.brands,
  auth: state.RedAuth
});
export default connect(
  mapStateToProps,
  {
    getCategories,
    getBrands,
    updateProduct
  }
)(UpdateProduct);
