import React, { Component } from "react";

class BeforeClinicAppointment extends Component {
  render() {
    const { alimentacion, md, mp, vacunado, desparacitado } = this.props.state;
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-bone"></i>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              id="inlineFormInputGroup"
              placeholder="Alimentacion"
              name="alimentacion"
              value={alimentacion || ""}
              onChange={this.props.onChange}
            />
          </div>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fab fa-amilia"></i>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              id="inlineFormInputGroup"
              name="md"
              placeholder="Medio ambiente"
              value={md || ""}
              onChange={this.props.onChange}
            />
          </div>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-pills"></i>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              id="inlineFormInputGroup"
              placeholder="Medicamentos previos"
              name="mp"
              value={mp || ""}
              onChange={this.props.onChange}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="inputGroupSelect01">
                Vacunado
              </label>
            </div>
            <select
              className="custom-select"
              id="inputGroupSelect01"
              onChange={this.props.onChange}
              name="vacunado"
              value={vacunado || ""}
            >
              <option defaultValue="DUDOSO">Elige una opcion...</option>
              <option value="SI">Si</option>
              <option value="NO">No</option>
              <option value="DUDOSO">Dudoso</option>
            </select>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="inputGroupSelect01">
                Desparacitado
              </label>
            </div>
            <select
              className="custom-select"
              id="inputGroupSelect01"
              onChange={this.props.onChange}
              name="desparacitado"
              value={desparacitado || ""}
            >
              <option defaultValue="DUDOSO">Elige una opcion...</option>
              <option value="SI">Si</option>
              <option value="NO">No</option>
              <option value="DUDOSO">Dudoso</option>
            </select>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default BeforeClinicAppointment;
