import React, { Component } from "react";
import { connect } from "react-redux";
import { updatePet } from "../../actions/ActPets";
import { createMessage } from "../../actions/ActMessages";
import PropTypes from "prop-types";

class Form extends Component {
  races = [
    { name: "Sin definir" },
    { name: "Afgano" },
    { name: "American bully" },
    { name: "Airedale Terrier" },
    { name: "Akita" },
    { name: "Alaskan Malamute" },
    { name: "American Staffordshire Terrier" },
    { name: "Australian Terrier" },
    { name: "Basenji" },
    { name: "Basset hound" },
    { name: "Beagle" },
    { name: "Bichón Frisé" },
    { name: "Booldhound" },
    { name: "Border Collie" },
    { name: "Border Terrier" },
    { name: "Boston Terrier" },
    { name: "Bouviwe des flandres" },
    { name: "Boxer" },
    { name: "Bull Terrrier" },
    { name: "Bulldog Francés" },
    { name: "Bulldog Inglés" },
    { name: "Bullmastiff" },
    { name: "Chihuahua" },
    { name: "Chow Chow" },
    { name: "Cocker spaniel" },
    { name: "Collie" },
    { name: "Corgi" },
    { name: "Crestado chino" },
    { name: "Dachshund" },
    { name: "Doberman" },
    { name: "Doberman Pinscher" },
    { name: "Dálmata" },
    { name: "English cocker spaniel" },
    { name: "Esquimal Americano" },
    { name: "Fox Terrier" },
    { name: "Golden retriever" },
    { name: "Gran Danés" },
    { name: "Greyhoud" },
    { name: "Griffon de Bruselas" },
    { name: "Husky Siberiano" },
    { name: "Labrador retriever" },
    { name: "Lhasa Apso" },
    { name: "Malinois Belga" },
    { name: "Maltés" },
    { name: "Mastiff" },
    { name: "Mestizo" },
    { name: "Papillon" },
    { name: "Pastor Alemán" },
    { name: "Pastor Australiano" },
    { name: "Pastor belga" },
    { name: "Pastor Shetland" },
    { name: "Pequinés" },
    { name: "Pinscher miniatura" },
    { name: "Pitbull" },
    { name: "Pointer" },
    { name: "Pomeranian" },
    { name: "Poodle" },
    { name: "Pug" },
    { name: "Rhodesian Ridgeback" },
    { name: "Rottweiler" },
    { name: "Samoyedo" },
    { name: "Schnauzer" },
    { name: "Scottish Terrier" },
    { name: "Setter inglés" },
    { name: "Shar pei" },
    { name: "Shiba Inu" },
    { name: "Shih Tzu" },
    { name: "Silky Terrier" },
    { name: "Staffordshire Bull Terrier" },
    { name: "Viejo pastor inglés" },
    { name: "Weimaraner" },
    { name: "West Highland White Terrier" },
    { name: "Whippet" },
    { name: "Yorkshire Terrier" },
  ];

  state = {
    name: "",
    age: "",
    species: "",
    race: "",
    color: "",
    reproductive_state: "",
    registered_on: "",
    date_joined: "",
  };

  monthDiff = (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    // edit: increment months if d2 comes later in its month than d1 in its month
    if (d2.getDate() >= d1.getDate()) months++;
    // end edit
    return months <= 0 ? 0 : months;
  };

  componentDidMount() {
    let {
      name,
      age,
      color,
      age_months,
      species,
      race,
      reproductive_state,
      registered_on,
    } = this.props;
    const ownerJoinedDate = new Date(this.props.owner.date_joined);
    const currentDate = new Date();
    const timeDiff = Math.abs(
      currentDate.getTime() - ownerJoinedDate.getTime()
    );
    const ageInYears = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25));
    const currentPetAge = age + ageInYears;

    this.setState({
      name,
      age: currentPetAge,
      age_months,
      color,
      species,
      race,
      reproductive_state,
      registered_on,
    });
  }

  static propTypes = {
    updatePet: PropTypes.func.isRequired,
  };

  handleDisablePet = (e) => {
    e.preventDefault();
    console.log("Desactivar mascota");
    const response = this.props.updatePet({ status: false }, this.props.id);
    console.log(response);
    this.props.createMessage({
      addPet: `Puede que tome un momento, pero la informacion de ${this.state.name} ha sido actualizada`,
    });
    setTimeout(function () {
      window.location.reload();
    }, 1000);
    // window.location.reload();
  };

  onSubmit() {
    let { name, age, color, age_months, species, race } = this.state;
    age = this.props.age;
    age_months = this.props.age_months;

    let pet = {
      name,
      age,
      color,
      age_months,
      species,
      race,
      reproductive_state:
        this.state.reproductive_state === "Entero" ? "ENT" : "EST",
    };
    console.log(this.props.id);
    this.props.updatePet(pet, this.props.id);
    this.props.createMessage({
      addPet: `Puede que tome un momento, pero la informacion de ${pet.name} ha sido actualizada`,
    });
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  }

  onChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { name, age, color, age_months, species, race, reproductive_state, date_joined } = this.state;
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Nombre"
                  value={name}
                  name="name"
                  onChange={this.onChange}
                  required
                />
              </div>
              <small>Registrado el: {this.state.registered_on}</small>
            </div>
            <div className="col d-flex flex-column justify-content-start align-items-start">
              <label htmlFor="">Edad en años y meses: </label>
              <strong>{this.state.age}</strong>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Años</label>
                <input
                  type="Number"
                  className="form-control"
                  id="inputName"
                  placeholder="Edad en números"
                  value={age}
                  name="age"
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>meses</label>
                <input
                  type="Number"
                  className="form-control"
                  id="inputName"
                  placeholder="0"
                  value={age_months}
                  name="age_months"
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Especie</label>
                <div class="input-group mb-3">
                  <select
                    class="custom-select"
                    id="inputGroupSelect01"
                    name="species"
                    onChange={this.onChange}
                    value={species}
                    required
                  >
                    <option selected value="default">
                      Elíge una opción...
                    </option>
                    <option value="Gato">Gato</option>
                    <option value="Perro">Perro</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="options-container">
                <div className="form-group">
                  <label>Raza</label>
                  <div class="input-group mb-3">
                    <select
                      class="custom-select"
                      id="inputGroupSelect01"
                      name="race"
                      onChange={this.onChange}
                      value={race}
                      required
                    >
                      <option selected value="default">
                        Elíge una opción...
                      </option>
                      {this.races.map((l) => {
                        return (
                          <option value={l.name} key={l.name}>
                            {l.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 mt-2">
              <div className="form-group">
                <label>Color</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputPhone"
                  placeholder="Color de pelo"
                  value={color}
                  name="color"
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
            <div className="col-6 mt-2">
              <div className="form-group">
                <label>
                  Estado reproductivo <strong>{reproductive_state}</strong>
                </label>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <button
                type="button"
                className="btn btn-danger"
                onClick={this.handleDisablePet}
              >
                Dar de baja
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.onSubmit()}
              >
                Actualizar
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default connect(null, { updatePet, createMessage })(Form);
