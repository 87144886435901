import React, { Component, Fragment } from "react";
import Pet from "../../components/Forms/PetModify";
import PropTypes from "prop-types";
import dogOnWindow from "../../assets/icons/dogOnWindow.svg";

import MaterialTable from "material-table";

import { connect } from "react-redux";
import { getAllPets } from "../../actions/ActPets";
import { getPetHistory } from "../../actions/ActApointments";
import { getClinicFile } from "../../actions/ActClinicfile";
import {searchPets} from "../../actions/ActPets";
class AllPetsManagement extends Component {
  state = {
    selectedPetTodeliver: null,
    petDelivered: null,
    showEditModal: false,
    petID: null,
    selectedPet: null
  };
  static propTypes = {
    getPetHistory: PropTypes.func.isRequired,
    apointments: PropTypes.array.isRequired,
    pets: PropTypes.array.isRequired,
    getClinicFile: PropTypes.func.isRequired,
    getAllPets: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    file: PropTypes.object,
    searchPets: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getAllPets();
  }

  render() {
    return (
      <Fragment>
        {this.state.showEditModal ? (
          <Modal
            show={() => {
              this.setState({ showEditModal: true });
            }}
            handleClose={() => {
              this.setState({ showEditModal: false });
            }}
          >
            <Pet {...this.state.selectedPet}></Pet>
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className="row"
                style={{
                  /*height: "6rem",*/
                  color: "black",
                  backgroundColor: "#dfe6e9",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2em",
                  marginBottom: "2em",
                }}
              >
                <div className="col-xl-4 col-lg-4 col-md-8 col-sm-8">
                  <div className="col text-center">
                    <h4>Mascotas registradas dentro del sistema</h4>
                    <img
                      src={dogOnWindow}
                      alt=""
                      style={{ maxWidth: "8rem" }}
                    />
                  </div>
                </div>

                <div className="col-12 d-flex pb-0 pt-0 justify-content-center align-items-center">
                  <form
                    className="row row-cols-lg-auto g-3 align-items-center m-2"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.props.searchPets(
                        document.getElementById("SearchQuery").value
                      );
                    }}
                  >
                    <div className="col-8">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="fa fa-search"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Nombre, Apellido, Telefono, Correo"
                          id="SearchQuery"
                          name="query"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <button type="submit" className="btn btn-primary">
                        Buscar
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div
                  className="row"
                  style={{
                    alignItems: "center",
                    padding: "0rem",
                    overflow: "scroll",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ padding: "0rem" }}>
                    {this.props.pets.length > 0 ? (
                      <MaterialTable
                        localization={{
                          toolbar: {
                            searchTooltip: "Buscar",
                            searchPlaceholder: "Paciente, Dueño y fecha",
                          },
                          body: {
                            emptyDataSourceMessage: "Ningún registro",
                          },
                        }}
                        options={{
                          pageSize: 10,
                          headerStyle: {
                            backgroundColor: "black",
                            color: "white",
                            paddingLeft: "20px",
                          },
                          exportButton: true,
                          /*actionsColumnIndex: -1,*/
                          detailPanelColumnAlignment: "right",
                        }}
                        columns={[
                          {
                            title: "Dueño username",
                            field: "owner.username",
                          },
                          {
                            title: "Dueño nombre",
                            field: "owner.first_name",
                          },
                          {
                            title: "Paciente",
                            field: "name",
                          },
                          {
                            title: "Registrado",
                            field: "registered_on",
                          },
                        ]}
                        data={this.props.pets.filter(
                          (pet) => pet.status === true
                        )}
                        title="Mascotas"
                        onRowClick={(event, rowData) => {
                          event.preventDefault();
                          this.setState({
                            petID: rowData.id,
                            selectedPet: rowData,
                          });
                          this.setState({ showEditModal: true });
                        }}
                      />
                    ) : (
                      <h1>Sin datos para mostrar...</h1>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      {" "}
      <section
        className="modal-main clients-frame"
        style={{ overflow: "scroll" }}
      >
        <span class="circle" onClick={handleClose}></span>
        {children}
      </section>
    </div>
  );
};

const mapStateToProps = state => ({
  apointments: state.RedApoint.apointments,
  pets: state.RedPets.pets,
  user: state.RedAuth.user,
  file: state.History.file
});

export default connect(mapStateToProps, {
  getAllPets,
  getClinicFile,
  getPetHistory,
  searchPets
})(AllPetsManagement);
