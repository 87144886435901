import axios from "axios";
import { returnErrors, createMessage } from "./ActMessages";
import {
  ADD_RECEIPT,
  ADD_RECEIPT_DETAIL,
  GET_RECEIPT_HEADER,
  LOADED_RECEIPT_HEADER,
  GET_ALLRECEIPT_HEADER,
  GET_DETAILS_HEADER,
  GET_ALLDETAILS_HEADER,
  LOADED_DETAILS,
  DELETE_DETAILS_SALES,
  __BASE__URL,
  DELETE_SALE
} from "./types";
import { tokenConfig } from "./ActAuths";

export const loadedDetails = () => dispatch => {
  //solo sirve para indicar que el producto buscado por codigo de barras ya se cargo
  dispatch({ type: LOADED_DETAILS });
};

//ADD RECECEIPT
export const addreceipt = receipt => (dispatch, getState) => {
  const url = __BASE__URL + "/api/receipts/";
  const body = JSON.stringify({
    ...receipt
  });
  //console.log(body);
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      //dispatch(createMessage({ addreceipt: "Venta Exitosa!" }));
      dispatch({
        type: ADD_RECEIPT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//GET RECEIPT HEADER SOLO OBTIENE EL ULTIMO INGRESADO QUE ES EL QUE NECESITO PARA AGREGAR EL DETALLE
export const getReceiptHeader = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/receiptsheader/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_RECEIPT_HEADER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//GET ALL RECEIPT HEADERS
export const getALLReceiptHeader = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/receipts/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_ALLRECEIPT_HEADER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

export const LoadedHeader = () => dispatch => {
  //para volver falsa la condicion despues de cargado el encabezado del recibo
  dispatch(createMessage({ addreceipt: "Venta Exitosa!" }));
  dispatch({ type: LOADED_RECEIPT_HEADER });
};

//ADD RECEIPT DETAIL
export const addreceiptdetail = receiptdetail => (dispatch, getState) => {
  const url = __BASE__URL + "/api/receiptsDetail/";
  const body = JSON.stringify({
    ...receiptdetail
  });
  //console.log(body);
  axios
    .post(url, body, tokenConfig(getState))
    .then(res => {
      //dispatch(createMessage({ addreceipt: "Venta Exitosa!" }));
      dispatch({
        type: ADD_RECEIPT_DETAIL
        //payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};

//GET DEAILS OF HEADER
export const getDeatailsOfHeader = id_sale => (dispatch, getState) => {
  const body = JSON.stringify({
    id_sale
  });

  const url = __BASE__URL + "/api/receiptsDetail/details";
  try {
    axios
      .post(url, body, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_DETAILS_HEADER,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch(returnErrors(err?.response?.data, err?.response?.data));
      });
  } catch (error) {
    console.log(error);
  }
};
//GET ALL DETAILS
export const getAllDetails = () => (dispatch, getState) => {
  const url = __BASE__URL + "/api/receiptsDetail/";
  axios
    .get(url, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_ALLDETAILS_HEADER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};


//-----------DELETE DETAILS--------------
export const deletedetailsSales = id => (dispatch, getState) => {
  const url = __BASE__URL + "/api/detailsDelete/";
   try {
  axios
    .delete(url + id, tokenConfig(getState))
    .then(res => {
      //mensahe
      dispatch(
        //createMessage({ deleteCategory: "Categoria Eliminada con Exito!" })
      );
      dispatch({
        type: DELETE_DETAILS_SALES,
        payload: id
      });
    })
    .catch(err =>
      console.log(err)
      //dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
   }catch (error) {
    //console.log(error);
  }
};


export const deleteSales = id => (dispatch, getState) => {
  const url = __BASE__URL + "/api/receipts/";
  axios
    .delete(url + id, tokenConfig(getState))
    .then(res => {
      //mensahe
      dispatch(
        createMessage({ deleteSale: "Venta Eliminada Con exito!" })
      );
      dispatch({
        type: DELETE_SALE,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err?.response?.data, err?.response?.data))
    );
};