import {
  ADD_RECEIPT,
  GET_RECEIPT_HEADER,
  LOADED_RECEIPT_HEADER,
  ADD_RECEIPT_DETAIL,
  GET_ALLRECEIPT_HEADER,
  GET_DETAILS_HEADER,
  GET_ALLDETAILS_HEADER,
  LOADED_DETAILS,
  DELETE_DETAILS_SALES,
  DELETE_SALE
} from "../actions/types";

const initialState = {
  receipts: [],
  details: [],
  loading: false,
  loadedHeader: false,
  loadingDetail:false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_RECEIPT:
      return {
        ...state,
        receipts: [...state.receipts, state.payload],
        loading: true
      };
    case GET_RECEIPT_HEADER:
      return {
        ...state,
        receipts: action.payload,
        loading: false,
        loadedHeader: true
      };
    case LOADED_RECEIPT_HEADER:
      return {
        ...state,
        loadedHeader: false
      };
    case ADD_RECEIPT_DETAIL:
      return {
        ...state
      };
    case GET_ALLRECEIPT_HEADER:
      return {
        ...state,
        receipts: action.payload
      };
    case GET_DETAILS_HEADER: // este toma los detalles segun el id del header
      return {
        ...state,
        details: action.payload,
        loadingDetail:true
      };
    case LOADED_DETAILS:
      return {
        ...state,
        loadingDetail:false
      }
    case GET_ALLDETAILS_HEADER:
      return {
        ...state,
        details: action.payload
      };
    case DELETE_DETAILS_SALES:
      return {
        ...state,
        details: state.details.filter(details => details.id !== action.payload)
      }  
    case DELETE_SALE:
      return {
        ...state,
        receipts: state.receipts.filter(receipts => receipts.id !== action.payload)
      }  
    default:
      return state;
  }
}
