import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getCategories,
  deleteCategory,
  updateCategory
} from "../../../actions/Categories";
import MaterialTable from "material-table";

export class AllCategories extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    getCategories: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,

    updateCategory: PropTypes.func.isRequired,
    loading: PropTypes.bool
  };

  componentDidMount() {
    this.props.getCategories();
  }

  state = {
    columns: [
      { title: "Nombre", field: "name" },
      { title: "Descripcion", field: "description" }
    ]
  };

  render() {

    if (this.props.loading) {
      this.props.getCategories();
    }
    
    var allcategories = this.props.categories.map(category => ({
      key: category.id,
      name: category.name,
      description: category.description
    }));

    return (
      <React.Fragment>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />

        <MaterialTable
          title="CATEGORIAS"
          columns={this.state.columns}
          data={allcategories}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  {
                    const data = allcategories;
                    const index = data.indexOf(oldData);
                    data[index] = newData;
                    this.props.updateCategory(
                      newData.key,
                      newData.name.toUpperCase(),
                      newData.description
                    );
                  }
                  resolve();
                }, 2000);
              })
          }}
          actions={[
            {
              icon: "delete",
              tooltip: "Eliminar",
              onClick: (event, rowData) => {
                this.props.deleteCategory(rowData.key);
              }
            }
          ]}
          options={{
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF"
            },
            /*rowStyle: {
              backgroundColor: "#EEE"
            },*/
            exportButton: true,
            actionsColumnIndex: -1
          }}
          localization={{
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar"
            },
            body: {
              emptyDataSourceMessage:
                "No se encontro ninguna Categoria Relacionada"
            },
            header: {
              actions: "Acciones"
            }
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.Categories.categories,
  loading: state.Categories.loading
});

export default connect(
  mapStateToProps,
  { getCategories, deleteCategory, updateCategory }
)(AllCategories);
