import {
  REMOVE_PROFILE,
  PROFILE_LOADED,
  PROFILE_LOADING,
  LOADING_TO_TABLE,
  PICTURE_UPLOADED,
  PICTURE_UPLOADING
} from "../actions/types.js";

const initialState = {
  id: null,
  DPI: null,
  address: null,
  phone2: null,
  phone: null,
  loading: false,
  picture: null,
  isCompleted: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PICTURE_UPLOADING:
    case PROFILE_LOADING:
      return {
        ...state,
        loading: true
      };
    case LOADING_TO_TABLE:
      return {
        profileInfo: action.payload,
        loading: false
      };
    case PICTURE_UPLOADED:
    case PROFILE_LOADED:
      let _isCompleted = false;
      const { id, DPI, phone, phone2, address, picture } = action.payload[0];
      if (DPI && address) {
        _isCompleted = true;
      }
      return {
        ...state,
        id: id,
        DPI: DPI,
        phone2: phone2,
        phone: phone,
        address: address,
        picture: picture,
        isCompleted: _isCompleted,
        loading: false
      };

    case REMOVE_PROFILE:
      return {
        ...state,
        DPI: null,
        address: null,
        phone2: null,
        phone: null,
        picture: null,
        isCompleted: false,
        loading: false
      };
    default:
      return state;
  }
}
