import React, { Component } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import programar from "../../assets/programar.svg";
import analisis from "../../assets/analisis.svg";
import ViewReportsByDate from "./NewReports/ViewReportsByDate";
import moment from "moment";
import FormHelperText from "@material-ui/core/FormHelperText";
export class ReportsSales extends Component {
  handleDateChange = e => {
    const date = moment(e).format("YYYY-MM-DD");

    this.setState({ result: "", selectedDateInitial: e, inicio: date });
  };

  handleDateChangeEnd = e => {
    const date = moment(e).format("YYYY-MM-DD");
    this.setState({ result: "", selectedDateEnd: e, final: date });
  };
  state = {
    selectedDateInitial: null,
    selectedDateEnd: null,
    selectOption: "",
    opciones: [
      { label: "HOY", value: "1" },
      { label: "MES ACTUAL", value: "2" }
      //{ label: "POR AÑO ACTUAL", value: "3" }
    ],
    result: "",
    inicio: null,
    final: null,
    inicioError: "",
    finalError: "",
    rangoError: ""
  };

  handleChangeOption = e => {
    this.setState({ result: "" });
    try {
      this.setState({
        selectOption: e.value,
        selectedDateInitial: null,
        selectedDateEnd: null,
        inicio: null,
        final: null,
        result: e.value
      });
    } catch (error) {
      this.setState({ selectOption: "" });
    }
  };

  validate = () => {
    let iserror = false;
    const errors = {
      inicioError: "",
      finalError: "",
      rangoError: ""
    };
    if (this.state.inicio === null) {
      iserror = true;
      errors.inicioError = "Debe seleccionar una Fecha";
    }
    if (this.state.final === null) {
      iserror = true;
      errors.finalError = "Debe seleccionar una Fecha";
    }
    if (this.state.inicio > this.state.final) {
      iserror = true;
      errors.rangoError = "El Rango de las Fechas esta al revés verifique";
    }
    this.setState({
      ...errors
    });
    return iserror;
  };

  pushSeleccionRango = e => {
    e.preventDefault();
    const err = this.validate();
    if (!err) {
      this.setState({ result: "rango" });
    } else {
      this.setState({ result: "" });
    }
  };

  render() {
    const date = this.state.selectedDateInitial;
    const date2 = this.state.selectedDateEnd;
    const result = this.state.result;
    const inicio = this.state.inicio;
    const final = this.state.final;
    function Verify() {
      if (result === "1") {
        document.getElementById("reporte").scrollIntoView();
        return <ViewReportsByDate result={"hoy"} />;
      } else if (result === "2") {
        document.getElementById("reporte").scrollIntoView();
        return <ViewReportsByDate result={"Mes"} />;
      } else if (result === "rango") {
        document.getElementById("reporte").scrollIntoView();
        return (
          <ViewReportsByDate result={"rango"} inicio={inicio} final={final} />
        );
      }
      return "";
    }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className="row"
                style={{
                  backgroundColor: "#156796",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "7rem"
                }}
              >
                <div className="col text-center">
                  <h4>Reportes</h4>
                </div>
              </div>
              <div
                className="row pl-2"
                style={{
                  height: "100vh"
                }}
              >
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div
                    className="row pt-5"
                    style={{
                      //height: "50%",
                      flexDirection: "column",
                      justifyContent: "center"
                    }}
                  >
                    <div className="card">
                      <img
                        src={programar}
                        className="card-img-top"
                        width="100"
                        height="100"
                        alt="programar"
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center">POR FECHA</h5>

                        <div className="row">
                          <div className="col-md-6 col-sm-12 col-lg-6">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className="form-control"
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Fecha Inicial"
                                value={date}
                                onChange={date => this.handleDateChange(date)}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                helperText={this.state.inicioError}
                                error={
                                  this.state.inicioError.length === 0
                                    ? false
                                    : true
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="col-md-6 col-sm-12 col-lg-6">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                className="form-control"
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline2"
                                label="Fecha Final"
                                value={date2}
                                onChange={date =>
                                  this.handleDateChangeEnd(date)
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                helperText={this.state.finalError}
                                error={
                                  this.state.finalError.length === 0
                                    ? false
                                    : true
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <FormHelperText error={true}>
                            {this.state.rangoError}
                          </FormHelperText>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              onClick={this.pushSeleccionRango}
                            >
                              MOSTRAR
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row pt-5"
                    style={{
                      //height: "50%",
                      flexDirection: "column",
                      justifyContent: "center"
                    }}
                  >
                    <div className="card">
                      <img
                        src={analisis}
                        className="card-img-top"
                        width="100"
                        height="100"
                        alt="analisis"
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          POR SELECCION
                        </h5>
                        <div className="row pt-4">
                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <Select
                              defaultValue={{
                                label: "Selecciona Fecha",
                                value: "0"
                              }}
                              onChange={this.handleChangeOption}
                              options={this.state.opciones}
                              isClearable={false}
                              id="seleccion"
                              isSearchable={false}
                            />
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-12 col-sm-12 col-lg-12"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-8 col-lg-8 col-md-12 col-sm-12"
                  id="reporte"
                >
                  <div className="row justify-content-center align-items-center h-100 pt-5">
                    <div className="col text-center">
                      <Verify />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportsSales;
