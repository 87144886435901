import React, { Component, Fragment } from "react";

class NewVaccine extends Component {
  render() {
    return (
      <Fragment>
        <form>
          <div class="form-group">
            <label for="exampleFormControlInput1">{this.props.label}</label>
            <input
              type="text"
              class="form-control"
              id="VaccineTextField"
              name="vaccine"
              placeholder={this.props.placeholder}
              onChange={this.props.onChange}
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Observaciones</label>
            <textarea
              class="form-control"
              name="observaciones"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={this.props.onChange}
            ></textarea>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default NewVaccine;
