import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
/*import Loading from "./Loading";*/

/*function arrayRemove(arr, value) {
  return arr.filter(function(ele) {
    return ele !== value;
  });
}*/

class Dateresume extends Component {
  state = {
    veterinary: "",
    pet: "",
    date: "",
    time: "",
  };

  times = this.props.times;

  static propTypes = {
    schedule: PropTypes.array,
  };

  render() {
    let { veterinary, pet, date } = this.props;
    return (
      <React.Fragment>
        <form>
          <small id="emailHelp" className="form-text text-muted">
            Detalle de la cita
          </small>

          <div className="form-row align-items-center">
            <div className="col">
              <label className="sr-only" htmlFor="inlineFormInput">
                El doctor
              </label>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="fas fa-user-md"></i>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="Te atiende"
                  value={veterinary || ""}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="form-row align-items-center">
            <div className="col">
              <label className="sr-only" htmlFor="inlineFormInputGroup">
                Atendera a tu mascota
              </label>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="fas fa-dog"></i>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="Mascota"
                  readOnly
                  value={pet || ""}
                />
              </div>
            </div>
          </div>
          <div className="form-row align-items-center">
            <div className="col">
              <label className="sr-only" htmlFor="inlineFormInputGroup">
                El dia
              </label>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="far fa-calendar-check"></i>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="YYYY-MM-DD"
                  value={date || ""}
                  readOnly
                />
              </div>
            </div>
          </div>
          <small id="emailHelp" className="form-text text-muted">
            Horarios disponibles para el dia solicitado
          </small>

          <div className="form-row align-items-center">
            <div className="col">
              <label className="sr-only" htmlFor="inlineFormInputGroup">
                A las
              </label>

              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="far fa-clock"></i>
                  </div>
                </div>

                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={this.props.onChange}
                  name="apointment"
                >
                  <option selected>Elige una opción...</option>
                  {(this.times = this.props.times)}
                  {this.times.map((time) => {
                    return <option value={time}>{time}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  schedule: state.RedApoint.schedule,
});

export default connect(mapStateToProps)(Dateresume);
